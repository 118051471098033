import React, { Dispatch, SetStateAction, useState } from 'react'
// Local
import Card from '../../v2/card'
import { conditions as conditionsUtils } from '../../common/conditionUtils'
import If from '../../common/if'
import Accordion from '../../v2/accordion'
import Condition from './condition'
import { KnownAndPotential } from '../../v2/processConditions'
import { EncounterConditionDisplayModified, TagStatus } from '../../authenticated/types'

type Props = {
  conditions: KnownAndPotential[]
  tagsClicked: Record<string, TagStatus>
  setTagsClicked: Dispatch<SetStateAction<Record<string, TagStatus>>>
}

function ClinicalConditions(props: Props): JSX.Element {
  const { conditions, tagsClicked, setTagsClicked } = props

  const renderedConditions = conditions.map(
    (c: KnownAndPotential, index: number) => {
      const areAllConditionsPotential = conditionsUtils.areAllPotential(
        c.conditions
      )

      const [ required, setRequired ] = useState<number>(c.required)

      return (
        <>
          <If
            isTrue={ c.conditions.length > 1 && c.title !== 'Other Conditions' }
            key={ c.title }
          >
            <Accordion
              id={ `clinical-condition-${index + 1}` }
              title={ c.title }
              isRequired={ required > 0 }
              isAddressed={ required === 0 && !!c.knownAddressed }
              clinicalConditions={ c.conditions }
            >
              { c.conditions.map(
                (
                  subCondition: EncounterConditionDisplayModified,
                  nestedIndex: number
                ): JSX.Element => (
                  <Condition
                    id={ `condition-${index + 1}-nested-${nestedIndex + 1}` }
                    key={ subCondition.id }
                    conditions={ c.conditions }
                    condition={ subCondition }
                    isStandAloneCondition={ false }
                    areAllConditionsInGroupPotential={ areAllConditionsPotential }
                    required={ required }
                    setRequired={ setRequired }
                    tagsClicked={ tagsClicked }
                    setTagsClicked={ setTagsClicked }
                  />
                )
              ) }
            </Accordion>
          </If>

          <If
            isTrue={ c.title === 'Other Conditions' || c.conditions.length === 1 }
          >
            { c.conditions.map(
              (
                subCondition: EncounterConditionDisplayModified,
                nestedIndex: number
              ) => (
                <Card
                  key={ subCondition.id }
                  id={ `clinical-condition-${index + 1}` }
                >
                  <Condition
                    id={ `condition-${index + 1}-nested-${nestedIndex + 1}` }
                    conditions={ c.conditions }
                    condition={ subCondition }
                    isStandAloneCondition={ true }
                    areAllConditionsInGroupPotential={ areAllConditionsPotential }
                    required={ required }
                    setRequired={ setRequired }
                    tagsClicked={ tagsClicked }
                    setTagsClicked={ setTagsClicked }
                  />
                </Card>
              )
            ) }
          </If>
        </>
      )
    }
  )

  return <>{ renderedConditions }</>
}

export default ClinicalConditions
