import React from 'react'
import { Button, Icon, Table } from 'semantic-ui-react'

type Props = {
  columns: number
  onMore: () => void
}

function MoreTableFooter(props: Props) {
  const { columns, onMore } = props
  return (
    <Table.Footer>
      <Table.Row>
        <Table.HeaderCell colSpan={ columns }>
          <Button
            className="table-button"
            color="blue"
            size="small"
            onClick={ onMore }
          >
            <Icon name="arrow down" />Load More Records
          </Button>
        </Table.HeaderCell>
      </Table.Row>
    </Table.Footer>
  )
}

export default MoreTableFooter
