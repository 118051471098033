import React, { Component } from "react";
import { connect } from "react-redux";
import { Container, Grid, Icon, Modal, Segment, Popup } from "semantic-ui-react";
import concat from "lodash/concat";
import { addDays, addMinutes, format, isAfter, isBefore, startOfDay, subMinutes } from "date-fns";
import { formatDob } from '../../common/formatUtils'
import { API_URL, ENCOUNTER, FORMAT, LIST_MAX_PAGES, LIST_PAGE_SIZE, PATIENT, REVIEW } from "../../../constant";
import { api } from "../../../action/api";
import { searchGenerator } from "../../../action/search";
import { DatePickerIcon } from "../../common/datePickerIcon"
import PdfHuddleReport from "../../common/pdfHuddleReport";
import SubHeader from "../../common/subHeader";
import Table from "../../common/table";
import "./physicianWorklist.css";
import { showVbcDisplay } from "../../common/currentUserUtils";

/**
 * Provides a worklist view of encounters for the Physician user role
 * @param onSelect Callback function to execute when an encounter is selected
 *        arguments: Encounter ID
 * @param practitionerId: ID of current practitioner
 * @param viewDate Override default worklist view date with a specific date
 */
class PhysicianWorklist extends Component {

  constructor(props) {
    super(props);

    const viewDate = this.props.viewDate
      ? startOfDay(new Date(this.props.viewDate))
      : startOfDay(new Date());

    this.state = {
      viewDate: viewDate,
      practitionerId: this.props.practitionerId,
      tenantId: this.props.currentUser.tenantId ? this.props.currentUser.tenantId : "",
      encounters: [],
      pagesDisplayed: 0,
      encounterSort: "start asc",
      userRole: this.props.currentUser.userRole
    }
  }

  componentDidMount() {
    const offset = 0;
    const practitionerId = this.state.practitionerId;
    if ( practitionerId ) {
      this.fetchEncounters(this.state.viewDate, practitionerId, offset);
    }
  }

  fetchEncounters(viewDate, practitionerId, offset) {
    const searchQuery = {
      start: viewDate,
      patientStatus: PATIENT.STATUS.ACTIVE,
      practitionerId: practitionerId,
      reviewCode: [
        REVIEW.CODE.READY_FOR_PHYSICIAN,
        REVIEW.CODE.READY_FOR_CODER
      ],
      encounterStatus: [
        ENCOUNTER.STATUS.PLANNED,
        ENCOUNTER.STATUS.ARRIVED,
        ENCOUNTER.STATUS.IN_PROGRESS,
        ENCOUNTER.STATUS.FINISHED
      ]
    };

    const sort = this.state.encounterSort;
    const url = API_URL.ENCOUNTER_SEARCH_WITH_ROLE(this.state.userRole);
    const limit = offset === 0 ? LIST_PAGE_SIZE + 1 : LIST_PAGE_SIZE;
    const params = searchGenerator(searchQuery, limit, offset, sort);

    api.get(url, { params: params }).then(({ data }) => {
      const results = offset === 0 ? data : concat(this.state.encounters, data);
      const pages = offset === 0 ? 1 : this.state.pagesDisplayed + 1;
      this.setState({
        viewDate: viewDate,
        encounters: results,
        practitionerId: practitionerId,
        pagesDisplayed: pages
      });
    });
  }

  dateTitle() {
    const date = this.state.viewDate;

    if (date.getTime() === startOfDay(new Date()).getTime())
      return "Today's Patients";
    else if (date.getTime() === addDays(startOfDay(new Date()), 1).getTime())
      return "Tomorrow's Patients";
    else if (date.getTime() === addDays(startOfDay(new Date()), -1).getTime())
      return "Yesterday's Patients";
    else if (date.getTime() < new Date())
      return "Patients from " + format(date, FORMAT.DAY_DATE);
    else return "Patients on " + format(date, FORMAT.DAY_DATE);
  }

  mobilePatientName(patient) {
    return patient.firstName + " " + patient.lastName +
      " ( " + formatDob(patient.birthDate, FORMAT.DATE) + " ) ";
  }

  rowClassName(startDate) {
    const baseClass = "cursor-pointer";
    const viewDate = this.state.viewDate;
    const onSchedule = viewDate.getTime() === startOfDay(new Date()).getTime();
    const encounterRange = 30;

    if ( !onSchedule ) {
      return baseClass;
    }

    const lowerLimit = subMinutes(new Date(), encounterRange);
    const upperLimit = addMinutes(new Date(), encounterRange);

    if (isBefore(startDate, lowerLimit)) {
      return baseClass + " past";
    } else if (isAfter(startDate, upperLimit)) {
      return baseClass + " future";
    } else {
      return baseClass + " current";
    }
  }

  generateTableData(list) {
    if ( !list || list.length === 0 ) return [];
    return list.map(record => {
      const time = format(record.start, FORMAT.TIME);
      const name = `${record.patient.firstName} ${record.patient.lastName}`;
      const nameDob = this.mobilePatientName(record.patient);
      const { vbcProgram } = record.patient;
      const practitioner = `${record.practitioner.firstName} ${record.practitioner.lastName}`;
      return {
        id: record.id,
        time,
        name,
        dob: formatDob(record.patient.birthDate, FORMAT.DATE),
        nameDob: nameDob,
        vbcProgram,
        practitioner,
        meta: {
          className: this.rowClassName(record.start)
        }
      };
    });
  }

  incrementViewDate = () => {
    this.changeDay(addDays(this.state.viewDate, 1));
  };

  decrementViewDate = () => {
    this.changeDay(addDays(this.state.viewDate, -1));
  };

  onDateSelect = (value) => {
    this.changeDay(value);
  };

  changeDay = newDate => {
    const offset = 0;
    this.fetchEncounters(newDate, this.state.practitionerId, offset);
  };

  onMore = () => {
    const viewDate = this.state.viewDate;
    const practitionerId = this.state.practitionerId;
    const pages = this.state.pagesDisplayed;
    const offset = LIST_PAGE_SIZE * pages + 1;
    this.fetchEncounters(viewDate, practitionerId, offset);
  }

  selectRecord = (id, event) => {
    if ( this.props.onSelect && id ) {
      this.props.onSelect(id, event);
    }
  }

  leftSubHeader() {
    return this.dateTitle();
  }

  rightSubHeader() {
    const practitionerId = this.state.practitionerId;
    const encounterIds = this.state.encounters.map(item => { return item.id; });
    return (
      <Grid>
        <Grid.Row style={{ paddingBottom: "0rem" }}>
          <Grid.Column floated="right" align="right">
            <span onClick={this.decrementViewDate}>
              <Popup content="Previous Day" mouseEnterDelay={1000}
                trigger={
                  <Icon
                    name="arrow alternate circle left outline"
                    size="large" color="grey" className="pointer" />} />
            </span>
            <span>
              <DatePickerIcon onSelect={this.onDateSelect.bind(this)}
                popperClassName="datePickerIcon-popper-left-adjust" />
            </span>
            <span onClick={this.incrementViewDate}>
              <Popup content="Next Day" mouseEnterDelay={1000}
                trigger={
                  <Icon
                    name="arrow alternate circle right outline"
                    size="large" color="grey" className="pointer" />} />
            </span>
            {encounterIds.length === 0 && (
              <span>
                <Icon size="large" className="empty" />
              </span>
            )}
            {encounterIds.length > 0 && (
              <span>
                <Popup content="Daily Huddle PDF" mouseEnterDelay={1000}
                  trigger={
                    <Modal
                      size="fullscreen"
                      trigger={<Icon name="print" size="large" color="grey"
                        className="pointer" />}>
                      <Modal.Content>
                        <PdfHuddleReport startDt={this.state.viewDate}
                          practitionerId={practitionerId} />
                      </Modal.Content>
                    </Modal>
                  } />
              </span>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }

  renderWorklistTable = () => {
    const tableData = this.generateTableData(this.state.encounters);
    const pages = this.state.pagesDisplayed;
    let computerColumns = [
      { name: "Time", value: "time"},
      { name: "Name", value: "name"},
      { name: "DOB", value: "dob"},
      { name: "Practitioner", value: "practitioner"}
    ];
    let displayMore = false;
    let displayMaxRecords = false;

    if ( tableData.length > pages * LIST_PAGE_SIZE ) {
      displayMore = pages < LIST_MAX_PAGES;
      displayMaxRecords = pages >= LIST_MAX_PAGES;
      tableData.pop();
    }

    const table = (
      <Table
        computerColumns={computerColumns}
        mobileColumns={[
          { value: "time", width: "4" },
          { value: "nameDob", width: "12" }
        ]}
        data={tableData}
        onClick={this.selectRecord}
        more={displayMore}
        max={displayMaxRecords}
        onMore={this.onMore}
      />
    );

    if (showVbcDisplay(this.props.currentUser)) {
      computerColumns.splice(3, 0, { name: "VBC Program", value: "vbcProgram"});
      return <span>{table}</span>;
     }
    return <span>{table}</span>;
  }

  render() {
    return (
      <div className="header-padding">
        <Container>
          <SubHeader left={this.leftSubHeader()} right={this.rightSubHeader()} />
          <Segment attached className="no-margin">
            <Grid>
              <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={16}>
                 {this.renderWorklistTable()}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        </Container>
      </div>
    );
  }
}

export default connect(
  state => ({
    currentUser: state.user
  }), {}
)(PhysicianWorklist);
