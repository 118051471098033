import React, { Component } from "react";
import { Segment } from "semantic-ui-react";
import { ENCOUNTER } from "../../../constant";

/**
 * Provides a component that renders the encounter status
 * @param encounter Encounter entity
 * @param mode Render mode. Default: left
 */
class EncounterStatus extends Component {

  statusText(status) {
    if ( status === ENCOUNTER.STATUS.CANCELLED ) {
      return (<div className="left-content-red">{status}</div>);
    }
    if ( status === ENCOUNTER.STATUS.IGNORED ) {
      return (<div className="left-content-red">{status}</div>);
    }
    return (<div className="left-content">{status}</div>);
  }

  renderLeft(encounter) {
    return (
      <Segment attached compact className="left-segment">
        <div className="left-title">Encounter Status:</div>
        {this.statusText(encounter.status)}
      </Segment>
    );
  }

  render() {
    const mode = this.props.mode ? this.props.mode : "left";
    if ( mode === "left" ) {
      return this.renderLeft(this.props.encounter);
    } else {
      return null;
    }
  }
}

export default EncounterStatus;
