import React, { Component } from "react";
import { Segment } from "semantic-ui-react";

/**
 */
class NavSpacer extends Component {

  render() {
    const height = this.props.height ? this.props.height : "64px";
    const mode = this.props.mode === "segment" ? "segment" : "empty";
    const style = {
      height: height
    };

    if ( mode === "segment" ) {
      return (
        <Segment attached compact className="left-segment">
          <div style={style}></div>
        </Segment>
      );
    } else {
      return (<div style={style}></div>);
    }
  }
}

export default NavSpacer
