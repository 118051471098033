import React, { Dispatch, SetStateAction } from 'react'
import { Button } from 'semantic-ui-react'
import { addMinutes } from 'date-fns'
import scheduleEncounter from './scheduleEncounter'
import { DEFAULT_PRACTITIONER } from '../../../constant'

type ScheduleButtonProps = {
  encounterId: string
  label: string
  setIsModalShowing: Dispatch<SetStateAction<boolean>>
  setEncounterIdState: Dispatch<SetStateAction<string>>
  isScheduleCallBeingMade: boolean,
  setIsScheduleCallBeingMade: Dispatch<SetStateAction<boolean>>,
  isOptum: boolean
}

// starting from now, return the next Date at the 0 or 30 minute mark
function findNextThirtyMinuteSlot(): Date {
  const now = new Date();
  const minutes = now.getMinutes();
  if (minutes in [0, 30]) {
     return now
  }
  if (minutes < 30) {
    return addMinutes(now, 30 - minutes)
  }
  return addMinutes(now, 60 - minutes)
}

function ScheduleButton(props: ScheduleButtonProps) {
  const {
    encounterId,
    label,
    setIsModalShowing,
    setEncounterIdState,
    isScheduleCallBeingMade,
    setIsScheduleCallBeingMade,
    isOptum
  } = props
  return (
    <Button
      compact
      content={ label }
      disabled={ isScheduleCallBeingMade }
      onClick={ () => {
        if( isOptum ){
          const timeSlot = findNextThirtyMinuteSlot()
          scheduleEncounter({
            setIsScheduleCallBeingMade,
            practitionerId: DEFAULT_PRACTITIONER.OPTUM,
            setEncounterIdState,
            encounterId: encounterId,
            date: timeSlot
          })
        } else {
          setEncounterIdState(encounterId)
          setIsModalShowing(true)
        }
      } }
    />
  )
}

export default ScheduleButton
