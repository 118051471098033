/* eslint-disable class-methods-use-this */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react'
import { Container, Grid, Header, Icon, Image, Menu, Segment } from 'semantic-ui-react'
import { API_URL, ERROR } from '../../constant'
import { api } from '../../action/api'
import UserRegistrationForm from './userRegistrationForm'
import './index.css'

/**
 * Provides a form for new user registration. Component properties should
 * include a path parameter that is a user registration token. Attempts to
 * fetch the unregistered user data with the token
 * @param token Path parameter containing a user registration token
 */
export class UserRegistration extends Component {

  constructor(props) {
    super(props)
    this.state = {
      unregisteredUser: null,
      errors: [],
      success: null
    }
  }

  componentDidMount() {
    if ( this.props.match && this.props.match.params.token ) {
      const {token} = this.props.match.params
      this.fetchUnregisteredUser(token)
    }
  }

  onSaveUserRegister(user) {
    const {token} = this.props.match.params
    const url = API_URL.UNREGISTERED_USER(token)
    api.post(url, user).then(({ data }) => {
      this.setState({
        errors: [],
        success: data
      })
    })
  }

  fetchUnregisteredUser(token) {
    const url = API_URL.UNREGISTERED_USER(token)
    api.get(url).then(
      ({data}) => {
        this.setState({
          unregisteredUser: data,
          errors: [],
          success: null
        })
      }
    )
      .catch(() => {
        this.setState({
          unregisteredUser: null,
          errors: [ ERROR.TYPE.EXPIRED_TOKEN ],
          success: false
        })
      })
  }

  renderSuccess(user) {
    return (
      <Grid centered columns={ 1 }>
        <Grid.Row>
          <Grid.Column mobile={ 16 } tablet={ 16 } computer={ 16 }>
            <center>
              <a href="https://curationhealthcare.com/">
                <Image src="/asset/image/curation-logo-caption.png" />
              </a>
            </center>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column mobile={ 16 } tablet={ 16 } computer={ 16 }>
            <center>
              <span><a href="/">Platform Login</a></span>
              <span> | </span>
              <span><a href="https://curationhealthcare.com/">Company Website</a></span>
            </center>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column mobile={ 16 } tablet={ 16 } computer={ 16 }>
            <div className="m-1-top">
              <h1>Thank you!</h1>
            </div>
            <div className="m-1-top">
              Your user registration is complete.
            </div>
            <div className="m-1-top">
              <span>You can sign into</span>
              <span> <a href="/">the platform</a></span>
              <span> using either your</span>
              <span> username: <strong>{ user.username }</strong></span>
              <span> or</span>
              <span> email address: <strong>{ user.email }</strong></span>
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    )
  }

  renderForm() {
    return (
      <div>
        <Header as="h4" attached="top" block className="main-schedule-header">
          <Icon name="users" /> New User Registration
        </Header>
        <Segment attached className="no-margin">
          <Grid>
            <Grid.Column mobile={ 16 } tablet={ 16 } computer={ 16 }>
              <UserRegistrationForm
                user={ this.state.unregisteredUser }
                token={ this.props.match.params.token }
                onSave={ this.onSaveUserRegister.bind(this) } />
            </Grid.Column>
          </Grid>
        </Segment>
      </div>
    )
  }

  renderEmpty() {
    return (
      <Grid centered columns={ 1 }>
        <Grid.Row>
          <Grid.Column mobile={ 16 } tablet={ 16 } computer={ 16 }>
            <center>
              <a href="https://curationhealthcare.com/">
                <Image src="/asset/image/curation-logo-caption.png" />
              </a>
            </center>
          </Grid.Column>
        </Grid.Row>
        { this.state.errors && this.state.errors.includes(ERROR.TYPE.EXPIRED_TOKEN) &&
          <Grid.Row>
            <Grid.Column mobile={ 16 } tablet={ 16 } computer={ 16 }>
              <center>
                <strong><h3>Your registration token expired.</h3></strong>
                <p>Please contact CurationHealth Client Success to receive a new registration token.</p>
              </center>
            </Grid.Column>
          </Grid.Row>
        }
        <Grid.Row>
          <Grid.Column mobile={ 16 } tablet={ 16 } computer={ 16 }>
            <center>
              <span><a href="/">Platform Login</a></span>
              <span> | </span>
              <span><a href="https://curationhealthcare.com/">Company Website</a></span>
            </center>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    )
  }

  render() {
    const success = this.state.success ? this.state.success : null
    let content = null
    if ( success ) {
      content = this.renderSuccess(success)
    } else if ( this.state.unregisteredUser ) {
      content = this.renderForm(this.state.unregisteredUser)
    } else {
      content = this.renderEmpty()
    }
    return (
      <div>
        <Menu borderless>
          <Menu.Item>
            <Image size="small" src="/asset/image/curation-logo-no-tag.png" />
          </Menu.Item>
        </Menu>
        <div className="header-padding">
          <Container>
            { content }
          </Container>
        </div>
      </div>
    )
  }
}

export default UserRegistration

