import React, {
  Dispatch,
  SetStateAction,
  SyntheticEvent,
  useCallback,
  useEffect,
  useState
} from 'react'
import { ascend, prop, sortWith } from 'ramda'
import {
  Button,
  DropdownProps,
  Form,
  Search,
  SearchProps
} from 'semantic-ui-react'
import { format } from 'date-fns'
import DatePicker from 'react-datepicker'
// Local
import { FORMAT, API_URL } from '../../../constant'
import scheduleEncounter, { ScheduleEncounterProps } from './scheduleEncounter'
import { searchGenerator } from '../../../action/search'
import { api } from '../../../action/api'
import Calendar from '../../icons/calendar'
import SearchIcon from '../../icons/search'
import If from '../if'

const now = new Date().setHours(0, 0, 0, 0)

const dateFormat = FORMAT.DATE

type AppointmentScheduleFlowProps = {
  encounterIdState: string
  setEncounterIdState: Dispatch<SetStateAction<string>>
  setIsScheduleCallBeingMade: Dispatch<SetStateAction<boolean>>
  practitionerId: string
  setIsModalShowing: Dispatch<SetStateAction<boolean>>
  scheduleEncounter: (x: ScheduleEncounterProps) => Promise<void>
}

type PractitionerSearch = {
  id: string
  tenantId: string
  identifier: string
  lastName: string
  prefix: string | null
  firstName: string
  given2: string | null
  suffix: string | null
}

type PractitionerResult = {
  key: string
  id: string
  title: string
  description: string
}

enum AMPMEnum {
  AM = 'AM',
  PM = 'PM'
}

const hourOptions = [
  {
    key: '12',
    value: '12',
    text: '12'
  },
  {
    key: '1',
    value: '1',
    text: '1'
  },
  {
    key: '2',
    value: '2',
    text: '2'
  },
  {
    key: '3',
    value: '3',
    text: '3'
  },
  {
    key: '4',
    value: '4',
    text: '4'
  },
  {
    key: '5',
    value: '5',
    text: '5'
  },
  {
    key: '6',
    value: '6',
    text: '6'
  },
  {
    key: '7',
    value: '7',
    text: '7'
  },
  {
    key: '8',
    value: '8',
    text: '8'
  },
  {
    key: '9',
    value: '9',
    text: '9'
  },
  {
    key: '10',
    value: '10',
    text: '10'
  },
  {
    key: '11',
    value: '11',
    text: '11'
  }
]

const minuteOptions = [
  {
    key: '00',
    value: '00',
    text: '00'
  },
  {
    key: '05',
    value: '05',
    text: '05'
  },
  {
    key: '10',
    value: '10',
    text: '10'
  },
  {
    key: '15',
    value: '15',
    text: '15'
  },
  {
    key: '20',
    value: '20',
    text: '20'
  },
  {
    key: '25',
    value: '25',
    text: '25'
  },
  {
    key: '30',
    value: '30',
    text: '30'
  },
  {
    key: '35',
    value: '35',
    text: '35'
  },
  {
    key: '40',
    value: '40',
    text: '40'
  },
  {
    key: '45',
    value: '45',
    text: '45'
  },
  {
    key: '50',
    value: '50',
    text: '50'
  },
  {
    key: '55',
    value: '55',
    text: '55'
  }
]

const sortByLastName = sortWith<PractitionerSearch>([ ascend(prop('lastName')) ])

const params = searchGenerator({ statusCode: 'ACTIVE' }, 999, 0, '', undefined)

const has = (x: string, name: string): boolean =>
  name.toLowerCase().includes(x.toLowerCase())

const containsUserInput = (x: string, practitioner: PractitionerSearch) =>
  has(x, practitioner.firstName) || has(x, practitioner.lastName)

function AppointmentScheduleFlow(props: AppointmentScheduleFlowProps) {
  const { setIsScheduleCallBeingMade, setEncounterIdState, encounterIdState } =
    props
  const [ dateState, setDateState ] = useState<string>(
    format(new Date(), dateFormat)
  )
  const [ hour, setHour ] = useState<string>('12')
  const [ minutes, setMinutes ] = useState<string>('00')
  const [ AMPM, setAMPM ] = useState<AMPMEnum>(AMPMEnum.AM)
  const [ practitionerIdState, setPractitionerIdState ] = useState<string>('')

  const [ searchResults, setSearchResults ] = useState<PractitionerSearch[]>([])
  const [ filteredSearchResults, setFilteredSearchResults ] = useState<
    PractitionerSearch[]
  >([])

  const [ isSearchOpen, setIsSearchOpen ] = useState<boolean>(false)

  // const [ isLoading, setIsLoading ] = useState<boolean>(false)

  const getPractitioners = useCallback(async () => {
    const { data } = await api.get(API_URL.PRACTITIONER_SEARCH('false'), {
      params
    })
    setSearchResults(data as PractitionerSearch[])
    setFilteredSearchResults(data as PractitionerSearch[])
  }, [ setSearchResults, setFilteredSearchResults ])

  useEffect(() => {
    getPractitioners()
  }, [ getPractitioners ])

  /**
   * Let's leave this commented out for now since it is a good example of how to
   * do a debounced function using lodash and useCallback
   */
  // const debouncedSearch = useCallback(
  //   debounce(
  //     (searchQuery: string | undefined) => {
  //       const query = { active: 'true' }
  //       const limit = 999
  //       const offset = 0
  //       const params = searchGenerator(query, limit, offset, '', undefined)
  //       api.get(searchURL(searchQuery), { params }).then(({ data }) => {
  //         setSearchResults(data as PractitionerSearch[])
  //         setIsLoading(false)
  //       })
  //     },
  //     500,
  //     { leading: false, trailing: true }
  //   ),
  //   []
  // )

  const [ practitionerValue, setPractitionerValue ] = useState<
    string | undefined
  >('')

  const onChange = (data: SearchProps) => {
    setPractitionerValue(data.value)
    if (!data || !data.value || !data.value.length) {
      setPractitionerIdState('')
      setFilteredSearchResults(searchResults)
      return
    }
    const filteredResults = searchResults.filter(practitioner =>
      containsUserInput(data.value as string, practitioner))

    setFilteredSearchResults(filteredResults)
    // setIsLoading(true)
    // debouncedSearch(data.value)
  }

  const onResultSelect = (practitioner: PractitionerResult) => {
    setIsSearchOpen(false)
    setPractitionerValue(practitioner.description)
    setPractitionerIdState(practitioner.id)
  }

  const [ isCalendarOpen, setIsCalendarOpen ] = useState<boolean>(false)

  const dateRegex = /\d{1,2}\/\d{1,2}\/\d{4}/

  const isValidDate = (date: string | Date | undefined | null) => {
    const isValidDateFormat = dateRegex.test(date as string)
    if (!isValidDateFormat) return false
    return date ? new Date(date).setHours(0, 0, 0, 0) >= now : false
  }

  return (
    <div className="cdi-flow">
      <h1>Schedule Appointment</h1>
      <div>
        <div className="form-label">Practitioner</div>
        <div className="search-container">
          <SearchIcon
            size={ 18 }
            onClick={ () => setIsSearchOpen(!isSearchOpen) }
          />
          { /* <If isTrue={ isLoading }>
            <Spinner size={ 18 } />
          </If> */ }
          <Search
            className="practitioner-search-select"
            name="practitioner-search-select"
            placeholder="Search Practitioners"
            onFocus={ () => setIsSearchOpen(true) }
            open={ isSearchOpen }
            value={ practitionerValue }
            onResultSelect={ (e, data) =>
              onResultSelect(data.result as PractitionerResult)
            }
            // loading={ isLoading }
            onSearchChange={ (e, data: SearchProps) => onChange(data) }
            results={ sortByLastName(filteredSearchResults).map(
              (practitioner: PractitionerSearch): PractitionerResult => ({
                key: practitioner.id,
                id: practitioner.id,
                title: '',
                description: `${practitioner.lastName}, ${practitioner.firstName}`
              })
            ) }
            // showNoResults={ !isLoading && !searchResults.length }
            showNoResults={ !searchResults.length }
            aligned="left"
            icon={ null }
            fluid={ true }
          />
        </div>
      </div>
      <div>
        <div className="form-label">Date</div>
        <div className="date-container">
          <Calendar
            size={ 18 }
            onClick={ () => setIsCalendarOpen(!isCalendarOpen) }
          />
          <DatePicker
            /**
             * onChange is required but we aren't using it
             * We use the onChangeRaw prop to change values on the input
             */
            onChange={ () => null }
            disabledKeyboardNavigation={ true }
            value={ dateState }
            minDate={ new Date() }
            isClearable={ true }
            onChangeRaw={ e => setDateState(e.target.value) }
            // onClickOutside={ () => setIsCalendarOpen(false) }
            onSelect={ (date: Date): void => {
              if (isCalendarOpen) {
                setDateState(date ? format(new Date(date), dateFormat) : '')
                setIsCalendarOpen(false)
              }
            } }
            open={ isCalendarOpen }
            customInput={
              <input
                type="text"
                className="cdi-date-input"
                placeholder="Date (mm/dd/yy)"
              />
            }
          />
        </div>
        <If isTrue={ !isValidDate(dateState) }>
          <div className="date-error">Invalid Date</div>
        </If>
      </div>
      <div>
        <div className="form-label">Time</div>
        <div className="cdi-flow-time">
          <Form.Select
            name="hours"
            options={ hourOptions }
            onChange={ (
              e: React.SyntheticEvent<HTMLElement, Event>,
              data: DropdownProps
            ) => {
              setHour(data.value as string)
            } }
            defaultValue={ hour }
          />
          <div className="colon">:</div>
          <Form.Select
            options={ minuteOptions }
            onChange={ (
              e: React.SyntheticEvent<HTMLElement, Event>,
              data: DropdownProps
            ) => {
              setMinutes(data.value as string)
            } }
            defaultValue={ minutes }
          />
          <Button
            onClick={ () =>
              setAMPM(AMPM === AMPMEnum.AM ? AMPMEnum.PM : AMPMEnum.AM)
            }
          >
            { AMPM }
          </Button>
        </div>
      </div>
      <Button
        disabled={ !practitionerIdState || !isValidDate(dateState) }
        className="schedule-button"
        onClick={ () => {
          scheduleEncounter({
            setIsScheduleCallBeingMade,
            practitionerId: practitionerIdState,
            setEncounterIdState,
            encounterId: encounterIdState,
            date: new Date(`${dateState} ${hour}:${minutes} ${AMPM}`)
          })
        } }
      >
        Schedule
      </Button>
    </div>
  )
}

export default AppointmentScheduleFlow
