import React, { Component } from "react";
import { connect } from "react-redux";
import { condition as conditionUtils }  from "../../common/conditionUtils";
import { encounterPractitionerTagsEnabled } from "../../common/currentUserUtils"
import { formatConditionTitleExt } from "../../common/formatUtils";
import AddressedIcon from "../common/addressedIcon";
import ConditionDismissed from "../common/conditionDismissed";
import PhysicianConditionHeaderToolbar from "./physicianConditionHeaderToolbar";

/**
 * Provides a component for the physician condition header title. The title
 * can include a toolbar and condition properties
 *
 * @param mode View mode. Options: null or "smart". Default: null
 * @param active True if condition is currently in active state
 * @param hccChild True if condition is a member of an HCC display group
 * @param hccAddressed True if condition is a member of an HCC display group
 *        and the group is marked as addressed
 * @param condition Condition entity
 * @param onConditionAction Callback for condition action
 *        Arguments: action, condition
 */
class PhysicianConditionHeaderTitle extends Component {

  renderSmart() {
    const condition = this.props.condition;
    const currentUser = this.props.currentUser;
    const isHccChild = this.props.hccChild;
    const isActive = this.props.active && this.props.active === true;
    const titleText = formatConditionTitleExt(condition, this.props);
    const showToolbar = encounterPractitionerTagsEnabled(currentUser) &&
      !isHccChild && !conditionUtils.isConditionAddressed(condition);
    return (
      <div className="physician-condition-title">
        <div className="flex-title">
          <AddressedIcon condition={condition} />
          <div className="condition-title">
            {isActive ? <strong>{titleText}</strong> : titleText}
            <div className="condition-toolbar">
              <ConditionDismissed condition={condition} />
            </div>
          </div>
        </div>
        {showToolbar && (
          <div className="m-1-top">
            <PhysicianConditionHeaderToolbar
              mode={this.props.mode}
              condition={condition}
              onConditionAction={this.props.onConditionAction} />
          </div>
        )}
      </div>
    );
  }

  renderDefault() {
    const condition = this.props.condition;
    const currentUser = this.props.currentUser;
    const isHccChild = this.props.hccChild;
    const isActive = this.props.active && this.props.active === true;
    const titleText = formatConditionTitleExt(condition, this.props);
    const showToolbar = encounterPractitionerTagsEnabled(currentUser) &&
      !isHccChild && !conditionUtils.isConditionAddressed(condition);
    return (
      <div className="physician-condition-title">
        <div className="flex-title">
          <AddressedIcon condition={condition} />
          <div className="condition-title">
            {isActive ? <strong>{titleText}</strong> : titleText}
          </div>
          <div className="condition-toolbar">
            {!showToolbar && (
              <ConditionDismissed condition={condition} />
            )}
            {showToolbar && (
              <PhysicianConditionHeaderToolbar
                mode={this.props.mode}
                condition={condition}
                onConditionAction={this.props.onConditionAction} />
            )}
          </div>
        </div>
      </div>
    );
  }

  render() {
    return this.props.mode && this.props.mode === "smart"
      ? this.renderSmart() : this.renderDefault();
  }
}

export default connect(
  state => ({
    currentUser: state.user
  }), {}
)(PhysicianConditionHeaderTitle);
