import React from 'react'
// Local
import { IconProps } from './iconProps'

type Props = IconProps & {
  onClick: () => void
}

function Search(props: Props): JSX.Element {
  const { size = 24, color = '#000000', onClick } = props
  return (
    <svg
      className="search"
      width={ size }
      height={ size }
      viewBox="0 0 24 24"
      fill="none"
      stroke={ color }
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      onClick={ onClick }
    >
      <circle cx="11" cy="11" r="8" />
      <line x1="21" y1="21" x2="16.65" y2="16.65" />
    </svg>
  )
}

export default Search
