export enum StatusCode {
  Active = 'ACTIVE',
  Disabled = 'DISABLED',
  Hidden = 'HIDDEN',
  Removed = 'REMOVED'
}

export enum ConditionType {
  Known = 'KNOWN',
  Potential = 'POTENTIAL',
  Activity = 'ACTIVITY'
}

export enum ConditionStatus {
  Addressed = 'ADDRESSED',
  Dismissed = 'DISMISSED',
  Open = 'OPEN',
  Seen = 'SEEN',
  Removed = 'REMOVED',
  Closed = 'CLOSED'
}

export enum TargetSourceCode {
  Curation = 'CURATION',
  External = 'EXTERNAL'
}

enum Gender {
  Male = 'M',
  Female = 'F'
}

type UUID = string

// previousEncounter = null or encounterType
// nextEncounter = null or encounterType

export enum ReviewCode {
  CoderBypassed = 'CODER_BYPASSED',
  Open = 'OPEN',
  ReadyForCDIQA = 'READY_FOR_CDI_QA',
  ReadyForPhysician = 'READY_FOR_PHYSICIAN',
  ReadyForCoder = 'READY_FOR_CODER',
  ReadyForCoderQA = 'READY_FOR_CODER_QA',
  Completed = 'COMPLETED',
  Removed = 'REMOVED'
}

export type Encounter = {
  encounterId: UUID
  patientId: UUID
  proposedEncounterType: string
  encounterStatus: string | null
  encounterStart: string | null
  encounterEnd: string | null
  reasonCode: string | null
  reasonDescription: string | null
  reviewId: UUID | null
  reviewCode: string | null
}

// export type Encounter = {
//   id: UUID
//   tenantId: UUID
//   status: string
//   start: string
//   end: string | null
//   reasonCode: string | null
//   reviewCode: ReviewCode
//   reasonDescription: string | null
// }

export type Patient = {
  id: UUID
  tenantId: UUID
  identifier: string
  ssn: string | null
  mrn: string | null
  id_mrn?: string | null
  statusCode: StatusCode
  idFhir: string | null
  idClaim: string | null
  idMbi: string | null
  idHicn: string | null
  idInternal: string | null
  idExternal: string | null
  driversLicense: string | null
  passport: string | null
  gender: Gender | null
  birthDate: Date | null
  deathDate: Date | null
  insuranceId: string | null
  insurance: string | null
  nameId: UUID | null
  lastName: string | null
  prefix: string | null
  firstName: string | null
  given2: string | null
  suffix: string | null
  maiden: string | null
  marital: string | null
  race: string | null
  ethnicity: string | null
  birthplace: string | null
  addressUse: string | null
  line1: string | null
  line2: string | null
  city: string | null
  state: string | null
  postalCode: string | null
  country: string | null
  homePhone: string | null
  mobilePhone: string | null
  personalEmail: string | null
  generalPractitionerOrgId: UUID | null
  sourceCodeType: string | null
  fileName: string | null
  ingestDate: Date
  // New Values the frontend is expecting
  prevEncounter: Encounter | null
  proposedEncounter: Encounter | null
  nextEncounter: Encounter | null
  // Other values from a potential duplicate type
  vbcProgram: string | null
  rulesFilterTypes: string | null
  rulesHccVersion: string | null
  onOffShore: string | null
  regionName: string | null
  generalPractitionerId: UUID | null
  managingOrgId: UUID | null
}
