import { Dispatch, SetStateAction } from 'react'
// Local
import { api } from '../../../action/api'
import { API_URL, PAGES } from '../../../constant'

export type ScheduleEncounterProps = {
  setIsScheduleCallBeingMade: Dispatch<SetStateAction<boolean>>
  practitionerId: string
  setEncounterIdState: Dispatch<SetStateAction<string>>
  encounterId: string
  date?: Date
}

async function scheduleEncounter(props: ScheduleEncounterProps): Promise<void> {
  const {
    setIsScheduleCallBeingMade,
    practitionerId,
    setEncounterIdState,
    encounterId,
    date
  } = props
  setIsScheduleCallBeingMade(true)
  const response = await api.put(
    API_URL.SCHEDULE_ENCOUNTER(
      encounterId,
      practitionerId,
      date === undefined ? new Date().getTime() : date.getTime()
    ),
    {}
  )
  // accountId: null
  // appointmentId: "91e898ca-9ea1-461e-b648-1229336a2d7e"
  // cost: null
  // description: null
  // encounterCode: null
  // encounterPriorityCode: null
  // encounterReasonCode: null
  // encounterReasonDescription: null
  // encounterStatusCode: "PLANNED"
  // encounterTypeCode: null
  // id: "b7c2c529-d553-41fe-9b8f-dfd3df7b2736"
  // identifier: "en-202112348-00231512203527063501"
  // incomingReferralId: null
  // length: 20
  // partOfId: null
  // periodEnd: "2021-12-14T05:20:00.000Z"
  // periodStart: "2021-12-18T02:55:56.884Z"
  // serviceProviderId: null
  // subjectId: "ef757524-1f52-47da-9564-0c02288791b4"
  // tenantId: "f3555587-829a-4f9d-b4cb-a44375c32fe0"
  setIsScheduleCallBeingMade(false)
  setEncounterIdState('')
  window.location.href = `${PAGES.ENCOUNTER_DETAIL}${response.data.id}`
}

export default scheduleEncounter
