import React, { Component } from "react";
import { connect } from "react-redux";
import { Icon, Popup } from "semantic-ui-react";
import { hasExpiredQuery, hasCoderQueryNote, condition as conditionUtils } from "../../common/conditionUtils";
import { coderQueryExpireHours } from "../../common/currentUserUtils";

/**
 * Provides a component that displays an icon depicting coder query status
 * @param condition Condition entity
 */
class ConditionQueryStatusIcon extends Component {

  render() {
    const condition = this.props.condition;
    const expireHours = coderQueryExpireHours(this.props.currentUser);
    if ( hasExpiredQuery(condition, expireHours) ) {
      return (
        <Popup content={`Condition has an Outstanding Query Waiting Longer Than ${expireHours} Hours`}
          mouseEnterDelay={1000}
          trigger={<Icon name='comment alternate outline' color="red" />} />
      );
    } else if ( conditionUtils.hasQuery(condition) ) {
      const content = this.props.popup
        ? this.props.popup
        : "Condition has an Outstanding Query"
      return (
        <Popup content={content}
          mouseEnterDelay={1000}
          trigger={<Icon name='comment alternate outline' />} />
      );
    } else if ( hasCoderQueryNote(condition) ) {
      return (
        <Popup content="Condition has a Coder Query Note"
          mouseEnterDelay={1000}
          trigger={<Icon name='comment alternate outline' />} />
      );
    } else {
      return null;
    }
  }
}

export default connect(
  state => ({
    currentUser: state.user
  }), {}
)(ConditionQueryStatusIcon);
