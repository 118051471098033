import React, { Component } from "react";
import { Button, Grid, Icon, Popup, Search } from "semantic-ui-react";
import debounce from "lodash/debounce";
import map from "lodash/map";
import { API_URL } from "../../../constant";
import { api } from "../../../action/api";
import { searchGenerator } from "../../../action/search";
import { formatIcd10Code, rawIcd10Code } from "../../common/formatUtils";

/**
 * Provides an ICD10 search
 *
 * Notes:
 *
 * This is a surprisingly tricky component.
 *
 * this.state.value is either a formatted ICD10 code or search input
 *
 * We format ICD10 codes for display with a decimal and store them as raw.
 * Example: Formatted: A01.01, Raw: A0101
 *
 * @param name Component name. Default: icd10
 * @param value Initial ICD10 value
 * @param placeholder Place holder override. Default: ICD10
 * @param onSelect Callback function for ICD10 code select
 *        Arguments: Object: { id, description }, matching the storage model,
 *        where id is the raw ICD10 code
 */
class Icd10Search extends Component {

  constructor(props) {
    super(props);

    const name = this.props.name ? this.props.name : "icd10";
    const value = this.props.value ? formatIcd10Code(this.props.value) : "";
    const placeholder = this.props.placeholder ? this.props.placeholder : "ICD10";

    this.state = {
      name: name,
      value: value,
      valid: true,
      results: [],
      loading: false,
      placeholder: placeholder
    };
  }

  onSelect = (e, { result }) => {
    this.setState({
      value: result.title,
      valid: true
    }, () => {
      if (this.props.setConditionDiagnosisCode) {
        this.props.setConditionDiagnosisCode(rawIcd10Code(result.title));
      }
      if (!this.props.setConditionDiagnosisCode && this.props.onSelect) {
        this.props.onSelect({
          id: rawIcd10Code(result.title),
          description: result.description
        });
      }
    });
  };

  fetchIcd10Codes = (e, { value }) => {
    const icd10 = rawIcd10Code(value);
    const query = null;
    const limit = 5;
    const offset = 0;
    const sort = null;
    const queryOverride = this.props.searchDescription === false
      ? "id:" + icd10 + "%"
      : "id:" + icd10 + "%,OR desc:" + value + "%";
    const params = searchGenerator(query, limit, offset, sort, queryOverride);
    const url = API_URL.ICD10_SEARCH;
    this.setState({ loading: true, value: value, valid: false });
    this.setState(() => {
      api.get(url, { params: params }).then(({ data }) => {
        const results = map(data, (icd10) => {
          return {
            title: formatIcd10Code(icd10.id),
            description: icd10.description
          };
        });
        this.setState({
          results: results,
          loading: false
        });
      });
    });
  }

  onClear = () => {
    const result = { title: "", description: "" };
    this.onSelect(null, { result });
  }

  onBlur = () => {
    const valid = this.state.valid;
    const results = this.state.results;
    const value = this.state.value;
    if (valid === false) {
      // handle case where input contains valid, but unselected, icd10 code
      const result = results.find((r) => {
        return rawIcd10Code(r.title) === rawIcd10Code(value)
      });
      if (result) {
        this.onSelect(null, { result });
      } else {
        this.setState({
          value: "",
          valid: true
        })
      }
    }
  }

  render() {
    const className = "field icd10 " + (this.props.error ? "error" : "");
    return (
      <Grid>
        <Grid.Row columns="2">
          <Grid.Column width="13" style={{ paddingLeft: "8px", paddingRight: 0 }}>
            <Search className={className}
              name={this.state.name}
              value={this.state.value}
              loading={this.state.loading}
              placeholder={this.state.placeholder}
              results={this.state.results}
              onResultSelect={this.onSelect}
              onSearchChange={debounce(this.fetchIcd10Codes, 250, { leading: true })}
              aligned="left"
              icon={null}
              fluid={true}
              onBlur={this.onBlur}
              clear={true}
            />
          </Grid.Column>
          <Grid.Column width="3" style={{ paddingLeft: "4px" }}>
            <Popup content="Clear ICD10 Value" mouseEnterDelay={500}
              trigger={
                <Button icon onClick={this.onClear}><Icon name="cancel" /></Button>
              } />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default Icd10Search;
