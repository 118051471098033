import concat from "lodash/concat";

const HISTORY_LENGTH = 50;

const initState = { list: [] };

export default (state = initState, action) => {
  var newList = concat(state.list, action);
  if (newList.length > HISTORY_LENGTH) {
    newList.shift();
  }
  return Object.assign({}, state, {
    list: newList
  });
};
