import React, { Component } from "react";
import { Divider } from "semantic-ui-react";
import EncounterPatient from "../common/encounterPatient";
import EncounterProfile from "../common/encounterProfile";

/**
 * Provides a component that renders the Patient Snapshot encounter left content
 * @param encounterDetail Encounter detail entity
 */
class PatientSnapshotEncounterLeft extends Component {

  render() {
    const detail = this.props.encounterDetail;
    const encounter = detail.encounter;

    return (
      <div>
        <Divider className="green-border" />
        <EncounterProfile mode="left" encounter={encounter} />
        <EncounterPatient mode="left" encounter={encounter}
          displayName={true}
          displayBirthDate={true} />
      </div>
    );
  }
}

export default PatientSnapshotEncounterLeft;
