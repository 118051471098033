/* eslint-disable react/destructuring-assignment */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable class-methods-use-this */
import React, { Component } from 'react'
import { Popup, Responsive } from 'semantic-ui-react'
import reduce from 'lodash/reduce'
import { API_URL, MOBILE_WIDTH, REVIEW, TABLET_WIDTH } from '../../constant'
import { api } from '../../action/api'

/**
 * Provides a component that displays the CDI user review count for the current
 * day. Count includes reviews marked READY_FOR_CDI_QA and READY_FOR_PHYSICIAN
 * Requires no properties.
 */
class CdiCounter extends Component {

  constructor(props) {
    super(props)
    this.state = {
      reviewCount: 0,
      readyForCdiQa: 0,
      readyForPhysician: 0
    }
  }

  componentDidMount() {
    this.fetchReviewCount()
  }

  reduceReviewCounts(reviewCounts, reviewCode) {
    return reduce(reviewCounts, (result, row) => {
      if ( row.reviewCode === reviewCode ) {
        return result + row.reviewCount
      }
      return result

    }, 0)
  }

  fetchReviewCount() {
    const url = API_URL.REVIEW_COUNT
    api.get(url).then(({ data }) => {
      const cdiQaCount = this.reduceReviewCounts(data, REVIEW.CODE.READY_FOR_CDI_QA)
      const physicianCount = this.reduceReviewCounts(data, REVIEW.CODE.READY_FOR_PHYSICIAN)
      const total = cdiQaCount + physicianCount
      this.setState({
        reviewCount: total,
        readyForCdiQa: cdiQaCount,
        readyForPhysician: physicianCount
      })
    })
  }

  counterMessage() {
    const msgs = []
    if ( this.state.readyForPhysician > 0 ) {
      msgs.push(`Ready for Physician: ${this.state.readyForPhysician}`)
    }
    if ( this.state.readyForCdiQa > 0 ) {
      msgs.push(`Ready for CDI QA: ${this.state.readyForCdiQa}`)
    }
    if ( this.state.reviewCount === 0 ) {
      msgs.push('Ready for Physician: 0; Ready for CDI QA: 0')
    }
    return msgs.join('; ')
  }

  render() {
    const msg = this.counterMessage()
    return (
      <span>
        <Responsive minWidth={ TABLET_WIDTH.minWidth } className="bold">
          <Popup content={ msg } mouseEnterDelay={ 1000 }
            trigger={ <span>Reviewed: { this.state.reviewCount }</span> } />
        </Responsive>
        <Responsive maxWidth={ MOBILE_WIDTH.maxWidth } className="bold">
          { this.state.reviewCount }
        </Responsive>
      </span>
    )
  }
}

export default CdiCounter
