/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-unused-class-component-methods */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Container, Form, Grid, Icon, Popup, Segment, Select } from 'semantic-ui-react'
import concat from 'lodash/concat'
import { format, startOfDay, startOfHour, subDays, subHours } from 'date-fns'
import { formatDob } from '../../common/formatUtils'
import {
  API_URL,
  ENCOUNTER,
  FORMAT,
  LIST_PAGE_SIZE,
  LIST_MAX_PAGES,
  LOCK,
  PATIENT,
  REVIEW,
  WORKLIST_VIEW
} from '../../../constant'
import { api } from '../../../action/api'
import { searchGenerator } from '../../../action/search'
import { updateUserWorklistFilters } from '../../../action/user'
import SubHeader from '../../common/subHeader'
import Filter from '../../common/filter'
import Table from '../../common/table'
import { patientMrn } from '../../common/patientUtils'
import { coderWorklistStartDays, coderWorklistEndHours, showVbcDisplay } from '../../common/currentUserUtils'
import CoderEncounterStatusIcon from './coderEncounterStatusIcon'
import { semiColonsToLineBreaks } from '../../v2/utils/utils'

/**
 * Provides a worklist view of encounters for the Coder user role
 *
 * Be aware that there is fussy logic around the encounter search date range.
 * If the user is viewing the Worklist encounter view, the encounters are
 * constrained to the recent past. These should be appointments,
 * ready-for-physician, that have recently finished -- unless the user has
 * explicitly picked a date.
 *
 * @param onSelect Callback function to execute when an encounter is selected
 *        arguments: Encounter ID
 */
class CoderWorklist extends Component {

  constructor(props) {
    super(props)
    this.state = {
      userRole: this.props.userRole,
      encounters: [],
      tenantId: this.props.currentUser.tenantId ? this.props.currentUser.tenantId : '',
      worklistView: WORKLIST_VIEW.CODER.WORKLIST,
      pagesDisplayed: 0,
      encounterSort: 'start asc',
      showFilter: false,
      queryFilters: {}
    }
  }

  componentDidMount() {
    const { currentUser } = this.props
    const userFilters = currentUser.worklistFilters
    const encounterSort = userFilters.encounterSort ? userFilters.encounterSort : 'start asc'
    const view = userFilters.view ? userFilters.view : this.state.worklistView
    const offset = 0
    const query = {
      start: userFilters.start ? new Date(userFilters.start) : this.state.queryFilters.start,
      birthDate: userFilters.birthDate ? new Date(userFilters.birthDate) : null,
      firstName: userFilters.firstName ? userFilters.firstName : null,
      lastName: userFilters.lastName ? userFilters.lastName : null,
      patientOrgId: userFilters.patientOrgId ? userFilters.patientOrgId : null,
      appointmentType: userFilters.appointmentType ? userFilters.appointmentType : null,
      practitionerId: userFilters.practitionerId ? userFilters.practitionerId : null,
      patientRegion: userFilters.patientRegion ? userFilters.patientRegion : null,
      vbcProgram: userFilters.vbcProgram ? userFilters.vbcProgram : null
    }
    const showFilter = userFilters.start || query.birthDate ||
      query.firstName || query.lastName || query.patientOrgId ||
      query.appointmentType || query.practitionerId || query.patientRegion ||
      query.vbcProgram
    this.setState({
      showFilter,
      encounterSort
    }, () => {
      this.fetchEncounters(view, offset, query)
    })
  }

  onWorklistViewSelect = (e, { value }) => {
    const view = value
    const query = this.state.queryFilters
    const userFilters = this.props.currentUser.worklistFilters
    userFilters.view = view
    if (view !== WORKLIST_VIEW.CODER.WORKLIST) {
      // reset start if view is not coder worklist
      const start = query.start && query.start instanceof Date ? query.start : null
      userFilters.start = start
      query.start = start
    }
    this.props.updateUserWorklistFilters(this.props.currentUser, userFilters)
    this.fetchEncounters(view, 0, query)
  }

  worklistViewOptions = () => [
    {
      key: WORKLIST_VIEW.CODER.WORKLIST,
      text: 'Worklist',
      value: WORKLIST_VIEW.CODER.WORKLIST
    },
    {
      key: WORKLIST_VIEW.CODER.REVIEW_REQUEST,
      text: 'Coder Review Request',
      value: WORKLIST_VIEW.CODER.REVIEW_REQUEST
    },
    {
      key: WORKLIST_VIEW.CODER.READY_FOR_CODER_QA,
      text: 'Ready for Coder QA',
      value: WORKLIST_VIEW.CODER.READY_FOR_CODER_QA
    },

    {
      key: WORKLIST_VIEW.CODER.COMPLETED,
      text: 'Completed',
      value: WORKLIST_VIEW.CODER.COMPLETED
    },
    {
      key: WORKLIST_VIEW.CODER.READY_FOR_PHYSICIAN,
      text: 'Ready for Physician',
      value: WORKLIST_VIEW.CODER.READY_FOR_PHYSICIAN
    },
    {
      key: WORKLIST_VIEW.CODER.LOCKED,
      text: 'Locked',
      value: WORKLIST_VIEW.CODER.LOCKED
    }
  ]

  filterMetadata = () => {
    // TODO - sboles - remove when we fix Devoted's data
    const { tenantId } = this.state
    const showVbcFilter = showVbcDisplay(this.props.currentUser)
    const metadata = [
      [
        { placeholder: 'MRN', name: 'mrn', width: 6, like: false },
        { placeholder: 'First Name', name: 'firstName', width: 5, like: true },
        { placeholder: 'Last Name', name: 'lastName', width: 5, like: true }
      ],
      [
        { placeholder: 'Appointment Date', name: 'start', width: 6, date: true },
        { placeholder: 'Date of Birth', name: 'birthDate', width: 5, date: true },
        { placeholder: 'Practice', name: 'patientOrgId', width: 5, organizationSelect: true }
      ],
      [
        { placeholder: 'Visit Type', name: 'appointmentType', width: 6, appointmentTypeSelect: true },
        { placeholder: 'Practitioner', name: 'practitionerId', width: 5, practitionerSelect: true },
        { placeholder: 'Region', name: 'patientRegion', width: 5, patientRegionSelect: true }
      ]
    ]

    if (showVbcFilter) {
      metadata.push([{
        placeholder: 'VBC Program',
        name: 'vbcProgram',
        width: 6,
        vbcProgramSelect: true
      }])
    }

    return metadata
  }

  setEncounterSort = sort => {
    const view = this.state.worklistView
    const offset = 0
    const query = this.state.queryFilters
    this.setState({ encounterSort: sort }, () => {
      this.fetchEncounters(view, offset, query)
    })
    this.props.updateUserWorklistFilters(this.props.currentUser, {
      encounterSort: sort
    })
  }

  sortOnHeaderClick = clickedColumn => () => {
    let sort = this.state.encounterSort

    switch (clickedColumn) {
      case 'time':
        sort = (sort === 'start asc' ? 'start desc' : 'start asc')
        this.setEncounterSort(sort)
        break
      case 'appointmentType':
        sort = (sort === 'appointmentType asc' ? 'appointmentType desc' : 'appointmentType asc')
        this.setEncounterSort(sort)
        break
      case 'name':
        sort = (sort === 'lastName asc' ? 'lastName desc' : 'lastName asc')
        this.setEncounterSort(sort)
        break
      case 'dob':
        sort = (sort === 'birthDate asc' ? 'birthDate desc' : 'birthDate asc')
        this.setEncounterSort(sort)
        break
      case 'mrn':
        sort = (sort === 'mrn asc' ? 'mrn desc' : 'mrn asc')
        this.setEncounterSort(sort)
        break
      case 'vbcProgram':
        sort = (sort === 'vbcProgram asc' ? 'vbcProgram desc' : 'vbcProgram asc')
        this.setEncounterSort(sort)
        break
      case 'practitioner':
        sort = (sort === 'practitionerLastName asc' ? 'practitionerLastName desc' : 'practitionerLastName asc')
        this.setEncounterSort(sort)
        break
      case 'nameMobile':
        sort = (sort === 'lastName asc' ? 'lastName desc' : 'lastName asc')
        this.setEncounterSort(sort)
        break
      default:
        sort = 'start asc'
        this.setEncounterSort(sort)
    }
  }

  onMore = () => {
    const view = this.state.worklistView
    const pages = this.state.pagesDisplayed
    const offset = LIST_PAGE_SIZE * pages + 1
    const query = this.state.queryFilters
    this.fetchEncounters(view, offset, query)
  }

  selectRecord = (id, event) => {
    if (this.props.onSelect && id) {
      this.props.onSelect(id, event)
    }
  }

  toggleFilter = (e, iconProps) => {
    this.setState({ showFilter: !this.state.showFilter })
  }

  onSubmitFilter = query => {
    const view = this.state.worklistView
    const offset = 0
    this.props.updateUserWorklistFilters(
      this.props.currentUser,
      {
        start: query.start ? new Date(query.start) : null,
        birthDate: query.birthDate ? new Date(query.birthDate) : null,
        firstName: query.firstName ? query.firstName.replaceAll('%', '') : null,
        lastName: query.lastName ? query.lastName.replaceAll('%', '') : null,
        patientOrgId: query.patientOrgId ? query.patientOrgId : null,
        appointmentType: query.appointmentType ? query.appointmentType : null,
        practitionerId: query.practitionerId ? query.practitionerId : null,
        patientRegion: query.patientRegion ? query.patientRegion : null,
        vbcProgram: query.vbcProgram ? query.vbcProgram : null,
        view
      }
    )
    this.fetchEncounters(view, offset, query)
  }

  filterInitialValues() {
    const userFilters = this.props.currentUser.worklistFilters
    const initialValues = {}
    if (userFilters.start) {
      const start = format(new Date(userFilters.start), FORMAT.DATE)
      initialValues.start = start
    }
    if (userFilters.birthDate) {
      const birthDate = formatDob(new Date(userFilters.birthDate), FORMAT.DATE)
      initialValues.birthDate = birthDate
    }
    initialValues.firstName = userFilters.firstName ? userFilters.firstName : null
    initialValues.lastName = userFilters.lastName ? userFilters.lastName : null
    initialValues.patientOrgId = userFilters.patientOrgId ? userFilters.patientOrgId : null
    initialValues.appointmentType = userFilters.appointmentType ? userFilters.appointmentType : null
    initialValues.practitionerId = userFilters.practitionerId ? userFilters.practitionerId : null
    initialValues.patientRegion = userFilters.patientRegion ? userFilters.patientRegion : null
    initialValues.vbcProgram = userFilters.vbcProgram ? userFilters.vbcProgram : null
    return initialValues
  }

  generateTableData(encounters) {
    if (!encounters || encounters.length === 0) return []

    const { currentUser } = this.props

    const formatDate = startDt => format(startDt, FORMAT.DATE_TIME)

    return encounters.map(record => {
      const { patient } = record
      const { practitioner } = record
      const patientName = `${patient.firstName} ${patient.lastName}`
      const mrn = patientMrn(patient, this.state.tenantId)
      const { vbcProgram } = patient
      const practitionerName = `${practitioner.firstName} ${practitioner.lastName}`
      const mobileName = `${patient.firstName} ${patient.lastName
      } ( ${formatDob(patient.birthDate, FORMAT.DATE)} )`

      return {
        id: record.id,
        time: formatDate(record.start),
        name: patientName,
        dob: formatDob(record.patient.birthDate, FORMAT.DATE),
        mrn: semiColonsToLineBreaks(mrn),
        vbcProgram,
        appointmentType: record.appointmentType,
        practitioner: practitionerName,
        nameMobile: mobileName,
        statusIcon: <CoderEncounterStatusIcon encounter={ record } currentUser={ currentUser } />,
        meta: { className: 'cursor-pointer' }
      }
    })
  }

  fetchEncounters(view, offset, query) {
    const { currentUser } = this.props
    const searchQuery = query || {}
    const encounterStatus = [
      ENCOUNTER.STATUS.PLANNED,
      ENCOUNTER.STATUS.ARRIVED,
      ENCOUNTER.STATUS.FINISHED,
      ENCOUNTER.STATUS.IN_PROGRESS
    ]
    let reviewCode = [ REVIEW.CODE.READY_FOR_CODER ]
    const patientStatus = [ PATIENT.STATUS.ACTIVE ]
    let reviewQueryStatus = null
    let lockStatus = null
    const sort = this.state.encounterSort
    switch (view) {
      case WORKLIST_VIEW.CODER.READY_FOR_CODER_QA:
        reviewCode = [ REVIEW.CODE.READY_FOR_CODER_QA ]
        break
      case WORKLIST_VIEW.CODER.REVIEW_REQUEST:
        reviewQueryStatus = REVIEW.QUERY_STATUS.REVIEW_QUERY_OUTSTANDING
        break
      case WORKLIST_VIEW.CODER.COMPLETED:
        reviewCode = [ REVIEW.CODE.COMPLETED ]
        break
      case WORKLIST_VIEW.CODER.READY_FOR_PHYSICIAN:
        reviewCode = [ REVIEW.CODE.READY_FOR_PHYSICIAN ]
        break
      case WORKLIST_VIEW.CODER.LOCKED:
        lockStatus = LOCK.STATUS.ACTIVE
        reviewCode = [ REVIEW.CODE.READY_FOR_PHYSICIAN, REVIEW.CODE.COMPLETED ]
        break
      default:
        reviewCode = [ REVIEW.CODE.READY_FOR_CODER ]
    }

    if (!searchQuery.patientRegion) {
      const defaultRegions = currentUser.worklistFilters.defaultPatientRegion
      if (defaultRegions) {
        searchQuery.patientRegion = defaultRegions
      }
    }

    searchQuery.reviewCode = reviewCode
    searchQuery.encounterStatus = encounterStatus
    searchQuery.lockStatus = lockStatus
    searchQuery.patientStatus = patientStatus
    searchQuery.reviewQueryStatus = reviewQueryStatus

    const url = API_URL.ENCOUNTER_SEARCH_WITH_ROLE(this.state.userRole)
    const limit = offset === 0 ? LIST_PAGE_SIZE + 1 : LIST_PAGE_SIZE
    const params = searchGenerator(searchQuery, limit, offset, sort)

    api.get(url, { params }).then(({ data }) => {
      const results = offset === 0 ? data : concat(this.state.encounters, data)
      const pages = offset === 0 ? 1 : this.state.pagesDisplayed + 1
      this.setState({
        encounters: results,
        worklistView: view,
        pagesDisplayed: pages,
        encounterSort: sort,
        queryFilters: query
      })
    })
  }

  worklistDateRange() {
    const now = new Date()
    const startDays = coderWorklistStartDays(this.props.currentUser)
    const endHours = coderWorklistEndHours(this.props.currentUser)
    const startDt = startOfDay(subDays(now, startDays))
    const endDt = startOfHour(subHours(now, endHours))
    return `[${format(startDt, FORMAT.API_DATE)
    } TO ${format(endDt, FORMAT.API_DATE)}]`
  }

  leftSubHeader() {
    const prefix = 'Coder Worklist'
    switch (this.state.worklistView) {
      case WORKLIST_VIEW.CODER.READY_FOR_CODER_QA:
        return `${prefix} - Ready for Coder QA`
      case WORKLIST_VIEW.CODER.COMPLETED:
        return `${prefix} - Complete`
      case WORKLIST_VIEW.CODER.READY_FOR_PHYSICIAN:
        return `${prefix} - Ready for Physician`
      default:
        return prefix
    }
  }

  rightSubHeader() {
    const tooltip = this.state.showFilter
      ? 'Hide Worklist Filters' : 'Show Worklist Filters'
    return (
      <Popup content={ tooltip } mouseEnterDelay={ 1000 }
        trigger={
          <Icon id="worklist-filter" name="filter"
            size="large" className="pointer"
            onClick={ this.toggleFilter } />
        } />
    )
  }

  renderWorklistTable = () => {
    const tableData = this.generateTableData(this.state.encounters)
    const pages = this.state.pagesDisplayed
    const computerColumns = [
      { name: null, value: 'statusIcon' },
      { name: 'Time', value: 'time' },
      { name: 'Type', value: 'appointmentType' },
      { name: 'Name', value: 'name' },
      { name: 'DOB', value: 'dob' },
      { name: 'MRN(s)', value: 'mrn' },
      { name: 'Practitioner', value: 'practitioner' }
    ]

    if (showVbcDisplay(this.props.currentUser)) {
      computerColumns.splice(7, 0, { name: 'VBC Program', value: 'vbcProgram' })
    }

    let displayMore = false
    let displayMaxRecords = false

    if (tableData.length > pages * LIST_PAGE_SIZE) {
      displayMore = pages < LIST_MAX_PAGES
      displayMaxRecords = pages >= LIST_MAX_PAGES
      tableData.pop()
    }

    const table = (
      <Table
        key={ this.state.worklistView }
        computerColumns={ computerColumns }
        mobileColumns={ [
          { value: 'time', width: '4' },
          { value: 'nameMobile', width: '12' }
        ] }
        data={ tableData }
        onClick={ this.selectRecord }
        more={ displayMore }
        max={ displayMaxRecords }
        onMore={ this.onMore }
        sortOnHeaderClick={ this.sortOnHeaderClick }
      />
    )

    return <span>{ table }</span>
  }

  render() {
    return (
      <div className="header-padding">
        <Container>
          <SubHeader left={ this.leftSubHeader() } right={ this.rightSubHeader() } />
          { this.state.showFilter && (
            <Filter
              submit={ this.onSubmitFilter }
              filters={ this.filterMetadata() }
              initialValues={ this.filterInitialValues() } />
          ) }
          <Segment attached className="no-margin">
            <Grid>
              <Grid.Row style={ { paddingBottom: '0rem', textAlign: 'right' } }>
                <Grid.Column mobile={ 16 } tablet={ 16 } computer={ 16 }>
                  <Form.Field
                    label="View: "
                    control={ Select }
                    value={ this.state.worklistView }
                    options={ this.worklistViewOptions() }
                    onChange={ this.onWorklistViewSelect }
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column mobile={ 16 } tablet={ 16 } computer={ 16 }>
                  { this.renderWorklistTable() }
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        </Container>
      </div>
    )
  }
}

export default connect(
  state => ({
    currentUser: state.user
  }), { updateUserWorklistFilters }
)(CoderWorklist)
