import React from "react";
import { Route, Redirect } from "react-router-dom";
import { PAGES } from "../../constant";

const PrivateRoute = ({ component: Component, authenticated, ...rest }) => {
  if (!authenticated) {
    return <Redirect to={PAGES.LOGIN} />;
  } else {
    return <Route {...rest} render={props => <Component {...props} /> } />;
  }
};

export default PrivateRoute;
