/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable consistent-return */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react'
import { Button, Grid, Header, Icon, Popup, Responsive } from 'semantic-ui-react'
import { CONDITION } from '../../../constant'

/**
 * Provides a display header for a group of conditions
 *
 * If the conditions parameter is defined, extra tooling for condition
 * management is shown
 *
 * @param title Header title text
 * @param displayIndex Headers with an index greater than 0 get extra top margin
 * @param conditions List of conditions in the associated condition view
 * @param onAction Callback for header action.
 *        Parameters: action and conditions affected
 */
class ConditionHeader extends Component {

  constructor(props) {
    super(props)
    this.onClickCollapseAll = this.onClickCollapseAll.bind(this)
    this.onClickExpandAll = this.onClickExpandAll.bind(this)
  }

  onClickExpandAll() {
    if (this.props.onAction) {
      this.props.onAction(CONDITION.ACTION.EXPAND_ALL, this.props.conditions)
    }
  }

  onClickCollapseAll() {
    if (this.props.onAction) {
      this.props.onAction(CONDITION.ACTION.COLLAPSE_ALL, this.props.conditions)
    }
  }

  headerClass() {
    return this.props.displayIndex === 0
      ? 'conditions-address hover' : 'conditions-address additional hover'
  }

  popupContent(prefix, conditions) {
    if (conditions && conditions.length > 0) {
      return prefix +
        (conditions.length === 1 ? ' ' : ' All ') +
        conditions.length +
        (conditions.length === 1 ? ' Condition' : ' Conditions')
    }
  }

  renderWithToolbar(conditions) {
    const columnCount = conditions.length > 1 ? 2 : 1
    return (
      <Header attached="top" className={ this.headerClass() }>
        <Grid columns={ columnCount }>
          <Grid.Column textAlign="left" verticalAlign="middle">
            { this.props.title }
          </Grid.Column>
          { conditions.length > 1 && (
            <Grid.Column textAlign="right">
              <span className="hover__hover">
                <Popup content={ this.popupContent('Collapse', conditions) }
                  trigger={
                    <Responsive as={ Button } icon basic size="mini"
                      onClick={ this.onClickCollapseAll }><Icon
                        name="angle double up" /></Responsive>
                  } />
                <Popup content={ this.popupContent('Expand', conditions) }
                  trigger={
                    <Responsive as={ Button } icon basic size="mini"
                      onClick={ this.onClickExpandAll }><Icon
                        name="angle double down" /></Responsive>
                  } />
              </span>
            </Grid.Column>
          ) }
        </Grid>
      </Header>
    )
  }

  renderBasic() {
    return (
      <Header
        attached="top"
        className={ this.headerClass() }
      >
        <span>
          { this.props.title }
        </span>
      </Header>
    )
  }

  render() {
    if (this.props.conditions && this.props.conditions.length > 1) {
      return this.renderWithToolbar(this.props.conditions)
    }
    return this.renderBasic()

  }
}

export default ConditionHeader
