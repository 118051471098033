import React, { Component } from "react";
import { startOfDay } from "date-fns";
import { BASE_URL } from "../../config";
import { API_URL } from "../../constant";

/**
 * Provides a component that renders a PDF huddle report in an iframe
 * The huddle report can include all encounters ready for physician on a
 * given date or can include just the encounters for a specified physician
 * @param startDt Optional. Default: current date. If provided, a millisecond
 *        date value (Date.getTime()) that specifies the huddle start date
 * @param practitionerId Optional. If provided, constrain encounters to the
 *        specified practitioner
 */
class PdfHuddleReport extends Component {

  pdfUrl() {
    const start = this.props.startDt ? startOfDay(new Date(this.props.startDt))
      : startOfDay(new Date());

    if ( this.props.practitionerId ) {
      return BASE_URL + API_URL.PDF_PRACTITIONER_HUDDLE_REPORT(this.props.practitionerId, start.getTime());
    }
    if ( this.props.orgId ) {
      return BASE_URL + API_URL.PDF_ORG_HUDDLE_REPORT(this.props.orgId, start.getTime());
    }
    if ( this.props.region ) {
      return BASE_URL + API_URL.PDF_REGION_HUDDLE_REPORT(this.props.region, start.getTime());
    }
    return BASE_URL + API_URL.PDF_HUDDLE_REPORT(start.getTime());
  }

  render() {
    const url = this.pdfUrl();
    return (
      <iframe title="Huddle PDF" className="pdf-iframe" src={url} />
    );
  }
}

export default PdfHuddleReport;

