import React, { Component } from "react";
import filter from "lodash/filter";
import { CONDITION } from "../../../constant";

/**
 * @param condition Condition entity
 */
class ConditionReasons extends Component {

  render() {
    const condition = this.props.condition;
    const rules = condition.rules;
    if ( rules ) {
      return filter(rules, function(rule) {
        return rule.statusCode === CONDITION.STATUS.ACTIVE &&
          rule.reason !== condition.content;
      }).map(rule => {
        const key = rule.id ? rule.id : rule.reason;
        return (
          <div key={key} className="reason-row">
            <strong>{rule.reason}</strong>
          </div>
        );
      });
    } else {
      return null;
    }
  }
}

export default ConditionReasons;
