/* eslint-disable react/sort-comp */
/* eslint-disable no-restricted-syntax */
/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unused-state */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Accordion } from 'semantic-ui-react'
import { format } from 'date-fns'
import { api } from '../../../action/api'
import { API_URL, FORMAT } from '../../../constant'

/**
 * Provides a component that renders previous encounters for a specified patient
 * @param encounterId Encounter ID to determine past encounters
 * @param subjectId Patient ID to determine previous encounters
 * @param onClickGoToPrevious Callback for navigation to previous encounter
 *        Arguments: Encounter ID
 */
class PreviousEncounters extends Component {

  constructor(props) {
    super(props)
    this.state = {
      encounters: null,
      pagesDisplayed: 0,
      encounterSort: 'start asc',
      showFilter: false,
      queryFilters: {},
      currentUser: props.currentUser
    }
  }

  componentDidMount() {
    this.fetchEncounters()
  }

  handleTitleClick = previous => {
    this.props.onClickGoToPrevious(previous.item.id)
  }

  fetchEncounters() {
    const userRole = this.state.currentUser.type
    const url = API_URL.GET_PREVIOUS_ENCOUNTERS_BY_SUBJECT_ID_WITH_ROLE(userRole, this.props.subjectId, this.props.encounterId)
    api.get(url).then(({ data }) => {
      this.setState({ encounters: data })
    })
  }

  generateTableData(encounters) {
    if (!encounters || encounters.length === 0) return []

    const result = []

    const map = new Map()

    for (const item of encounters) {
      if (!map.has(item.id)) {
        map.set(item.id, true)
        result.push({
          item
        })
      }
    }

    return result
  }

  getAccordionTitle = previous => {
    const { encounter, index } = this.props

    return (
      <Accordion.Title
        key={ previous.item.id }
        index={ encounter }
        onClick={ () => { this.handleTitleClick(previous) } }
        className={ index === 0 ? 'flex-title first-accordion' : 'flex-title' }>
        <div>
          { format(previous.item.start, FORMAT.DATE) } - { previous.item.reasonDescription }
        </div>
      </Accordion.Title>
    )
  }

  render() {
    if (this.state.encounters) {
      const tableData = this.generateTableData(this.state.encounters)
      return (
        <div className="condition">
          { tableData.map(previous => (
            this.getAccordionTitle(previous)
          )) }
        </div>
      )
    }
    return null

  }
}

export default connect (
  reduxState => ({ currentUser: reduxState.user })
)(PreviousEncounters)