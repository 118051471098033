/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-syntax */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/sort-comp */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unused-state */
// import React, { Component } from 'react'
// import { Accordion } from 'semantic-ui-react'
// import { format } from 'date-fns'
import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react'
import { api } from '../../../action/api'
import { API_URL } from '../../../constant'
import { Encounter, EncounterCopy, EncounterDetail } from '../../authenticated/types'
import PreviousEncounterTable from '../snapshot/previousEncounterTable'

/**
 * Provides a component that renders previous encounters for a specified patient
 * @param encounterId Encounter ID to determine past encounters
 * @param subjectId Patient ID to determine previous encounters
 * @param onClickGoToPrevious Callback for navigation to previous encounter
 *        Arguments: Encounter ID
 */

type Props = {
  encounter: Encounter
  subjectId: string
  isEncounterLocked: boolean
  onClickGoToPrevious: (id: string | undefined) => void
  setEncounterDetail: Dispatch<SetStateAction<EncounterDetail>>
  setIsACopiedEncounter: Dispatch<SetStateAction<boolean>>
}

function PreviousEncountersSticky(props: Props) {
  const {
    subjectId,
    encounter,
    isEncounterLocked,
    onClickGoToPrevious,
    setEncounterDetail,
    setIsACopiedEncounter
  } = props

  const encounterId: string | undefined = encounter.id

  const [ encounters, setEncounters ] = useState<Encounter[]>([])

  const fetchEncounterCopies = async (encId: string | undefined): Promise<void> => {
    const { data } = await api.get(API_URL.GET_ENCOUNTER_COPIES(encId))
    const hasEncounterCopies = (data as Array<EncounterCopy>).length > 0
    if ( hasEncounterCopies ) {
      setIsACopiedEncounter(true)
    }
  }

  const fetchEncounters = useCallback(async () => {
    const url = API_URL.GET_PREVIOUS_ENCOUNTERS_BY_SUBJECT_ID(
      subjectId,
      encounterId
    )
    const response = await api.get(url)
    setEncounters(response.data)
    if ( isEncounterLocked ) {
      fetchEncounterCopies(encounterId)
    }
  }, [ subjectId, encounterId ])

  useEffect(() => {
    fetchEncounters()
  }, [ fetchEncounters ])

  return (
    <PreviousEncounterTable
      currentEncounter={ encounter }
      isCurrentEncounterLocked={ props.isEncounterLocked }
      previousEncounters={ encounters }
      onClick={ onClickGoToPrevious }
      setEncounterDetail={ setEncounterDetail }
      setIsACopiedEncounter={ setIsACopiedEncounter }
    />
  )
}

export default PreviousEncountersSticky
