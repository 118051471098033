import React from 'react'
import { List } from 'semantic-ui-react'
import { format } from 'date-fns'
// Local
import { CONDITION, FORMAT } from '../../../constant'
import {
  headerKeyValueMap,
  codingOptionsKeyValueMap,
  coderOnClaimStatusKeyValueMap,
  coderReportableStatusKeyValueMap,
  formatCoderAdditionOfIcd10Reason,
  condition as conditionUtils
} from '../../common/conditionUtils'
import { formatIcd10Code } from '../../common/formatUtils'
import {
  EncounterConditionCoderTagsRow,
  EncounterConditionDisplay
} from '../../authenticated/types'
import { ConditionType } from '../../patient/patient'

/**
 * Provides a read-only view of the state of Coder encounter condition coder
 * tag properties
 *
 * @param condition Condition entity (mapping is coderTags.encounterConditionId === condition.id)
 */

type Props = {
  condition: EncounterConditionDisplay
}

function CoderTagsRead(props: Props): JSX.Element {
  const { condition } = props

  const renderActivityTags = (
    coderTags: EncounterConditionCoderTagsRow | null
  ): JSX.Element => {
    const activityStatus = coderTags ? coderTags.activityMetStatusCode : ''
    return (
      <div>
        { activityStatus === CONDITION.ACTIVITY_MET_STATUS.ACTIVITY_MET && (
          <span>
            Activity Marked as <strong>Met</strong>
          </span>
        ) }
        { activityStatus === CONDITION.ACTIVITY_MET_STATUS.ACTIVITY_NOT_MET && (
          <span>
            Activity Marked as <strong>Not Met</strong>
          </span>
        ) }
        { activityStatus === CONDITION.ACTIVITY_MET_STATUS.NOT_APPLICABLE && (
          <span>
            Activity Marked as <strong>Not Applicable</strong>
          </span>
        ) }
      </div>
    )
  }

  const renderConditionQueryTags = (
    coderTags: EncounterConditionCoderTagsRow | null
  ): JSX.Element => (
    <div>
      { coderTags &&
        (coderTags.queryCreationDate || coderTags.queryResponseDate) && (
        <div className="ui segment raised">
          { coderTags.queryCreationDate && (
            <div>
              <span>Query sent: </span>
              <span>
                { format(coderTags.queryCreationDate, FORMAT.DATE_TIME) }
              </span>
              { coderTags.queryCreationUsername && (
                <div className="p-1-left">
                  { ' ' }
                    - { coderTags.queryCreationUsername }
                </div>
              ) }
            </div>
          ) }
          { coderTags.queryResponseDate && (
            <div className="m-1-top">
              <span>Query response: </span>
              <span>
                { format(coderTags.queryResponseDate, FORMAT.DATE_TIME) }
              </span>
              { coderTags.queryResponseUsername && (
                <div className="p-1-left">
                  { ' ' }
                    - { coderTags.queryResponseUsername }
                </div>
              ) }
            </div>
          ) }
        </div>
      ) }
    </div>
  )

  const renderConditionReasonTags = (
    coderTags: EncounterConditionCoderTagsRow | null
  ): JSX.Element => {
    const coderOnClaimStatusText =
      coderTags &&
      coderTags.onClaimStatusCode &&
      (coderOnClaimStatusKeyValueMap as Record<string, any>)[
        coderTags.onClaimStatusCode
      ].text
        ? (coderOnClaimStatusKeyValueMap as Record<string, any>)[
          coderTags.onClaimStatusCode
        ].text
        : null
    const coderReportableStatusText =
      coderTags &&
      coderTags.reportableStatusCode &&
      (coderReportableStatusKeyValueMap as Record<string, any>)[
        coderTags.reportableStatusCode
      ].text
        ? (coderReportableStatusKeyValueMap as Record<string, any>)[
          coderTags.reportableStatusCode
        ].text
        : null
    const coderReportableHeaderGroup =
      coderTags &&
      coderTags.reportableHeaderCode &&
      (headerKeyValueMap as Record<string, any>)[coderTags.reportableHeaderCode]
        .text
        ? (headerKeyValueMap as Record<string, any>)[
          coderTags.reportableHeaderCode
        ].text
        : null
    const coderReportableReason =
      coderTags &&
      coderTags.reportableReason &&
      (codingOptionsKeyValueMap as Record<string, any>)[
        coderTags.reportableReason
      ].text
        ? (codingOptionsKeyValueMap as Record<string, any>)[
          coderTags.reportableReason
        ].text
        : null

    if (
      coderTags &&
      coderTags.onClaimStatusCode === CONDITION.ON_CLAIM_STATUS.NOT_ON_CLAIM &&
      coderTags.reportableStatusCode === CONDITION.REPORTABLE_STATUS.REPORTABLE
    ) {
      return (
        <div>
          <div className="header small">
            <strong>
              <em>Coder Detail</em>
            </strong>
          </div>
          <ul>
            <List.Item>
              <strong>
                <em>On Claim Status: </em>
              </strong>
              { coderOnClaimStatusText || 'No on claim status code recorded' }
            </List.Item>
            <List.Item>
              <strong>
                <em>Reportable Status: </em>
              </strong>
              { coderReportableStatusText ||
                'No reportable status code recorded' }
            </List.Item>
            <List.Item>
              <strong>
                <em>Reportable Reason: </em>
              </strong>
              { formatCoderAdditionOfIcd10Reason(coderTags) }
            </List.Item>
          </ul>
        </div>
      )
    }

    if (
      coderTags &&
      coderTags.onClaimStatusCode === CONDITION.ON_CLAIM_STATUS.ON_CLAIM &&
      coderTags.reportableStatusCode === CONDITION.REPORTABLE_STATUS.REPORTABLE
    ) {
      return (
        <div>
          <div className="header small">
            <strong>
              <em>Coder Detail</em>
            </strong>
          </div>
          <ul>
            <List.Item>
              <strong>
                <em>On Claim Status: </em>
              </strong>
              { coderOnClaimStatusText || 'No on claim status code recorded' }
            </List.Item>
            <List.Item>
              <strong>
                <em>Reportable Status: </em>
              </strong>
              { coderReportableStatusText ||
                'No reportable status code recorded' }
            </List.Item>
          </ul>
        </div>
      )
    }

    if (
      coderTags &&
      coderTags.reportableHeaderCode === CONDITION.REPORTABLE_HEADER.OTHER
    ) {
      return (
        <div>
          <div className="header small">
            <strong>
              <em>Coder Detail</em>
            </strong>
          </div>
          <ul>
            <List.Item>
              <strong>
                <em>On Claim Status: </em>
              </strong>
              { coderTags.onClaimStatusCode && coderOnClaimStatusText
                ? coderOnClaimStatusText
                : 'No on claim status code recorded' }
            </List.Item>
            <List.Item>
              <strong>
                <em>Reportable Status: </em>
              </strong>
              { coderTags.reportableStatusCode && coderReportableStatusText
                ? coderReportableStatusText
                : 'No reportable status code recorded' }
            </List.Item>
            <List.Item>
              <strong>
                <em>Reportable Text: </em>
              </strong>
              { coderTags.reportableText
                ? coderTags.reportableText
                : 'No reportable text recorded' }
            </List.Item>
          </ul>
        </div>
      )
    }

    return (
      <div>
        <div className="header small">
          <strong>
            <em>Coder Detail</em>
          </strong>
        </div>
        { coderTags && (
          <ul>
            <List.Item>
              <strong>
                <em>On Claim Status: </em>
              </strong>
              { coderTags.onClaimStatusCode && coderOnClaimStatusText
                ? coderOnClaimStatusText
                : 'No on claim status code recorded' }
            </List.Item>
            <List.Item>
              <strong>
                <em>Reportable Status: </em>
              </strong>
              { coderTags.reportableStatusCode && coderReportableStatusText
                ? coderReportableStatusText
                : 'No reportable status code recorded' }
            </List.Item>
            <List.Item>
              <strong>
                <em>Reportable Reason Group: </em>
              </strong>
              { coderTags.reportableHeaderCode && coderReportableHeaderGroup
                ? coderReportableHeaderGroup
                : 'No reportable group recorded' }
            </List.Item>
            <List.Item>
              <strong>
                <em>Reportable Reason: </em>
              </strong>
              { coderTags.reportableReason && coderReportableReason
                ? coderReportableReason
                : 'No reportable reason recorded' }
            </List.Item>
            { coderTags.reportableHeaderCode ===
              CONDITION.REPORTABLE_HEADER.CODING_DETAILS &&
              coderTags.reportableReason ===
                CONDITION.REPORTABLE_REASON
                  .MORE_OR_LESS_SPECIFIC_CODE_DOCUMENTED && (
              <List.Item>
                <strong>
                  <em>Coder ICD10 Diagnosis Code: </em>
                </strong>
                { `${formatIcd10Code(coderTags.conditionDiagnosisCode)}` }
              </List.Item>
            ) }
          </ul>
        ) }
      </div>
    )
  }

  return (
    <div>
      { /* // TODO: This needs to be using a util ~Tal */ }
      { condition.conditionStatus === ConditionType.Activity &&
        renderActivityTags(condition.coderTags) }
      { conditionUtils.isKnownOrPotential(condition) &&
        renderConditionQueryTags(condition.coderTags) &&
        renderConditionReasonTags(condition.coderTags) }
    </div>
  )
}

export default CoderTagsRead
