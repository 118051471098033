import React from 'react'
import { Grid } from 'semantic-ui-react'

export type Column = {
  value: string
  width: number
}

type Props = {
  column: Column
  record: any
}

function MobileCell(props: Props) {
  const { column, record } = props
  const { value, width } = column
  return (
    <Grid.Column key={ value } width={ width }>
      { record[value] }
    </Grid.Column>
  )
}

export default MobileCell
