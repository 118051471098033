import keyBy from "lodash/keyBy";
import forEach from "lodash/forEach";

export const SET_SELECTED_CONDITION = "CONDITION_SET_SELECTED_CONDITION";
export const SET_SELECTED_PREVIOUS_CONDITION = "CONDITION_SET_SELECTED_PREVIOUS_CONDITION";
export const UPDATE_ENCOUNTER_CONDITIONS = "CONDITION_UPDATE_ENCOUNTER_CONDITIONS";
export const UPDATE_PREVIOUS_CONDITIONS = "CONDITION_UPDATE_PREVIOUS_CONDITIONS";

export const UPDATE_EDIT_CONDITION = "CONDITION_UPDATE_EDIT_CONDITION";
export const RESET_EDIT_CONDITION = "CONDITION_RESET_EDIT_CONDITION";

export const NOT_ADDRESSED = "CONDITION_NOT_ADDRESSED";
export const ADDRESSED = "CONDITION_ADDRESSED";

export const CLEAR_STATE = "CONDITION_CLEAR_STATE";
export const CLEAR_ALL_STATE = "CLEAR_ALL_STATE";

const initState = {
  conditionIndex: undefined,
  previousConditionIndex: undefined,
  editConditionId: undefined,
  list: {
    byId: {}
  },
  previousList: {
    byId: {},
    byConditionClassifierId: {}
  }
};

export default (state = initState, action) => {
  let condition, newState;
  switch (action.type) {
    case CLEAR_STATE:
    case CLEAR_ALL_STATE:
      return initState;
    case SET_SELECTED_CONDITION:
      return Object.assign({}, state, {
        conditionIndex: action.payload
      });
    case SET_SELECTED_PREVIOUS_CONDITION:
      return Object.assign({}, state, {
        previousConditionIndex: action.payload
      });

    case RESET_EDIT_CONDITION:
      return Object.assign({}, state, { editConditionId: undefined });
    case UPDATE_EDIT_CONDITION:
      return Object.assign({}, state, { editConditionId: action.payload });
    case NOT_ADDRESSED:
      condition = Object.assign({}, state.list.byId[action.payload]);
      newState = Object.assign({}, state);
      condition.conditionCode = "OPEN";
      newState.list.byId[action.payload] = condition;
      return newState;
    case ADDRESSED:
      condition = Object.assign({}, state.list.byId[action.payload]);
      newState = Object.assign({}, state);
      condition.conditionCode = "ADDRESSED";
      newState.list.byId[action.payload] = condition;
      return newState;
    case UPDATE_ENCOUNTER_CONDITIONS:
      return Object.assign({}, state, { list: { byId: keyBy(action.payload, condition => condition.id) } });
    case UPDATE_PREVIOUS_CONDITIONS:
      if (!action.payload) {
        return Object.assign({}, state, { previousList: { byId: {}, byConditionClassifierId: {} } });
      }
      var conditionClassifierList = {};
      forEach(action.payload, previous => {
        if (!conditionClassifierList[previous.conditionClassifierId]) {
          conditionClassifierList[previous.conditionClassifierId] = [];
        }
        conditionClassifierList[previous.conditionClassifierId].push(previous);
      });
      return Object.assign({}, state, {
        previousList: {
          byId: keyBy(action.payload, previous => previous.id),
          byConditionClassifierId: conditionClassifierList
        }
      });
    default:
      return state;
  }
};
