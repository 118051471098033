import { identity } from 'ramda'
import { RULE_TYPE_CODES } from '../../../constant'
import { EncounterConditionDisplay } from '../../authenticated/types'

export const removeEmptyValues = <Type>(x: Type[]): Type[] => x.filter(identity)

export const capitalizeFirstLetter = (x: string | null): string =>
  x ? `${x.charAt(0).toUpperCase()}${x.slice(1)}` : ''

/**
 * @param icd10 ICD10 code ("raw" ICD10 after stripped from JSON string array)
 * @return Formatted ICD10 code or empty string if param is null or zero length
 */
export function formatIcd10Code(icd10: string): string {
  if (icd10 && icd10.length > 0) {
    if (icd10.includes('.')) return icd10.toUpperCase()
    const result =
      icd10.slice(0, 3) + (icd10.length > 3 ? `.${icd10.slice(3)}` : '')
    return result.toUpperCase()
  }
  return ''
}

/**
 *
 * @param x string
 * @returns string
 * @description Function assumes the input is all caps with an underscore
 * @example toCapitalized('SOME_STRING') => 'Some String'
 */
const toCapitalized = (x: string): string => {
  const firstLetter = x.substring(0, 1)
  const rest = x.substring(1, x.length)
  return `${firstLetter}${rest.toLocaleLowerCase().replace('_', ' ')}`
}

const dateOptions = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit'
} as const

export const formatDate = (x: Date | string): string =>
  new Date(x).toLocaleDateString('en-us', dateOptions)

export const isNLPDocument = (condition: EncounterConditionDisplay): boolean =>
  !!condition.rules &&
  !!condition.rules.length &&
  !!condition.rules[0].ruleInputJson &&
  condition.rules[0].ruleInputJson.type === RULE_TYPE_CODES.NLP_DOCUMENT.key

/**
 *
 * @param mrnString mrnString Backend is returning MRNs to us as '1234;5678;0235'
 * @returns an array of MRNs
 * @example mrns('1234;5678;0235') => [ '1234', '5678', '0235' ]
 */
export const formatMRNs = (mrnString: string): string[] => mrnString.split(';')
const lineBreak = '\n'
const allSemiColons = /;/g

/**
 *
 * @param x string
 * @returns string
 * @example semiColonsToLineBreaks('123;456') => '123 456'
 * @description Used generally in combination with CSS white-space pre-line
 * to break strings onto multiple lines without the need for extra markup
 */
export const semiColonsToLineBreaks = (x: string): string =>
  x.replace(allSemiColons, lineBreak)

export default {
  removeEmptyValues,
  capitalizeFirstLetter,
  formatIcd10Code,
  toCapitalized,
  formatDate,
  isNLPDocument,
  formatMRNs,
  semiColonsToLineBreaks
}
