import React, { Dispatch, SetStateAction } from 'react'
import { Modal } from 'semantic-ui-react'
// Local
import scheduleEncounter from './scheduleEncounter'
import {
  allowPatientTabSchedulingCdi,
  allowPatientTabSchedulingProvider
} from '../currentUserUtils'
import PractitionerFlow from '../practitionerFlow'
import If from '../if'
import CdiFlow from '../cdiFlow'
import Close from '../../icons/close'
import { User } from '../../authenticated/types'
import AppointmentScheduleFlow from './appointmentScheduleFlow'
import { isOptum } from '../tenantUtils'

type ScheduleModalProps = {
  encounterIdState: string
  setEncounterIdState: Dispatch<SetStateAction<string>>
  isModalShowing: boolean
  setIsModalShowing: Dispatch<SetStateAction<boolean>>
  practitionerId: string
  setIsScheduleCallBeingMade: Dispatch<SetStateAction<boolean>>
  user: User
}

function ScheduleModal(props: ScheduleModalProps): JSX.Element {
  const {
    setIsModalShowing,
    isModalShowing,
    encounterIdState,
    setEncounterIdState,
    practitionerId,
    setIsScheduleCallBeingMade,
    user
  } = props
  return (
    <Modal
      onClose={ () => setIsModalShowing(false) }
      onOpen={ () => setIsModalShowing(true) }
      open={ isModalShowing }
      size="tiny"
      closeIcon={
        <div className="modal-close">
          <div className="close-icon">
            <Close />
          </div>
        </div>
      }
      closeOnDimmerClick={ false }
      // trigger={ <Button>Basic Modal</Button> }
    >
      <If isTrue={ allowPatientTabSchedulingProvider(user) }>
        <AppointmentScheduleFlow
          encounterIdState={ encounterIdState }
          setEncounterIdState={ setEncounterIdState }
          setIsScheduleCallBeingMade={ setIsScheduleCallBeingMade }
          scheduleEncounter={ scheduleEncounter }
          setIsModalShowing={ setIsModalShowing }
          practitionerId={ practitionerId }
        />
        {/* <PractitionerFlow
          encounterIdState={ encounterIdState }
          setEncounterIdState={ setEncounterIdState }
          setIsScheduleCallBeingMade={ setIsScheduleCallBeingMade }
          scheduleEncounter={ scheduleEncounter }
          setIsModalShowing={ setIsModalShowing }
          practitionerId={ practitionerId }
        /> */}
      </If>
      <If isTrue={ allowPatientTabSchedulingCdi(user) }>
        <CdiFlow
          setIsScheduleCallBeingMade={ setIsScheduleCallBeingMade }
          setEncounterIdState={ setEncounterIdState }
          encounterIdState={ encounterIdState }
        />
      </If>
    </Modal>
  )
}

export default ScheduleModal
