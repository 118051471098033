/* eslint-disable no-param-reassign */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/sort-comp */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/unbound-method */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Accordion } from 'semantic-ui-react'
import concat from 'lodash/concat'
import filter from 'lodash/filter'
import uniq from 'lodash/uniq'
import { CONDITION, NOTE, ROLES } from '../../../constant'
import {
  condition as conditionUtils,
  conditionGroupTitle,
  filterNlpConditions,
  filterWithStatus,
  hasConditionsOrActivities,
  orderConditionsByHccConfidenceDescending
} from '../../common/conditionUtils'
import {
  encounterConditionGroups,
  encounterConditionGroupsEnabled
} from '../../common/currentUserUtils'
import * as lockUtils from '../../common/lockUtils'
import ConditionHeader from '../common/conditionHeader'
import ConditionCreate from '../common/conditionCreate'
import CdiConditionEdit from './cdiConditionEdit'
import CdiConditionRead from './cdiConditionRead'
import If from '../../common/if'
import '../detail.css'

/**
 * Provides a component that renders the CDI encounter conditions and condition
 * editors in the Version 1 display format.
 *
 * In version 1 of the display, conditions are shown in a list ordered by
 * HCC code with unaddressed conditions sorting to the top
 *
 * @param encounterDetail Encounter detail entity
 * @param activeConditionId Active condition ID (default: null). This is the
 *        last condition that the user acted on
 * @param onConditionAction Callback for condition action.
 *        Arguments: action, condition
 * @param onConditionNoteAction Callback for condition note action
 *        Arguments action, condition, content, reason code (default: OPEN)
 */
class CdiEncounterConditionsV1 extends Component {

  constructor(props) {
    super(props)
    this.onClickConditionTitle = this.onClickConditionTitle.bind(this)
    this.onHeaderAction = this.onHeaderAction.bind(this)
    this.onSubmitConditionNote = this.onSubmitConditionNote.bind(this)
    this.onCreateCondition = this.onCreateCondition.bind(this)

    const { activeConditionId } = this.props
    const activeConditions = activeConditionId ? [ activeConditionId ] : []

    // TODO - sboles - remove activeConditionId from state.
    //      - activeConditions should be sufficient. Maybe...
    this.state = {
      activeConditionId,
      activeConditions
    }
  }

  addActive(condition) {
    const { activeConditions } = this.state
    if (!this.isActive(condition)) {
      let active = activeConditions
      // special case where only 1 condition is active at a time
      if (active.length === 1) {
        active = [ condition.id ]
      } else {
        active.push(condition.id)
      }
      this.setState({ activeConditions: active })
    }
  }

  removeActive(condition) {
    if (this.isActive(condition)) {
      const active = filter(this.state.activeConditions, id => id !== condition.id)
      this.setState({ activeConditions: active })
    }
  }

  onClickConditionTitle(condition) {
    if (this.isActive(condition)) {
      this.removeActive(condition)
    } else {
      this.addActive(condition)
    }
  }

  onHeaderAction(action, conditions) {
    const ids = conditions.map(c => c.id)
    if (action === CONDITION.ACTION.EXPAND_ALL) {
      const active = uniq(concat(ids, this.state.activeConditions))
      this.setState({ activeConditions: active })
    }
    else if (action === CONDITION.ACTION.COLLAPSE_ALL) {
      const active = filter(this.state.activeConditions, id => !ids.includes(id))
      this.setState({ activeConditions: active })
    }
  }

  onSubmitConditionNote(condition, noteContent, noteReason) {
    const { onConditionNoteAction } = this.props
    onConditionNoteAction(
      NOTE.ACTION.CREATE,
      condition,
      noteContent,
      noteReason
    )
  }

  onCreateCondition(condition) {
    const { onConditionAction } = this.props
    onConditionAction(CONDITION.ACTION.CREATE, condition)
  }

  isActive(condition) {
    const { activeConditions } = this.state
    const hasResult = activeConditions.includes(condition.id)
    return hasResult
  }

  sortConditions(conditions) {
    const addressed = filter(conditions, condition => conditionUtils.isConditionAddressed(condition))
    const notAddressed = filter(conditions, condition => !conditionUtils.isConditionAddressed(condition))
    return notAddressed.concat(addressed)
  }

  render() {
    const { currentUser, encounterDetail } = this.props
    const detail = encounterDetail
    const { lock } = detail.encounter
    const { patient } = detail.encounter
    const conditions = filterNlpConditions(currentUser, detail.conditions)
    const conditionGroups = encounterConditionGroups(currentUser)
    const groupsEnabled = encounterConditionGroupsEnabled(currentUser)
    const ignoreCodes = [ CONDITION.CODE.REMOVED, CONDITION.CODE.DISMISSED ]
    const editMode = lockUtils.canEdit(lock, currentUser)
    const key = detail.encounter.id
    const displayConditions = []

    conditionGroups.forEach(g => {
      if (groupsEnabled[g] === true) {
        displayConditions.push(
          {
            title: conditionGroupTitle(g),
            conditions: this.sortConditions(
              orderConditionsByHccConfidenceDescending(
                filterWithStatus(ROLES.CDI, conditions, g, ignoreCodes)
              )
            )
          }
        )
      }
    })

    displayConditions.push(
      {
        title: 'Removed Conditions',
        conditions: orderConditionsByHccConfidenceDescending(filter(conditions, c => c.conditionCode === CONDITION.CODE.REMOVED ||
          c.conditionCode === CONDITION.CODE.DISMISSED))
      }
    )

    return (
      <div key={ key }>
        {
          displayConditions.map((display, displayIndex) => {
            display.hasInsightsToShow = hasConditionsOrActivities(display.conditions, currentUser.type)
            return (
              <div key={ displayIndex }>
                <ConditionHeader title={ display.title }
                  displayIndex={ displayIndex + 1 }
                  conditions={ display.conditions }
                  onAction={ this.onHeaderAction }
                />
                { display.hasInsightsToShow &&
                  <Accordion fluid styled>
                    {
                      display.conditions.map((condition, index) => editMode ? (
                        <CdiConditionEdit key={ condition.id }
                          condition={ condition }
                          displayIndex={ index }
                          isActive={ this.isActive(condition) }
                          onClickTitle={ this.onClickConditionTitle }
                          onSubmitNote={ this.onSubmitConditionNote }
                          onConditionAction={ this.props.onConditionAction }
                          hccChild={ false }
                        />
                      ) : (
                        <CdiConditionRead key={ condition.id }
                          condition={ condition }
                          displayIndex={ index }
                          isActive={ this.isActive(condition) }
                          onClickTitle={ this.onClickConditionTitle }
                          onSubmitNote={ this.onSubmitConditionNote }
                          hccChild={ false }
                        />
                      ))
                    }
                  </Accordion>
                }
                <If isTrue={ !display.hasInsightsToShow }>
                  <Accordion styled fluid>
                    <Accordion.Content>
                      <span>{ `No ${display.title} to show at this time.` }</span>
                    </Accordion.Content>
                  </Accordion>
                </If>
              </div>
            )
          })
        }
        <If isTrue={ editMode }>
          <div>
            <ConditionHeader title="Add Condition" />
            <Accordion fluid styled>
              <ConditionCreate
                patient={ patient }
                onSave={ this.onCreateCondition }
              />
            </Accordion>
          </div>
        </If>
      </div>
    )
  }
}

export default connect(
  state => ({
    currentUser: state.user
  }), {}
)(CdiEncounterConditionsV1)
