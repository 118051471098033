/* eslint-disable default-param-last */
export const HAS_FORM_UPDATES = 'HAS_FORM_UPDATES'

export type CoderState = {
  hasFormUpdates: boolean
}

const inititalState: CoderState = {
  hasFormUpdates: false
}

type Action = {
  type: typeof HAS_FORM_UPDATES
  payload: boolean
}

export default (state = inititalState, action: Action) => {
  switch (action.type) {
    case HAS_FORM_UPDATES:
      return { hasFormUpdates: action.payload }
    default:
      return state
  }
}
