/* eslint-disable @typescript-eslint/naming-convention */
import axios, { AxiosResponse } from 'axios'
import * as Sentry from '@sentry/browser'
// import { method } from 'lodash'
import { BASE_URL } from '../config'
import store from '../store'
import { MESSAGE } from '../constant'
import { CLEAR_ALL_STATE, UPDATE_LOGIN } from '../reducer/user'
import { HttpErrorType } from '../../types'

/**
 * Provides a wrapper and helpers for API requests
 */
export const api = {
  isLocalDevWindow: window.location.host && window.location.host.includes('localhost') ||
    window.location.host.includes('curationhealthui.ngrok'),
  // eslint-disable-next-line @typescript-eslint/naming-convention
  defaultHeaders: {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    headers: { 'content-type': 'application/json' },
    withCredentials: true
  },

  defaultResponseHandler: (response: AxiosResponse<any>) => response,

  defaultErrorHandler: (error: HttpErrorType) => {
    if (error && error.response && error.response.status === 403) {
      store.dispatch({ type: CLEAR_ALL_STATE })
      store.dispatch({
        type: UPDATE_LOGIN,
        payload: { authMessage: MESSAGE.LOGOUT }
      })
      return Promise.reject(error)
    }
    Sentry.captureException(error)
    return Promise.reject(error)
  },

  get: async (url: string, config?: Record<string, any>) => {
    let completeConfig = Object.assign({}, config)
    if ( api.isLocalDevWindow ) {
      // use vanilla config in local development
      completeConfig = Object.assign({withCredentials: true}, config)
    } else {
      // in deployed instances add cache control headers for legacy IE support
      completeConfig = Object.assign({
        withCredentials: true,
        headers: {
          // IE11 cache buster to support Epic integration
          'Cache-Control': 'no-cache, max-age=0',
          'Pragma': 'no-cache',
          'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT',
          'If-Modified-Since': '0'
        }
      }, config)
    }
    try {
      const response = await axios.get(BASE_URL + url, completeConfig)
      return api.defaultResponseHandler(response)
    } catch (error) {
      return api.defaultErrorHandler(error as HttpErrorType)
    }
  },

  getUnsafe: (url: string, config?: Record<string, any>) => {
    const completeConfig = Object.assign({ withCredentials: true }, config)
    return axios.get(BASE_URL + url, completeConfig)
  },

  put: async (url: string, params?: unknown, config?: Record<string, any>) => {
    const completeConfig = Object.assign(api.defaultHeaders, config)
    try {
      const response = await axios.put(BASE_URL + url, params, completeConfig)
      return api.defaultResponseHandler(response)
    } catch (error) {
      return api.defaultErrorHandler(error as HttpErrorType)
    }
  },

  post: async (url: string, params: unknown, config?: Record<string, any>) => {
    const completeConfig = Object.assign(api.defaultHeaders, config)
    try {
      const response = await axios.post(BASE_URL + url, params, completeConfig)
      return api.defaultResponseHandler(response)
    } catch (error) {
      return api.defaultErrorHandler(error as HttpErrorType)
    }
  },

  postUnsafe: (url: string, params: unknown, config?: Record<string, any>) => {
    const completeConfig = Object.assign(api.defaultHeaders, config)
    return axios.post(BASE_URL + url, params, completeConfig)
  }
}

export default api
