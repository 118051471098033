import React, { Component } from "react";
import { Button, Form, Icon, Message, Popup } from "semantic-ui-react";
import { API_URL } from '../../constant'
import { api } from '../../action/api'

/**
 * Provides an MFA form. Assumes that if the UI is showing the MFA code entry
 * form than the user has completed
 * @param id An ID attribute prefix used to identify component elements
 * @param onSubmit Login form submit callback function
 *        Arguments: MFA code, username, password, rememberMe
 * @param email Optional default email address
 * @param username Optional default username
 * @param password Optional default password
 * @param errorMessage Optional default error message
 */
class MfaForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      email: this.props.email ? this.props.email : "",
      username: this.props.username ? this.props.username : "",
      password: this.props.password ? this.props.password : "",
      mfaCode: "",
      rememberMe: true,
      showResendStatus: false,
      isLoading: false
    };
  }

  onSubmit = () => {
    const email = this.state.email;
    const username = this.state.username;
    const password = this.state.password;
    const mfaCode = this.state.mfaCode;
    const rememberMe = this.state.rememberMe;
    if ( username.length > 0 && password.length > 0 && this.validateMfaCode(mfaCode) ) {
      this.setState({isLoading: true}, () => {
        this.props.onSubmit(mfaCode, email, username, password, rememberMe);
      });
    }
  }

  onClickResendStatusClose = () => {
    this.setState({
      showResendStatus: false
    });
  }

  resendMfaCode = () => {
    const email = this.state.email;
    this.setState({isLoading: true}, () => {
      api.postUnsafe(API_URL.MFA_CODE_RESEND, { email }).then(({ data }) => {
        this.setState({
          isLoading: false,
          showResendStatus: true
        });
      });
    });
  }

  onChangeMfaCode = (e, { name, value }) => {
    this.setState({ [name]: value });
  };

  toggleRememberMe = (checked) => {
    this.setState({ rememberMe: checked});
  }

  maskEmail(email) {
    if ( email && email.includes('@') ) {
      const name = email.split('@')[0];
      const domain = email.split('@')[1];
      const first = name.slice(0, 1);
      const last = name.slice(-1);
      return first + '...' + last + '@' + domain;
    } else {
      return email;
    }
  }

  validateMfaCode(code) {
    if ( code ) {
      return code.length === 7 && /^\d+$/.test(code);
    }
    return false;
  }

  render() {
    const id = this.props.id;
    const email = this.state.email;
    const mfaCode = this.state.mfaCode;
    const rememberMe = this.state.rememberMe;
    const errorMessage = this.props.errorMessage;
    const showResendStatus = this.state.showResendStatus;
    const rememberMePopupContent = "By selecting this box, we will wait" +
      " 30 days before we confirm your identity again. Do not select" +
      " this option if this computer is used by multiple people.";

    return (
      <div id={`mfa-${id}-login-container`} className="m-2-top">
        {errorMessage && (
          <Message error={true} content={errorMessage} />
        )}
        <div className="mfa-login-form">
          <div className="m-1-top">
            <h2>Verify Your Identity</h2>
          </div>
          <div className="mfa-login-form-header m-1-top">
            For patient protection, additional verification is needed before
            you can continue.
          </div>
          <div className="m-1-top">
            <span>An email has been sent to</span>
            <span> </span>
            <strong>
              <span id={`mfa-${id}-login-email-mask`}>{this.maskEmail(email)}</span>
            </strong>
            <span> </span>
            <span>with a verification code.</span>
          </div>
          <div className="mfa-login-form-warning">
            <strong>
              Do not close this screen. Open your email in a new screen to locate
              your verification code and enter the code below within 10 minutes.
            </strong>
          </div>
          <div className="m-1-top">
            <Form onSubmit={this.onSubmit} error={errorMessage != null}>
              <Form.Group inline>
                <Form.Input
                  id={`mfa-${id}-login-code-input`}
                  label="Verification Code:"
                  placeholder="MFA Code"
                  onChange={this.onChangeMfaCode}
                  name="mfaCode"
                  value={mfaCode}
                />
                <div className="m-1-top">
                  <Button id={`mfa-${id}-login-submit`}
                    color="blue" type="submit"
                    disabled={!this.validateMfaCode(mfaCode) || this.state.isLoading}>
                    Verify
                  </Button>
                </div>
              </Form.Group>
              <div>
                <Form.Group>
                  <Form.Checkbox
                    id={`mfa-${id}-login-remember-me`}
                    label="Remember Me"
                    onChange={(e, data) => this.toggleRememberMe(data.checked)}
                    checked={rememberMe} />
                  <Popup content={rememberMePopupContent} mouseEnterDelay={500}
                    trigger={<Icon name="info circle" className="pointer" />} />
                  </Form.Group>
              </div>
            </Form>
            <div className="m-1-top">
              Not seeing this email? Check your spam/junk folder first for a
              message with the subject line: Your Curation Health Identity
              Verification Code.
              If you do not receive the email or your code expires, select Resend.
            </div>
            <div className="m-1-top text-align-center">
              <Button
                id={`mfa-${id}-login-resend-button`}
                onClick={this.resendMfaCode}
                disabled={this.state.isLoading}
                className="mfa-login-form-resend"
                color="grey">Resend</Button>
            </div>
            { showResendStatus && (
              <div id={`mfa-${id}-login-resend-status`} className="m-1-top">
                <Message>
                  Resent verification code. Please check your email.
                  <span> </span><span className="linkable"
                    onClick={this.onClickResendStatusClose}>Ok</span>
                </Message>
              </div>
            ) }
          </div>
        </div>
      </div>
    );
  }
};

export default MfaForm;
