import React, { Component } from "react";
import { connect } from "react-redux";
import {Button, Form, Icon, Label, Message, Segment } from "semantic-ui-react";
import RichTextEditor from "react-rte";
import filter from "lodash/filter";
import { CONDITION } from "../../../constant";
import { cdiExpectedDiagnosisEnabled } from "../../common/currentUserUtils";
import { formatIcd10Code } from "../../common/formatUtils";
import { NEW_RULE, ReasonHandler } from "../condition/reason";
import Icd10Search from "../condition/Icd10Search";

/**
 * Provides a component that allows creation of a new condition
 * @param patient The patient entity for whom this condition applies. The
 *        patient is used for reference data
 * @param onSave Callback for condition save button click
 *        Arguments: condition
 */
class ConditionCreate extends Component {

  constructor(props) {
    super(props);
    this.onClickSave = this.onClickSave.bind(this);
    this.onClickClear = this.onClickClear.bind(this);
    this.state = this.getInitialState();
  }

  getInitialState() {
    return {
      title: "",
      conditionStatus: "POTENTIAL",
      content: RichTextEditor.createValueFromString("", "markdown"),
      icd10Code: "",
      icd10Desc: "",
      expectedDiagnosisCode: "",
      expectedDiagnosisDesc: "",
      rules: [],
      error: null
    };
  }

  toolbarConfig() {
    return {
      display: ["INLINE_STYLE_BUTTONS", "BLOCK_TYPE_BUTTONS"],
      INLINE_STYLE_BUTTONS: [
        { label: "Bold", style: "BOLD", className: "custom-css-class" },
        { label: "Italic", style: "ITALIC" },
        { label: "Underline", style: "UNDERLINE" }
      ],
      BLOCK_TYPE_BUTTONS: [
        { label: "UL", style: "unordered-list-item" },
        { label: "OL", style: "ordered-list-item" }
      ]
    }
  }

  onChangeContent = (content) => {
    this.setState({ content });
  };

  onChangeValue = (e, { name, value }) => {
    this.setState({ [name]: value });
  };

  onSelectConditionStatus = (e, { name, value }) => {
    if ( value === CONDITION.TYPE.POTENTIAL ) {
      this.setState({ conditionStatus: value });
    } else {
      this.setState({
        conditionStatus: value,
        expectedDiagnosisCode: "",
        expectedDiagnosisDesc: ""
      });
    }
  }

  onSelectIcd10 = (result) => {
    this.setState({
      icd10Code: result.id,
      icd10Desc: result.description,
      title: result.description
    });
  };

  onSelectExpectedDiagnosis = (result) => {
    const code = result && result.id && result.id.length > 0 ? result.id : null;
    this.setState({
      expectedDiagnosisCode: code,
      expectedDiagnosisDesc: result.description
    });
  }

  onClickClear() {
    this.setState(this.getInitialState());
  };

  onClickSave() {
    const title = this.state.title;
    const rules = this.state.rules;
    const content = this.state.content;
    const hccVersion = this.props.patient.rulesHccVersion;
    const icd10Code = this.state.icd10Code;
    const conditionStatus = this.state.conditionStatus;
    const expectedDiagnosisCode = this.state.expectedDiagnosisCode;

    if ( !title || title === "" ) {
      this.setState({ error: {
        field: "title",
         description: "Each Entry Must Have a Title"
       } });
      return;
    }

    const icd10Json = icd10Code && icd10Code.length > 0 ? [ icd10Code ] : null;

    const cdiTags = {};

    if ( expectedDiagnosisCode && expectedDiagnosisCode.length > 0 ) {
      cdiTags.expectedDiagnosis = expectedDiagnosisCode;
    }

    const conditionRules = filter(rules, (rule) => {
      // filter out new rules with empty reasons
      if ( rule.type === NEW_RULE ) {
        return !(rule.reason.length === 0 || !rule.reason.trim());
      } else {
        return true;
      }
    }).map((rule) => {
      if ( rule.type === NEW_RULE ) {
        rule.id = null;
        rule.type = null;
        rule.title = rule.reason;
        rule.statusCode = CONDITION.STATUS.ACTIVE;
      } else if ( rule.reason.length === 0 || !rule.reason.trim() ) {
        rule.statusCode = CONDITION.STATUS.REMOVED;
      }
      return rule;
    });

    const condition = {
      status: CONDITION.STATUS.ACTIVE,
      conditionCode: CONDITION.CODE.OPEN,
      conditionStatus: conditionStatus,
      icd10CodeJson: icd10Json,
      title: title,
      content: content.toString("markdown"),
      addressedDate: null,
      priority: 0,
      hccVersion: hccVersion,
      rules: conditionRules,
      cdiTags: cdiTags
    };

    this.props.onSave(condition);
  };

  render() {
    const error = this.state.error;
    const rules = this.state.rules;
    const title = this.state.title;
    const content = this.state.content;
    const icd10Code = this.state.icd10Code;
    const icd10Desc = this.state.icd10Desc;
    const conditionStatus = this.state.conditionStatus;
    const expectedDiagnosisCode = this.state.expectedDiagnosisCode;
    const expectedDiagnosisDesc = this.state.expectedDiagnosisDesc;

    const currentUser = this.props.currentUser;
    const showExpectedDiagnosis = cdiExpectedDiagnosisEnabled(currentUser) &&
      conditionStatus === CONDITION.TYPE.POTENTIAL;

    const statusOptions = [
      { key: CONDITION.TYPE.POTENTIAL, value: CONDITION.TYPE.POTENTIAL, text: "Potential" },
      { key: CONDITION.TYPE.KNOWN, value: CONDITION.TYPE.KNOWN, text: "Known" },
      { key: CONDITION.TYPE.ACTIVITY, value: CONDITION.TYPE.ACTIVITY, text: "Activity" }
    ];

    const key = (new Date()).getTime();

    return (
      <Segment attached>
        <Message><Icon name="info circle" />
          Add Conditions (HCC or non-HCC, Activity or Care Gap)
        </Message>
        <Form error={error !== null}>
          {error && <Message error>{error.description}</Message>}
          <Form.Group widths="equal">
            <Form.Input
              name="title"
              label="Condition Title"
              error={error !== null && error.field === "title"}
              placeholder="Title"
              value={title}
              onChange={this.onChangeValue}
            />
            <span className="field">
              <label>ICD10 Code</label>
              <Icd10Search
                key={key}
                value={icd10Code}
                onSelect={this.onSelectIcd10} />
            </span>
            <Form.Dropdown fluid selection
              label="Condition Status"
              name="conditionStatus"
              placeholder="Condition Status"
              onChange={this.onSelectConditionStatus}
              options={statusOptions}
              value={conditionStatus} />
          </Form.Group>
          { icd10Code && (
            <div className="m-1-top m-1-bottom">
              <Label pointing="right">ICD10 Code</Label>
              <span>{formatIcd10Code(icd10Code)}</span>
              <span>, </span>
              <span className="italic">{icd10Desc}</span>
            </div>
          )}
          <span className="field">
            <label>Reasons</label>
            <ReasonHandler rules={rules || []} onChangeValue={this.onChangeValue} />
          </span>
          <span className="field">
            <label>Content</label>
            <RichTextEditor
              value={content}
              onChange={this.onChangeContent}
              toolbarConfig={this.toolbarConfig()} />
          </span>
          { showExpectedDiagnosis && (
            <Segment>
              <span className="field">
                <label>Expected Diagnosis</label>
                <Icd10Search
                  key={key}
                  value={expectedDiagnosisCode}
                  onSelect={this.onSelectExpectedDiagnosis}
                  placeholder="Expected Diagnosis" />
              </span>
              <div className="m-1-top">
                {expectedDiagnosisCode && (
                  <div>
                    <Label pointing="right">Expected Diagnosis Code</Label>
                    <span> {formatIcd10Code(expectedDiagnosisCode)}</span>
                    <span>, {expectedDiagnosisDesc}</span>
                  </div>
                )}
                <div className="m-1-top italic">
                  An expected diagnosis code associated with this condition
                  for the purpose of reporting. This diagnosis code is not
                  displayed to the practitioner or coder
                </div>
              </div>
            </Segment>
          )}
          <div className="m-1-top text-align-right">
            <span>
              <Button color="blue" className="condition-fields"
                onClick={this.onClickSave}>
                Add Condition
              </Button>
            </span>
            <span className="m-1-left">
              <Button className="condition-fields" onClick={this.onClickClear}>
                Clear
              </Button>
            </span>
          </div>
        </Form>
      </Segment>
    );
  }
}

export default connect(
  state => ({
    currentUser: state.user
  }), {}
)(ConditionCreate);
