import React, { Component } from "react";
import { connect } from "react-redux";
import { Container,  Button, Grid, Icon, Message, Segment } from "semantic-ui-react";
import { NotificationContainer, NotificationManager } from "react-notifications";
import "react-notifications/lib/notifications.css";
import { API_URL, ATHENA_CHROME_PLUGIN_URL, ATHENA_EDGE_PLUGIN_URL, CRON_JOB  } from "../../constant";
import { api } from '../../action/api';
import { goHome, initiateResetPassword } from "../../action/user";
import { formatRoleName } from "../common/formatUtils";
import { isAthena } from "../common/tenantUtils";
import RoleSelect from "./roleSelect";
import UserPropertyDetails from "./userPropertyDetails";
import "./index.css";

export class Account extends Component {

  handleResetPasswordClick = () => {
    this.props.initiateResetPassword(() => {
        NotificationManager.success('An email has been sent with a link to reset your password.');
      }
    );
  };

  handleResetDemoDataClick = (tenantId) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const url = `${API_URL.CRON_JOB_EXECUTE(CRON_JOB.DEMO_DATA_JOB_ID, tenantId)}`
    const cronJobParamsBody = [] // leave empty for now -- demo data job doesn't need params to run
    api.post(url, cronJobParamsBody).then(({ data }) => {
      NotificationManager.success('Successfully initiated demo data reset'
      + '. The data for this demo tenant will be refreshed momentarily.')
    }).catch( error => {
      NotificationManager.error('Something went wrong, please try the demo data reset again.')
      console.log('API error from attempt to call demo data reset:')
      console.log(error)
    })
  }

  isDemoOrDevEnvironment = () => window.location.href.includes('localhost')
    || window.location.href.includes('demo')

  render() {
    const currentUser = this.props.currentUser;
    const activeRole = currentUser.type;
    const roles = currentUser.roles ? currentUser.roles : [];
    const userProperties = currentUser.userProperties ? currentUser.userProperties : [];
    const tenantId = currentUser.tenantId;
    return (
      <div className="header-padding">
        <Container>
          <NotificationContainer/>
          <Segment>
            <Grid>
              <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={16}>
                  <h3>User Role</h3>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={16}>
                  <span>Active User Role:</span>
                  <span> </span>
                  <span><strong>{formatRoleName(activeRole)}</strong></span>
                </Grid.Column>
              </Grid.Row>
              { roles.length > 1 && (
              <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={16}>
                  <RoleSelect currentUser={currentUser} />
                </Grid.Column>
              </Grid.Row>
              )}
            </Grid>
          </Segment>
          { this.isDemoOrDevEnvironment() && (
            <Segment>
            <Grid>
              <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={16}>
                  <h3>Demo Data Reset</h3>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={16}>
                <Message><Icon name="info circle" />
                  Click "Reset Demo Data" to initiate resetting the demo data back
                   to the defaults for this demo tenant.
                </Message>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={16} textAlign="right">
                  <Button content="Reset Demo Data" onClick={() => this.handleResetDemoDataClick(tenantId)} />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
          )}
          { userProperties.length > 0 && (
          <Segment>
            <Grid>
              <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={16}>
                  <h3>User Properties</h3>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={16}>
                  <UserPropertyDetails currentUser={currentUser} />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
          )}
          <Segment>
            <Grid>
              <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={16}>
                  <h3>Password Reset</h3>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={16}>
                <Message><Icon name="info circle" />
                  Click "Reset Password" to initiate a password reset. You will
                  receive an email with a link and directions for changing your
                  password.
                </Message>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={16} textAlign="right">
                  <Button content="Reset Password" onClick={this.handleResetPasswordClick.bind(this)} />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
          {isAthena(tenantId) && (
          <Segment>
            <Grid>
              <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={16}>
                  <h3>Athena Browser Plugin</h3>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={16}>
                  <span>
                  Platform integration with Athena is provided with a browser
                  plugin. The plugin can be downloaded and installed from the
                  </span>
                  <span> </span>
                  <span>
                    <a href={ATHENA_CHROME_PLUGIN_URL} target="_blank" rel="noopener noreferrer">Google Chrome Web Store</a>
                  </span>
                  <span> and the </span>
                  <span>
                    <a href={ATHENA_EDGE_PLUGIN_URL} target="_blank" rel="noopener noreferrer">Microsoft Edge Web Store</a>
                  </span>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
          )}
        </Container>
      </div>
    );
  }
}

export default connect(
  state => ({currentUser: state.user}),
  { goHome, initiateResetPassword }
)(Account);