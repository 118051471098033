import React, { Component } from "react";
import { CONDITION } from "../../../constant";

/**
 * Provides a view of encounter condition practitioner tags with the option for
 * the provider to undo the selected response
 * @param condition Condition entity
 * @param hccChild True if the condition is a member of an HCC group
 * @param onConditionAction Callback for condition action
 *        Arguments action, condition
 */
class PhysicianConditionTags extends Component {

  constructor(props) {
    super(props);
    this.onClickUndo = this.onClickUndo.bind(this);
    this.state = {
      practitionerTags: this.props.condition.practitionerTags
    }
  }

  onClickUndo() {
    const condition = this.props.condition;
    const action = CONDITION.ACTION.UPDATE_PRACTITIONER_TAGS;
    if ( condition.practitionerTags ) {
      condition.practitionerTags.practitionerResponseCode = null;
      this.props.onConditionAction(action, [condition]);
    }
  }

  renderPractitionerTags(condition) {
    // TODO - sboles - confirm that it is safe to remove the Undo
    //      - and then clean out undo support
    const allowUndo = false;
    return (
      <span>
        <span className="italic">
          <span>Marked as:</span>
          <span> </span>
          <span className="bold">{
            condition.practitionerTags.practitionerResponseCode
          }</span>
          <span> </span>
          <span>by Provider</span>
        </span>
        {allowUndo && (
          <span>
            <span> | </span>
            <span className="linkable" onClick={this.onClickUndo}>Undo</span>
          </span>
        )}
      </span>
    );
  }

  render() {
    const condition = this.props.condition;
    return condition.practitionerTags
      ? this.renderPractitionerTags(condition)
      : null;
  }
}

export default PhysicianConditionTags;
