import React, { Component } from "react";
import { Segment } from "semantic-ui-react";

/**
 * Provides a component that renders the encounter reason
 * @param encounter Encounter entity
 * @param mode Render mode. Default: left
 */
class EncounterReason extends Component {

  renderLeft(encounter) {
    const reason = encounter.reasonDescription
      ? encounter.reasonDescription
      : <span className="italic">Unavailable</span>;

    return (
      <Segment attached compact className="left-segment">
        <div className="left-title">Reason for Visit:</div>
        <div className="left-content">{reason}</div>
      </Segment>
    );
  }

  render() {
    const mode = this.props.mode ? this.props.mode : "left";
    if ( mode === "left" ) {
      return this.renderLeft(this.props.encounter);
    } else {
      return null;
    }
  }
}

export default EncounterReason;
