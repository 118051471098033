import React from 'react'
import { Header, Message } from 'semantic-ui-react'
import { LoginLogo } from '../common/brandingUtils'

function LoginEmpty(props) {
  return (
    <div>
      <Header as="h2" textAlign="center">
        <Header.Content>{ LoginLogo() }</Header.Content>
      </Header>
      { props.errorMessage != null && <Message error={ true } content={ props.errorMessage } /> }
      { props.infoMessage != null && <Message content={ props.infoMessage } /> }
    </div>
  )
}

export default LoginEmpty
