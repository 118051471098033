/* eslint-disable no-param-reassign */
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Accordion } from 'semantic-ui-react'
import concat from 'lodash/concat'
import filter from 'lodash/filter'
import uniq from 'lodash/uniq'
import { without } from 'ramda'
// Local
import { CONDITION, NOTE, ROLES } from '../../../constant'
import * as lockUtils from '../../common/lockUtils'
import {
  condition as conditionUtils,
  conditionGroupTitle,
  filterNlpConditions,
  filterWithStatus,
  hasConditionsOrActivities,
  orderConditionsByHccConfidenceDescending
} from '../../common/conditionUtils'
import {
  encounterConditionGroups,
  encounterConditionGroupsEnabled
} from '../../common/currentUserUtils'
import ConditionHeader from '../common/conditionHeader'
import CoderConditionEdit from './CoderConditionEdit'
import CoderConditionRead from './CoderConditionRead'
import {
  EncounterConditionDisplay,
  EncounterDetail,
  ObjectValues,
  User
} from '../../authenticated/types'
import If from '../../common/if'
// Styling
import '../detail.css'

type DisplayCondition = {
  title: string
  status: ObjectValues<typeof CONDITION.STATUS>
  conditions: EncounterConditionDisplay[]
  hasInsightsToShow?: boolean
}

type Props = {
  activeConditionId: string
  onConditionNoteAction: (
    action: ObjectValues<typeof NOTE.ACTION>,
    condition: EncounterConditionDisplay,
    noteContent: string,
    noteReason: string
  ) => void
  onConditionAction: (condition: EncounterConditionDisplay) => void
  encounterDetail: EncounterDetail
  isFormSaved: boolean
}

type PropsFromRedux = Omit<Props, 'currentUser'> & {
  currentUser: User
}

function CoderEncounterConditions(props: PropsFromRedux): JSX.Element {
  const {
    activeConditionId,
    onConditionNoteAction,
    encounterDetail,
    onConditionAction,
    currentUser,
    isFormSaved
  } = props

  const [ activeConditions, setActiveConditions ] = useState<string[]>(
    activeConditionId ? [ activeConditionId ] : []
  )

  const isActive = (condition: EncounterConditionDisplay) =>
    activeConditions.includes(condition.id)

  const toggleActive = (condition: EncounterConditionDisplay) => {
    const activeConditionsRevised = activeConditions.includes(condition.id)
      ? without([ condition.id ], activeConditions)
      : [ condition.id ]
    setActiveConditions(activeConditionsRevised)
  }

  const onHeaderAction = (
    action: ObjectValues<typeof CONDITION.ACTION>,
    conditions: EncounterConditionDisplay[]
  ) => {
    const ids = conditions.map(c => c.id)
    if (action === CONDITION.ACTION.EXPAND_ALL) {
      const active = uniq(concat(ids, activeConditions))
      setActiveConditions(active)
    } else if (action === CONDITION.ACTION.COLLAPSE_ALL) {
      const active = filter(activeConditions, id => !ids.includes(id))
      setActiveConditions(active)
    }
  }

  const onSubmitConditionNote = (
    condition: EncounterConditionDisplay,
    noteContent: string,
    noteReason: string
  ) => {
    onConditionNoteAction(
      NOTE.ACTION.CREATE,
      condition,
      noteContent,
      noteReason
    )
  }

  const sortConditions = (conditions: EncounterConditionDisplay[]) => {
    const addressed = filter(conditions, condition =>
      conditionUtils.isConditionAddressed(condition))
    const notAddressed = filter(
      conditions,
      condition => !conditionUtils.isConditionAddressed(condition)
    )
    return notAddressed.concat(addressed)
  }

  const conditions = filterNlpConditions(
    currentUser,
    encounterDetail.conditions
  )
  const conditionGroups = encounterConditionGroups(currentUser)
  const groupsEnabled = encounterConditionGroupsEnabled(currentUser)
  const ignoreCodes = [ CONDITION.CODE.REMOVED, CONDITION.CODE.DISMISSED ]
  const isEditMode =
    !!encounterDetail &&
    !!encounterDetail.encounter &&
    lockUtils.canEdit(encounterDetail.encounter.lock, currentUser)
  const displayConditions: DisplayCondition[] = []

  conditionGroups.forEach(g => {
    if (groupsEnabled[g] === true) {
      displayConditions.push({
        title: conditionGroupTitle(g as keyof typeof CONDITION.TYPE),
        status: CONDITION.STATUS.ACTIVE,
        conditions: sortConditions(
          orderConditionsByHccConfidenceDescending(
            filterWithStatus(ROLES.CODER, conditions, g, ignoreCodes)
          )
        )
      })
    }
  })

  displayConditions.push({
    title: 'Removed Conditions',
    status: CONDITION.STATUS.REMOVED,
    conditions: orderConditionsByHccConfidenceDescending(
      filter(
        conditions,
        c =>
          c.conditionCode === CONDITION.CODE.REMOVED ||
          c.conditionCode === CONDITION.CODE.DISMISSED
      )
    )
  })

  return (
    <div>
      { displayConditions.map((display, displayIndex) => {
        display.hasInsightsToShow = hasConditionsOrActivities(
          display.conditions,
          currentUser.type
        )
        return (
          <div key={ display.title }>
            <ConditionHeader
              title={ display.title }
              displayIndex={ displayIndex + 1 }
              conditions={ display.conditions }
              onAction={ onHeaderAction }
            />
            <If isTrue={ display.hasInsightsToShow }>
              <Accordion fluid styled>
                { display.conditions.map((condition, index) => {
                  if (
                    isEditMode &&
                    display.status === CONDITION.STATUS.ACTIVE
                  ) {
                    return (
                      <CoderConditionEdit
                        key={ condition.id }
                        condition={ condition }
                        displayIndex={ index }
                        active={ isActive(condition) }
                        onClickTitle={ toggleActive }
                        activeConditionID={ activeConditionId }
                        onSubmitNote={ onSubmitConditionNote }
                        onConditionAction={ onConditionAction }
                        isFormSaved={ isFormSaved }
                      />
                    )
                  }
                  return (
                    <CoderConditionRead
                      key={ condition.id }
                      condition={ condition }
                      displayIndex={ index }
                      isActive={ isActive(condition) }
                      onClickTitle={ toggleActive }
                      onSubmitNote={ onSubmitConditionNote }
                    />
                  )
                }) }
              </Accordion>
            </If>
            <If isTrue={ !display.hasInsightsToShow }>
              <Accordion styled fluid>
                <Accordion.Content>
                  <span>{ `No ${display.title} to show at this time.` }</span>
                </Accordion.Content>
              </Accordion>
            </If>
          </div>
        )
      }) }
    </div>
  )
}

const mapStateToProps = (state: any): any => ({
  currentUser: state.user
})

export default connect(mapStateToProps)(CoderEncounterConditions)
