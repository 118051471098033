import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Icon, Modal } from 'semantic-ui-react'
// Local
import { ENCOUNTER, REVIEW } from '../../../constant'
import * as lockUtils from '../../common/lockUtils'
import { isReadyForCoderQa } from '../../common/reviewUtils'
import { isCoderQaManager } from '../../common/roleUtils'
import Pdf from '../pdf'
import HeaderIcon from '../common/headerIcon'
import LockIcon from '../common/lockIcon'
import { Encounter, ObjectValues, User } from '../../authenticated/types'

/**
 * Provides a component that renders the Coder action buttons on an encounter
 * header. Encounter actions are determined by the state of the encounter review
 * and the encounter lock
 * @param encounter Encounter
 * @param onClick Callback on button click. Arguments: Button action
 */

type Props = {
  encounter: Encounter
  onClick?: (action: ObjectValues<typeof ENCOUNTER.ACTION>) => void
}

type PropsFromRedux = Omit<Props, 'currentUser'> & {
  currentUser: User
}

function CoderEncounterHeaderButtons(props: PropsFromRedux): JSX.Element {
  const { encounter, onClick, currentUser } = props

  const [ queryStatusCode, setQueryStatusCode ] = useState<
    string | null | undefined
  >(encounter.review && encounter.review.queryStatusCode)

  const onClickFollowUp = () => {
    const queryOutstanding = REVIEW.QUERY_STATUS.REVIEW_QUERY_OUTSTANDING
    const queryReceived = REVIEW.QUERY_STATUS.REVIEW_QUERY_RESPONSE_RECEIVED
    const newStatus =
      queryStatusCode === queryOutstanding ? queryReceived : queryOutstanding
    setQueryStatusCode(newStatus)
    if (onClick) onClick(ENCOUNTER.ACTION.FOLLOW_UP_TOGGLE)
  }

  const followUpColor = () =>
    queryStatusCode === REVIEW.QUERY_STATUS.REVIEW_QUERY_OUTSTANDING
      ? 'red'
      : 'grey'

  const followUpText = () =>
    queryStatusCode === REVIEW.QUERY_STATUS.REVIEW_QUERY_OUTSTANDING
      ? 'Coder Review Requested. Click to mark Resolved'
      : 'Click to request Coder Review'

  const { review } = encounter
  const { lock } = encounter
  const action = ENCOUNTER.ACTION
  let canEdit = true
  if (isReadyForCoderQa(review) && !isCoderQaManager(currentUser.roles)) {
    canEdit = false
  }

  const renderRead = () => (
    <span>
      { canEdit && (
        <span>
          <LockIcon
            lock={ lock }
            currentUser={ currentUser }
            onClick={ () => {
              if (onClick) onClick(action.LOCK_TOGGLE)
            } }
          />
        </span>
      ) }
      <Modal
        size="fullscreen"
        trigger={
          <Icon name="print" size="large" color="grey" className="pointer" />
        }
      >
        <Modal.Content>
          <Pdf encounterId={ encounter.id } />
        </Modal.Content>
      </Modal>
    </span>
  )

  const renderEdit = () => {
    const reviewCode = review ? review.reviewCode : null
    let actions = []
    if (reviewCode && reviewCode === REVIEW.CODE.READY_FOR_CODER) {
      actions = [
        {
          name: 'question circle',
          color: 'yellow',
          popUp: 'Click to mark Ready for Coder QA',
          onClick: () => {
            if (onClick) onClick(action.MARK_READY_FOR_CODER_QA)
          }
        },
        {
          name: 'check circle',
          color: 'green',
          popUp: 'Click to mark Coder Complete',
          onClick: () => {
            if (onClick) onClick(action.MARK_CODER_COMPLETE)
          }
        },
        {
          name: 'hourglass half',
          color: followUpColor(),
          popUp: followUpText(),
          onClick: () => onClickFollowUp()
        }
      ]
    } else if (reviewCode && reviewCode === REVIEW.CODE.READY_FOR_CODER_QA) {
      actions = [
        {
          name: 'undo',
          color: 'orange',
          popUp: 'Click to return encounter to Coder Worklist',
          onClick: () => {
            if (onClick) onClick(action.MARK_OPEN)
          }
        },
        {
          name: 'check circle',
          color: 'green',
          popUp: 'Click to mark Coder Complete',
          onClick: () => {
            if (onClick) onClick(action.MARK_CODER_COMPLETE)
          }
        },
        {
          name: 'hourglass half',
          color: followUpColor(),
          popUp: followUpText(),
          onClick: () => onClickFollowUp()
        }
      ]
    } else if (reviewCode && reviewCode === REVIEW.CODE.COMPLETED) {
      actions = [
        {
          name: 'undo',
          color: 'orange',
          popUp: 'Click to return encounter to Coder Worklist',
          onClick: () => {
            if (onClick) onClick(action.MARK_OPEN)
          }
        },
        {
          name: 'question circle',
          color: 'yellow',
          popUp: 'Click to mark Ready for Coder QA',
          onClick: () => {
            if (onClick) onClick(action.MARK_READY_FOR_CODER_QA)
          }
        },
        {
          name: 'hourglass half',
          color: followUpColor(),
          popUp: followUpText(),
          onClick: () => onClickFollowUp()
        }
      ]
    } else {
      actions = [
        {
          name: 'undo',
          color: 'orange',
          popUp: 'Click to return encounter to Coder Worklist',
          onClick: () => {
            if (onClick) onClick(action.MARK_OPEN)
          }
        },
        {
          name: 'question circle',
          color: 'yellow',
          popUp: 'Click to mark Ready for Coder QA',
          onClick: () => {
            if (onClick) onClick(action.MARK_READY_FOR_CODER_QA)
          }
        },
        {
          name: 'check circle',
          color: 'green',
          popUp: 'Click to mark Coder Complete',
          onClick: () => {
            if (onClick) onClick(action.MARK_CODER_COMPLETE)
          }
        },
        {
          name: 'hourglass half',
          color: followUpColor(),
          popUp: followUpText(),
          onClick: () => onClickFollowUp()
        }
      ]
    }

    return (
      <span>
        { actions.map(a => (
          <HeaderIcon
            key={ a.name }
            name={ a.name }
            onClick={ a.onClick }
            color={ a.color }
            popUp={ a.popUp }
          />
        )) }
        <span>
          <LockIcon
            lock={ lock }
            currentUser={ currentUser }
            onClick={ () => { if (onClick) onClick(action.LOCK_TOGGLE) } }
          />
        </span>
        <Modal
          size="fullscreen"
          trigger={
            <Icon name="print" size="large" color="grey" className="pointer" />
          }
        >
          <Modal.Content>
            <Pdf encounterId={ encounter.id } />
          </Modal.Content>
        </Modal>
      </span>
    )
  }

  if (lockUtils.canEdit(lock, currentUser)) return renderEdit()
  return renderRead()
}

const mapStateToProps = (state: any): any => ({
  currentUser: state.user
})

export default connect(mapStateToProps)(CoderEncounterHeaderButtons)
