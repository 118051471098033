/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react'
import { Container, Grid, Icon, Message, Segment } from 'semantic-ui-react'

/**
 * Provides a component that displays an informational message informing the
 * current user that the patient they tried to view is not within Curation
 * scope. The idea here is that we need a fallback page for cases where an EMR
 * integration has initiated a launch for a patient that we do not know about
 */
class EmptyScope extends Component {

  render() {
    return (
      <div className="header-padding">
        <Container>
          <Segment attached className="no-margin">
            <Grid>
              <Grid.Row>
                <Grid.Column mobile={ 16 } tablet={ 16 } computer={ 16 }>
                  <Message warning icon>
                    <Icon name="info circle" />
                    <Message.Content>
                      <Message.Header />
                      <div>
                        This patient is not currently in scope for Curation Health.
                      </div>
                    </Message.Content>
                  </Message>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        </Container>
      </div>
    )
  }
}

export default EmptyScope;