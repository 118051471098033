import React, { Component } from "react";
import { connect } from "react-redux";
import { autoLogin } from "../../action/user";
import { Container, Dimmer, Loader } from "semantic-ui-react";
import { DISPLAY_MODE } from "../../constant";
import "./autoLogin.css";
import qs from "query-string";

export class AutoLogin extends Component {
  componentDidMount() {
    const params = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
    this.props.autoLogin(params);
  }

  render() {
    const params = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
    let mode = DISPLAY_MODE.SMART;
    if ( params.embedded && params.embedded === "true" ) {
      mode = DISPLAY_MODE.EMBEDDED;
    }
    if ( mode === DISPLAY_MODE.EMBEDDED ) {
      // if display mode is embedded, skip the friendly text
      return (
        <Container>
          <Dimmer active inverted>
            <Loader className="autologin-loader" size="big" />
          </Dimmer>
        </Container>
      );
    } else {
      return (
        <Container>
          <Dimmer active inverted>
            <Loader className="autologin-loader" size="big">
              <div className="autologin-header">Hang Tight!</div>
              <div className="autologin-content">We are logging you in.</div>
            </Loader>
          </Dimmer>
        </Container>
      );
    }
  }
}

export default connect(state => ({}), { autoLogin })(AutoLogin);
