import { API_URL } from '../constant'
import { api } from './api'

export function submitFeedback(comment: string, state: any) {
  let encounterId = null
  const path = state && state.router && state.router.location
    ? state.router.location.pathname : null
  if ( path && path.includes('detail') ) {
    const l = path.split('/')
    encounterId = l[l.length - 1]
  }
  const body = {
    feedbackTypeCode: 'feedback',
    encounterId,
    applicationTypeCode: 'feedback',
    applicationState: state,
    description: comment
  }
  return () => api.post(API_URL.FEEDBACK, body)
}

export default submitFeedback
