/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable arrow-body-style */
import React, { Dispatch, SetStateAction, useState } from 'react'
import { connect } from 'react-redux'
import {
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
  Popup
} from 'semantic-ui-react'
// Local
import { ENCOUNTER, REVIEW } from '../../../constant'
import { Encounter, EncounterDetail, User } from '../../authenticated/types'
import CopyPasteEncounterModal from '../../common/copyPasteEncounterModal'
import { allowEncounterCDICopyPaste } from '../../common/currentUserUtils'
import If from '../../common/if'
import Clipboard from '../../icons/clipboard'
import Lock from '../../icons/lock'

type Props = {
  currentEncounter: Encounter
  isCurrentEncounterLocked: boolean
  previousEncounters: Encounter[]
  onClick: (id: string | undefined) => void
  setEncounterDetail: Dispatch<SetStateAction<EncounterDetail>>
  setIsACopiedEncounter: Dispatch<SetStateAction<boolean>>
  reduxState: any
}

const previousEncounterHasCopyableReviewStatus = (
  encounter: Encounter
): boolean => {
  if (!!encounter && encounter.status === ENCOUNTER.STATUS.CANCELLED) {
    return true
  }
  return (
    !!encounter &&
    !!encounter.review &&
    encounter.review.reviewCode !== REVIEW.CODE.OPEN &&
    encounter.review.reviewCode !== REVIEW.CODE.READY_FOR_CDI_QA
  )
}

const currentEncounterHasCopyableReviewStatus = (
  encounter: Encounter
): boolean =>
  !!encounter &&
  !!encounter.review &&
  (encounter.review.reviewCode === REVIEW.CODE.OPEN ||
    encounter.review.reviewCode === REVIEW.CODE.READY_FOR_CDI_QA)

const isCdiCopyPasteAllowed = (
  currentUser: User,
  currentEncounter: Encounter,
  previousEncounter: Encounter
): boolean =>
  !!currentUser &&
  !!currentUser.tenantProperties &&
  allowEncounterCDICopyPaste(currentUser) &&
  currentEncounterHasCopyableReviewStatus(currentEncounter) &&
  previousEncounterHasCopyableReviewStatus(previousEncounter)

const title = (encounter: Encounter): string =>
  encounter.description || encounter.reasonDescription || ''

const formatEncounterType = (encounter: Encounter) => {
  return (
	isCancelledEncounter(encounter)
	? <Popup content="Cancelled" mouseEnterDelay={ 1000 } trigger={ <s>{ title(encounter) }</s> } /> 
    : <span>{title(encounter) }</span>
  )
}

const isCancelledEncounter = (encounter: Encounter): boolean =>
  !!encounter && encounter.status === ENCOUNTER.STATUS.CANCELLED

const reviewCode = (encounter: Encounter): keyof typeof REVIEW.CODE | '' =>
  (encounter &&
    encounter.review &&
    (encounter.review.reviewCode as keyof typeof REVIEW.CODE)) ||
  ''

const dateOptions = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit'
} as const

const formatDate = (x: Date): string =>
  new Date(x).toLocaleDateString('en-us', dateOptions)

function PreviousEncounterTable(props: Props): JSX.Element {
  const {
    currentEncounter,
    isCurrentEncounterLocked,
    previousEncounters,
    onClick,
    setEncounterDetail,
    setIsACopiedEncounter,
    reduxState
  } = props

  const { user } = reduxState

  const [isModalShowing, setIsModalShowing] = useState<boolean>(false)
  const [previousEncounterToCopy, setPreviousEncounterToCopy] =
    useState<Encounter>()

  const handleClick = (id: string | undefined): void => {
    onClick(id)
  }

  function renderModal(previousEncounter: Encounter): JSX.Element {
    return (
      <If isTrue={isModalShowing}>
        <CopyPasteEncounterModal
          currentEncounter={currentEncounter}
          previousEncounter={previousEncounter}
          isEncounterLocked={isCurrentEncounterLocked}
          isModalShowing={isModalShowing}
          setEncounterDetail={setEncounterDetail}
          setIsModalShowing={setIsModalShowing}
          setIsACopiedEncounter={setIsACopiedEncounter}
        />
      </If>
    )
  }

  return (
    <table className="ui selectable compact table previous-encounters-table">
      <TableHeader>
        <TableRow>
          <TableHeaderCell>Date</TableHeaderCell>
          <TableHeaderCell>Type</TableHeaderCell>
          <TableHeaderCell textAlign="center">Review Code</TableHeaderCell>
          <TableHeaderCell textAlign="center">Copy Encounter</TableHeaderCell>
        </TableRow>
      </TableHeader>
      <TableBody>
        {previousEncounters.map(previousEncounter => {
          const prevEncounterTitleStyle = isCancelledEncounter(
            previousEncounter
          )
            ? { textDecorationLine: 'line-through' }
            : {}
          // TODO - @bzey - I believe the prevailing path forward will be the eventual patient-level
          // locking work of Wenham via https://curationhealth.atlassian.net/browse/OR-7686
          // const isPreviousEncounterLocked = isEncounterLocked(previousEncounter)
          return (
            <TableRow key={previousEncounter.id}>
              {/* Date */}
              <TableCell onClick={() => handleClick(previousEncounter.id)}>
                <span>
                {previousEncounter.start
                  ? formatDate(previousEncounter.start)
                  : ''}
                </span>
              </TableCell>
              {/* Title */}
              <TableCell onClick={() => handleClick(previousEncounter.id)}>
                <span>
                  {formatEncounterType(previousEncounter) }
                </span>
              </TableCell>
              {/* Review Code */}
              <TableCell textAlign="center" onClick={() => handleClick(previousEncounter.id)}>
                <span>
                  {reviewCode(previousEncounter).replace(/_/g, ' ')}
                  {/* <ReviewStatusIcon reviewCode={ reviewCode(previousEncounter) } /> */}
                </span>
              </TableCell>
              {/* Copy Encounter */}
              <TableCell textAlign="center">
                <If
                  isTrue={
                    isCdiCopyPasteAllowed(
                      user,
                      currentEncounter,
                      previousEncounter
                    ) && isCurrentEncounterLocked
                  }
                >
                  <Clipboard
                    onClick={() => {
                      setIsModalShowing(true)
                      setPreviousEncounterToCopy(previousEncounter)
                    }}
                  />
                </If>
                <If
                  isTrue={
                    isCdiCopyPasteAllowed(
                      user,
                      currentEncounter,
                      previousEncounter
                    ) &&
                    isCurrentEncounterLocked &&
                    isModalShowing
                  }
                >
                  {previousEncounterToCopy !== undefined
                    ? renderModal(previousEncounterToCopy)
                    : 'No previous encounter to copy'}
                </If>
                <If
                  isTrue={
                    !isCdiCopyPasteAllowed(
                      user,
                      currentEncounter,
                      previousEncounter
                    ) || !isCurrentEncounterLocked
                  }
                >
                  <Lock />
                </If>
              </TableCell>
            </TableRow>
          )
        })}
      </TableBody>
    </table>
  )
}

const mapStateToProps = (state: any) => ({
  reduxState: state
})

export default connect(mapStateToProps)(PreviousEncounterTable)
