import React, { Component } from "react";
import { connect } from "react-redux";
import { Divider } from "semantic-ui-react";
import { ENCOUNTER } from "../../../constant";
import * as lockUtils from "../../common/lockUtils";
import { isCdiQaManager } from "../../common/roleUtils";
import EncounterAppointment from "../common/encounterAppointment";
import EncounterCancelButton from "../common/EncounterCancelButton";
import EncounterCdiQa from "../common/encounterCdiQa";
import EncounterIgnoreButton from "../common/encounterIgnoreButton";
import EncounterPatient from "../common/encounterPatient";
import EncounterPractitioner from "../common/encounterPractitioner";
import EncounterProfile from "../common/encounterProfile";
import EncounterReason from "../common/encounterReason";
import EncounterSource from "../common/encounterSource";
import EncounterStatus from "../common/encounterStatus";
import NavSpacer from "../common/navSpacer";
import PatientRegion from "../common/patientRegion";
import PatientRuleFilters from "../common/patientRuleFilters";
import PatientRuleHccVersion from "../common/patientRuleHccVersion";
import VbcProgram from "../common/vbcProgram";

/**
 * Provides a component that displays the left column of the CDI encounter
 * detail
 * @param encounterDetail Encounter detail entity
 * @param onAction Callback for action. Arguments: action
 */
class CdiEncounterLeft extends Component {

  constructor(props) {
    super(props);
    this.onClickCancel = this.onClickCancel.bind(this);
    this.onClickIgnore = this.onClickIgnore.bind(this);
  }

  onClickCancel(encounterStatus) {
    if ( encounterStatus === ENCOUNTER.STATUS.CANCELLED ) {
      this.props.onAction(ENCOUNTER.ACTION.STATUS_TOGGLE_CANCELLED);
    }
    if ( encounterStatus === ENCOUNTER.STATUS.PLANNED ) {
      this.props.onAction(ENCOUNTER.ACTION.STATUS_TOGGLE_PLANNED);
    }
  }

  onClickIgnore(encounterStatus) {
    if ( encounterStatus === ENCOUNTER.STATUS.IGNORED ) {
      this.props.onAction(ENCOUNTER.ACTION.STATUS_TOGGLE_IGNORED);
    }
    if ( encounterStatus === ENCOUNTER.STATUS.PLANNED ) {
      this.props.onAction(ENCOUNTER.ACTION.STATUS_TOGGLE_PLANNED);
    }
  }

  render() {
    const currentUser = this.props.currentUser;
    const detail = this.props.encounterDetail;
    const encounter = detail.encounter;
    const patient = encounter.patient;
    const review = encounter.review;
    const editMode = lockUtils.canEdit(encounter.lock, currentUser);

    return (
      <div>
        <Divider className="green-border" />
        <EncounterCdiQa mode="left" review={review} />
        <EncounterProfile mode="left" encounter={encounter} />
        <EncounterPatient mode="left" encounter={encounter} />
        <PatientRegion mode="left" patient={patient} />
        <EncounterSource mode="left" encounter={encounter} />
        <EncounterReason mode="left" encounter={encounter} />
        <EncounterAppointment mode="left" encounter={encounter} />
        <EncounterStatus mode="left" encounter={encounter} />
        <EncounterPractitioner mode="left" encounter={encounter} />
        <VbcProgram mode="left" patient={patient} />
        <PatientRuleFilters mode="left" patient={patient} currentUser={currentUser} />
        <PatientRuleHccVersion mode="left" patient={patient} currentUser={currentUser} />
        {editMode && (
          <EncounterCancelButton mode="left" encounter={encounter}
            onClick={this.onClickCancel} />
        )}
        {editMode && isCdiQaManager(currentUser.roles) && (
          <div>
            <NavSpacer height="64px" mode="segment" />
            <EncounterIgnoreButton mode="left" encounter={encounter}
              onClick={this.onClickIgnore} />
          </div>
        )}
      </div>
    );
  }
}

export default connect(
  state => ({
    currentUser: state.user
  }), {}
)(CdiEncounterLeft);

