import React, { Component } from "react";
import { formatConditionTitleExt } from "../../common/formatUtils";
import AddressedIcon from "../common/addressedIcon";
import ConditionDismissed from "../common/conditionDismissed";

/**
 * Provides a component for the clinical admin condition header title.
 *
 * @param mode View mode. Options: null or "smart". Default: null
 * @param active True if condition is currently in active state
 * @param hccChild True if condition is a member of an HCC display group
 * @param hccAddressed True if condition is a member of an HCC display group
 *        and the group is marked as addressed
 * @param condition Condition entity
 * @param onConditionAction Callback for condition action
 *        Arguments: action, condition
 */
class ClinicalAdminConditionHeaderTitle extends Component {

  render() {
    const condition = this.props.condition;
    const isActive = this.props.active && this.props.active === true;
    const titleText = formatConditionTitleExt(condition, this.props);
    return (
      <div className="physician-condition-title">
        <div className="flex-title">
          <AddressedIcon condition={condition} />
          <div className="condition-title">
            {isActive ? <strong>{titleText}</strong> : titleText}
          </div>
          <div className="condition-toolbar">
            <ConditionDismissed condition={condition} />
          </div>
        </div>
      </div>
    );
  }
}

export default ClinicalAdminConditionHeaderTitle;
