import React from 'react'

type Props = {
  strokeWidth?: number
}

function ChevronDown(props: Props): JSX.Element {
  const { strokeWidth = 2 } = props
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth={ strokeWidth }
      strokeLinecap="round"
      strokeLinejoin="round"
      className="curation-ui-chevron-down"
    >
      <polyline points="6 9 12 15 18 9" />
    </svg>
  )
}

export default ChevronDown
