import React from 'react'
import { hasPath } from 'ramda'
import { Grid, Table } from 'semantic-ui-react'
// Local
import { PatientRecord } from '../patient/utils'
import MobileCell, { Column } from './mobileCell'

type Props = {
  record: PatientRecord
  onClick: (id: string) => void
  mobileColumns: Column[]
}

function MobileRow(props: Props) {
  const { record, onClick, mobileColumns } = props
  const { id, meta } = record
  const className = hasPath([ 'className' ], meta) ? meta.className : ''
  return (
    <Table.Row key={ id } className={ className } onClick={ () => onClick(id) }>
      <Table.Cell>
        <div className="compact-table">
          <Grid.Row>
            { mobileColumns.map((column,idx) => (
              <MobileCell key={ record.id + '-' + idx } record={ record } column={ column } />
            )) }
          </Grid.Row>
        </div>
      </Table.Cell>
    </Table.Row>
  )
}

export default MobileRow
