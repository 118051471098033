import React, { Component } from "react";
import { BASE_URL } from "../../config";
import { API_URL } from "../../constant";

/**
 * Provides a component that renders a PDF encounter report in an iframe
 * @param encounterId Render iframe with PDF report for specified encounter ID
 */
class PdfEncounterReport extends Component {

  render() {
    if ( this.props.encounterId ) {
      const url = BASE_URL + API_URL.PDF_ENCOUNTER_REPORT(this.props.encounterId);
      return (
        <iframe title="Encounter PDF" className="pdf-iframe" src={url} />
      );
    } else {
      return null;
    }
  }
}

export default PdfEncounterReport;

