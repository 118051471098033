/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { useState } from 'react'
import { Modal } from 'semantic-ui-react'
// Local
import { EncounterConditionDisplay } from '../../authenticated/types'
import If from '../../common/if'
import { condition as conditionUtils } from '../../common/conditionUtils'
import { isNLPDocument } from '../../v2/utils/utils'
import { NLPPayload } from '../../../json/nlpPayload'
import { API_URL } from '../../../constant'
import { api } from '../../../action/api'
import Close from '../../icons/close'
import Nlp from '../../common/nlp'

type Props = {
  condition: EncounterConditionDisplay
}

async function getNLPText(documentId: string): Promise<NLPPayload[]> {
  const nlpData = await api
    .get(API_URL.NLP_TEXT(documentId))
    .then(response => response.data)
  return nlpData
}

function NLPExtractedLink(props: Props): JSX.Element {
  const { condition } = props

  const [ nlpData, setNlpData ] = useState<NLPPayload[]>([] as NLPPayload[])
  const [ isNLPModalShowing, setIsNLPModalShowing ] = useState<boolean>(false)

  const handleNLPClick = async (e: any, c: EncounterConditionDisplay) => {
    e.preventDefault()
    e.stopPropagation()
    const ruleId = (c && c.rules && c.rules[0].targetId) || ''
    const data = await getNLPText(ruleId)
    setNlpData(data)
    // setNlpData(nlpPayload)
    setIsNLPModalShowing(true)
  }

  return (
    <>
      <Modal
        className="nlp-modal"
        onClose={ () => setIsNLPModalShowing(false) }
        onOpen={ () => setIsNLPModalShowing(true) }
        open={ isNLPModalShowing }
        size="tiny"
        closeIcon={
          <div className="modal-close">
            <div className="close-icon">
              <Close />
            </div>
          </div>
        }
        closeOnDimmerClick={ false }
        // trigger={ <Button>Basic Modal</Button> }
      >
        <>
          <div className="nlp-modal-header">
            <span className="nlp-header-doc">Document Type:</span>{ ' ' }
            { nlpData.length && nlpData[0].documentTypeCode }
            <br />
            <span className="nlp-header-doc">
              Document Date of Service:{ ' ' }
            </span>{ ' ' }
            { nlpData.length && nlpData[0].documentDateOfService }
          </div>
          <Nlp payload={ nlpData } />
        </>
      </Modal>
      <If
        isTrue={
          conditionUtils.isPotential(condition) && isNLPDocument(condition)
        }
      >
        <ul className="nlp-extracted-link">
          <li>
            <span>Click&nbsp;</span>
            <a href="#" onClick={ e => handleNLPClick(e, condition) }>
              here&nbsp;
            </a>
            <span>to view annotated text from original document.</span>
          </li>
        </ul>
      </If>
    </>
  )
}

export default NLPExtractedLink
