import React, { Component } from "react";
import { Button, Icon, Popup, Segment } from "semantic-ui-react";
import { ENCOUNTER } from "../../../constant";

/**
 * Provides a component that renders an encounter ignore button. The component
 * includes support for both ignoring and undoing the ignore action
 * @param encounter Encounter entity
 * @param onClick Callback function for button click.
 *        Arguments: the updated status of the encounter
 * @param mode Render mode. Default: left
 */
class EncounterIgnoreButton extends Component {

  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
    this.state = {
      encounterStatus: this.props.encounter.status
    }
  }

  onClick() {
    const status = this.state.encounterStatus;
    const newStatus = status === ENCOUNTER.STATUS.IGNORED
      ? ENCOUNTER.STATUS.PLANNED
      : ENCOUNTER.STATUS.IGNORED;
    this.setState({ encounterStatus: newStatus },
      () => { this.props.onClick(newStatus)});
  }

  renderLeft(encounter) {
    const status = this.state.encounterStatus;
    const popupTxt = "Mark Encounter Ignored. If there is no intent to work an" +
    " encounter and move it to Ready for Physician, marking the encounter" +
    " ignored will remove it from all work lists.";
    if ( status === ENCOUNTER.STATUS.IGNORED ) {
      return (
        <Segment attached compact className="left-segment">
          <div className="left-title-center">
            <Popup content="Mark Patient Encounter as Planned" mouseEnterDelay={1000}
              trigger={
                <Button type="submit"
                  onClick={this.onClick}>
                  <Icon name="undo" />Undo Encounter Ignore</Button>
              } />
          </div>
        </Segment>
      );
    } else {
      return (
        <Segment attached compact className="left-segment">
          <div className="left-title-center">
            <div>
              <span>
                Mark encounter Ignored and remove it from all work lists.
              </span>
            </div>
            <div className="m-1-top">
              <Popup content={popupTxt} mouseEnterDelay={500}
                trigger={
                  <Button type="submit" onClick={this.onClick}>
                    <Icon name="cut" />Ignore Encounter</Button>
                } />
            </div>
          </div>
        </Segment>
      );
    }
  }

  render() {
    const mode = this.props.mode ? this.props.mode : "left";
    if ( mode === "left" ) {
      return this.renderLeft(this.props.encounter);
    } else {
      return null;
    }
  }
}

export default EncounterIgnoreButton;
