/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react'
import { Segment } from 'semantic-ui-react'
import { formatDob } from '../../common/formatUtils'
import { patientMrn } from '../../common/patientUtils'
import { FORMAT } from '../../../constant'
import { semiColonsToLineBreaks } from '../../v2/utils/utils'

/**
 * Provides a component that renders the encounter patient information. The
 * default return is the patient MRN.
 *
 * Unfortunately we have not been consistent with our patient identifiers.
 * Ideally we would just always show the patient MRN property value but for
 * various reasons, many of which are lost to time, the tenants are inconsistent
 * with how they store patient identifiers.
 *
 * @param encounter Encounter entity
 * @param mode Render mode. Default: left
 * @param displayName If true display the patient's name
 *        Default: false
 * @param displayBirthDate If true display the patient's date of birth
 *        Default: false
 */
class EncounterPatient extends Component {

  renderLeft(patient) {
    const {tenantId} = this.props.encounter
    const mrn = patientMrn(patient, tenantId)
    return (
      <Segment attached compact className="left-segment">
        { this.props.displayName && (
          <div>
            <div className="left-title">Patient Name:</div>
            <div className="left-content">{ patient.firstName } { patient.lastName }</div>
          </div>
        ) }
        <div className="left-title">Patient MRN(s):</div>
        <div className="left-content mrn">{ semiColonsToLineBreaks(mrn) }</div>
        { this.props.displayBirthDate && (
          <div>
            <div className="left-title">Date of Birth:</div>
            <div className="left-content">{ formatDob(patient.birthDate, FORMAT.DATE) }</div>
          </div>
        ) }
      </Segment>
    )
  }

  render() {
    const mode = this.props.mode ? this.props.mode : 'left'
    if ( mode === 'left' ) {
      return this.renderLeft(this.props.encounter.patient)
    }
    return null

  }
}

export default EncounterPatient
