import React from 'react'
// Local
import Checkmark from './checkmark'
import If from '../common/if'

type Props = {
  isAddressed: boolean
  isRequired: boolean
}

function Icon(props: Props): JSX.Element {
  const { isAddressed, isRequired } = props

  function getClassNames() {
    const isAddressedClass = isAddressed ? 'addressed' : ''
    const isRequiredClass = isRequired ? 'required' : ''
    return `condition-status ${isAddressedClass} ${isRequiredClass}`
  }

  return (
    <div className={ getClassNames() }>
      <If isTrue={ isAddressed }>
        <Checkmark />
      </If>
    </div>
  )
}

export default Icon
