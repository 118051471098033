import { Dispatch, SetStateAction } from 'react'

export enum MessageStatusChoice {
  ActionRecommended = 'Action Recommended'
}

// Types
export type ButtonClick = {
  onClick: (buttonValue: string) => void
}

export type SelectedButton = {
  selectedButton: string
  setSelectedButton: Dispatch<SetStateAction<string>>
}

export default {
  MessageStatusChoice
}
