import React, { Component } from 'react'
import { Card, Form, Icon, Message } from "semantic-ui-react";

/**
 * Provides a password change form. The form provides basic validations and then
 * depends on the password reset API to complete validations
 * @param passwordChange Password change request entity
 * @param onSave Save callback. Argument: Password reset request
 * @param errors List of error messages. Default undefined
 */
class UserPasswordChange extends Component {

  constructor(props) {
    super(props);

    this.onSave = this.onSave.bind(this);

    this.state = {
      passwordChange: this.props.passwordChange,
      errors: this.props.errors ? this.props.errors : null
    }
  }

  onSave() {
    const passwordChange = this.state.passwordChange;
    const errors = this.validate(passwordChange);
    if ( errors.length === 0 ) {
      this.props.onSave(passwordChange);
    } else {
      this.setState({passwordChange: passwordChange, errors: errors});
    }
  }

  onChange = (e, {name, value}) => {
    const passwordChange = this.state.passwordChange;
    passwordChange[name] = value;
    this.setState({ passwordChange: passwordChange });
  }

  validate(passwordChange) {
    const errors = [];
    const password = passwordChange.password;
    const confirmPassword = passwordChange.confirmPassword;

    if ( !password || password.length === 0 ) {
      errors.push('New password required');
    } else if ( password && password.length < 7 ) {
      errors.push('New password must be at least 7 characters long');
    } else if ( !confirmPassword || confirmPassword !== password ) {
      errors.push('Password confirmation does not match');
    }
    return errors;
  }

  render() {
    const errors = this.state.errors;
    const password = this.state.passwordChange.password;
    const confirmPassword = this.state.passwordChange.confirmPassword;
    const showSave = password && password.length > 0
      && confirmPassword && confirmPassword.length > 0
      && password.length === confirmPassword.length;
    return (
      <div>
        <Card>
          <Card.Content>
            <Card.Header>
              <span>
                <Icon name="lock" /> Password Requirements
              </span>
            </Card.Header>
          </Card.Content>
          <Card.Content>
            <ul>
              <li>Your password must be at least seven characters long</li>
              <li>It must contain 1 each of the following:
                <ul>
                  <li>Upper case letter</li>
                  <li>Lower case letter</li>
                  <li>Number</li>
                  <li>Special character</li>
                </ul>
              </li>
              <li>Passwords must not be based on something that can be easily guessed or obtained using
              personal information (e.g. names, favorite sports team, etc.)</li>
              <li>Passwords must not use four or more sequential or repetitive characters</li>
              <li>Passwords that have been compromised in a data breach must not be used</li>
            </ul>
          </Card.Content>
          <Card.Content>
            <span id='password-reset-log-back-in'>
            <Icon name='info circle' /> To keep your account secure, changing your password will require you to log back in to continue working.
            </span>
          </Card.Content>
        </Card>
        {errors && (
          <div className="m-1-top m-1-bottom">
            {
              errors ? <Message negative list={errors} /> : null
            }
          </div>
        )}
        <Form>
          <Form.Input
            label='Email'
            placeholder='Email'
            name='email'
            value={this.state.passwordChange.email}
            onChange={this.onChange}
          />
          <Form.Input
            label='Password'
            type='password'
            placeholder='Password'
            name='password'
            value={this.state.passwordChange.password}
            onChange={this.onChange}
          />
          <Form.Input
            label='Confirm Password'
            type='password'
            placeholder='Confirm Password'
            name='confirmPassword'
            value={this.state.passwordChange.confirmPassword}
            onChange={this.onChange}
          />
          {showSave && (
            <Form.Button
              content='Save'
              onClick={this.onSave} />
          )}
        </Form>
      </div>
    );
  }
}

export default UserPasswordChange;