import React, { Component } from "react";
import { Icon, Popup, Segment } from "semantic-ui-react";
import { PATIENT_RULES } from "../../../constant";
import { expandSpecialtyFilter } from "../../common/ruleFilterUtils"
import { cdiRuleFilterAndRuleRiskCategoryVersionEnabled } from "../../common/currentUserUtils";
/**
 * Provides a component that renders the patient rule filters, if provided
 *
 * @param patient Patient entity
 * @param mode Render mode. Default: left
 */
class PatientRuleFilters extends Component {
  displayFilters(filters) {
    const tokens = filters.split(',')
    const lastFilter = tokens.slice(-1)[0]
    const fullSpecialty = expandSpecialtyFilter(lastFilter)
    const spanId = 'patient-filter-span'

    if (fullSpecialty === '') {
      // did not match to a specialty
      return ( <span id={ spanId }>{ tokens.join(', ') }</span> )
    }

    tokens.pop(); tokens.push(fullSpecialty)

    return (
      <span id={ spanId }>
      { tokens.join(', ').toUpperCase() }
      <Popup content={ this.popupMsg(fullSpecialty) } mouseEnterDelay={500}
        trigger={<span id="patient-filter-span-icon">&nbsp;<Icon id="patient-filter-icon" name="info circle" className="pointer" /></span>} />
      </span>
    )
  }

  popupMsg(expanded) {
    return `Only ${expanded} conditions will present to the Provider for this encounter`
  }

  renderLeft(patient, currentUser) {
    if(!cdiRuleFilterAndRuleRiskCategoryVersionEnabled(currentUser)){
      return null
    } else if ( patient && patient.rulesFilterTypes ) {
      const filters = patient.rulesFilterTypes;
      const isCuration3 = filters.toUpperCase().includes("DYN");
      return (
        <Segment attached compact className="left-segment">
          <div className="left-title">Patient Rule Filters:</div>
          <div className="left-content">
            <div>{ this.displayFilters(filters) }</div>
            { isCuration3 ? <div><span className="italic">Curation 3.0</span></div> : null }
          </div>
        </Segment>
      );
    } else{
      return (
        <Segment attached compact className="left-segment">
        <div className="left-title">Patient Rule Filters:</div>
        <div className="left-content">
          <div>{ PATIENT_RULES.PATIENT_RULES_FILTERS }</div>
        </div>
      </Segment> 
      )
    }
  }


  render() {
    const mode = this.props.mode ? this.props.mode : "left";
    if ( mode === "left" ) {
      return this.renderLeft(this.props.patient, this.props.currentUser);
    } else {
      return null;
    }
  }
}

export default PatientRuleFilters;