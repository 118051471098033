import React, { Component } from "react";
import { connect } from "react-redux";
import { Container, Grid, Segment } from "semantic-ui-react";
import SubHeader from "../common/subHeader";
import DailyProductivityCount from "./dailyProductivityCount";
import TotalReviewCount from "./totalReviewCount";

class Report extends Component {

  render() {
    return (
      <div className="header-padding">
        <Container>
          <SubHeader left="Reports" />
          <Segment attached className="no-margin">
            <Grid>
              <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={16}>
                  <TotalReviewCount />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={16}>
                  <DailyProductivityCount />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        </Container>
      </div>
    );
  }
}

export default connect(
  state => ({currentUser: state.user})
)(Report);
