import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Container, Grid, Segment } from 'semantic-ui-react'
import { API_URL, CONDITION, DISPLAY_MODE } from '../../../constant'
import { isEmbeddedView, isSmartView } from '../../common/currentUserUtils'
import { api } from '../../../action/api'
import PatientSnapshotWarning from '../common/patientSnapshotWarning'
import PatientSnapshotEncounterConditions from './patientSnapshotEncounterConditions'
import PatientSnapshotEncounterLeft from './patientSnapshotEncounterLeft'

/**
 * Patient snapshot provides a read-only view of an encounter that is
 * effectively divorced from the encounter specifics. The goal is to provide
 * basic patient information and Rules Engine results. The initial
 * implementation of the patient snapshot is similar to the Clinical Admin
 * view of the encounter
 */

export class PatientSnapshot extends Component {

  constructor(props) {
    super(props)
    const encounterId = this.props.match.params.id
    this.onConditionAction = this.onConditionAction.bind(this)
    this.state = {
      encounterId,
      encounterDetail: null
    }
  }

  componentDidMount() {
    this.fetchEncounter(this.state.encounterId)
  }

  fetchEncounter(encounterId) {
    const userRole = this.props.currentUser.type
    const url = `${API_URL.GET_ENCOUNTER_DETAIL_BY_ID_WITH_ROLE(userRole)}${encounterId}`
    // const url = API_URL.GET_ENCOUNTER_DETAIL_BY_ID + encounterId
    api.get(url).then(({ data }) => {
      this.setState({
        encounterId,
        encounterDetail: data
      })
    })
  }

  onConditionActivate(condition) {
    if (condition === null) {
      this.setState({
        activeConditionId: null
      })
    } else {
      const id = typeof (condition) === 'string' ? condition : condition.id
      this.setState({
        activeConditionId: id
      })
    }
  }

  onConditionDeactivate() {
    this.setState({
      activeConditionId: null
    })
  }

  onConditionRead(condition) {
    if (condition) {
      api.post(API_URL.AUDIT('READ', 'ENCOUNTER_CONDITION', condition.id), {})
    }
  }

  onConditionAction(action, condition) {
    if (action === CONDITION.ACTION.ACTIVATE) {
      this.onConditionActivate(condition)
    }
    if (action === CONDITION.ACTION.DEACTIVATE) {
      this.onConditionDeactivate(condition)
    }
    if (action === CONDITION.ACTION.READ) {
      this.onConditionRead(condition)
    }
  }

  renderSnapshot(detail) {
    const key = (new Date()).getTime()
    let mode = null

    if (isSmartView(this.props.match.path)) {
      mode = DISPLAY_MODE.SMART
    }

    if (isEmbeddedView(this.props.match.path)) {
      mode = DISPLAY_MODE.EMBEDDED
    }

    return (
      <div className="header-padding">
        <Container>
          <Segment attached className="no-margin">
            <PatientSnapshotWarning encounterDetail={ detail } />
            <Grid stackable>
              <Grid.Row>
                <Grid.Column width={ 4 }>
                  <PatientSnapshotEncounterLeft encounterDetail={ detail } />
                </Grid.Column>
                <Grid.Column width={ 12 }>
                  <PatientSnapshotEncounterConditions
                    key={ `encounter-conditions-${key}` }
                    currentUser={ this.props.currentUser }
                    mode={ mode }
                    encounterDetail={ detail }
                    conditionGroups={ this.props.conditionGroups }
                    activeConditionId={ this.state.activeConditionId }
                    onConditionAction={ this.onConditionAction } />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        </Container>
      </div>
    )
  }

  render() {
    const detail = this.state.encounterDetail
    return detail ? this.renderSnapshot(detail) : null
  }
}

export default withRouter(connect(
  state => ({
    currentUser: state.user
  }), {}
)(PatientSnapshot))
