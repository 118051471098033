import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import 'url-search-params-polyfill';
import { Header, Container, Grid, Image } from 'semantic-ui-react'
import { API_URL, AUTH_STATUS, AUTH_TYPE } from '../../constant'
import { api } from '../../action/api'
import { login, logout, mfaLogin, mfaTotpLogin, mfaQrTotpLogin, withCurrentUser } from '../../action/user'
import LoginEmpty from './loginEmpty'
import LoginForm from './loginForm'
import MfaForm from './mfaForm'
import TotpMfaForm from './totpMfaForm'
import QrTotpMfaForm from './qrTotpMfaForm'
import { AutoLoginParams } from '../../../types'
import { User } from '../authenticated/types'
import './index.css'
import { LoginLogo } from "../common/brandingUtils";
import { isNil } from 'ramda'

const queryParamsToObject = (
  queryParams: string
): AutoLoginParams => {
  const params = new URLSearchParams(queryParams)
  const result: Record<string, string> = {}
  params.forEach((value, key) => {
    result[key] = value
  })
  return result as AutoLoginParams
}

export function Login(props: any): JSX.Element {
  const { email, username, password, authMessage, authStatus, authAttempts, authType, qrCodeUuid, dispatch } = props

  const queryParams = queryParamsToObject(window.location.search)

  const [ isLoading, setIsLoading ] = useState<boolean>(true)

  const handleLoginSubmit = (usernameProp: string, passwordProp: string, recaptchaToken: string) => {
    logout('', dispatch, () => {
      login(
        usernameProp,
        passwordProp,
        recaptchaToken,
        authAttempts,
        queryParams,
        dispatch
      )
    })
  }

  const handleMfaSubmit = (mfaCodeProp: string, emailProp: string, usernameProp: string, passwordProp: string, rememberMeProp: string) => {
    mfaLogin(
      mfaCodeProp,
      emailProp,
      usernameProp,
      passwordProp,
      rememberMeProp,
      authAttempts,
      queryParams,
      dispatch
    )
  }

  const handleMfaTotpSubmit = (mfaCodeProp: string, emailProp: string, usernameProp: string, passwordProp: string, rememberMeProp: string, authType: string, qrCodeUuid: string) => {
      mfaTotpLogin(
        mfaCodeProp,
        emailProp,
        usernameProp,
        passwordProp,
        rememberMeProp,
        authAttempts,
        authType,
        qrCodeUuid,
        queryParams,
        dispatch
      )
    }

 const handleMfaQrTotpSubmit = (mfaCodeProp: string, emailProp: string, usernameProp: string, passwordProp: string, rememberMeProp: string, authType: string, qrCodeUuid: string) => {
      mfaQrTotpLogin(
        mfaCodeProp,
        emailProp,
        usernameProp,
        passwordProp,
        rememberMeProp,
        authAttempts,
        authType,
        qrCodeUuid,
        queryParams,
        dispatch
      )
    }

  useEffect(() => {
    const fetchCurrentUser = () => {
      const url = API_URL.USERS_CURRENT
      api
        .getUnsafe(url)
        .then(({ data }: { data: User }) => {
          withCurrentUser(data, queryParams.encounter, dispatch)
        })
        .catch(() => {
          setIsLoading(false)
        })
    }
    fetchCurrentUser()
  }, [])

  const renderEmpty = () => (
    <Container>
      <div className="ui stackable grid">
        <Grid.Row columns={ 3 } only="computer" id="c-login-row-computer">
          <Grid.Column />
          <Grid.Column>
            <LoginEmpty id="computer" errorMessage={ authMessage } />
          </Grid.Column>
          <Grid.Column />
        </Grid.Row>
        <Grid.Row columns={ 1 } only="mobile tablet" id="c-login-row-mobile">
          <Grid.Column>
            <LoginEmpty id="mobile" errorMessage={ authMessage } />
          </Grid.Column>
        </Grid.Row>
      </div>
    </Container>
  )

  const renderMfaForm = () => {
    const key = `mfa-form-key-${(new Date()).getTime()}`
    return (
      <Container>
        <div className="ui stackable grid">
          <Grid.Row/>
          <Grid.Row columns={ 3 } id="c-mfa-header-row-computer">
            <Grid.Column />
            <Grid.Column>
              <h2 className="ui header">
                <Header.Content>
                  {LoginLogo()}
                </Header.Content>
              </h2>
            </Grid.Column>
            <Grid.Column />
          </Grid.Row>
        </div>
        <div className="ui stackable grid">
          <Grid.Row columns={ 1 } only="computer" id="c-mfa-login-row-computer">
            <Grid.Column />
            <Grid.Column>
              <MfaForm
                key={ `${key}-computer` }
                id="computer"
                active={ true }
                onSubmit={ handleMfaSubmit }
                email={ email || '' }
                username={ username || '' }
                password={ password || '' }
                errorMessage={ authMessage }
              />
            </Grid.Column>
            <Grid.Column />
          </Grid.Row>
          <Grid.Row columns={ 1 } only="mobile tablet" id="c-mfa-login-row-mobile">
            <Grid.Column />
            <Grid.Column>
              <MfaForm
                key={ `${key}-mobile` }
                id="mobile"
                active={ true }
                onSubmit={ handleMfaSubmit }
                email={ email || '' }
                username={ username || '' }
                password={ password || '' }
                errorMessage={ authMessage }
              />
            </Grid.Column>
            <Grid.Column />
          </Grid.Row>
        </div>
      </Container>
    )
  }

  const renderTotpMfaForm = () => {
    const key = `mfa-form-key-${(new Date()).getTime()}`
    return (
      <Container>
        <div className="ui stackable grid">
          <Grid.Row/>
          <Grid.Row columns={ 3 } id="c-mfa-header-row-computer">
            <Grid.Column />
            <Grid.Column>
              <h2 className="ui header">
                <Header.Content>
                  {LoginLogo()}
                </Header.Content>
              </h2>
            </Grid.Column>
            <Grid.Column />
          </Grid.Row>
        </div>
        <div className="ui stackable grid">
          <Grid.Row columns={ 1 } only="computer" id="c-mfa-login-row-computer">
            <Grid.Column />
            <Grid.Column>
              <TotpMfaForm
                key={ `${key}-computer` }
                id="computer"
                active={ true }
                onSubmit={ handleMfaTotpSubmit }
                email={ email || '' }
                username={ username || '' }
                password={ password || '' }
                errorMessage={ authMessage }
              />
            </Grid.Column>
            <Grid.Column />
          </Grid.Row>
          <Grid.Row columns={ 1 } only="mobile tablet" id="c-mfa-login-row-mobile">
            <Grid.Column />
            <Grid.Column>
              <TotpMfaForm
                key={ `${key}-mobile` }
                id="mobile"
                active={ true }
                onSubmit={ handleMfaTotpSubmit }
                email={ email || '' }
                username={ username || '' }
                password={ password || '' }
                errorMessage={ authMessage }
              />
            </Grid.Column>
            <Grid.Column />
          </Grid.Row>
        </div>
      </Container>
    )
  }

  const renderQrTotpMfaForm = () => {
    const key = `mfa-form-key-${(new Date()).getTime()}`
    return (
      <Container>
        <div className="ui stackable grid">
          <Grid.Row/>
          <Grid.Row columns={ 3 } id="c-mfa-header-row-computer">
            <Grid.Column />
            <Grid.Column>
              <h2 className="ui header">
                <Header.Content>
                  {LoginLogo()}
                </Header.Content>
              </h2>
            </Grid.Column>
            <Grid.Column />
          </Grid.Row>
        </div>
        <div className="ui stackable grid">
          <Grid.Row columns={ 1 } only="computer" id="c-mfa-login-row-computer">
            <Grid.Column />
            <Grid.Column>
              <QrTotpMfaForm
                key={ `${key}-computer` }
                id="computer"
                active={ true }
                onSubmit={ handleMfaQrTotpSubmit }
                email={ email || '' }
                username={ username || '' }
                password={ password || '' }
                errorMessage={ authMessage }
                qrCodeUuid={ qrCodeUuid }
              />
            </Grid.Column>
            <Grid.Column />
          </Grid.Row>
          <Grid.Row columns={ 1 } only="mobile tablet" id="c-mfa-login-row-mobile">
            <Grid.Column />
            <Grid.Column>
              <QrTotpMfaForm
                key={ `${key}-mobile` }
                id="mobile"
                active={ true }
                onSubmit={ handleMfaQrTotpSubmit }
                email={ email || '' }
                username={ username || '' }
                password={ password || '' }
                errorMessage={ authMessage }
                qrCodeUuid={ qrCodeUuid }
              />
            </Grid.Column>
            <Grid.Column />
          </Grid.Row>
        </div>
      </Container>
    )
  }

  const renderLoginForm = () => (
    <Container>
      <div className="ui stackable grid">
        <Grid.Row columns={ 3 } only="computer" id="c-login-row-computer">
          <Grid.Column />
          <Grid.Column>
            <LoginForm
              id="computer"
              active={ true }
              onSubmit={ handleLoginSubmit }
              username={ username || '' }
              errorMessage={ authMessage }
            />
          </Grid.Column>
          <Grid.Column />
        </Grid.Row>
        <Grid.Row columns={ 1 } only="mobile tablet" id="c-login-row-mobile">
          <Grid.Column>
            <LoginForm
              id="mobile"
              active={ true }
              onSubmit={ handleLoginSubmit }
              username={ username }
              errorMessage={ authMessage }
            />
          </Grid.Column>
        </Grid.Row>
      </div>
    </Container>
  )

  const renderMfa = () => {
    return !(!isNil(authType) && authType.includes(AUTH_TYPE.TOTP)) ? renderMfaForm() :
      authType === AUTH_TYPE.QR_TOTP ? renderQrTotpMfaForm() : renderTotpMfaForm()
  }

  return isLoading ? renderEmpty() :
    authStatus === AUTH_STATUS.MFA_REQUIRED ? renderMfa() : renderLoginForm()
}

export default connect(
  (state: any) => ({
    email: state.user.email,
    username: state.user.username,
    password: state.user.password,
    authMessage: state.user.authMessage,
    authStatus: state.user.authStatus,
    authAttempts: state.user.authAttempts,
    authType: state.user.authType,
    qrCodeUuid: state.user.qrCodeUuid
  })
)(Login)
