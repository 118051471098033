import React from 'react'

/**
 * @return Path to company logo image based on state of BRANDING_IDENTITY
 */
const companyLogoPath = '/asset/image/curation-logo-no-tag.png'

export function HeaderLogo(): JSX.Element {
  return (
    <img
      src={ companyLogoPath }
      className="ui small image"
      alt="Curation Healthcare"
    />
  )
}

export function LoginLogo(): JSX.Element {
  return (
    <img
      src={ companyLogoPath }
      className="ui fluid image"
      alt="Curation Healthcare"
    />
  )
}
