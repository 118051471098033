import React, { useState } from 'react'
import { format } from 'date-fns'
import { Button, Form, List } from 'semantic-ui-react'
import filter from 'lodash/filter'
// Local
import { FORMAT } from '../../../constant'
import { EncounterNoteRow } from '../../authenticated/types'
import If from '../../common/if'

type Props = {
  onSubmit: (note: string) => Promise<void>
  notes: EncounterNoteRow[] | null | undefined
}

function EncounterNotes(props: Props) {
  const { onSubmit, notes: notesProp } = props
  const [ noteState, setNoteState ] = useState<string>('')

  const onChange = (value: string) => {
    setNoteState(value)
  }

  const notes = filter(notesProp, { encounterConditionId: null })

  return (
    <div>
      <div role="list" className="ui divided list">
        { notes &&
          notes.map(note => (
            <List.Item key={ note.id }>
              <div className="m-1-top m-1-bottom">
                <span>{ format(note.creationDate, FORMAT.DATE_TIME) }</span>
                <span> - { note.createdByUserName } - </span>
                <span className="italic">&quot;{ note.noteBody }&quot;</span>
              </div>
            </List.Item>
          )) }
      </div>
      <Form.TextArea
        name="note"
        value={ noteState }
        className="condition-notes-textarea"
        placeholder="Encounter Notes: Only Viewable by CDI and Coder Users."
        onChange={ (event: any, { value }: { value: string }) => onChange(value) }
      />
      <If isTrue={ !!noteState && !!noteState.length }>
        <div>
          <Button
            color="blue"
            type="submit"
            onClick={ () => {
              onSubmit(noteState)
            } }
          >
              Add Note
          </Button>
        </div>
      </If>
    </div>
  )
}

export default EncounterNotes
