import React, { Component } from "react";
import { Label } from "semantic-ui-react";
import { formatIcd10Code, displayRiskCode } from "../../common/formatUtils";
import AddressedIcon from "../common/addressedIcon";

/**
 * Provides a component for the clinical admin HCC condition group header title.
 *
 * @param mode View mode. Options: null or "smart". Default: null
 * @param active True if group is currently in active state
 * @param hccCode HCC Code
 * @param hccDescription HCC description
 * @param conditions Group conditions
 * @param onConditionAction Callback for condition action
 *        Arguments: action, condition
 */
class ClinicalAdminConditionHccHeaderTitle extends Component {

  conditionHccTitle(conditions) {
    const isActive = this.props.active && this.props.active === true;
    const labelColor = isActive ? "blue" : "grey"
    const hccCode = this.props.hccCode;
    const hccDescription = this.props.hccDescription;
    const icd10Codes = conditions.filter(c => {
      return c.icd10CodeJson && c.icd10CodeJson.length > 0;
    }).map((c) => {
      return formatIcd10Code(c.icd10CodeJson[0]);
    }).join(", ");
    return (
      <span>
        <Label pointing="right" color={labelColor}>{displayRiskCode(hccCode)}</Label>
        <span> </span>
        <span>
          {isActive ? <strong>{hccDescription}</strong> : <span>{hccDescription}</span>}
        </span>
        <span> </span>
        {icd10Codes.length > 0 && (
          (<span className="italic">{icd10Codes}</span>)
        )}
      </span>
    );
  }

  render() {
    const conditions = this.props.conditions;
    const isActive = this.props.active && this.props.active === true;
    const titleText = this.conditionHccTitle(conditions);
    return (
      <div className="physician-condition-title">
        <div className="flex-title">
          <AddressedIcon conditions={conditions} />
          <div className="condition-title">
            {isActive ? <strong>{titleText}</strong> : titleText}
          </div>
        </div>
      </div>
    );
  }
}

export default ClinicalAdminConditionHccHeaderTitle;
