/* eslint-disable no-unused-vars */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import { MESSAGE, PAGES } from '../../constant'
import Account from '../account'
import Encounter from '../encounter/encounter'
import Appointments from '../encounter'
import Footer from './footer'
import Header from './header'
import Home from '../home'
import Patient from '../patient'
import PatientSnapshot from '../encounter/snapshot/patientSnapshot'
import Report from '../report'
import Version from '../version'

export class Authenticated extends Component {
  handleVersionClick = (event, data) => {
    this.props.history.push(PAGES.VERSION)
  }

  handleAccountClick = (event, data) => {
    this.props.history.push(PAGES.ACCOUNT)
  }

  render() {
    return (
      <div id="authenticated">
        <Header
          account={ this.handleAccountClick }
          version={ this.handleVersionClick }
          active={ this.props.pathname }
          history={ this.props.history }
          currentUser={ this.props.currentUser }
        />
        <Switch>
          <Route path={ `${PAGES.HOME  }:date?` } component={ Home } />
          <Route path={ `${PAGES.ENCOUNTER_DETAIL  }:id` } component={ Encounter } />
          <Route path={ PAGES.ENCOUNTER_LIST } component={ Appointments } />
          <Route path={ `${PAGES.PATIENT_SNAPSHOT }:id` } component={ PatientSnapshot } />
          <Route path={ PAGES.PATIENT_LIST } component={ Patient } />
          <Route path={ PAGES.REPORT } component={ Report } />
          <Route path={ PAGES.ACCOUNT } component={ Account } />
          <Route path={ PAGES.VERSION } component={ Version } />
        </Switch>
        <Footer />
      </div>
    )
  }
}

export default connect(
  state => ({
    pathname: state.router.location.pathname,
    currentUser: state.user
  }), {}
)(Authenticated)
