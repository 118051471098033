import React, { Component } from "react";
import { Dropdown, Responsive } from 'semantic-ui-react';
import { API_URL, PATIENT_PROPERTY } from "../../constant";
import { api } from "../../action/api";

/**
 * Provides a stand-alone patient VBC / quality program select widget
 *
 * @param onChange Callback function to execute when a program is selected
 *   arguments: (e, {name, value}) where value is the program name or "" if
 *   the ALL option is selected
 * @param name Name of the program select widget in a form context
 * @param value Initial selected program
 * @param placeholder Placeholder text. Default: VBC Programs
 * @param allOption If true, include an 'ALL' option. Default: true
 */
class VbcProgramSelect extends Component {

  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);

    this.state = {
      programs: [],
      allOption: this.props.allOption === false ? false : true
    };
  }

  componentDidMount() {
    this.fetchVbcPrograms();
  }

  fetchVbcPrograms() {
    const url = API_URL.PATIENT_PROPERTY_VALUES(PATIENT_PROPERTY.QUALITY_PROGRAM);
    api.get(url, {}).then(({ data }) => {
      this.setState({ programs: data });
    });
  }

  onChange = (e, { value }) => {
    if ( this.props.onChange ) {
      const name = this.props.name ? this.props.name : "vbcProgram";
      const val = value === 'ALL' ? null : value;
      this.props.onChange(e, {name: name, value: val});
    }
  }

  render() {
    const options = this.state.programs.map(name => {
      return {
        key: name,
        value: name,
        text: name
      };
    });
    if ( this.state.allOption === true ) {
      options.unshift({ key: "ALL", text: "All", value: "ALL" });
    }
    const placeholder = this.props.placeholder ? this.props.placeholder : "VBC Programs";
    const value = this.props.value ? this.props.value : undefined;
    return (
      <Responsive
        as={Dropdown}
        selection
        placeholder={placeholder}
        onChange={this.onChange}
        options={options}
        value={value} />
    );
  }
}

export default VbcProgramSelect;