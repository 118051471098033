import concat from "lodash/concat";
import { UPDATE_ENCOUNTER_CONDITIONS } from "./condition";

export const UPDATE_LIST = "ENCOUNTER_UPDATE_LIST";
export const APPEND_LIST = "ENCOUNTER_APPEND_LIST";
export const UPDATE_PDF_LIST = "ENCOUNTER_UPDATE_PDF_LIST";
export const CLEAR_PDF_LIST = "ENCOUNTER_CLEAR_PDF_LIST";
export const UPDATE_SELECTED = "ENCOUNTER_UPDATE_SELECTED";
export const UPDATE_RAF = "ENCOUNTER_UPDATE_RAF";

export const PDF_READY = "ENCOUNTER_PDF_READY";

export const CLEAR_STATE = "ENCOUNTER_CLEAR_STATE";
export const CLEAR_ALL_STATE = "CLEAR_ALL_STATE";
export const UPDATE_PAGES_SEEN = "ENCOUNTER_UPDATE_PAGES_SEEN";

const initState = {
  selected: {},
  list: [],
  onSchedule: false,

  pdfList: [],
  pdfReady: false,

  physicianDay: undefined,
  pagesSeen: 1
};

export default (state = initState, action) => {
  let newState;
  let newSelected;
  switch (action.type) {
    case CLEAR_STATE:
    case CLEAR_ALL_STATE:
      return initState;
    case UPDATE_LIST:
      return Object.assign({}, state, {
        list: action.payload.list,
        onSchedule: action.payload.onSchedule,
        physicianDay: action.payload.physicianDay
      });
    case APPEND_LIST:
      var newList = concat(state.list, action.payload);
      return Object.assign({}, state, {
        list: newList
      });
    // Numerous encounter details for PDF Generation
    case UPDATE_PDF_LIST:
      newState = Object.assign({}, state);
      newState.pdfList = concat(newState.pdfList, action.payload);
      return newState;
    case CLEAR_PDF_LIST:
      return Object.assign({}, state, { pdfList: [], pdfReady: false });
    case UPDATE_SELECTED:
      return Object.assign({}, state, {
        selected: action.payload
      });
    case UPDATE_ENCOUNTER_CONDITIONS:
      newState = Object.assign({}, state);
      newSelected = Object.assign({}, newState.selected);
      newSelected.conditions = action.payload;
      newState.selected = newSelected;
      return newState;
    case PDF_READY:
      return Object.assign({}, state, { pdfReady: action.payload });
    case UPDATE_RAF:
      newState = Object.assign({}, state);
      newSelected = Object.assign({}, newState.selected);
      newSelected.raf = action.payload;
      newState.selected = newSelected;
      return newState;

    // Specifically for the appointments list
    case UPDATE_PAGES_SEEN:
      newState = Object.assign({}, state);
      newState.pagesSeen = action.payload
      return newState;
    default:
      return state;
  }
};
