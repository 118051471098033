import { ONE_HOUR_MS } from "../../constant";

/*******************************************************************************
 * Provides additional utilities for date time
 */

/**
 * @param testDate Date timestamp (long) in UTC
 * @param expireHours Hours until expiration
 * @return True if the date has past the expiration hours
 */
export function isExpired(testDate, expireHours) {
  const todayMs = (new Date()).getTime();
  const dateMs = (new Date(testDate)).getTime();
  const expireMs = expireHours * ONE_HOUR_MS;
  return (todayMs - dateMs) > expireMs;
}