import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Container, Form, Grid, Icon, Message } from "semantic-ui-react";
import { switchUserRole } from "../../action/user";
import { formatRoleName } from "../common/formatUtils";
import { withPrimaryRoles } from "../common/roleUtils";

/**
 * Provides a component for changing the current user's active role. When the
 * active role is changed, a user redux-action is triggered that ultimately
 * updates the `type` property on the user in the store and redirects back to
 * home
 * @param currentUser Current user entity as defined in redux state.user
 */
class RoleSelect extends Component {

  constructor(props) {
    super(props);

    this.state = {
      roleDefault: this.props.currentUser.type,
      roleUpdate: this.props.currentUser.type
    };
  }

  onChangeRole = (e, {name, value}) => {
    this.setState({
      roleUpdate: value
    });
  }

  handleChangeRoleClick = () => {
    if ( this.state.roleDefault !== this.state.roleUpdate ) {
      this.props.switchUserRole(this.props.currentUser, this.state.roleUpdate);
    }
  }

  render() {
    const roles = withPrimaryRoles(this.props.currentUser.roles);
    return (
      <Container>
        <Grid>
          <Grid.Row>
            <Grid.Column mobile={16} tablet={16} computer={16}>
              <Message><Icon name="info circle" />
                Your user account has access to multiple roles. Change your role
                by selecting an option below and clicking 'Change Role'. You
                will be redirected to home.
              </Message>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Form>
              <Form.Field label="Available Roles" />
              {
                roles.map(role => {
                  return (
                    <Form.Radio
                      key={role.id}
                      label={formatRoleName(role.name)}
                      name={role.name}
                      value={role.name}
                      checked={this.state.roleUpdate === role.name}
                      onChange={this.onChangeRole}
                    />
                  );
                })
              }
            </Form>
          </Grid.Row>
          {this.state.roleUpdate !== this.state.roleDefault && (
            <Grid.Row>
              <Grid.Column mobile={16} tablet={16} computer={16} textAlign="right">
                <Button content="Change Role" onClick={this.handleChangeRoleClick.bind(this)} />
              </Grid.Column>
            </Grid.Row>
          )}
        </Grid>
      </Container>
    );
  }
}

export default connect(
  state => ({}),
  { switchUserRole }
)(RoleSelect);
