import { filter, isNil, map, pipe, reject, split, toUpper } from 'ramda'
// Local
import {
  CODER,
  CONDITION,
  PAGES,
  ROLES,
  TENANT,
  TENANT_PROPERTY,
  USER_PROPERTY
} from '../../constant'
import {
  ButtonOptions,
  User,
  EncounterConditionDisplayModified,
  Group,
  GroupingTitle,
  Property,
  TenantProperty
} from '../authenticated/types'
import { ConditionType } from '../patient/patient'
import { findProperty, propertyValue } from './userPropertyUtils'

/**
 * @param conditionType Condition group type enumeration
 * @return Corresponding condition group title for the provided type
 */
export function conditionGroupTitle(
  conditionType: string
  // conditionType: keyof typeof CONDITION
): GroupingTitle {
  switch (conditionType) {
    case ConditionType.Activity:
      return GroupingTitle.Activities
    case ConditionType.Known:
      return GroupingTitle.KnownConditions
    case ConditionType.Potential:
      return GroupingTitle.PotentialOpportunities
    default:
      return GroupingTitle.Default
  }
}

export const isPhysician = (role: string): boolean => role === ROLES.PHYSICIAN

export const isClinicalAdmin = (role: string): boolean =>
  role === ROLES.CLINICAL_ADMIN

const processConditions = pipe(split(','), map(toUpper))

const getPropValue = (
  tenantProperties: TenantProperty[],
  conditionGroups: string | undefined
): string | null =>
  conditionGroups ||
  propertyValue(
    tenantProperties,
    TENANT_PROPERTY.ENCOUNTER_CONDITION_DISPLAY_GROUPS
  )

/**
 * @param currentUser Current user entity
 * @param conditionGroups Optional override to tenant properties
 * @return Map of enabled encounter condition groups as defined by tenant
 * property or default if no property is defined for tenant. The return value
 * is a map of group name -> boolean
 */
export function encounterConditionGroupsEnabled(
  currentUser: User,
  conditionGroups: string | undefined = undefined
  // ): Record<keyof typeof CONDITION.TYPE, boolean> {
): Record<string, boolean> {
  const propValue = getPropValue(currentUser.tenantProperties, conditionGroups)

  if (propValue) {
    return propValue.split(',').reduce(
      (previousValue, currentValue) => ({
        ...previousValue,
        [currentValue.toUpperCase()]: true
      }),
      {} as Record<keyof typeof CONDITION.TYPE, boolean>
    )
  }

  return {
    ACTIVITY: true,
    KNOWN: true,
    POTENTIAL: true
  }
}

/**
 * @param currentUser Current user entity
 * @param conditionGroups Optional override to tenant properties
 * @return List of enabled encounter condition groups as defined by tenant
 * property or default if no property is defined for tenant.
 */
export function encounterConditionGroups(
  currentUser: User,
  conditionGroups: string | undefined = undefined
): string[] {
  const propValue = getPropValue(currentUser.tenantProperties, conditionGroups)
  if (propValue)
    return processConditions(propValue) as (keyof typeof CONDITION.TYPE)[]

  return isPhysician(currentUser.type) || isClinicalAdmin(currentUser.type)
    ? [ ConditionType.Known, ConditionType.Potential, ConditionType.Activity ]
    : [ ConditionType.Activity, ConditionType.Known, ConditionType.Potential ]
}

export default {
  conditionGroupTitle
}

/**
 * Provides an internal helper for getting a tenant property value from the
 * current user tenant properties
 */
// eslint-disable-next-line no-underscore-dangle
const tenantPropertyValuePrivate = (
  currentUser: User,
  propertyKey: keyof typeof TENANT_PROPERTY
): string | null => propertyValue(currentUser.tenantProperties, propertyKey)

/**
 * Provides an internal helper for getting a user property value from the
 * current user user properties
 */
// eslint-disable-next-line no-underscore-dangle
// const _userPropertyValue = (currentUser: User, propertyKey: string) =>
//   userPropertyValue(currentUser.userProperties, propertyKey)

/**
 * @param currentUser Current user entity
 * @return Object containing practitioner tag options or null
 */

/**
 * @param currentUser Current user entity
 * @return True encounter practitioner tags are enabled for current user
 */
export function encounterPractitionerTagsEnabled(currentUser: User) {
  const propKey = TENANT_PROPERTY.ENCOUNTER_PRACTITIONER_TAGS_ENABLED
  const propValue = tenantPropertyValuePrivate(
    currentUser,
    propKey as keyof typeof TENANT_PROPERTY
  )
  return propValue ? propValue.toLowerCase() === 'true' : false
}

export function encounterPractitionerTagOptions(
  currentUser: User
): Record<string, ButtonOptions[]> | null {
  const propValue = tenantPropertyValuePrivate(
    currentUser,
    TENANT_PROPERTY.ENCOUNTER_PRACTITIONER_TAGS_VALUE as keyof typeof TENANT_PROPERTY
  )
  const returnValue = propValue
    ? (JSON.parse(propValue) as Record<
        keyof typeof CONDITION.TYPE,
        ButtonOptions[]
      >)
    : null
  return returnValue
}

const hasHccCode = (group: Group): boolean => !isNil(group.hccCode)
const isAddressed = (group: Group): boolean => group.addressed

export function sortConditionGroups(groups: Group[]): Group[] {
  const withHcc = filter(hasHccCode, groups)
  const withHccAddressed = filter(isAddressed, withHcc)
  const withHccNotAddressed = reject(isAddressed, withHcc)
  const withoutHcc = reject(hasHccCode, groups)
  const withoutHccAddressed = filter(isAddressed, withoutHcc)
  const withoutHccNotAddressed = reject(isAddressed, withoutHcc)
  return withHccNotAddressed
    .concat(withoutHccNotAddressed)
    .concat(withHccAddressed)
    .concat(withoutHccAddressed)
}

/**
 * Determines if HCC grouping is enabled for the current user based on tenant
 * property
 *
 * As of 5/11/2021 HCC grouping is the default display for conditions. This
 * method checks to see if grouping is explicitly disabled.
 *
 * @param currentUser Current user entity
 * @return True if HCC grouping is enabled for current user, false if not
 */
export function hccGroupingEnabled(currentUser: User): boolean {
  const propValue = propertyValue(
    currentUser.tenantProperties,
    TENANT_PROPERTY.ENCOUNTER_CONDITION_DISPLAY_GROUPING
  )
  return !(propValue && propValue.toLowerCase() === 'none')
}

const getTags = (
  key: string,
  tagOptions: Record<string, ButtonOptions[]> | null
): ButtonOptions[] => (tagOptions && tagOptions[key]) || []

export const handleTags = (
  c: EncounterConditionDisplayModified,
  conditionStatus: keyof typeof CONDITION.TYPE,
  tagOptions: Record<string, ButtonOptions[]> | null
): ButtonOptions[] =>
  c.isExternal
    ? getTags(CONDITION.TARGET_SOURCE_CODE.EXTERNAL, tagOptions)
    : getTags(conditionStatus, tagOptions)

function userPropertyValue(properties: Property[], key: string) {
  const prop = findProperty(properties, key)
  return prop ? prop.propertyValue : null
}

/**
 * Provides an internal helper for getting a user property value from the
 * current user user properties
 */
function userPropertyValuePrivate(
  currentUser: User,
  propertyKey: keyof typeof USER_PROPERTY
) {
  return userPropertyValue(currentUser.userProperties, propertyKey)
}

/**
 * Provides an internal helper for getting a property value from either the
 * current user tenant properties or the current user user properties. A user
 * property value overrides a tenant property value of the same key
 */
function propertyValueInternal(
  currentUser: User,
  propertyKey: keyof typeof TENANT_PROPERTY | keyof typeof USER_PROPERTY
) {
  const tenantValue = tenantPropertyValuePrivate(
    currentUser,
    propertyKey as keyof typeof TENANT_PROPERTY
  )
  const userValue = userPropertyValuePrivate(
    currentUser,
    propertyKey as keyof typeof USER_PROPERTY
  )
  return userValue || tenantValue || null
}

/**
 * @param currentUser Current user entity
 * @return Specified feature version of the Physician encounter condition
 * display or null if not defined
 */
export const encounterConditionDisplayVersion = (
  currentUser: User
): string | null =>
  propertyValueInternal(
    currentUser,
    TENANT_PROPERTY.ENCOUNTER_CONDITION_DISPLAY_FEATURE_VERSION as keyof typeof TENANT_PROPERTY
  )

/**
 * @param currentUser Current user entity
 * @return Specified feature version of the encounter condition clinical admin
 * display or null if not defined
 */
export const encounterConditionClinicalAdminDisplayVersion = (
  currentUser: User
): string | null =>
  propertyValueInternal(
    currentUser,
    TENANT_PROPERTY.ENCOUNTER_CONDITION_CLINICAL_ADMIN_DISPLAY_FEATURE_VERSION as keyof typeof TENANT_PROPERTY
  )

/**
 * @param currentUser Current user entity
 * @return Specified feature version of the CDI encounter condition display
 * or null if not defined
 */
export const encounterConditionCdiDisplayVersion = (
  currentUser: User
): string | null =>
  propertyValueInternal(
    currentUser,
    TENANT_PROPERTY.ENCOUNTER_CONDITION_CDI_DISPLAY_FEATURE_VERSION as keyof typeof TENANT_PROPERTY
  )

/**
 * @param currentUser Current user entity
 * @return true if the current user is a user of a tenant that wants to display
 * VBC programs in worklist views
 */
export function showVbcDisplay(currentUser: User): boolean {
  const propKey = TENANT_PROPERTY.VBC_DISPLAY_ENABLED
  const propValue = propertyValue(currentUser.tenantProperties, propKey)
  return propValue ? propValue.toLowerCase() === 'true' : false
}

/**
 * @param currentUser Current user entity
 * @return true If the tenant properties allow scheduling of patient appointments
 * from the Patients tab. Note that this function is not doing a role check
 */
export function allowPatientTabSchedulingProvider(currentUser: User): boolean {
  const propKey = TENANT_PROPERTY.PATIENT_TAB_PROVIDER_SCHEDULE_ENABLED
  const propValue = propertyValue(currentUser.tenantProperties, propKey)
  return propValue ? propValue.toLowerCase() === 'true' : false
}

export function allowPatientTabSchedulingCdi(currentUser: User): boolean {
  const propKey = TENANT_PROPERTY.PATIENT_TAB_CDI_SCHEDULE_ENABLED
  const propValue = propertyValue(currentUser.tenantProperties, propKey)
  return propValue ? propValue.toLowerCase() === 'true' : false
}

export function allowEncounterCDICopyPaste(currentUser: User): boolean {
  const propKey = TENANT_PROPERTY.ENCOUNTER_CDI_COPY_PASTE_ENABLED
  const propValue = propertyValue(currentUser.tenantProperties, propKey)
  return propValue ? propValue.toLowerCase() === 'true' : false
}

/**
 * @param currentUser Current user entity
 * @return true if current user tenant property allows CDI to add an expected
 * diagnosis to an encounter condition
 */
export function cdiExpectedDiagnosisEnabled(currentUser: User): boolean {
  const propKey = TENANT_PROPERTY.ENCOUNTER_CONDITION_EXPECTED_DIAGNOSIS_ENABLED
  const propValue = propertyValue(currentUser.tenantProperties, propKey)
  return propValue ? propValue.toLowerCase() === 'true' : true
}

/**
 * @param currentUser Current user entity
 * @return true if current user tenant property allows rule filter and rule risk category version to show
 */
export function cdiRuleFilterAndRuleRiskCategoryVersionEnabled(currentUser: User): boolean {
  const propKey = TENANT_PROPERTY.ENCOUNTER_CDI_RULE_FILTER_AND_RULE_RISK_CATEGORY_VERSION_ENABLED
  const propValue = propertyValue(currentUser.tenantProperties, propKey)
  return propValue ? propValue.toLowerCase() === 'true' : true
}

/**
 *
 * @param currentUser current logged in user
 * @returns TRUE if tenant property exists and is set to TRUE
 */
export function knownConditionDismissEnabled(
  currentUser: User
): boolean {
  const propKey = TENANT_PROPERTY.ENCOUNTER_CONDITION_KNOWN_DISMISS_ENABLED
  const propValue = propertyValue(currentUser.tenantProperties, propKey)
  return propValue ? propValue.toLowerCase() === 'true' : false
}

export function coderQueryExpireHours(currentUser: User): number {
  const propKey = TENANT_PROPERTY.REVIEW_CODER_QUERY_WINDOW_HOURS
  const propValue = propertyValue(currentUser.tenantProperties, propKey)
  return propValue ? parseInt(propValue, 10) : CODER.QUERY_EXPIRE_HOURS_DEFAULT
}

export function coderWorklistEndHours(currentUser: User): number {
  const propKey = TENANT_PROPERTY.WORKLIST_CODER_QUERY_END_HOURS
  const propValue = propertyValue(currentUser.tenantProperties, propKey)
  return propValue ? parseInt(propValue, 10) : CODER.WORKLIST_QUERY_END_HOURS
}

export function coderWorklistStartDays(currentUser: User): number {
  const propKey = TENANT_PROPERTY.WORKLIST_CODER_QUERY_START_DAYS
  const propValue = propertyValue(currentUser.tenantProperties, propKey)
  return propValue ? parseInt(propValue, 10) : CODER.WORKLIST_QUERY_START_DAYS
}

export function tenantOffshoreFilterEnabled(currentUser: User): boolean {
  const tenantPropKey = TENANT_PROPERTY.OFFSHORE_FILTER_ENABLED
  const tenantPropValue = tenantPropertyValuePrivate(
    currentUser,
    tenantPropKey as keyof typeof TENANT_PROPERTY
  )
  return tenantPropValue ? tenantPropValue.toLowerCase() === 'true' : false
}

/**
 * Provides an internal helper for getting a property value from either the
 * current user tenant properties or the current user user properties. A user
 * property value overrides a tenant property value of the same key
 */
function tenantOrUserPropertyValue(
  currentUser: User,
  propertyKey: string
): string | null {
  const tenantValue = propertyValue(currentUser.tenantProperties, propertyKey)
  const userValue = propertyValue(currentUser.userProperties, propertyKey)
  return userValue || tenantValue || null
}

/**
 * @param currentUser Current user entity
 * @return Show encounter conditions from NLP
 */
export function encounterConditionDisplayNlpInsights(currentUser: User) {
  // const propKey = TENANT_PROPERTY.ENCOUNTER_CONDITION_DISPLAY_NLP_INSIGHTS
  const propKey = USER_PROPERTY.ENCOUNTER_CONDITION_DISPLAY_NLP_INSIGHTS
  return tenantOrUserPropertyValue(currentUser, propKey)
}

/**
 * @param urlPath String representation of the path location in the app
 * @return Boolean True if the urlPath indicates that the current app view should be EMBEDDED
 */
export const isEmbeddedView = (urlPath: string): boolean =>
  urlPath.startsWith(PAGES.EMBEDDED_ENCOUNTER_DETAIL)

/**
 * @param urlPath String representation of the path location in the app
 * @return Boolean True if the urlPath indicates that the current app view should be a SMART view
 */
export const isSmartView = (urlPath: string): boolean =>
  urlPath.startsWith(PAGES.SMART_ENCOUNTER_DETAIL)

/**
 * @param currentUser Current user entity
 * @return Organization IDs for encounter worklist filtering or null
 * region filter set on current user
 */
export function patientOrgFilter(currentUser: User) {
  const properties = currentUser.userProperties
  const propKey = USER_PROPERTY.USER_PATIENT_ORG_FILTER
  const propValue = userPropertyValue(properties, propKey)
  if (propValue) {
    const values = propValue.split(',')
    if (values.length > 0) {
      return values[0]
    }
    return null
  }
  return null
}

/**
 * @param currentUser Current user entity
 * @return List of regions for encounter worklist filtering or null
 * region filter set on current user
 */
export function patientRegionFilter(currentUser: User) {
  const properties = currentUser.userProperties
  const propKey = USER_PROPERTY.USER_PATIENT_REGION_FILTER
  const propValue = userPropertyValue(properties, propKey)
  if (propValue) {
    return propValue.split(',').map(v => v.toUpperCase())
  }
  return null
}

/**
 * This is associated with the CDI_RESTRICTED role.
 *
 * @param currentUser Current user entity
 * @return Offshore filter property for this user wrapped in an array, or null
 */
export function patientOffshoreFilter(currentUser: User) {
  // Check the associated tenant level property
  if (!tenantOffshoreFilterEnabled(currentUser)) {
    return null;
  }

  const properties = currentUser.userProperties
  const propKey = USER_PROPERTY.USER_PATIENT_OFFSHORE_FILTER
  const propValue = userPropertyValue(properties, propKey)
  if (propValue) {
    return propValue.split(',').map(v => v.toUpperCase())
  }
  return null
}