import React, { Component } from "react";
import { BASE_URL } from "../../config";
import { API_URL } from "../../constant";

/**
 * @param encounterId If defined, render iframe with PDF report for specified
 *        encounter
 */
class Pdf extends Component {

  render() {
    const reportUrl = this.props.isCdi === true
      ? API_URL.PDF_ENCOUNTER_FOR_CDI_REPORT(this.props.encounterId)
      : API_URL.PDF_ENCOUNTER_REPORT(this.props.encounterId)

    return (
      <iframe title="Encounter PDF" className="pdf-iframe" src={BASE_URL + reportUrl} />
    );
  }
}

export default Pdf;
