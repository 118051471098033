import React, { Component } from "react";
import { Image } from "semantic-ui-react";
import * as config from "../../../config";
import { API_URL } from "../../../constant";
import { connect } from 'react-redux'
import { useCdiRestricted } from '../../common/roleUtils'

/**
 * @param patientId Patient ID
 * @param clazz Optional class name to apply to image
 */
class PatientProfile extends Component {

  render() {
    const effectiveRole = useCdiRestricted(this.props.currentUser)
    const url = config.BASE_URL + API_URL.PATIENT_PROFILE_WITH_ROLE(effectiveRole) + this.props.patientId;
    const className = this.props.clazz ? this.props.clazz : "";

    return (
      <Image src={url} className={className} />
    );
  }
}

export default connect(
  reduxState => ({
    currentUser: reduxState.user
  })
)(PatientProfile);
