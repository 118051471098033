/* eslint-disable @typescript-eslint/naming-convention */
const categoryMapping: Record<string, string> = {
  null: 'Other Conditions',
  0: 'Other Conditions',
  1: 'HIV/AIDS',
  2: 'Septicemia, Sepsis, SIRS/Shock',
  6: 'Opportunistic Infections',
  8: 'Metastatic Cancer and Acute Leukemia',
  9: 'Lung and Other Severe Cancers',
  10: 'Lymphoma and Other Cancers',
  11: 'Colorectal, Bladder, and Other Cancers',
  12: 'Breast, Prostate, and Other Cancers and Tumors',
  17: 'Diabetes',
  18: 'Diabetes',
  19: 'Diabetes',
  21: 'Protein-Calorie Malnutrition',
  22: 'Morbid Obesity',
  23: 'Other Significant Endocrine and Metabolic Disorders',
  27: 'Liver Disease',
  28: 'Liver Disease',
  29: 'Liver Disease',
  33: 'Intestinal Obstruction/Perforation',
  34: 'Chronic Pancreatitis',
  35: 'Inflammatory Bowel Disease',
  39: 'Bone/Joint/Muscle Infections/Necrosis',
  40: 'Rheumatoid Arthritis and Inflammatory Connective Tissue Disease',
  46: 'Hematological Disorders',
  47: 'Disorders of Immunity',
  48: 'Hematological Disorders',
  51: 'Dementia',
  52: 'Dementia',
  54: 'Substance Use Disorder',
  55: 'Substance Use Disorder',
  56: 'Substance Use Disorder',
  57: 'Schizophrenia',
  58: 'Reactive and Unspecified Psychosis',
  59: 'Major Depressive, Bipolar, and Paranoid Disorders',
  60: 'Personality Disorders',
  70: 'Paralyses and Spine Injuries',
  71: 'Paralyses and Spine Injuries',
  72: 'Paralyses and Spine Injuries',
  73: 'Amyotrophic Lateral Sclerosis and Other Motor Neuron Disease',
  74: 'Cerebral Palsy',
  75: 'Myasthenia Gravis/Myoneural Disorders and Guillain-Barre Syndrome/ Inflammatory and Toxic Neuropathy',
  76: 'Muscular Dystrophy',
  77: 'Multiple Sclerosis',
  78: 'Parkinson’s and Huntington’s Diseases',
  79: 'Seizure Disorders and Convulsions',
  80: 'Coma, Brain Compression/Anoxic Damage',
  82: 'Respiratory Decompensation',
  83: 'Respiratory Decompensation',
  84: 'Respiratory Decompensation',
  85: 'Congestive Heart Failure',
  86: 'Angina and AMI',
  87: 'Angina and AMI',
  88: 'Angina and AMI',
  96: 'Specified Heart Arrhythmias',
  99: 'Intracranial Hemorrhage',
  100: 'Ischemic or Unspecified Stroke',
  103: 'Paralyses and Spine Injuries',
  104: 'Paralyses and Spine Injuries',
  106: 'Vascular Disease',
  107: 'Vascular Disease',
  108: 'Vascular Disease',
  110: 'Lung Disorders',
  111: 'Lung Disorders',
  112: 'Lung Disorders',
  114: 'Pneumonias',
  115: 'Pneumonias',
  122: 'Proliferative Diabetic Retinopathy and Vitreous Hemorrhage',
  124: 'Exudative Macular Degeneration',
  134: 'Kidney Disease',
  135: 'Kidney Disease',
  136: 'Kidney Disease',
  137: 'Kidney Disease',
  138: 'Kidney Disease',
  157: 'Ulcer of Skin',
  158: 'Ulcer of Skin',
  159: 'Ulcer of Skin',
  161: 'Ulcer of Skin',
  162: 'Severe Skin Burn or Condition',
  166: 'Head Injury',
  167: 'Head Injury',
  169: 'Vertebral Fractures without Spinal Cord Injury',
  170: 'Hip Fracture/Dislocation',
  173: 'Traumatic Amputations and Complications',
  176: 'Complications of Specified Implanted Device or Graft',
  186: 'Major Organ Transplant or Replacement Status',
  188: 'Artificial Openings for Feeding or Elimination',
  189: 'Amputation Status, Lower Limb/Amputation Complications'
}

export default categoryMapping
