/* eslint-disable max-len */
/* eslint-disable react/no-unused-prop-types */
import React, { Dispatch, SetStateAction, useState } from 'react'
import { Button, Popup, Icon, Modal, Container } from 'semantic-ui-react'
// Local
import { ENCOUNTER, REVIEW } from '../../../constant'
import * as lockUtils from '../../common/lockUtils'
import { isReadyForCdiQa } from '../../common/reviewUtils'
import { isCdiQaManager } from '../../common/roleUtils'
import Pdf from '../pdf'
import HeaderIcon from '../common/headerIcon'
import LockIcon from '../common/lockIcon'
import If from '../../common/if'
import Close from '../../icons/close'
import resetEncounter from '../../common/resetEncounter'
import { allowEncounterCDICopyPaste } from '../../common/currentUserUtils'
import { User, Encounter, ObjectValues } from '../../authenticated/types'

const encounterLockMessage = 'Additional encounters for this patient have been locked. Consider curating the additional encounters in the worklist.'

type EncounterAction = ObjectValues<typeof ENCOUNTER.ACTION>

type Props = {
  encounter: Encounter
  currentUser: User
  isACopiedEncounter: boolean
  onClick: (action: EncounterAction) => void
}

type RenderEditProps = {
  encounter: Encounter
  currentUser: User
  isACopiedEncounter: boolean
  setIsResetEncounterModalShowing: Dispatch<SetStateAction<boolean>>
  isResetEncounterAllowed: boolean
  onClick: (action: any) => void
  onClickFollowUp: () => void
  followUpColor: () => void
  followUpText: () => void
}

type RenderResetModalProps = {
  encounter: Encounter
  isCopyEncounterInitiated: boolean
  setIsCopyEncounterInitiated: Dispatch<SetStateAction<boolean>>
  isACopiedEncounter: boolean
  isResetEncounterModalShowing: boolean
  setIsResetEncounterModalShowing: Dispatch<SetStateAction<boolean>>
}

function RenderRead(props: Props) {
  const { encounter, currentUser, onClick } = props
  const { review } = encounter
  const { lock } = encounter
  const action = ENCOUNTER.ACTION
  let canEdit = true
  if (isReadyForCdiQa(review) && !isCdiQaManager(currentUser.roles)) {
    canEdit = false
  }

  const shouldAllowCDICopyPaste = allowEncounterCDICopyPaste(currentUser)

  return (
    <span>
      <If isTrue={ canEdit }>
        <span className="lock-container">
          <LockIcon
            lock={ lock }
            currentUser={ currentUser }
            onClick={ () => onClick(action.LOCK_TOGGLE) }
          />
          <If isTrue={ shouldAllowCDICopyPaste && (!!lock && lock.patientLockCount > 0) }>
            <Popup
              content={ encounterLockMessage }
              mouseEnterDelay={ 1000 }
              trigger={
                <span className="badge">{ lock && lock.patientLockCount }</span>
              }
            />
          </If>
        </span>
      </If>
      <Modal
        size="fullscreen"
        trigger={
          <Icon name="print" size="large" color="grey" className="pointer" />
        }
      >
        <Modal.Content>
          <Pdf encounterId={ encounter.id } isCdi={ true } />
        </Modal.Content>
      </Modal>
    </span>
  )
}

function RenderEdit(props: RenderEditProps) {
  const {
    currentUser,
    encounter,
    isACopiedEncounter,
    isResetEncounterAllowed,
    setIsResetEncounterModalShowing,
    onClick,
    onClickFollowUp,
    followUpColor,
    followUpText
  } = props
  const { review } = encounter
  const { lock } = encounter
  const reviewCode = review ? review.reviewCode : null
  const action = ENCOUNTER.ACTION
  let actions = []
  if (reviewCode && reviewCode === REVIEW.CODE.OPEN) {
    actions = [
      {
        name: 'question circle',
        color: 'yellow',
        popUp: 'Click to mark Ready for CDI QA',
        onClick: () => onClick(action.MARK_READY_FOR_CDI_QA)
      },
      {
        name: 'check circle',
        color: 'green',
        popUp: 'Click to mark Ready for Physician',
        onClick: () => onClick(action.MARK_READY_FOR_PHYSICIAN)
      }
    ]
  } else if (reviewCode && reviewCode === REVIEW.CODE.READY_FOR_CDI_QA) {
    actions = [
      {
        name: 'undo',
        color: 'orange',
        popUp: 'Click to mark review as Open and send back to CDI Worklist',
        onClick: () => onClick(action.MARK_OPEN)
      },
      {
        name: 'check circle',
        color: 'green',
        popUp: 'Click to mark Ready for Physician',
        onClick: () => onClick(action.MARK_READY_FOR_PHYSICIAN)
      },
      {
        name: 'hourglass half',
        color: followUpColor(),
        popUp: followUpText(),
        onClick: () => onClickFollowUp()
      }
    ]
  } else if (reviewCode && reviewCode === REVIEW.CODE.READY_FOR_PHYSICIAN) {
    actions = [
      {
        name: 'undo',
        color: 'orange',
        popUp: 'Click to mark review as Open and send back to CDI Worklist',
        onClick: () => onClick(action.MARK_OPEN)
      },
      {
        name: 'question circle',
        color: 'yellow',
        popUp: 'Click to mark Ready for CDI QA',
        onClick: () => onClick(action.MARK_READY_FOR_CDI_QA)
      }
    ]
  } else {
    actions = [
      {
        name: 'undo',
        color: 'orange',
        popUp: 'Click to mark review as Open',
        onClick: () => onClick(action.MARK_OPEN)
      },
      {
        name: 'question circle',
        color: 'yellow',
        popUp: 'Click to mark Ready for CDI QA',
        onClick: () => onClick(action.MARK_READY_FOR_CDI_QA)
      },
      {
        name: 'check circle',
        color: 'green',
        popUp: 'Clock to mark Ready for Physician',
        onClick: () => onClick(action.MARK_READY_FOR_PHYSICIAN)
      }
    ]
  }

  const shouldAllowCDICopyPaste = allowEncounterCDICopyPaste(currentUser)

  return (
    <span>
      <If isTrue={ isACopiedEncounter && isResetEncounterAllowed }>
        <Button
          className="warning"
          onClick={ () => {
            setIsResetEncounterModalShowing(true)
          } }
        >
          Reset Copied Encounter
        </Button>
      </If>
      { actions.map(a => (
        <HeaderIcon
          key={ a.name }
          name={ a.name }
          onClick={ a.onClick }
          color={ a.color }
          popUp={ a.popUp }
        />
      )) }
      <span className="lock-container">
        <LockIcon
          lock={ lock }
          currentUser={ currentUser }
          onClick={ () => onClick(action.LOCK_TOGGLE) }
        />
        <If isTrue={ shouldAllowCDICopyPaste && (!!lock && lock.patientLockCount > 0) }>
          <Popup
            content={ encounterLockMessage }
            mouseEnterDelay={ 1000 }
            trigger={
              <span className="badge">{ lock && lock.patientLockCount }</span>
            }
          />
        </If>
      </span>
      <Modal
        size="fullscreen"
        trigger={
          <Icon name="print" size="large" color="grey" className="pointer" />
        }
      >
        <Modal.Content>
          <Pdf encounterId={ encounter.id } isCdi={ true } />
        </Modal.Content>
      </Modal>
    </span>
  )
}

function RenderResetEncounterModal(props: RenderResetModalProps) {
  const {
    isACopiedEncounter,
    setIsCopyEncounterInitiated,
    isResetEncounterModalShowing,
    setIsResetEncounterModalShowing,
    isCopyEncounterInitiated,
    encounter
  } = props
  return (
    <Modal
      onClose={ () => setIsResetEncounterModalShowing(false) }
      onOpen={ () => setIsResetEncounterModalShowing(true) }
      open={ isACopiedEncounter && isResetEncounterModalShowing }
      closeIcon={
        <div className="modal-close">
          <div className="close-icon">
            <Close />
          </div>
        </div>
      }
      size="large"
      closeOnDimmerClick={ false }
      closeOnDocumentClick={ false }
      closeOnEscape={ true }
      closeOnPortalMouseLeave={ false }
      closeOnRootNodeClick={ false }
    >
      <div className="cdi-flow">
        <h2>Reset copied encounter requested</h2>
        <If isTrue={ !isCopyEncounterInitiated }>
          <p>
            Resetting this enounter will be cause it to revert back to its
            original state and all edits made will be lost.
            <br />
            Are you sure you want to continue?
          </p>
        </If>
        <If isTrue={ isCopyEncounterInitiated }>
          <p>
            Please wait a moment while we finish resetting this previously
            copied encounter. This should only take a few moments...
          </p>
        </If>
        <Container>
          <Button
            onClick={ () => {
              setIsCopyEncounterInitiated(true)
              if (encounter.id) {
                resetEncounter(encounter.id)
              }
            } }
            disabled={ isCopyEncounterInitiated }
          >
            Reset Encounter
          </Button>
          <Button
            onClick={ () => {
              setIsResetEncounterModalShowing(false)
            } }
            disabled={ isCopyEncounterInitiated }
          >
            Cancel
          </Button>
        </Container>
      </div>
    </Modal>
  )
}

function CDIEncounterHeaderButtons(props: Props): JSX.Element {
  const { encounter, currentUser, onClick, isACopiedEncounter } = props

  const DISALLOWED_REVIEW_STATUSES_FOR_RESET = [
    REVIEW.CODE.READY_FOR_PHYSICIAN,
    REVIEW.CODE.READY_FOR_CODER,
    REVIEW.CODE.READY_FOR_CODER_QA,
    REVIEW.CODE.CODER_BYPASSED,
    REVIEW.CODE.COMPLETED
  ]

  const queryStatus = encounter.review ? encounter.review.queryStatusCode : null

  /// /////////////////////////
  const [ isResetEncounterModalShowing, setIsResetEncounterModalShowing ] =
    useState(false)
  const [ queryStatusCode, setQueryStatusCode ] = useState(queryStatus)
  const [ isCopyEncounterInitiated, setIsCopyEncounterInitiated ] =
    useState(false)
  /// /////////////////////////

  const isResetEncounterAllowed =
    !!encounter.review &&
    !!encounter.review.reviewCode &&
    !DISALLOWED_REVIEW_STATUSES_FOR_RESET.includes(encounter.review.reviewCode)

  const handleClick = (action: EncounterAction) => {
    if (onClick) onClick(action)
  }

  const onClickFollowUp = () => {
    const queryOutstanding = REVIEW.QUERY_STATUS.REVIEW_QUERY_OUTSTANDING
    const queryReceived = REVIEW.QUERY_STATUS.REVIEW_QUERY_RESPONSE_RECEIVED
    const newStatus =
      queryStatusCode === queryOutstanding ? queryReceived : queryOutstanding
    setQueryStatusCode(newStatus)
    if (newStatus === queryOutstanding) {
      onClick(ENCOUNTER.ACTION.REVIEW_QUERY_OUTSTANDING)
    } else {
      onClick(ENCOUNTER.ACTION.REVIEW_QUERY_RESOLVED)
    }
  }

  const followUpColor = (): 'red' | 'grey' =>
    queryStatusCode === REVIEW.QUERY_STATUS.REVIEW_QUERY_OUTSTANDING
      ? 'red'
      : 'grey'

  const followUpText = (): string =>
    queryStatusCode === REVIEW.QUERY_STATUS.REVIEW_QUERY_OUTSTANDING
      ? 'CDI review requested. Click to mark Resolved'
      : 'Click to request CDI review and send back to CDI worklist'

  if (isResetEncounterModalShowing) {
    return (
      <RenderResetEncounterModal
        encounter={ encounter }
        isCopyEncounterInitiated={ isCopyEncounterInitiated }
        setIsCopyEncounterInitiated={ setIsCopyEncounterInitiated }
        isACopiedEncounter={ isACopiedEncounter }
        isResetEncounterModalShowing={ isResetEncounterModalShowing }
        setIsResetEncounterModalShowing={ setIsResetEncounterModalShowing }
      />
    )
  }

  if (lockUtils.canEdit(encounter.lock, currentUser)) {
    return (
      <RenderEdit
        encounter={ encounter }
        currentUser={ currentUser }
        isACopiedEncounter={ isACopiedEncounter }
        isResetEncounterAllowed={ isResetEncounterAllowed }
        setIsResetEncounterModalShowing={ setIsResetEncounterModalShowing }
        onClick={ handleClick }
        onClickFollowUp={ onClickFollowUp }
        followUpColor={ followUpColor }
        followUpText={ followUpText }
      />
    )
  }

  return (
    <RenderRead
      encounter={ encounter }
      currentUser={ currentUser }
      isACopiedEncounter={ isACopiedEncounter }
      onClick={ handleClick }
    />
  )
}

export default CDIEncounterHeaderButtons
