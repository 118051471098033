/* eslint-disable react/no-unstable-nested-components */
import React from 'react'
import { prop } from 'ramda'
// Local
import Button from './button'
import { API_URL } from '../../../constant'
import { api } from '../../../action/api'
import { ButtonOptions, EncounterConditionDisplayModified } from '../../authenticated/types'
import { ButtonClick, MessageStatusChoice } from './action-buttons'
import useSelectedButton from './useButtonStatus'

export type Props = ButtonClick & {
  id: string
  defaultSelectedButton: string
  buttons: ButtonOptions[]
  condition: EncounterConditionDisplayModified
}

const getStatusMessage = (selectedButton: string): string =>
  selectedButton.length ? selectedButton : MessageStatusChoice.ActionRecommended

const getClass = (selectedButton: string | undefined): string =>
  `message${selectedButton === undefined ? ' action-required' : ''}`

/**
 * Update practitioner tags on one or more conditions. Practitioner tags can
 * get updated on a single condition or on a group of conditions
 * @param conditions Array of conditions
 */
function updatePractitionerTags(conditions: any[]): any {
  const tags = conditions.map(prop('practitionerTags'))
  api
    .put(API_URL.UPDATE_CONDITION_PRACTITIONER_TAGS, tags)
    .then(({ data }) => data)
}

function updatePractitionerTag(
  value: string,
  condition: EncounterConditionDisplayModified
): void {
  const practitionerTags = {
    encounterConditionId: condition.id,
    practitionerResponseCode: value
  }
  updatePractitionerTags([{ ...condition, practitionerTags }])
}

function ActionButtons(props: Props): JSX.Element {
  const {
    id,
    condition,
    defaultSelectedButton,
    buttons,
    onClick
  } = props

  const { selectedButton, setSelectedButton } = useSelectedButton(
    defaultSelectedButton
  )

  function RenderButton(buttonProps: ButtonOptions): JSX.Element {
    const { label, text, value } = buttonProps
    return (
      <Button
        id={ `${id}-${value}` }
        text={ text }
        label={ label }
        value={ value }
        selectedButton={ selectedButton }
        setSelectedButton={ setSelectedButton }
        onClick={ (): void => {
          onClick(value)
          updatePractitionerTag(value, condition)
        } }
      />
    )
  }

  return (
    <div className="curation-ui-action-buttons">
      { /* Button Status Message */ }
      <div className={ getClass(selectedButton) }>
        { getStatusMessage(selectedButton) }
      </div>

      { /* Buttons */ }
      <div className="buttons">{ buttons.map(RenderButton) }</div>
    </div>
  )
}

export default ActionButtons
