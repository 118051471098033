import "./condition.css";

// Error Strings
export const TITLE_ERROR = "Each Entry Must Have a Title";
export const DIAGNOSIS_ERROR = "Each Entry Must Have a Description";
export const ICD10_ERROR = "Invalid ICD10";

// Title Strings
export const BUTTON_TITLES = {
  activate: 'Mark as Active',
  activity: 'Mark as Activity',
  known: 'Mark as Known',
  potential: 'Mark as Potential',
  remove: 'Mark as Removed',
}

// Configuration for the ReactMarkdown component
export const toolbarConfig = {
  display: ["INLINE_STYLE_BUTTONS", "BLOCK_TYPE_BUTTONS"],
  INLINE_STYLE_BUTTONS: [
    { label: "Bold", style: "BOLD", className: "custom-css-class" },
    { label: "Italic", style: "ITALIC" },
    { label: "Underline", style: "UNDERLINE" }
  ],
  BLOCK_TYPE_BUTTONS: [{ label: "UL", style: "unordered-list-item" }, { label: "OL", style: "ordered-list-item" }]
};
