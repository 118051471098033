import React, { Dispatch, SetStateAction, useState } from 'react'
import { Button, Container,  Modal } from 'semantic-ui-react'
// Local
import If from './if'
import Close from '../icons/close'
import { Encounter, EncounterDetail } from '../authenticated/types'
import copyEncounter from './copyEncounter'

type CopyPasteModalProps = {
  currentEncounter: Encounter
  previousEncounter: Encounter
  isEncounterLocked: boolean
  isModalShowing: boolean
  setEncounterDetail: Dispatch<SetStateAction<EncounterDetail>>
  setIsModalShowing: Dispatch<SetStateAction<boolean>>
  setIsACopiedEncounter: Dispatch<SetStateAction<boolean>>
}

const dateOptions = {
  year: '2-digit',
  month: '2-digit',
  day: '2-digit'
} as const

const formatDate = (x: Date | null | undefined): string =>
  x ? new Date(x).toLocaleDateString('en-us', dateOptions)
    : ''

function CopyPasteEncounterModal(props: CopyPasteModalProps): JSX.Element {
  const {
    currentEncounter,
    previousEncounter,
    isEncounterLocked,
    isModalShowing,
    setEncounterDetail,
    setIsModalShowing,
    setIsACopiedEncounter
  } = props

  const [ isCopyCallNotifcationModalOpen, setIsCopyCallNotifcationModalOpen ] = useState<boolean>(false)

  return (
    <>
      <If isTrue={ isEncounterLocked }>
        <Modal
          onClose={ () => setIsModalShowing(false) }
          onOpen={ () => setIsModalShowing(true) }
          open={ isModalShowing }
          size="tiny"
          closeIcon={
            <div className="modal-close">
              <div className="close-icon">
                <Close />
              </div>
            </div>
          }
          closeOnDimmerClick={ false }
        >
          <div className="cdi-flow">
            <h1>WARNING!</h1>
            <p>{ `This action will copy CDI curation from the ${formatDate(previousEncounter.start)} encounter
              to this ${formatDate(currentEncounter.start)} encounter. Are you sure you want to continue?` }
            </p>
            <Container>
              <Button
                disabled={ !currentEncounter || !previousEncounter }
                className="schedule-button"
                onClick={ () => {
                  setIsCopyCallNotifcationModalOpen(true)
                  copyEncounter({
                    currentEncounterId: currentEncounter.id,
                    previousEncounterId: previousEncounter.id,
                    setEncounterDetail,
                    setIsModalShowing,
                    setIsACopiedEncounter
                  })
                } }
              >
              Copy Encounter
              </Button>
              <Button
                className="cancel"
                onClick={ () => setIsModalShowing(false) }
              >
              Cancel
              </Button>
            </Container>
          </div>
        </Modal>
        <Modal
          onClose={ () => setIsCopyCallNotifcationModalOpen(false) }
          onOpen={ () => setIsCopyCallNotifcationModalOpen(true) }
          open={ isCopyCallNotifcationModalOpen }
          size="large"
          closeOnDimmerClick={ false }
          closeOnDocumentClick={ false }
          closeOnEscape={ false }
          closeOnPortalMouseLeave={ false }
          closeOnRootNodeClick={ false }
        >
          <div className="cdi-flow">
            <h2>Copy encounter requested</h2>
            <p>
            Please wait a moment while we finish copying previously curated encounter into this encounter.
            This should only take a few moments...
            </p>
          </div>
        </Modal>
      </If>
      <If isTrue={ !isEncounterLocked }>
        <p>
      Current encounter must be locked for CDI to execute encounter copy.
        </p>
      </If>
    </>
  )
}

export default CopyPasteEncounterModal
