import { useState } from 'react'
// Local
import { SelectedButton } from './action-buttons'

// module "/Users/taldanan/Code/treehouse/ui/client/src/component/v2/ActionButtons/action-buttons"

function useSelectedButton(button: string): SelectedButton {
  const [ selectedButton, setSelectedButton ] = useState<string>(button)
  return {
    selectedButton,
    setSelectedButton
  }
}

export default useSelectedButton
