import {
  map,
  pipe,
  prop,
  sum,
  uniq,
  reject,
  filter,
  length,
  omit,
  identity,
  sortWith,
  descend,
  ascend
} from 'ramda'
// Local
import categoryMapping from './utils/categoryMapping'
import { condition, isCmsV28ConditionRule } from '../common/conditionUtils'
import { encounterPractitionerTagOptions } from '../common/currentUserUtils'
import {
  EncounterConditionDisplay,
  EncounterConditionDisplayModified,
  TagStatus
} from '../authenticated/types'

/// //////////////////////////////
// Types
/// //////////////////////////////

// TODO: ARE THESE THE SAME?
export type KnownAndPotential = {
  title: string
  conditions: EncounterConditionDisplayModified[]
  score: number
  required: number
  knownAddressed: number
}

type OutputWithScore = {
  title: string
  conditions: EncounterConditionDisplayModified[]
  score: number
  defaultState: number
  required: number
  knownAddressed: number
}

export type FinalOutput = {
  activities: EncounterConditionDisplayModified[]
  conditions: KnownAndPotential[]
}

/// //////////////////////////////
// Utility Functions
/// //////////////////////////////

// Array of HCC codes used for grouping conditions in the UI
const hccOrEmptyString = (x: EncounterConditionDisplayModified) =>
  x.hccCode || ''
const hccCodes = pipe<
  EncounterConditionDisplayModified[][],
  string[],
  string[]
>(map(hccOrEmptyString), uniq)

const confidence = (x: EncounterConditionDisplayModified): number =>
  x.hccConfidence

const sumScores = pipe<EncounterConditionDisplayModified[][], number[], number>(
  map(confidence),
  sum
)

const isRequired = (x: EncounterConditionDisplayModified): boolean => {
  const isNotEvaluated = x.practitionerResponseCode === TagStatus.NotEvaluated
  const isNull = x.practitionerResponseCode === null
  const isNotKnownAddressed = !x.isKnownAddressed
  return (isNotEvaluated || isNull) && isNotKnownAddressed
}

const isRequiredCondition = (
  x: EncounterConditionDisplay,
  practitionerResponseCode: TagStatus,
  isKnownAddressed: boolean
): boolean => {
  const isNull = practitionerResponseCode === null
  const isNotKnownAddressed = isKnownAddressed === false
  const isNotEvaluated = practitionerResponseCode === TagStatus.NotEvaluated
  return (isNull && isNotKnownAddressed) || isNotEvaluated
}

const required = pipe<
  EncounterConditionDisplayModified[][],
  EncounterConditionDisplayModified[],
  number
>(filter(isRequired), length)

const isKnownAddressed = (x: EncounterConditionDisplayModified): boolean =>
  x.isKnownAddressed

const knownAddressed = pipe<
  EncounterConditionDisplayModified[][],
  EncounterConditionDisplayModified[],
  number
>(filter(isKnownAddressed), length)

const isDefaultState = (x: EncounterConditionDisplayModified): boolean =>
  x.isDefaultState

const defaultState = pipe<
  EncounterConditionDisplayModified[][],
  EncounterConditionDisplayModified[],
  number
>(filter(isDefaultState), length)

const categorySort = sortWith<OutputWithScore>([
  descend(prop('required')),
  descend(prop('defaultState')),
  descend(prop('score')),
  descend(prop('knownAddressed')),
  ascend(prop('title'))
])

const conditionSort = sortWith<EncounterConditionDisplayModified>([
  descend(prop('isRequired')),
  descend(prop('isDefaultState')),
  descend(prop('hccConfidence')),
  descend(prop('isKnownAddressed')),
  ascend(prop('title'))
])

const title = (
  sortedConditions: EncounterConditionDisplayModified[],
  hccCode: string
): string =>
  sortedConditions.length > 1
    ? categoryMapping[hccCode]
    : sortedConditions[0].title

const conditionHCCNull = (x: EncounterConditionDisplayModified): boolean =>
  x.hccCode === null
const conditionHCCNotNull = (x: EncounterConditionDisplayModified): boolean =>
  x.hccCode !== null

const knownAndPotential = (
  conditionsArray: EncounterConditionDisplayModified[]
): OutputWithScore[] => {
  const knownAndPotentials = conditionsArray.filter(
    condition.isKnownOrPotentialModified
  )
  const conditionsWithoutHCCCodes = knownAndPotentials.filter(conditionHCCNull)
  const standAlonesWithNoHCCCode = conditionsWithoutHCCCodes.map(
    (ec: EncounterConditionDisplayModified): OutputWithScore => ({
      title: ec.title,
      conditions: [ ec ],
      score: ec.hccConfidence,
      defaultState: ec.isDefaultState ? 1 : 0,
      required: isRequired(ec) ? 1 : 0,
      knownAddressed: isKnownAddressed(ec) ? 1 : 0
    })
  )
  const conditionsWithHccCodesFiltered =
    knownAndPotentials.filter(conditionHCCNotNull)
  const conditionsWithHccCodes = hccCodes(
    conditionsWithHccCodesFiltered
  ).reduce((output, currentHCCCode) => {
    const knownAndPotentialMatchingHCCCode = conditionsArray
      .filter(encounter => encounter.isPotential || encounter.isKnown)
      .filter(encounter => encounter.hccCode === currentHCCCode)
    const sortedConditions = conditionSort(knownAndPotentialMatchingHCCCode)
    return [
      ...output,
      {
        title: title(sortedConditions, currentHCCCode),
        conditions: sortedConditions,
        score: sumScores(sortedConditions),
        defaultState: defaultState(sortedConditions),
        required: required(sortedConditions),
        knownAddressed: knownAddressed(sortedConditions)
      }
    ]
  }, [] as OutputWithScore[])
  return conditionsWithHccCodes.concat(standAlonesWithNoHCCCode)
}

function addProperties(
  c: EncounterConditionDisplay,
  currentUser: any
): EncounterConditionDisplayModified {
  const conditionsWithout = omit(
    [
      'version',
      'addressedDate',
      'cdiTags',
      'coderTags',
      'checkMark',
      'priority',
      'modificationDate',
      'ruleFilterId',
      'statusCode',
      'reasonCode',
      'practitionerTags',
      'tenantId',
      'confidence',
      // 'rules', // we will likely uncomment this later when we rip out this hack for OR-8606 at a later time
      'comments',
      'createdById',
      'creationDate',
      'notes',
      'modifiedById',
      'conditionCode',
      'hccDescription',
      'hccVersion',
      'reason'
    ],
    c
  )
  const newTitle = isCmsV28ConditionRule(c) ? `${c.title} [CMS v28]` : c.title
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const tagOptions = encounterPractitionerTagOptions(currentUser)
  const tagOptionsByConditionStatus = tagOptions
    ? tagOptions[c.conditionStatus]
    : []
  const practitionerResponseCode = condition.getPractitionerResponseCode(
    c
  ) as TagStatus
  const isConditionKnownAddressed = condition.isKnownAddressed(c)
  const isConditionRequired = isRequiredCondition(
    c,
    practitionerResponseCode,
    isConditionKnownAddressed
  )
  const isAddressed = condition.isConditionAddressed(c)
  return {
    ...conditionsWithout,
    title: newTitle,
    hccDescription: '',
    hccConfidence: Number(c.confidence && c.confidence.hccConfidence) || 0,
    tagOptions: tagOptionsByConditionStatus,
    isAddressed,
    isKnownAddressed: isConditionKnownAddressed,
    isKnown: condition.isKnown(c),
    isExternal: condition.isExternal(c),
    description: c.rules
          ? (c.rules.map(x => x.targetSourceCode && x.targetSourceCode.toUpperCase() === 'EXTERNAL' ? 'External' : x.reason).filter(identity) as string[])
          : [],
    isPotential: condition.isPotential(c),
    isDefaultState: !isConditionRequired && !isAddressed,
    isRequired: isConditionRequired,
    practitionerResponseCode
  }
}

const filterPipeline = (x: EncounterConditionDisplay[], currentUser: any) => {
  const withRemovedItems = reject(condition.shouldRemoveFromUI, x)
  return withRemovedItems.map(v => addProperties(v, currentUser))
}

export const processConditions = (
  x: EncounterConditionDisplay[],
  currentUser: any
): FinalOutput => {
  const processedConditions = filterPipeline(x, currentUser)
  return {
    activities: conditionSort(processedConditions.filter(condition.isActivity)),
    conditions: categorySort(knownAndPotential(processedConditions))
  }
}

export default {
  processConditions
}
