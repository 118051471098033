import React, { Component } from "react";
import { Message } from "semantic-ui-react";
import { REVIEW } from "../../../constant";

/**
 * Provides a component that renders and encounter review warning
 * @param review Encounter review entity
 * @param mode Render mode. Default: left
 */
class EncounterReviewWarning extends Component {

  renderLeft(review) {
    if ( review && review.reviewCode === REVIEW.CODE.OPEN ) {
      return (
        <Message error className="cdi">
          This patient has not yet been reviewed by the CDI Team
        </Message>
      );
    } else {
      return null;
    }
  }

  render() {
    const mode = this.props.mode ? this.props.mode : "left";
    if ( mode === "left" ) {
      return this.renderLeft(this.props.review);
    } else {
      return null;
    }
  }
}

export default EncounterReviewWarning;
