import React, { Component } from "react";
import { connect } from "react-redux";

import { Button, Form, Modal, Responsive } from "semantic-ui-react";

import { MOBILE_WIDTH, TABLET_WIDTH } from "../../constant";
import { submitFeedback } from "../../action/feedback";

import "./index.css";

/**
 * TODO - sboles - refactor this so it is has some idea of the context that it
 * is working in. Having to detect an encounter ID from redux state sucks.
 */
export class Feedback extends Component {
  constructor() {
    super(...arguments);
    this.state = this.initState();
  }

  initState = () => ({ open: false, form: {} });

  clickFeedback = () => {
    this.setState({ open: true, form: {} });
  };

  closeModal = () => {
    this.setState({ open: false });
  };

  updateField = (e, { name, value }) => {
    this.setState({ form: Object.assign({}, this.state.form, { [name]: value }) });
  };

  submit = () => {
    this.props.submitFeedback(this.state.form.comment, this.props.reduxState);
    this.setState({ open: false });
  };

  render() {
    const { open, form } = this.state;
    return (
      <div id="feedback">
        <Responsive
          as={Button}
          className="feedback"
          content="Feedback"
          icon="comments"
          color="black"
          onClick={this.clickFeedback}
        />
        <Modal closeIcon open={open} onClose={this.closeModal}>
          <Responsive as={Modal.Header} minWidth={TABLET_WIDTH.minWidth}>
            Please provide any clinical or technical feedback here, omitting PHI.
            For urgent matters, please reach out directly to a member of Curation Health.
          </Responsive>
          <Responsive as={Modal.Header} maxWidth={MOBILE_WIDTH.maxWidth}>
            How can we help?&nbsp;&nbsp;&nbsp;What can we do better?
          </Responsive>
          <Modal.Content>
            <Form onSubmit={this.submit}>
              <Form.TextArea
                name="comment"
                value={form["comment"]}
                placeholder="Type your message..."
                onChange={this.updateField}
              />
              <Button type="submit" color="blue">
                Submit
              </Button>
            </Form>
          </Modal.Content>
        </Modal>
      </div>
    );
  }
}

export default connect(
  state => ({
    reduxState: state
  }),
  { submitFeedback }
)(Feedback);
