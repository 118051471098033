import find from 'lodash/find'
// Local
import { TenantProperty } from '../authenticated/types'

export type ValueOf<T> = T[keyof T]

/** *****************************************************************************
 * Provides utilities for managing tenant properties
 */

export const findProperty = (
  properties: TenantProperty[] = [],
  key: ValueOf<TenantProperty>
): TenantProperty | undefined => find(properties, p => p.propertyKey === key)

export function hasProperty(
  properties: TenantProperty[],
  key: ValueOf<TenantProperty>
): boolean {
  const prop = findProperty(properties, key)
  return !!prop
}

export function propertyValue(
  properties: TenantProperty[],
  key: ValueOf<TenantProperty>
): string | null {
  const prop = findProperty(properties, key)
  return prop ? prop.propertyValue : null
}

export default {
  findProperty,
  hasProperty,
  propertyValue
}
