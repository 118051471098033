import React, { useState } from 'react'
import { isNil, pathOr } from 'ramda'
import ReactMarkdown from 'react-markdown'
// Local
import If from '../../common/if'
import {
  capitalizeFirstLetter,
  formatIcd10Code,
  removeEmptyValues
} from '../../v2/utils/utils'
import { EncounterConditionDisplayModified } from '../../authenticated/types'
import { truncatedDescription } from '../../common/formatUtils'

type Props = {
  condition: EncounterConditionDisplayModified
  sources: (string | null)[]
}

const maxCharactersAllowed = 200

const truncate = truncatedDescription(maxCharactersAllowed)

const hasDescriptionFor =
  (condition: EncounterConditionDisplayModified) =>
    (x: string): boolean =>
      !!pathOr('', [ x ], condition).length

function Description(props: Props): JSX.Element {
  const { condition, sources } = props

  const sourcesCapitalized = sources.map(capitalizeFirstLetter)

  const has = hasDescriptionFor(condition)

  const icd10CodeJsonToString: string = isNil(condition.icd10CodeJson)
    ? ''
    : condition.icd10CodeJson.map(formatIcd10Code).join(', ')

  const description = removeEmptyValues([
    condition.content,
    icd10CodeJsonToString
  ]) as string[]

  const shouldShowFullDescription =
    description.toString().length <= maxCharactersAllowed

  const [ shouldShowFullDescriptionState, setShouldShowFullDescriptionState ] =
    useState<boolean>(shouldShowFullDescription)

  return (
    <div className="curation-ui-description">
      <If isTrue={ shouldShowFullDescriptionState }>
        <If isTrue={ has('content') }>
          <p>
            <ReactMarkdown>{ condition.content || '' }</ReactMarkdown>
          </p>
        </If>
        <If
          isTrue={ condition.isKnownAddressed && !!icd10CodeJsonToString.length }
        >
          <p>{ icd10CodeJsonToString }</p>
        </If>
      </If>
      { /* More Button */ }
      <If isTrue={ !shouldShowFullDescriptionState }>
        <p>
          <span>{ `${truncate(description[0])}...` }</span>
          <span
            className="curation-ui-description-toggle"
            onClick={ () => setShouldShowFullDescriptionState(true) }
          >
            More
          </span>
        </p>
      </If>
      { /* Less Button */ }
      <If isTrue={ !shouldShowFullDescription && shouldShowFullDescriptionState }>
        <span
          className="curation-ui-description-toggle"
          onClick={ () => setShouldShowFullDescriptionState(false) }
        >
          Less
        </span>
      </If>
      <If isTrue={ !!sources.length }>
        <div className="source-container">
          <div className="source">
            <span className="bold">
              { `Source${sources.length > 1 ? 's' : ''}` }:{ ' ' }
            </span>
            <span>{ sourcesCapitalized.join('; ').replace("Source: ", "") }</span>
          </div>
        </div>
      </If>
    </div>
  )
}

export default Description
