import { PRACTITIONER_SPECIALTIES } from "../../constant"

// if does not match to a specialty, return ''
export function expandSpecialtyFilter(filterAbbreviation) {
    // we use startsWith because we only use the prefix as keys
    const fn = (k) => filterAbbreviation.startsWith(k) ? PRACTITIONER_SPECIALTIES[k] : '';
    return getPrefixes().map(fn).join('');
}

export function specialtyFilterKeys() {
    return getPrefixes().sort()
}

const getPrefixes = () => Object.keys(PRACTITIONER_SPECIALTIES)