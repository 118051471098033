import React, { Component } from "react";
import { Button, Form, Grid, Icon, Popup, Segment } from "semantic-ui-react";
import flatten from "lodash/flatten";
import forEach from "lodash/forEach";
import pickBy from "lodash/pickBy";
import map from "lodash/map";
import mapValues from "lodash/mapValues";
import { DateInput } from "semantic-ui-calendar-react";
import { parse } from "date-fns";
import * as c from "../../constant";
import AppointmentTypeSelect from "./appointmentTypeSelect";
import OrganizationSelect from "./organizationSelect";
import PatientRegionSelect from "./patientRegionSelect";
import PractitionerSelect from "./practitionerSelect";
import PotentialCountSelect from "./potentialCountSelect";
import SpecialtySelect from "./specialtySelect";
import VbcProgramSelect from "./vbcProgramSelect";
import "./filter.css";

/**
 * Provides a generic search filter component
 * @param submit Callback on submit button click
 *   Arguments: Object of filter key -> filter value. This object is ready to
 *   send to a search endpoint
 * @param filters Array of search filter metadata. Example:
 *   [
 *     [
 *       { placeholder: "MRN", name: "mrn", width: 8, like: true },
 *       { placeholder: "Last Name", name: "lastName", width: 8, like: true }
 *     ],
 *     [
 *       { placeholder: "Appointment Date", name: "start", width: 8, date: true },
 *       { placeholder: "Date of Birth", name: "birthDate", width: 8, date: true },
 *     ]
 *   ];
 *   Each child array is rendered as a row in the search form. The name
 *   property must match the query map in the corresponding repository. The
 *   width sets the grid column width. The default filter type is text input.
 *   Other supported types are: date: true, practitionerSelect: true,
 *   appointmentTypeSelect: true, organizationSelect: true,
 *   patientRegionSelect: true, vbcProgramSelect: true
 * @param initialValues Object of initial filter values: filter key -> value
 * @param onChange Callback function executed when a filter changes. Note that
 *   this is optional. The filter component is managing its own state
 *   Arguments: filter key, new value
 */
class FilterComponent extends Component {

  constructor() {
    super(...arguments);

    const initialValues = this.props.initialValues ? this.props.initialValues : {};

    const initialForm = flatten(this.props.filters).map((row) => {
      const val = initialValues[row.name] ? initialValues[row.name] : "";
      return { key: row.name, val: val };
    }).reduce((map, obj) => {
      map[obj.key] = obj.val;
      return map;
    }, {});

    this.state = {
      form: initialForm
    };
  }

  updateField = (e, { name, value }) => {
    this.setState({ form: Object.assign({}, this.state.form, { [name]: value }) });
    if ( this.props.onChange ) {
      this.props.onChange(name, value);
    }
  };

  submit = () => {
    const form = this.state.form;
    const filters = this.props.filters;
    if (this.props.updateUserPatientListFilters) {
      this.props.updateUserPatientListFilters(this.props.user, this.props.initialValues);
    }
    this.props.submit(generateQueryFilters(filters, form));
  };

  cancel = () => {
    const form = this.state.form;
    for (let key in form) {
      form[key] = "";
    }
    this.setState({ form: form });
    this.props.submit(generateQueryFilters(this.props.filters, form));
    if (this.props.setInitialValuesState) {
      this.props.setInitialValuesState(generateQueryFilters(this.props.filters, form));
    }
    if ( this.props.updateUserPatientListFilters ) {
      this.props.updateUserPatientListFilters(this.props.user, form);
    }
    if ( this.props.onClear ) {
      this.props.onClear();
    }
  }

  render() {
    const form = this.state.form;
    const filterFields = this.props.filters.map((row, index) => {
      var rowFilters = row.map(filter => {
        let field;
        if (filter.date) {
          field = (
            <DateInput
              className="dateinput"
              name={filter.name}
              placeholder={filter.placeholder}
              value={form[filter.name]}
              onChange={this.updateField.bind(this)}
              dateFormat={c.FORMAT.DATE}
              autoComplete="off"
            />
          );
        } else if ( filter.organizationSelect ) {
          field = (
            <OrganizationSelect
              name={filter.name}
              value={form[filter.name]}
              onChange={this.updateField.bind(this)}
              placeholder={filter.placeholder}
              multiple={false}
              allOption={false}
            />
          );
        } else if ( filter.practitionerSelect ) {
          field = (
            <PractitionerSelect
              name={filter.name}
              value={form[filter.name]}
              onChange={this.updateField.bind(this)}
              allOption={false}
            />
          );
        } else if ( filter.patientRegionSelect ) {
          field = (
            <PatientRegionSelect
              name={filter.name}
              value={form[filter.name]}
              onChange={this.updateField.bind(this)}
              allOption={false}
            />
          );
        } else if ( filter.appointmentTypeSelect ) {
          field = (
            <AppointmentTypeSelect
              name={filter.name}
              value={form[filter.name]}
              onChange={this.updateField.bind(this)}
              allOption={false}
            />
          );
        } else if ( filter.vbcProgramSelect ) {
          field = (
            <VbcProgramSelect
              name={filter.name}
              value={form[filter.name]}
              onChange={this.updateField.bind(this)}
              allOption={false}
            />
          );
        }
        else if ( filter.potentialCountSelect ) {
          field = (
            <PotentialCountSelect
              name={filter.name}
              value={form[filter.name]}
              onChange={this.updateField.bind(this)}
              allOption={false}
            />
          );
        }
        else if ( filter.specialtySelect ) {
          field = (
            <SpecialtySelect
              name={filter.name}
              value={form[filter.name]}
              onChange={this.updateField.bind(this)}
              allOption={false}
            />
          );
        }
        else {
          field = (
            <Form.Input
              name={filter.name}
              placeholder={filter.placeholder}
              value={form[filter.name]}
              onChange={this.updateField.bind(this)}
            />
          );
        }
        return (
          <Grid.Column width={filter.width} key={filter.name}>
            {field}
          </Grid.Column>
        );
      });
      return <Grid.Row key={index}>{rowFilters}</Grid.Row>;
    });

    return (
      <Segment attached className="no-margin filter" id="filter">
        <Form>
          <Grid className="filter-grid" stackable>
            {filterFields}
            <Grid.Row>
              <Grid.Column textAlign="right">
                <span className="filter-submit-button">
                  <Button
                    onClick={this.submit}
                    color="blue">{!this.props.buttonText && "Filter"}</Button>
                </span>
                <span className="filter-clear-button">
                  <Popup content="Clear Filters" mouseEnterDelay={1000}
                    trigger={
                      <Button icon
                        onClick={this.cancel}><Icon name="cancel" /></Button>
                    } />
                </span>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </Segment>
    );
  }
}

/**
 * Generates the filter criteria for a search. This is called internally on
 * filter submit to send the caller the state of the filter key / value pairs
 * Client components may want to call this directly if the component is
 * maintaining its own additional search filters. A common case is a component
 * that provides view states as well as search filters
 *
 * @param values is an object or map with keys and values
 */
export const generateQueryFilters = (filters, values) => {
  const filterAttribute = (filters, attribute) => {
    // Using an object to emulate a hashmap.  While normally a datasmall set,
    // there are three 0(n^2) operations below that I wanted to optimize
    const filtersWithAttribute = {};
    forEach(filters, row => {
      forEach(row, value => {
        if (value[attribute]) {
          filtersWithAttribute[value.name] = true;
        }
      });
    });
    return filtersWithAttribute;
  };

  const LIKE_CRITERIA = "%";

  const likeFields = filterAttribute(filters, "like");
  const dateFields = filterAttribute(filters, "date");
  const practitionerSearchFields = filterAttribute(filters, "practitionerSelect");

  const onlyValues = pickBy(values, (value, key) => {
    if (Array.isArray(value)) {
      return value.length > 0
    }
    return value ? true : false
  });

  // if string has space, wrap string in parens
  const wrapParen = (txt) => {
    if (txt.indexOf(' ') > 0) {
      return '(' + txt + ')'
    } else {
      return txt
    }
  }

  if ('appointmentType' in onlyValues) {
    onlyValues.appointmentType = map(onlyValues.appointmentType, wrapParen);
  }
  if ('specialty' in onlyValues) {
    onlyValues.specialty = map(onlyValues.specialty, wrapParen)
  }

  const withWildcard = mapValues(onlyValues, (value, key) => (likeFields[key] ? value + LIKE_CRITERIA : value));
  const withDate = mapValues(withWildcard, (value, key) => (dateFields[key] ? parse(value) : value));
  const withPractitionerSearch = mapValues(withDate, (value, key) => (practitionerSearchFields[key] ? value : value));

  return withPractitionerSearch;
};

export default FilterComponent;