/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable react/no-children-prop */
import React from 'react'
import { Accordion, Grid } from 'semantic-ui-react'
import ReactMarkdown from 'react-markdown'
// Local
import { CONDITION, ROLES } from '../../../constant'
import {
  conditionClaimSource,
  condition as conditionUtils
} from '../../common/conditionUtils'
import { formatConditionTitle, displayRiskCode } from '../../common/formatUtils'
import AddressedIcon from '../common/addressedIcon'
import ConditionQueryStatusIcon from '../common/conditionQueryStatusIcon'
import ConditionAddressedDate from '../common/conditionAddressedDate'
import ConditionDismissed from '../common/conditionDismissed'
import ConditionNotes from '../common/ConditionNotes'
import ConditionReasons from '../common/conditionReasons'
import { EncounterConditionDisplay } from '../../authenticated/types'
import If from '../../common/if'
import CoderConditionEditor from './CoderConditionEditor'

type Props = {
  condition: EncounterConditionDisplay
  onSubmitNote: (
    condition: EncounterConditionDisplay,
    noteBody: string,
    noteReason: string
  ) => void
  active: boolean
  displayIndex: number
  onClickTitle: (condition: EncounterConditionDisplay) => void
  onConditionAction: (condition: EncounterConditionDisplay) => void
  isFormSaved: boolean
  activeConditionID: string
}

function CoderConditionEdit(props: Props): JSX.Element {
  const {
    condition,
    onSubmitNote,
    active: isActive,
    displayIndex,
    onClickTitle,
    onConditionAction,
    isFormSaved,
    activeConditionID
  } = props

  const shouldShowDismissStatus =
    condition.conditionCode === CONDITION.CODE.DISMISSED
  const shouldShowQueryStatus = !shouldShowDismissStatus
  const claimSource = conditionClaimSource(condition)
  const hccCode = condition.hccCode
    ? `(${displayRiskCode(condition.hccCode)})`
    : ''
  const titleText = formatConditionTitle(condition)
  const clazz =
    displayIndex && displayIndex === 0
      ? 'flex-title first-accordion'
      : 'flex-title'

  // do not show any coder editor if condition is known-addressed
  const shouldShowCoderEditor = !conditionUtils.isKnownAddressed(condition)

  const columnCount = shouldShowCoderEditor ? 2 : 1

  const onSubmitNoteFn = (
    c: EncounterConditionDisplay,
    noteBody: string,
    noteReason: string
  ) => {
    onSubmitNote(c, noteBody, noteReason)
  }

  return (
    <div className="condition">
      <Accordion.Title
        active={ isActive }
        index={ condition.id }
        onClick={ () => {
          onClickTitle(condition)
        } }
        className={ clazz }
      >
        <AddressedIcon condition={ condition } />
        <div className="condition-title">
          <If isTrue={ isActive }>
            <strong>{ `${hccCode} ${titleText}` }</strong>
          </If>
          <If isTrue={ !isActive }>
            <span>
              <strong>{ hccCode }</strong> { titleText }
            </span>
          </If>
        </div>
        <div className="condition-toolbar">
          <If isTrue={ shouldShowDismissStatus }>
            <ConditionDismissed condition={ condition } />
          </If>
          <If isTrue={ shouldShowQueryStatus }>
            <ConditionQueryStatusIcon condition={ condition } />
          </If>
        </div>
      </Accordion.Title>
      <Accordion.Content active={ isActive }>
        <div className="ui stackable grid internal-condition">
          <Grid.Row className="first-row" columns={ columnCount } divided>
            <Grid.Column>
              <ConditionReasons condition={ condition } />
              <ReactMarkdown
                children={ condition.content || '' }
                linkTarget="_blank"
              />
              <ConditionAddressedDate condition={ condition } />
            </Grid.Column>
            <If isTrue={ shouldShowCoderEditor }>
              <Grid.Column>
                <CoderConditionEditor
                  condition={ condition }
                  onConditionAction={ onConditionAction }
                  isFormSaved={ isFormSaved }
                  activeConditionID={ activeConditionID }
                />
              </Grid.Column>
            </If>
          </Grid.Row>
          <If isTrue={ !!claimSource }>
            <Grid.Row>
              <Grid.Column textAlign="center">
                <span className="footnote">
                  Initial source of condition: { claimSource }
                </span>
              </Grid.Column>
            </Grid.Row>
          </If>
          <If isTrue={ conditionUtils.hasPractitionerTags(condition) }>
            <Grid.Row>
              <Grid.Column textAlign="center">
                <span>Provider Response:</span>
                <span> </span>
                <span className="bold">
                  { condition.practitionerTags &&
                    condition.practitionerTags.practitionerResponseCode }
                </span>
              </Grid.Column>
            </Grid.Row>
          </If>
          <Grid.Row className="divider" columns={ 1 }>
            <Grid.Column>
              <ConditionNotes
                condition={ condition }
                userRole={ ROLES.CODER }
                onSubmit={ onSubmitNoteFn }
              />
            </Grid.Column>
          </Grid.Row>
        </div>
      </Accordion.Content>
    </div>
  )
}

export default CoderConditionEdit
