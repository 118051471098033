import React, { Component } from "react";
import { Button, Popup } from "semantic-ui-react";
import { CONDITION } from "../../../constant";

/**
 * Provides a component that renders a condition cancel button. The component
 * includes support for both canceling and undoing the cancel
 * @param condition Condition entity
 * @param onClick Callback function for button click.
 *        Arguments: action and condition
 */
class ConditionDismissButton extends Component {

  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    const condition = this.props.condition;
    if ( condition.conditionCode === CONDITION.CODE.DISMISSED ) {
      this.props.onClick(CONDITION.ACTION.MARK_OPEN, condition);
    } else {
      this.props.onClick(CONDITION.ACTION.MARK_DISMISSED, condition);
    }
  }

  renderButton(condition) {
    if ( condition.conditionCode === CONDITION.CODE.DISMISSED ) {
      return (
        <span>
          <span className="italic">Marked as Dismissed by Provider</span>
          <span> | </span>
          <span className="linkable" onClick={this.onClick}>Undo</span>
        </span>
      );
    } else {
      return (
        <Popup content="Mark as Dismissed" mouseEnterDelay={1000}
          trigger={
            <Button onClick={this.onClick}>Dismiss</Button>
          } />
      );
    }
  }

  render() {
    return this.renderButton(this.props.condition);
  }
}

export default ConditionDismissButton;
