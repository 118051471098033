import React from "react";
import { format } from "date-fns";
import { Icon, Popup } from "semantic-ui-react";
import { FORMAT, LOCK, REVIEW } from "../../../constant";
import { isExpired } from "../../common/dateTimeUtils";
import { coderQueryExpireHours } from "../../common/currentUserUtils";

/**
 * Provides an icon indicating the encounter status, relevant to Coder users
 * @param currentUser Current user
 * @param encounter Encounter record
 */
const CoderEncounterStatusIcon = (props) => {

  const lockMessage = (lock) => {
    const lockOwner = lock.createdByUsername;
    const date = format(lock.creationDate, FORMAT.DATE_TIME);
    return `Locked for ${lockOwner}, ${date}`;
  };

  const lockColor = (lock, currentUser) => {
    return ( lock.createdById === currentUser.userId ) ? "green" : "black";
  };

  const hasQueryOutstanding = (encounter) => {
    return encounter.coderQueryOutstandingDate ? true : false;
  };

  const hasQueryExpired = (encounter, currentUser) => {
    if ( encounter.coderQueryOutstandingDate ) {
      const expireHours = coderQueryExpireHours(currentUser);
      return isExpired(encounter.coderQueryOutstandingDate, expireHours);
    }
  };

  const currentUser = props.currentUser;
  const encounter = props.encounter;
  const review = encounter.review;
  const lock = encounter.lock;

  const conditionQueryOutstanding = hasQueryOutstanding(encounter);
  const conditionQueryExpired = hasQueryExpired(encounter, currentUser);
  const expireHours = coderQueryExpireHours(currentUser);

  return (
    <span>
      {lock && lock.statusCode === LOCK.STATUS.ACTIVE && (
        <Popup mouseEnterDelay={500} content={lockMessage(lock)}
          trigger={
            <Icon name="lock" color={lockColor(lock, currentUser)} />
          } />
      )}
      {review && review.reviewCode === REVIEW.CODE.COMPLETED && (
        <Popup content="Complete" mouseEnterDelay={1000}
          trigger={<Icon name='checkmark' color="green" />} />
      )}
      {review && review.reviewCode === REVIEW.CODE.READY_FOR_CODER_QA && (
        <Popup content="Ready for Coder QA" mouseEnterDelay={1000}
          trigger={<Icon name="question circle" color="yellow" />} />
      )}
      {(conditionQueryOutstanding && !conditionQueryExpired) && (
        <Popup content="Outstanding Query" mouseEnterDelay={1000}
          trigger={<Icon name="comment alternate outline" />} />
      )}
      {conditionQueryExpired && (
        <Popup content={`Outstanding Query Greater Than ${expireHours} Hours`} mouseEnterDelay={1000}
          trigger={<Icon name="comment alternate outline" color="red" />} />
      )}
      {encounter.review.queryStatusCode === REVIEW.QUERY_STATUS.REVIEW_QUERY_OUTSTANDING && (
        <Popup content="Coder Review Requested" mouseEnterDelay={1000}
          trigger={<Icon name="hourglass half" color="red" />} />
      )}
    </span>
  );
}

export default CoderEncounterStatusIcon;

