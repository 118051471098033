/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react'
import { Modal, Table } from 'semantic-ui-react'
import { format } from 'date-fns'
// Local
import { cursorClass } from './utils'
import { FORMAT, PAGES } from '../../constant'

/** Prepare encounter data for display in modal */
// const generateTableData = (list: PatientScheduleModalTableData[]) =>
const generateTableData = () =>
  [
    {
      id: 'foo',
      reasonDescription: 'bar',
      start: new Date()
    }
  ].map(record => ({
    id: record.id,
    reasonDescription: record.reasonDescription,
    time: format(record.start, FORMAT.DATE_TIME),
    mobileTime: format(record.start, FORMAT.DATE),
    meta: {
      className: cursorClass
    }
  }))


/** Display a set of encounters for a specific patient in modal */
function PatientScheduleModal(props) {
  const { history, list, closeModal } = props
  /** Open the selected encounter */
  function selectEncounter(id) {
    history.push(PAGES.ENCOUNTER_DETAIL + id)
  }

  return list ? (
    <Modal closeIcon centered={ false } open={ true } onClose={ closeModal }>
      <Modal.Content>
        <div>
          <Table
            computerColumns={ [
              { name: 'Time', value: 'time' },
              { name: 'Reason for Visit', value: 'reasonDescription' }
            ] }
            mobileColumns={ [
              { value: 'mobileTime', width: '4' },
              { value: 'reasonDescription', width: '12' }
            ] }
            // data={ generateTableData(props.list) }
            data={ generateTableData() }
            onClick={ selectEncounter }
          />
        </div>
      </Modal.Content>
    </Modal>
  ) : <></>
}

export default PatientScheduleModal
