import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Container, Grid, Segment } from "semantic-ui-react";
import { API_URL, DISPLAY_MODE, CONDITION, ENCOUNTER, PAGES } from "../../../constant";
import { api } from "../../../action/api";
import { spliceConditions, withEncounter } from "../../common/conditionUtils";
import NavHeader from "../common/navHeader";
import EncounterDateWarning from "./../common/encounterDateWarning";
import PhysicianEncounterLeft from "./physicianEncounterLeft";
import PhysicianEncounterConditions from "./physicianEncounterConditions";

/**
 * Provides the top-level Physician encounter detail component
 *
 * @param mode View mode. Options: 'smart', null. Default: null
 * @param encounterId Encounter ID
 * @param conditions Optional encounter conditions to display
 * @param onEncounterNav Encounter navigation callback. Use this callback to
 *        navigate to another encounter. Argument: encounter ID
 */
class PhysicianEncounter extends Component {

  constructor(props) {
    super(props);
    this.onHeaderAction = this.onHeaderAction.bind(this);
    this.onConditionAction = this.onConditionAction.bind(this);
    this.state = {
      encounterId: this.props.encounterId,
      encounterDetail: null,
      activeConditionId: null
    };
  }

  componentDidMount() {
    this.fetchEncounter(this.state.encounterId);
  }

  fetchEncounter(encounterId) {
    const url = API_URL.GET_ENCOUNTER_DETAIL_BY_ID + encounterId;
    api.get(url).then(({ data }) => {
      this.setState({
        encounterId: encounterId,
        encounterDetail: data
      });
    })
  }

  spliceCondition(condition) {
    return this.state.encounterDetail.conditions.map((c) => {
      if ( c.id === condition.id ) {
        return condition;
      } else {
        return c;
      }
    });
  }

  updateConditionCode(condition, newCode) {
    const url = API_URL.UPDATE_CONDITION + "/" + condition.id;
    condition.conditionCode = newCode;
    api.put(url, condition).then(({ data }) => {
      const detail = this.state.encounterDetail;
      detail.conditions = this.spliceCondition(data);
      this.setState({
        encounterDetail: detail,
        activeConditionId: condition.id
      });
    });
  }

  /**
   * Update practitioner tags on one or more conditions. Practitioner tags can
   * get updated on a single condition or on a group of conditions
   * @param conditions Array of conditions
   */
  updatePractitionerTags(conditions) {
    const tags = conditions.map((c) => { return c.practitionerTags });
    const url = API_URL.UPDATE_CONDITION_PRACTITIONER_TAGS;
    api.put(url, tags).then(({ data }) => {
      const detail = this.state.encounterDetail;
      detail.conditions = withEncounter(
        spliceConditions(conditions, detail.conditions), detail.encounter);
      this.setState({
        encounterDetail: detail
      });
    });
  }

  /**
   * @param condition Can be: null, condition or ID. An ID can be a condition or
   * a group identifier
   */
  onConditionActivate(condition) {
    if ( condition === null ) {
      this.setState({
        activeConditionId: null
      });
    } else {
      const id = typeof(condition) === "string" ? condition : condition.id;
      this.setState({
        activeConditionId: id
      });
    }
  }

  /**
   * Currently physician conditions are modal: either 1 condition (or condition
   * group) is active or no conditions are active
   */
  onConditionDeactivate() {
    this.setState({
      activeConditionId: null
    });
  }

  onConditionRead(condition) {
    if ( condition ) {
      api.post(API_URL.AUDIT("READ", "ENCOUNTER_CONDITION", condition.id), {});
    }
  }

  onHeaderAction(action) {
    const detail = this.state.encounterDetail;
    const links = detail.links;
    if ( action === ENCOUNTER.ACTION.PREVIOUS_ENCOUNTER ) {
      if ( links.prev ) {
        this.props.onEncounterNav(links.prev);
      }
    }
    if ( action === ENCOUNTER.ACTION.NEXT_ENCOUNTER ) {
      if ( links.next ) {
        this.props.onEncounterNav(links.next);
      }
    }
    if ( action === ENCOUNTER.ACTION.WORKLIST ) {
      this.props.history.push(PAGES.HOME);
    }
  }

  /**
   * Top level router for physician encounter condition actions
   * @param action The thing that happened
   * @param condition This can be a single condition, array of conditions,
   * a condition ID or NULL. Look to the action handler function to see what
   * it expects
   */
  onConditionAction(action, condition) {
    if ( action === CONDITION.ACTION.ACTIVATE ) {
      this.onConditionActivate(condition);
    }
    if ( action === CONDITION.ACTION.DEACTIVATE ) {
      this.onConditionDeactivate(condition);
    }
    if ( action === CONDITION.ACTION.MARK_DISMISSED ) {
      this.updateConditionCode(condition, CONDITION.CODE.DISMISSED);
    }
    if ( action === CONDITION.ACTION.MARK_OPEN ) {
      this.updateConditionCode(condition, CONDITION.CODE.OPEN);
    }
    if ( action === CONDITION.ACTION.READ ) {
      this.onConditionRead(condition);
    }
    if ( action === CONDITION.ACTION.UPDATE_PRACTITIONER_TAGS ) {
      this.updatePractitionerTags(condition);
    }
  }

  renderDetail(detail) {
    const encounter = detail.encounter;
    const links = detail.links;
    const key = (new Date()).getTime();
    if ( this.props.mode === DISPLAY_MODE.EMBEDDED ) {
      return (
        <div className="header-padding">
          <Container>
            <Segment attached className="no-margin">
              <Grid stackable>
                <Grid.Row>
                  <Grid.Column>
                    <PhysicianEncounterConditions
                      key={`encounter-conditions-${key}`}
                      mode={this.props.mode}
                      encounterDetail={detail}
                      conditionGroups={this.props.conditionGroups}
                      activeConditionId={this.state.activeConditionId}
                      onConditionAction={this.onConditionAction} />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Segment>
          </Container>
        </div>
      );
    } else {
      return (
        <div className="header-padding">
          <Container>
            <NavHeader
              mode={this.props.mode}
              encounter={encounter}
              links={links}
              onAction={this.onHeaderAction} />
            <EncounterDateWarning encounter={encounter} mode={this.props.mode} />
            <Segment attached className="no-margin">
              <Grid stackable>
                <Grid.Row>
                  <Grid.Column width={4}>
                    <PhysicianEncounterLeft encounterDetail={detail} />
                  </Grid.Column>
                  <Grid.Column width={12}>
                    <PhysicianEncounterConditions
                      key={`encounter-conditions-${key}`}
                      mode={this.props.mode}
                      encounterDetail={detail}
                      conditionGroups={this.props.conditionGroups}
                      activeConditionId={this.state.activeConditionId}
                      onConditionAction={this.onConditionAction} />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Segment>
          </Container>
        </div>
      );
    }
  }

  render() {
    const detail = this.state.encounterDetail;
    return detail ? this.renderDetail(detail) : null;
  }
}

export default withRouter(PhysicianEncounter);
