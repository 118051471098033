import { DISPLAY_MODE } from "../../constant";

/*******************************************************************************
 * Provides utilities for managing practitioner tags
 */

/**
 * @param props
 * @return True if props indicate that the display mode is SMART
 */
export function isSmartDisplay(props) {
  return props && props.mode && props.mode.toUpperCase() === DISPLAY_MODE.SMART;
}

/**
 * @param props
 * @return True if props indicate that the display mode is EMBEDDED
 */
export function isEmbeddedDisplay(props) {
  return props && props.mode && props.mode.toUpperCase() === DISPLAY_MODE.EMBEDDED;
}