import React, { Component } from "react";
import { Form, Radio } from "semantic-ui-react";
import { CONDITION } from "../../../constant";
import { formatIcd10Code } from "../../common/formatUtils";

/**
 * Provides a component that allows a CDI user to edit CDI potential condition
 * tag properties. For example: mark a condition as Net New, Known Potential or Neither.
 *
 * @param condition Condition entity
 * @param onConditionAction Callback for condition action
 *        Arguments action, condition
 */
class CdiPotentialConditionTagsEditor extends Component {

  constructor(props) {
    super(props);
    const condition = this.props.condition;
    const cdiTags = condition.cdiTags
      ? condition.cdiTags
      : { encounterConditionId: condition.id };
    this.onChange = this.onChange.bind(this);
    this.state = { cdiTags: cdiTags };
  }

  onChange(e, { value }) {
    const condition = this.props.condition;
    const cdiTags = this.state.cdiTags;
    if ( value === "NONE" ) {
      cdiTags.netNewStatusCode = null;
      cdiTags.knownPotentialStatusCode = null;
    }
    if ( value === CONDITION.NET_NEW ) {
      cdiTags.netNewStatusCode = CONDITION.NET_NEW;
      cdiTags.knownPotentialStatusCode = null;
    }
    if ( value === CONDITION.KNOWN_POTENTIAL ) {
      cdiTags.knownPotentialStatusCode = CONDITION.KNOWN_POTENTIAL;
      cdiTags.netNewStatusCode = null;
    }
    cdiTags.encounterConditionId = condition.id;
    condition.cdiTags = cdiTags;
    this.setState({ cdiTags: cdiTags}, () => {
      this.props.onConditionAction(CONDITION.ACTION.UPDATE_CDI_TAGS, condition);
    });
  }

  render() {
    const cdiTags = this.state.cdiTags;
    const isNetNew = cdiTags && cdiTags.netNewStatusCode === CONDITION.NET_NEW;
    const isKnownPotential = cdiTags && cdiTags.knownPotentialStatusCode === CONDITION.KNOWN_POTENTIAL;
    const expectedDiagnosis = cdiTags && cdiTags.expectedDiagnosis
      ? cdiTags.expectedDiagnosis : null;
    return (
      <Form className="m-1-top">
        <Form.Group inline>
          <label>Mark Condition:</label>
          <Form.Field
            control={Radio}
            label="Net New"
            name="netNewKnownPotential"
            value={CONDITION.NET_NEW}
            checked={cdiTags.netNewStatusCode === CONDITION.NET_NEW}
            onChange={this.onChange} />
          <Form.Field
            control={Radio}
            label="Known Potential"
            name="netNewKnownPotential"
            value={CONDITION.KNOWN_POTENTIAL}
            checked={cdiTags.knownPotentialStatusCode === CONDITION.KNOWN_POTENTIAL}
            onChange={this.onChange} />
          <Form.Field
              control={Radio}
              label="Neither"
              name="netNewKnownPotential"
              value="NONE"
              checked={!(isNetNew || isKnownPotential)}
              onChange={this.onChange} />
        </Form.Group>
        {expectedDiagnosis && (
          <div className="m-1-top">
            Expected Diagnosis: <strong>{formatIcd10Code(expectedDiagnosis)}</strong>
          </div>
        )}
      </Form>
    )
  }
}

export default CdiPotentialConditionTagsEditor;
