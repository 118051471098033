import React, { Component } from "react";
import { Dropdown, Responsive } from 'semantic-ui-react';

/**
 * Provides a stand-alone select widget that allows you to select 1, 2, 3, 4+
 * potential condition counts.
 * @param onChange Callback function on potential count select
 *   Arguments: (e, {name, value}) where value is the potential count
 *    or "" if the ALL option is selected
 * @param name Name of the select widget in a form context. Default:
 *   potentialCountSelect
 * @param value Initial selected potential count
 * @param placeholder Placeholder text. Default: Potential Opportunities
 * @param multiple Allow multiple selections. Default: true
 */
class PotentialCountSelect extends Component {

  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);

    this.state = {
      potentialCounts: ['1 Potential Opportunity', '2 Potential Opportunities', '3 Potential Opportunities', '4+ Potential Opportunities'],
      multiple: this.props.multiple === false ? false : true,
    }
  }

  onChange = (e, { value }) => {
    if ( this.props.onChange ) {
      const name = this.props.name ? this.props.name : 'potentialConditionCount';
      this.props.onChange(e, {name: name, value: value});
    }
  }

  render() {
    const options = this.state.potentialCounts.map(element => {
      return {
        key: element,
        value: element.replaceAll(/[^+\d]/g, ''), // Preserve digits and the '+' symbol. Erase others
        text: element,
        id: element.substring(0, 1) + '-potential-opp'
      };
    });

    const placeholder = this.props.placeholder ? this.props.placeholder : 'Potential Opportunities';
    const id = 'potential-count-select';
    if ( this.state.multiple === true ) {
      const value = this.props.value ? this.props.value : [];
      return (
        <Responsive
          as={Dropdown}
          id={id}
          fluid multiple selection
          placeholder={placeholder}
          onChange={this.onChange}
          options={options}
          value={value} />
      );
    } else {
      const value = this.props.value ? this.props.value : undefined;
      return (
        <Responsive
          as={Dropdown}
          id={id}
          fluid selection
          placeholder={placeholder}
          onChange={this.onChange}
          options={options}
          value={value} />
      );
    }
  }
}

export default PotentialCountSelect;