import { Patient } from '../patient/patient'

/** *****************************************************************************
 * Provides utilities for patient entities
 */

/**
 * We have not been consistent with how we store the MRN on the patient
 * entity and it can be different based on the tenant. Once we clean up the
 * Devoted data we should always be able to get the MRN from the mrn (id_mrn)
 * property.
 *
 * If the tenant is identified as Devoted, return the identifier. Otherwise,
 * return the MRN
 * @param patient Patient entity
 * @param tenantId Tenant ID
 */
export function patientMrn(patient: Patient, tenantId: string) {
  const mrn = patient.id_mrn ? patient.id_mrn : patient.mrn
  return mrn
}

export default patientMrn
