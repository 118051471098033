import 'babel-polyfill'
import React from 'react'
import ReactDOM from 'react-dom'
import 'semantic-ui-css/semantic.min.css'
import './index.css'
import 'react-datepicker/dist/react-datepicker.css'
import 'semantic-ui-calendar-react/dist/css/calendar.min.css'

import { ConnectedRouter } from 'react-router-redux'
import { Provider } from 'react-redux'
import * as Sentry from '@sentry/browser'
import App from './app'
import store, { history } from './store'
import { BASE_URL } from './config'

import * as serviceWorker from './serviceWorker'

// We need the DSN in a particular format so Sentry does not reject it, namely a 'key@' before the hostname
const getDsnUrl = () => {
  const addKey = url => {
    if (url.startsWith('http://')) {
      return url.replace('http://', 'http://key@')
    } if (url.startsWith('https://')) {
      return url.replace('https://', 'https://key@')
    }
    throw new Error('Unrecognized protocol in URL')

  }

  const url = (BASE_URL !== '') ? BASE_URL : `${window.location.protocol  }//${  window.location.hostname}`
  return `${addKey(url)  }/logger`
}

Sentry.init({
  dsn: getDsnUrl(),
  maxBreadcrumbs: 50,
  debug: true
})

window.recaptchaOptions = {
  enterprise: true
};

ReactDOM.render(
  <Provider store={ store }>
    <ConnectedRouter history={ history }>
      <App />
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
)

serviceWorker.unregister()