import React, { Component } from "react";
import { Segment } from "semantic-ui-react";
import { PATIENT_RULES } from "../../../constant";
import { cdiRuleFilterAndRuleRiskCategoryVersionEnabled } from "../../common/currentUserUtils";

/**
 * Provides a component that renders the patient rule HCC version, if provided
 *
 * @param patient Patient entity
 * @param mode Render mode. Default: left
 */
class PatientRuleHccVersion extends Component {

  renderLeft(patient, currentUser) {
    if(!cdiRuleFilterAndRuleRiskCategoryVersionEnabled(currentUser)){
      return null
    } else if ( patient && patient.rulesHccVersion ) {
      const hccVersion = patient.rulesHccVersion;
      return (
        <Segment attached compact className="left-segment">
          <div className="left-title">Patient Rule Risk Category Version:</div>
          <div className="left-content">{ hccVersion }</div>
        </Segment>
      )
    } else {
      return (        
      <Segment attached compact className="left-segment">
        <div className="left-title">Patient Rule Risk Category Version:</div>
        <div className="left-content">{ PATIENT_RULES.PATIENT_RULE_RISK_CATEGORY_VERSION }</div>
      </Segment>
  )}
}

  render() {
    const mode = this.props.mode ? this.props.mode : "left";
    if ( mode === "left" ) {
      return this.renderLeft(this.props.patient, this.props.currentUser);
    } else {
      return null;
    }
  }
}

export default PatientRuleHccVersion;
