import React from 'react'
// Local
import { ButtonOptions } from '../../authenticated/types'
import { ButtonClick, SelectedButton } from './action-buttons'

type Props = ButtonOptions & SelectedButton & ButtonClick

const getClass = (selectedButton: string, text: string): string =>
  selectedButton === text ? 'selected' : ''

function stopPropagation(e: any) {
  e.stopPropagation()
  e.nativeEvent.stopImmediatePropagation()
}

function Button(props: Props): JSX.Element {
  const { text, id, value, label, selectedButton, setSelectedButton, onClick } =
    props

  function handleClick(e: any) {
    stopPropagation(e)
    setSelectedButton(text)
    onClick(value)
  }

  return (
    <button
      id={ id }
      type="button"
      className={ getClass(selectedButton, text) }
      onClick={ e => handleClick(e) }
    >
      { label }
    </button>
  )
}

export default Button
