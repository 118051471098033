import filter from "lodash/filter";
import uniq from "lodash/uniq";
import { condition as conditionUtils, groupByAddressed, withAddressedConditions, withRuleType, withoutRuleType } from "./conditionUtils";
import { RULE_TYPE_CODES } from "../../constant";

/*******************************************************************************
 * Provides utilities for managing HCCs
 *
 * Most of these functions expect to run with a condition or list of conditions
 */

/**
 * @param Condition entity
 * @param hccCode Optional HCC code to match on
 * @return If an HCC code is provided, then true if condition has HCC code and
 * the HCC code matches the parameter value. Otherwise, true if condition has
 * any defined HCC code
 */
export function hasHccCode(condition, hccCode) {
  return hccCode
    ? condition.hccCode !== null && condition.hccCode === hccCode
    : condition.hccCode !== null && condition.hccCode.length > 0;
}

/**
 * @param conditions List of conditions
 * @param hccCode Optional HCC code to filter on
 * @return If an HCC code is provided, then return a list of conditions with the
 * matching HCC code. If no HCC code parameter is provided return a list of
 * conditions with any defined HCC code
 */
export function withHccCode(conditions, hccCode) {
  return conditions.filter((c) => { return hasHccCode(c, hccCode); });
}

/**
 * @param conditions List of conditions
 * @return List of unique, non-null, non-empty, HCC codes provided by conditions
 */
export function findHccCodes(conditions) {
  return uniq(filter(conditions.map((c) => {
    return c.hccCode;
  }), (hcc) => {
    return hcc !== null && hcc.length > 0;
  }));
}

/**
 * Groups a list of conditions by HCC code. The returned list will include a
 * group of the conditions that do not have an HCC. Note that there is fussy
 * logic to specifically not group conditions based on some rule types
 * @param conditions List of conditions
 * @return A list of objects:
 * {
 *    hccCode: string,
 *    hccDescription: string,
 *    conditions: array,
 *    addressed: boolean
 * }
 * Where each object defines a unique HCC code, including null, and the
 * conditions that match that HCC code. If the group represents an HCC code,
 * the group is addressed if at least 1 condition is addressed
 */
export function groupByHccCode(conditions) {
  // Grab conditions with MEDICATION rule type
  const medication = RULE_TYPE_CODES.MEDICATION.key
  const conditionsWithMedRule = withRuleType(conditions, medication);

  // Do not group by HCC Code if the rule type is MEDICATION
  const medRuleConditions = conditionsWithMedRule.map((conditionWithMedRule) => {
    const description = conditionWithMedRule.length > 0 ? conditionWithMedRule[0].hccDescription : null;
    const addressed = conditionUtils.isConditionAddressed(conditionWithMedRule);
    return {
      hccCode: null, // Remove HCC Code from Title of condition tied to a med rule
      hccDescription: description,
      conditions: [ conditionWithMedRule ],
      addressed: addressed
    };
  });

  // Group by HCC Code for all other conditions
  const conditionsWithoutMedRule = withoutRuleType(conditions, medication);

  const hccs = findHccCodes(conditionsWithoutMedRule);

  const hccGroups = hccs.map((hcc) => {
    const data = withHccCode(conditionsWithoutMedRule, hcc);
    const description = data.length > 0 ? data[0].hccDescription : null;
    const addressed = withAddressedConditions(data).length > 0;
    return {
      hccCode: hcc,
      hccDescription: description,
      conditions: data,
      addressed: addressed
    };
  });

  return medRuleConditions.concat(
    hccGroups.concat(
      groupByAddressed(conditions.filter((c) => {
        return (c.hccCode === null || c.hccCode.length === 0);
      }))
    )
  );
}

