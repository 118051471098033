import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Header } from 'semantic-ui-react'
// Local
import * as formatUtils from '../../common/formatUtils'
import CDIEncounterHeaderButtons from './cdiEncounterHeaderButtonsT'

/**
 * Provides an encounter detail header for CDI users
 *
 * @param encounter Encounter entity. Required
 * @param onAction Callback for header button action. Arguments: action
 */
class CdiEncounterHeader extends Component {

  constructor(props) {
    super(props)
    this.onClickHeaderButton = this.onClickHeaderButton.bind(this)
  }

  onClickHeaderButton(action) {
    if (this.props.onAction) {
      this.props.onAction(action)
    }
  }

  renderHeader(encounter) {
    const { currentUser } = this.props
    const date = formatUtils.formatEncounterHeaderDate(encounter.start)
    const patientName = formatUtils.formatPatientNameDob(encounter.patient)
    return (
      <Header as="h4" attached="top" block className="main-schedule-header">
        <div className="time">{ date }</div>
        <div className="name">{ patientName }</div>
        <div className="header-arrows cdi">
          <CDIEncounterHeaderButtons
            encounter={ encounter }
            currentUser={ currentUser }
            isACopiedEncounter={ this.props.isACopiedEncounter }
            onClick={ this.onClickHeaderButton } />
        </div>
      </Header>
    )
  }

  render() {
    if (this.props.encounter) {
      return this.renderHeader(this.props.encounter)
    }
    return null

  }
}

export default connect(
  state => ({
    currentUser: state.user
  }), {}
)(CdiEncounterHeader)
