import React from "react";
import { Icon, Popup } from "semantic-ui-react";

/**
 * Provides a component for that renders an encounter detail header icon
 * @param name Icon name
 * @param size Icon size. Default large
 * @param popup Popup text. Default: null
 * @param color Icon color. Default: grey
 * @param onClick On click callback function
 * @param disabled Default: false
 */
const HeaderIcon = props => {
  const size = props.size ? props.size : "large";
  const color = props.color ? props.color : "grey";
  if ( props.disabled ) {
    return <Icon name={props.name} disabled size={size} color={color} />;
  } else {
    if ( props.popUp ) {
      return (
        <Popup content={props.popUp} mouseEnterDelay={800} trigger={
          <Icon
            name={props.name}
            size={size}
            color={color}
            onClick={props.onClick}
            className="pointer"
          />
        } />
      );
    } else {
      return (
        <Icon
          name={props.name}
          size={size}
          color={color}
          onClick={props.onClick}
          className="pointer"
        />
      );
    }
  }
};

export  default HeaderIcon;
