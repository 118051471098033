import { Dispatch, SetStateAction } from 'react'

export enum MessageStatusChoice {
  ActionRecommended = 'Action Recommended',
  MarkedAsDocumented = 'This diagnosis was marked as documented but was not added to the chart. To complete the documentation process, please add the diagnosis to the patient’s chart.'
}

export enum ActionButtonLabel {
  MarkAsDocumented = 'Mark as documented',
  MarkedAsDocumented = 'Marked as documented'
}

// Types
export type ButtonClick = {
  onClick: (buttonValue: string) => void
}

export type SelectedButton = {
  selectedButton: string
  setSelectedButton: Dispatch<SetStateAction<string>>
}

export default {
  MessageStatusChoice
}
