import React, { Component } from 'react'
import { Image } from 'semantic-ui-react'
import { condition as conditionUtils, hasKnownAddressed, hasClosedActivity, hasKnown, hasPotential, withAddressedConditions, withClosedActivities } from '../../common/conditionUtils'

/**
 * Provides a component that displays a condition addressed icon
 *
 * The component can test a single condition or a list of conditions
 *
 * Note that this logic is a little hairy. The Rules Engine can provide
 * check mark guidance. This is a 'checkMark' property on the condition. If
 * the Rules Engine provides guidance, follow the guidance; otherwise fall
 * back to default check mark display logic
 *
 * Assumptions
 * - Either a stand alone condition or a group; not both
 * - Condition groups contain conditions of all the same type (all known,
 *   all potential, all activities)
 *
 * @param condition Condition entity. Default null
 * @param conditions List of condition entities. Default null
 */
class AddressedIcon extends Component {

  /*
   * Helpers
   */
  greenCheckMark() {
    return <Image src="/asset/image/checkmark-green.png"
      className="addressed-icon" />
  }

  /*
   * Stand alone condition rendering logic
   */

  renderStandAloneCheckMark(condition) {
    if (conditionUtils.isKnown(condition)) {
      return this.applyStandAloneKnownCheckMark(condition)
    } if (conditionUtils.isPotential(condition)) {
      return this.applyStandAlonePotentialCheckMark(condition)
    } if (conditionUtils.isClosedActivity(condition)) {
      return this.applyStandAloneActivityCheckMark(condition)
    }
    return null
  }

  /**
   * Apply check mark to stand alone known condition.
   * Known addressed conditions are green
   */
  applyStandAloneKnownCheckMark(condition) {
    if (conditionUtils.isKnownAddressed(condition)) {
      return this.greenCheckMark()
    }
    return null
  }

  /**
   * Apply check mark to stand alone potential condition
   * Potential addressed conditions get none
   */
  applyStandAlonePotentialCheckMark(condition) {
    return null
  }

  /**
   * Apply check mark to stand alone closed activity condition.
   * First check for check mark guidance. If no guidance, closed activity conditions are green
   */
  applyStandAloneActivityCheckMark(condition) {
    return this.greenCheckMark()
  }

  /*
   * Condition group rendering logic
   */

  renderGroupCheckMark(conditions) {
    if (hasKnown(conditions)) {
      return this.applyGroupKnownCheckMark(conditions)
    } if (hasPotential(conditions)) {
      return this.applyGroupPotentialCheckMark(conditions)
    } if (hasClosedActivity(conditions)) {
      return this.applyGroupActivityCheckMark(conditions)
    }
    return null

  }

  /**
   * Apply check mark to group of known conditions.
   */
  applyGroupKnownCheckMark(conditions) {
    if (hasKnownAddressed(conditions)) {
      return this.greenCheckMark()
    }
    return null

  }

  /**
   * Apply check mark to group of potential conditions.
   * IPotential addressed conditions are none
   */
  applyGroupPotentialCheckMark(conditions) {
    return null
  }

  /**
   * Apply check mark to group of activity conditions.
   * First check for check mark guidance. If no guidance, closed activity conditions are green
   */
  applyGroupActivityCheckMark(conditions) {
    return this.greenCheckMark()
  }

  /**
   * Return true if props provides a stand alone condition and that condition
   * is addressed or is a closed activity
   */
  withCondition() {
    return this.props.condition && (
      conditionUtils.isConditionAddressed(this.props.condition) || conditionUtils.isClosedActivity(this.props.condition)
    )
  }

  /**
   * Return true if props provides a condition group and that condition
   * group contains addressed conditions or is a closed activities
   */
  withConditions() {
    return this.props.conditions && (
      withAddressedConditions(this.props.conditions).length > 0
      || withClosedActivities(this.props.conditions).length > 0
    )
  }

  render() {
    if (this.withCondition()) {
      return this.renderStandAloneCheckMark(this.props.condition)
    } if (this.withConditions()) {
      return this.renderGroupCheckMark(this.props.conditions)
    }
    return null

  }
}

export default AddressedIcon
