/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react'
import { Icon, Modal } from 'semantic-ui-react'
import { ENCOUNTER } from '../../../constant'
import PdfEncounterReport from '../../common/pdfEncounterReport'
import HeaderIcon from './headerIcon'

/**
 * Provides a component that renders the action buttons on an encounter nav
 * header.
 *
 * @param encounter Encounter
 * @param links Encounter links to previous and next encounters
 * @param onClick Callback on button click. Arguments: Button action
 */
class NavHeaderButtons extends Component {

  onClick(action) {
    if (this.props.onClick) {
      this.props.onClick(action)
    }
  }

  render() {
    const {encounter} = this.props
    const {links} = this.props
    const action = ENCOUNTER.ACTION
    const actions = [
      {
        name: 'arrow alternate circle left outline',
        popUp: 'Previous Encounter',
        disabled: links.prev === null,
        onClick: () => this.onClick(action.PREVIOUS_ENCOUNTER)
      },
      {
        name: 'arrow alternate circle up',
        popUp: 'Return to Work List',
        onClick: () => this.onClick(action.WORKLIST)
      },
      {
        name: 'arrow alternate circle right outline',
        popUp: 'Next Encounter',
        disabled: links.next === null,
        onClick: () => this.onClick(action.NEXT_ENCOUNTER)
      }
    ]

    return (
      <span>
        {
          actions.map(a => (
            <HeaderIcon
              key={ a.name }
              name={ a.name }
              disabled={ a.disabled }
              onClick={ a.onClick }
              color={ a.color }
              popUp={ a.popUp } />
          ))
        }
        <Modal size="fullscreen"
          trigger={ <Icon name="print" size="large" color="grey" className="pointer" /> }>
          <Modal.Content>
            <PdfEncounterReport encounterId={ encounter.id } />
          </Modal.Content>
        </Modal>
      </span>
    )
  }
}

export default NavHeaderButtons
