import React, { Component } from "react";
import { Segment } from "semantic-ui-react";

/**
 * Provides a component that renders the VBC Program, if provided
 *
 * @param patient Patient entity
 * @param mode Render mode. Default: left
 */
class VbcProgram extends Component {

  renderLeft(patient) {
    if ( patient.vbcProgram ) {
      return (
        <Segment attached compact className="left-segment">
          <div className="left-title">VBC Program:</div>
          <div className="left-content">{patient.vbcProgram}</div>
        </Segment>
      );
    } else {
      return null;
    }
  }

  render() {
    const mode = this.props.mode ? this.props.mode : "left";
    if ( mode === "left" ) {
      return this.renderLeft(this.props.patient);
    } else {
      return null;
    }
  }
}

export default VbcProgram;
