import React, { Component } from "react";
import { Accordion, Button, Grid, Popup } from "semantic-ui-react";
import { CONDITION, ROLES } from "../../../constant";
import { conditionClaimSource, condition as conditionUtils }  from "../../common/conditionUtils";
import { formatConditionTitle, displayRiskCode } from "../../common/formatUtils";
import AddressedIcon from "../common/addressedIcon";
import ConditionDismissed from "../common/conditionDismissed";
import CdiConditionHeaderToolbar from "./cdiConditionHeaderToolbar";
import ConditionNotes from "../common/ConditionNotes";
import CdiConditionDetail from "./cdiConditionDetail";
import CdiConditionEditor from "./cdiConditionEditor";
import CdiPotentialConditionTagsEditor from "./cdiPotentialConditionTagsEditor";
import NLPExtractedLink from "./nlpExtractedLink";

/**
 * Provides a component that renders a CDI condition edit view
 *
 * A point of clarification, if you are just looking at this component out of
 * context: This is the view of the condition that shows when a CDI has locked
 * the encounter. A condition in this view can itself be edited, in which case
 * the condition editor sub-components will display
 *
 * @param condition Condition entity
 * @param displayIndex Condition display index relative to its display group
 * @param isActive If true, display accordion open. Default: false
 * @param onClickTitle Callback for condition accordion title click
 *        Arguments: condition
 * @param onSubmitNote Callback for condition note create
 *        Arguments: condition, note body, note reason
 * @param onConditionAction Callback for condition action
 *        Arguments action, condition
 * @param hccChild True if condition is a member of an HCC display group
 */
class CdiConditionEdit extends Component {

  constructor(props) {
    super(props);
    this.onClickEdit = this.onClickEdit.bind(this);
    this.onClickCancel = this.onClickCancel.bind(this);
    this.onSaveCondition = this.onSaveCondition.bind(this);
    this.onSubmitNote = this.onSubmitNote.bind(this);
    this.state = {
      editMode: false,
      condition: this.props.condition
    }
  }

  onSubmitNote(condition, noteBody, noteReason) {
    this.props.onSubmitNote(condition, noteBody, noteReason);
  }

  onClickEdit() {
    this.setState({ editMode: true });
  }

  onClickCancel() {
    this.setState({ editMode: false });
  }

  onSaveCondition(condition) {
    this.props.onConditionAction(CONDITION.ACTION.UPDATE_WITH_RULES, condition);
    this.setState({ condition: condition, editMode: false });
  }

  render() {
    const condition = this.state.condition;
    const claimSource = conditionClaimSource(condition);
    const isActive = this.props.isActive;
    const hccCode = condition.hccCode ? `(${displayRiskCode(condition.hccCode)})` : "";
    const isHccChild = this.props.hccChild === true ? true : false;
    const titleText = formatConditionTitle(condition);
    const clazz = this.props.displayIndex && this.props.displayIndex === 0
      ? "flex-title first-accordion hover" : "flex-title hover";
    const editMode = this.state.editMode;
    const readMode = !editMode;
    const containerId = `cdi-encounter-condition-edit-${condition.id}`;
    const titleId = `cdi-encounter-condition-edit-title-${condition.id}`
    const contentId = `cdi-encounter-condition-edit-body-${condition.id}`
    const editButtonId = `cdi-encounter-condition-edit-button-${condition.id}`

    return (
      <div id={containerId} className="condition">
        <Accordion.Title
          active={isActive}
          index={condition.id}
          onClick={() => { if ( !isHccChild ) this.props.onClickTitle(condition) }}
          className={clazz}>
          <AddressedIcon condition={condition} />
          <div id={titleId} className="condition-title">
            {isActive ? <strong>{`${hccCode} ${titleText}`}</strong> : <span><strong>{hccCode}</strong> {titleText}</span>}
          </div>
          <div className="condition-toolbar">
            <div className="hover__no-hover">
              <ConditionDismissed condition={condition} />
            </div>
            <div className="hover__hover">
              <CdiConditionHeaderToolbar
                condition={condition}
                onClick={this.props.onConditionAction} />
            </div>
          </div>
        </Accordion.Title>
        <Accordion.Content active={isActive}>
          <Grid className="internal-condition" columns={1} divided stackable>
            <Grid.Row className="first-row">
              <Grid.Column>
                <div id={contentId}>
                  {editMode && (
                    <CdiConditionEditor
                      condition={condition}
                      onCancel={this.onClickCancel}
                      onSave={this.onSaveCondition} />
                  )}
                  {readMode && (
                    <CdiConditionDetail condition={condition} />
                  )}
                  <NLPExtractedLink condition={condition} />
                </div>
              </Grid.Column>
            </Grid.Row>
            { claimSource && (
              <Grid.Row>
                <Grid.Column textAlign="center">
                  <span className="footnote">
                    Initial source of condition: {claimSource}
                  </span>
                </Grid.Column>
              </Grid.Row>
            )}
            { readMode && conditionUtils.isPotential(condition) && (
              <Grid.Row className="divider">
                <Grid.Column>
                  <CdiPotentialConditionTagsEditor condition={condition}
                    onConditionAction={this.props.onConditionAction} />
                </Grid.Column>
              </Grid.Row>
            )}
            { readMode && (
              <Grid.Row className="divider">
                <Grid.Column>
                  <ConditionNotes condition={condition}
                    userRole={ROLES.CDI}
                    onSubmit={this.onSubmitNote} />
                </Grid.Column>
              </Grid.Row>
            )}
            { readMode && (
              <Grid.Row>
                <Grid.Column textAlign="right">
                  <Popup content="Edit This Condition" mouseEnterDelay={1000}
                    trigger={
                      <Button id={editButtonId} color="blue"
                        onClick={this.onClickEdit}>Edit</Button>
                    } />
                </Grid.Column>
              </Grid.Row>
            )}
          </Grid>
        </Accordion.Content>
      </div>
    );
  }
}

export default CdiConditionEdit;
