import React from 'react'
// Local
import { BASE_URL } from '../../config'
import { API_URL, ROLES } from '../../constant'
import { Encounter, EncounterReviewer } from '../authenticated/types'
import { semiColonsToLineBreaks } from './utils/utils'
import { useCdiRestricted } from '../common/roleUtils'

type Props = {
  encounter: Encounter
  reviewer: EncounterReviewer | null
  currentUser: any
}

const renderReviewer = (reviewer: EncounterReviewer | null): JSX.Element =>
  reviewer && reviewer.userId ? (
    <>
      { reviewer.firstName } { reviewer.lastName }
    </>
  ) : (
    <span className="italic">CDI Review Bypassed</span>
  )

function Header(props: Props): JSX.Element {
  const { encounter, reviewer, currentUser } = props
  const patientProfileUrl = BASE_URL + API_URL.PATIENT_PROFILE_WITH_ROLE(useCdiRestricted(currentUser))   

  return (
    <div className="curation-ui-header">
      <div className="left">
        { /* Avatar */ }
        <div className="avatar">
          <img
            alt="patient"
            src={ `${patientProfileUrl}${
              encounter && encounter.patient && encounter.patient.id
            }` }
          />
        </div>
        <div>
          <div>
            { encounter && encounter.patient && encounter.patient.firstName }{ ' ' }
            { encounter && encounter.patient && encounter.patient.lastName }
          </div>
          <div>
            <span>MRN(s)</span>
            <span className="mrn">
              { semiColonsToLineBreaks(
                encounter && encounter.patient && encounter.patient.mrn
                  ? encounter && encounter.patient && encounter.patient.mrn
                  : ''
              ) }
            </span>
          </div>
          { /* Reason for Visit */ }
          <div>
            <span>Reason for Visit</span>
            <span className={ encounter.reasonDescription ? '' : 'italic' }>
              { encounter.reasonDescription || 'Unavailable' }
            </span>
          </div>
          { /* Appointment Type */ }
          <div>
            <span>Appointment Type</span>
            <span className={ encounter.appointmentType ? '' : 'italic' }>
              { encounter.appointmentType || 'Unavailable' }
            </span>
          </div>
        </div>
      </div>
      <div className="right">
        <div>REVIEWER</div>
        <>{ renderReviewer(reviewer) }</>
      </div>
    </div>
  )
}

export default Header
