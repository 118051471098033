import find from 'lodash/find'
// Local
import { Property, TenantProperty } from '../authenticated/types'

/** *****************************************************************************
 * Provides utilities for managing user properties
 */

export function findProperty(
  properties: Property[] | TenantProperty[],
  key: string
): Property | undefined {
  const props = properties || []
  return find(props, p => p.propertyKey === key)
}

export function hasProperty(properties: Property[], key: string): boolean {
  const prop = findProperty(properties, key)
  return !!prop
}

export function propertyValue(
  properties: Property[] | TenantProperty[],
  key: string
): string | null {
  const prop = findProperty(properties, key)
  return prop ? prop.propertyValue : null
}
