import React from 'react'
// Local
import { IconProps } from './iconProps'

type CalendarProps = IconProps & {
  onClick: () => void
  className?: string
}

const baseClassName = 'clipboard-icon'

function Clipboard(props: CalendarProps): JSX.Element {
  const { size = 24, color = '#000000', onClick, className } = props
  return (
    <svg
      width={ size }
      height={ size }
      viewBox="0 0 24 24"
      fill="none"
      stroke={ color }
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={ className ? `${baseClassName} ${className}` : baseClassName }
      onClick={ () => onClick() }
    >
      <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2" />
      <rect x="8" y="2" width="8" height="4" rx="1" ry="1" />
    </svg>
  )
}

export default Clipboard
