/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react'
import { Header } from 'semantic-ui-react'
import { DISPLAY_MODE } from '../../../constant'
import * as formatUtils from '../../common/formatUtils'
import NavHeaderButtons from './navHeaderButtons'

/**
 * Provides a vanilla encounter navigation header with links to next and
 * previous encounters and a button to view the encounter as a PDF
 *
 * @param encounter Encounter entity. Required
 * @param links Encounter links to previous and next encounters
 * @param onAction Callback for header button action. Arguments: action
 * @param mode View mode. Options: "SMART", "EMBEDDED", null. Default: null
 */
class NavHeader extends Component {

  constructor(props) {
    super(props)
    this.onClickHeaderButton = this.onClickHeaderButton.bind(this)
  }

  onClickHeaderButton(action) {
    if (this.props.onAction) {
      this.props.onAction(action)
    }
  }

  renderHeader(encounter, links) {
    const date = formatUtils.formatEncounterHeaderDate(encounter.start)
    const patientName = formatUtils.formatPatientNameDob(encounter.patient)
    const renderButtons = !(this.props.mode && this.props.mode === DISPLAY_MODE.SMART)
    return (
      <Header as="h4" attached="top" block className="main-schedule-header" data-testid="nav-header">
        <div className="time">{ date }</div>
        <div className="name">{ patientName }</div>
        { renderButtons && (
          <div className="header-arrows">
            <NavHeaderButtons
              encounter={ encounter }
              links={ links }
              onClick={ this.onClickHeaderButton } />
          </div>
        ) }
      </Header>
    )
  }

  render() {
    const {encounter} = this.props
    const {links} = this.props
    if (encounter) {
      return this.renderHeader(encounter, links)
    }
    return null

  }
}

export default NavHeader
