import React, { Component } from "react";
import { CONDITION } from "../../../constant";
import { formatIcd10Code } from "../../common/formatUtils";

/**
 * Provides a component that shows a read-only view of CDI potential condition
 * tag properties.
 *
 * @param condition Condition entity
 */
class CdiPotentialConditionTagsRead extends Component {

  render() {
    const condition = this.props.condition;
    const cdiTags = condition.cdiTags ? condition.cdiTags : null;
    const isNetNew = cdiTags && cdiTags.netNewStatusCode === CONDITION.NET_NEW;
    const isKnownPotential = cdiTags && cdiTags.knownPotentialStatusCode === CONDITION.KNOWN_POTENTIAL;
    const expectedDiagnosis = cdiTags && cdiTags.expectedDiagnosis
      ? cdiTags.expectedDiagnosis : null;
    return (
      <div>
        {isNetNew && (
          <div>Condition marked as <strong>Net New</strong></div>
        )}
        {isKnownPotential && (
          <div>Condition marked as <strong>Known Potential</strong></div>
        )}
        {expectedDiagnosis && (
          <div>Expected Diagnosis: <strong>{formatIcd10Code(expectedDiagnosis)}</strong></div>
        )}
      </div>
    );
  }
}

export default CdiPotentialConditionTagsRead;
