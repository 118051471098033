import React, { PropsWithChildren, useState } from 'react'
// Local
import Icon from './icon'
import If from '../common/if'
import ChevronDown from '../icons/chevronDown'

type Props = {
  id: string
  title: string
  isDown?: boolean
  isTopLevel?: boolean
  isAddressed?: boolean
  isRequired?: boolean
}

function Accordion(props: PropsWithChildren<Props>): JSX.Element {
  const {
    id,
    title,
    children,
    isDown: isDownProp = false,
    isTopLevel = false,
    isAddressed = false,
    isRequired = false
  } = props

  const [isDown, setIsDown] = useState<boolean>(isDownProp)

  function onClick(event: any): void {
    if (isTopLevel) {
      event.stopPropagation()
      setIsDown(!isDown)
    }
  }

  return (
    <div
      className={ `curation-ui-accordion-${isTopLevel ? 'top-level' : 'nested'}${
        isDown ? ' is-down' : ''
      }` }
      id={ id }
    >
      <div className="accordion-title-container" onClick={ onClick }>
        <If isTrue={ !isTopLevel }>
          <Icon isAddressed={ isAddressed } isRequired={ isRequired } />
        </If>
        <div className="accordion-title">{ title }</div>
        <If isTrue={ isTopLevel }>
          <div
            className={ `curation-ui-chevron-container${
              isDown ? ' is-down' : ''
            }` }
            onClick={ onClick }
          >
            <ChevronDown />
          </div>
        </If>
      </div>
      <div className="accordion-content">{ children }</div>
    </div>
  )
}

export default Accordion
