import React, { Component } from "react";
import { Button, Form, Icon, Image, Message, Popup } from "semantic-ui-react";
import * as config from '../../config'
import { API_URL } from '../../constant'
import { AUTH_TYPE } from '../../constant'

/**
 * Provides an MFA form. Assumes that if the UI is showing the MFA code entry
 * form than the user has completed
 * @param id An ID attribute prefix used to identify component elements
 * @param onSubmit Login form submit callback function
 *        Arguments: MFA code, username, password, rememberMe
 * @param email Optional default email address
 * @param username Optional default username
 * @param password Optional default password
 * @param errorMessage Optional default error message
 */
class QrTotpMfaForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      email: this.props.email ? this.props.email : "",
      username: this.props.username ? this.props.username : "",
      password: this.props.password ? this.props.password : "",
      mfaCode: "",
      rememberMe: true,
      isLoading: false
    };
  }

  onSubmit = () => {
    const email = this.state.email;
    const username = this.state.username;
    const password = this.state.password;
    const mfaCode = this.state.mfaCode;
    const rememberMe = this.state.rememberMe;
    const authType = AUTH_TYPE.QR_TOTP
    const qrCodeUuid = this.props.qrCodeUuid
    if ( username.length > 0 && password.length > 0 && this.validateMfaCode(mfaCode) ) {
      this.setState({isLoading: true}, () => {
        this.props.onSubmit(mfaCode, email, username, password, rememberMe, authType, qrCodeUuid);
      });
    }
  }

  onChangeMfaCode = (e, { name, value }) => {
    this.setState({ [name]: value });
  };

  toggleRememberMe = (checked) => {
    this.setState({ rememberMe: checked});
  }

  validateMfaCode(code) {
    if ( code ) {
      return code.length === 6 && /^\d+$/.test(code);
    }
    return false;
  }

  render() {
    const qrCodeUrl = `${config.BASE_URL}${API_URL.MFA_QR_CODE(this.props.qrCodeUuid)}`

    const id = this.props.id;
    const mfaCode = this.state.mfaCode;
    const rememberMe = this.state.rememberMe;
    const errorMessage = this.props.errorMessage;
    const rememberMePopupContent = "By selecting this box, we will wait" +
      " 30 days before we confirm your identity again. Do not select" +
      " this option if this computer is used by multiple people.";

    return (
      <div id={`mfa-${id}-login-container`} className="m-2-top">
        {errorMessage && (
          <Message error={true} content={errorMessage} />
        )}
        <div className="mfa-login-form">
          <div className="m-1-top">
            <h2>Verify Your Identity</h2>
          </div>
          <div className="mfa-login-form-header m-1-top">
            Curation Health uses multi-factor authentication to
            secure your account. Once enabled, you will be
            required to enter a code that is generated by your
            authentication application for future logins.
          </div>
          <div className="m-1-top">
            <span><b>Step 1</b></span>
          </div>
          <div className="m-1-top">
            <span>
              Install a compatible authentication application such as Google
              Authenticator or Microsoft Authenticator on your mobile device.
            </span>
          </div>
          <div className="m-1-top">
            <span><b>Step 2</b></span>
          </div>
          <div className="m-1-top">
            <span>
              Open your authenticator app and scan this QR code using
              your phone’s camera.
            </span>
          </div>
          <div className="m-1-top">
            <Image src={ qrCodeUrl }/>
          </div>
          <div className="m-1-top">
            <span><b>Step 3</b></span>
          </div>
          <div className="m-1-top">
            <span>
              Enter the code generated by your app in the box provided
              below.
            </span>
          </div>
          <div className="m-1-top">
            <Form onSubmit={this.onSubmit} error={errorMessage != null}>
              <Form.Group inline>
                <Form.Input
                  id={`mfa-qr-totp-${id}-login-code-input`}
                  label="Verification Code:"
                  placeholder="MFA Code"
                  onChange={this.onChangeMfaCode}
                  name="mfaCode"
                  value={mfaCode}
                />
                <div className="m-1-top">
                  <Button id={`mfa-qr-totp-${id}-login-submit`}
                    color="blue" type="submit"
                    disabled={!this.validateMfaCode(mfaCode) || this.state.isLoading}>
                    Verify
                  </Button>
                </div>
              </Form.Group>
              <div>
                <Form.Group>
                  <Form.Checkbox
                    id={`mfa-qr-totp-${id}-login-remember-me`}
                    label="Remember Me"
                    onChange={(e, data) => this.toggleRememberMe(data.checked)}
                    checked={rememberMe} />
                  <Popup content={rememberMePopupContent} mouseEnterDelay={500}
                    trigger={<Icon name="info circle" className="pointer" />} />
                  </Form.Group>
              </div>
            </Form>
          </div>
        </div>
      </div>
    );
  }
};

export default QrTotpMfaForm;
