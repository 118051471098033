import React, { Component } from "react";
import { Button, Icon, Popup, Responsive } from "semantic-ui-react";
import { CONDITION } from "../../../constant";

/**
 * Provides a component that renders a condition header toolbar for CDI users
 * The toolbar provides buttons for changing the condition code and status
 * @param condition Condition entity
 * @param onClick Callback for toolbar button click
 *        Arguments: action, condition
 */
class CdiConditionHeaderToolbar extends Component {

  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  onClick(e, action) {
    e.stopPropagation();
    this.props.onClick(action, this.props.condition);
  }

  render() {
    const condition = this.props.condition;
    const actions = CONDITION.ACTION;
    return (
      <span>
        {condition.conditionCode === CONDITION.CODE.REMOVED && (
          <Responsive as={Button}
            icon basic size="mini"
            title="Mark as Active"
            onClick={(e) => { this.onClick(e, actions.MARK_OPEN) }}
          ><Icon name='undo' /></Responsive>
        )}
        {condition.conditionCode !== CONDITION.CODE.REMOVED &&
          condition.conditionCode !== CONDITION.CODE.DISMISSED &&
          condition.conditionStatus === CONDITION.TYPE.POTENTIAL && (
          <span>
            <Popup content="Mark as Removed" mouseEnterDelay={1000}
              trigger={
                <Responsive as={Button} icon basic size="mini"
                  onClick={(e) => { this.onClick(e, actions.MARK_REMOVED) }}
                ><Icon name='trash alternate outline' /></Responsive>
              } />
          </span>
        )}
        {condition.conditionCode !== CONDITION.CODE.REMOVED &&
          condition.conditionCode !== CONDITION.CODE.DISMISSED &&
          condition.conditionStatus === CONDITION.TYPE.ACTIVITY && (
          <span>
            <Popup content="Mark as Removed" mouseEnterDelay={1000}
              trigger={
                <Responsive as={Button} icon basic size="mini"
                  onClick={(e) => { this.onClick(e, actions.MARK_REMOVED) }}
                ><Icon name='trash alternate outline' /></Responsive>
              } />
          </span>
        )}
        {condition.conditionCode !== CONDITION.CODE.REMOVED &&
          condition.conditionCode !== CONDITION.CODE.DISMISSED &&
          condition.conditionStatus === CONDITION.TYPE.KNOWN && (
          <span>
            <Popup content="Mark as Removed" mouseEnterDelay={1000}
              trigger={
                <Responsive as={Button} icon basic size="mini"
                  onClick={(e) => { this.onClick(e, actions.MARK_REMOVED) }}
                ><Icon name='trash alternate outline' /></Responsive>
              } />
          </span>
        )}
      </span>
    );
  }
}

export default CdiConditionHeaderToolbar;
