import React from 'react'
import { Table } from 'semantic-ui-react'

type Props = {
  columns: number
}

function MaxTableFooter(props: Props) {
  const { columns } = props
  return (
    <Table.Footer>
      <Table.Row>
        <Table.HeaderCell className="table-footer-max" colSpan={ columns }>
          To View Additional Records, Please Refine Your Search
        </Table.HeaderCell>
      </Table.Row>
    </Table.Footer>
  )
}

export default MaxTableFooter
