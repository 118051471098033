import React, { Component } from "react";
import { Divider } from "semantic-ui-react";
import EncounterAppointment from "../common/encounterAppointment";
import EncounterPatient from "../common/encounterPatient";
import EncounterProfile from "../common/encounterProfile";
import EncounterReason from "../common/encounterReason";
import EncounterReviewer from "../common/encounterReviewer";
import EncounterReviewWarning from "../common/encounterReviewWarning";

/**
 * Provides a component that renders the Physician encounter left nav
 * @param encounterDetail Encounter detail entity
 */
class PhysicianEncounterLeft extends Component {

  render() {
    const detail = this.props.encounterDetail;
    const encounter = detail.encounter;
    const review = encounter.review;

    return (
      <div>
        <Divider className="green-border" />
        <EncounterReviewWarning review={review} />
        <EncounterProfile mode="left" encounter={encounter} />
        <EncounterPatient mode="left" encounter={encounter} />
        <EncounterReason mode="left" encounter={encounter} />
        <EncounterAppointment mode="left" encounter={encounter} />
        <EncounterReviewer mode="left" review={review} />
      </div>
    );
  }
}

export default PhysicianEncounterLeft;