export const UPDATE_SELECTED = "PATIENT_UPDATE_SELECTED";
export const UPDATE_LIST = "PATIENT_UPDATE_LIST";
export const APPEND_LIST = "PATIENT_APPEND_LIST";
export const CLEAR_STATE = "PATIENT_CLEAR_STATE";

export const CLEAR_ALL_STATE = "CLEAR_ALL_STATE";

const initState = {
  list: [],
  selected: {}
};

export default (state = initState, action) => {
  switch (action.type) {
    case CLEAR_STATE:
    case CLEAR_ALL_STATE:
      return initState;
    case UPDATE_SELECTED:
      return Object.assign({}, state, {
        selected: action.payload
      });
    case UPDATE_LIST:
      return Object.assign({}, state, {
        list: action.payload
      });
    case APPEND_LIST:
      var appendedList = state.list.concat(action.payload);
      return Object.assign({}, state, {
        list: appendedList
      });
    default:
      return state;
  }
};
