/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Container, Grid, Icon, Message, Segment } from 'semantic-ui-react'

class AdminWelcome extends Component {

  render() {
    const {currentUser} = this.props
    return (
      <div className="header-padding">
        <Container>
          <Segment attached className="no-margin">
            <Grid>
              <Grid.Row>
                <Grid.Column mobile={ 16 } tablet={ 16 } computer={ 16 }>
                  <Message warning icon>
                    <Icon name="info circle" />
                    <Message.Content>
                      <Message.Header>Hello { currentUser.username }</Message.Header>
                      <div className="m-1-top">
                        It looks like you are logged into the client as an Admin.
                      </div>
                      <div className="m-1-top">
                        You will see this message if you signed out of a
                        <span className="italic"> Run-As </span>
                        user context. If so, just close the tab and return to the
                        Admin application.
                      </div>
                    </Message.Content>
                  </Message>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        </Container>
      </div>
    )
  }
}

export default connect(
  state => ({
    currentUser: state.user
  }), {}
)(AdminWelcome)
