import React, { Component, createRef } from "react";
import { Button, Form, Header, Message } from "semantic-ui-react";
import { LoginLogo } from "../common/brandingUtils";
import ReCAPTCHA from "react-google-recaptcha";

const SITE_KEY = "6LeJk80pAAAAANi-2KD-hFGJmX2IilJflDoS4CFG";

/**
 * Provides a login form
 * @param id An ID attribute prefix used to identify component elements
 * @param onSubmit Login form submit callback function
 *        Arguments: username and password
 * @param username Optional default username
 * @param errorMessage Optional default error message
 */
class LoginForm extends Component {

  constructor(props, ...args) {
    super(props);
    this.state = {
      username: this.props.username ? this.props.username : "",
      password: "",
      expired: "false",
      recaptchaLoaded: false
    };
    this.reCaptchaRef = createRef();
    var isIE11 = window.navigator.userAgent.indexOf("Trident/7.0") > 0;
    this.enabledRecaptcha = !isIE11;
  }

  asyncScriptOnLoad = () => {
    this.setState({ recaptchaLoaded: true });
  };

  onSubmit = () => {
    const username = this.state.username;
    const password = this.state.password;
    if (this.enabledRecaptcha) {
      const grecaptcha = this.reCaptchaRef.current.props.grecaptcha;
      grecaptcha.enterprise.execute({action: 'LOGIN'}).then( recaptchaToken => {
        if ( username.length > 0 && password.length > 0 ) {
          this.props.onSubmit(username, password, recaptchaToken);
        }
      });
    } else {
      if ( username.length > 0 && password.length > 0 ) {
        this.props.onSubmit(username, password, null);
      };
    }
  }

  onChange = (e, value) => {
    if (value != null && value.name != null && value.value != null) {
      this.setState({ [value.name]: value.value });
    }
    if (value === null) this.setState({ expired: "true" });
  };

  render() {
    const id = this.props.id;
    const username = this.state.username;
    const password = this.state.password;
    const recaptchaLoaded = this.state.recaptchaLoaded;
    const errorMessage = this.props.errorMessage;

    return (
      <Form error={errorMessage != null}>
        {this.enabledRecaptcha && (
          <ReCAPTCHA
            size="invisible"
            sitekey={SITE_KEY}
            ref={this.reCaptchaRef}
            onChange={this.onChange}
            asyncScriptOnLoad={this.asyncScriptOnLoad}
          />
        )}
        <Header as="h2" textAlign="center">
          <Header.Content>{LoginLogo()}</Header.Content>
        </Header>
        {errorMessage && (
          <Message error={true} content={errorMessage} />
        )}
        <Form.Input
          id={ `${id}-login-username` }
          label="Username:"
          placeholder="Username"
          onChange={this.onChange}
          name="username"
          value={username}
        />
        <Form.Input
          id={ `${id}-login-password` }
          label="Password:"
          placeholder="Password"
          onChange={this.onChange}
          name="password"
          value={password}
          type="password"
        />
        <Button onClick={this.onSubmit} disabled={this.enabledRecaptcha && !recaptchaLoaded} id={ `${id}-login-submit` } color="blue" type="submit">
          Submit
        </Button>
      </Form>
    );
  }
};

export default LoginForm;
