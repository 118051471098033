/* eslint-disable @typescript-eslint/naming-convention */


// This file/map was forked from v2
// In v2, HCCs were grouped into "Clinical Categories",
// where there could be more than one HCC in each clinical category

// In v3, we want to have our nesting be based on HCCs, not clinical categories.
// What this means for the logic is that each HCC code must have a unique mapping.
// HOWEVER, we do not actually use the descriptions associated with each HCC code below.
// Instead, we pull the HCC description from conditions provided to us in the UI.
// The descriptions below could be anything really, but I tried to put accurate information
// where possible in case we have another use for this mapping in the future

// These are all of the distinct HCC codes in CMS_v24 and CMS_v28 found in t_hcc_code as of 10/10/2024

const categoryMapping: Record<string, string> = {
    null:   '',
    1:	    'HIV/AIDS',
    2:	    'Septicemia, Sepsis, Systemic Inflammatory Response Syndrome/Shock',
    6:	    'Opportunistic Infections',
    8:	    'Metastatic Cancer and Acute Leukemia',
    9:	    'Lung and Other Severe Cancers',
    10:	    'Lymphoma and Other Cancers',
    11:	    'Colorectal, Bladder, and Other Cancers',
    12:	    'Breast, Prostate, and Other Cancers and Tumors',
    17:	    'HCC 17 Description Varies Between HCC Versions',
    18:	    'HCC 18 Description Varies Between HCC Versions',
    19:	    'HCC 19 Description Varies Between HCC Versions',
    20:	    'Lung and Other Severe Cancers',
    21:	    'HCC 21 Description Varies Between HCC Versions',
    22:	    'HCC 22 Description Varies Between HCC Versions',
    23:	    'HCC 23 Description Varies Between HCC Versions',
    27:	    'End-Stage Liver Disease',
    28:	    'Cirrhosis of Liver',
    29:	    'Chronic Hepatitis',
    33:	    'Intestinal Obstruction/Perforation',
    34:	    'Chronic Pancreatitis',
    35:	    'HCC 35 Description Varies Between HCC Versions',
    36:	    'Diabetes with Severe Acute Complications',
    37:	    'Diabetes with Chronic Complications',
    38:	    'Diabetes with Glycemic, Unspecified, or No Complications',
    39:	    'Bone/Joint/Muscle Infections/Necrosis',
    40:	    'Rheumatoid Arthritis and Inflammatory Connective Tissue Disease',
    46:	    'Severe Hematological Disorders',
    47:	    'Disorders of Immunity',
    48:	    'HCC 48 Description Varies Between HCC Versions',
    49:	    'Specified Lysosomal Storage Disorders',
    50:	    'Amyloidosis, Porphyria, and Other Specified Metabolic Disorders',
    51:	    'HCC 51 Description Varies Between HCC Versions',
    52:	    'Dementia Without Complication',
    54:	    'Substance Use with Psychotic Complications',
    55:	    'Substance Use Disorder, Moderate/Severe, or Substance Use with Complications',
    56:	    'Substance Use Disorder, Mild, Except Alcohol and Cannabis',
    57:	    'Schizophrenia',
    58:	    'Reactive and Unspecified Psychosis',
    59:	    'Major Depressive, Bipolar, and Paranoid Disorders',
    60:	    'Personality Disorders',
    62:	    'Liver Transplant Status/Complications',
    63:	    'Chronic Liver Failure/End-Stage Liver Disorders',
    64:	    'Cirrhosis of Liver',
    65:	    'Chronic Hepatitis',
    68:	    'Cholangitis and Obstruction of Bile Duct Without Gallstones',
    70:	    'Quadriplegia',
    71:	    'Paraplegia',
    72:	    'Spinal Cord Disorders/Injuries',
    73:	    'Amyotrophic Lateral Sclerosis and Other Motor Neuron Disease',
    74:	    'Cerebral Palsy',
    75:	    'Myasthenia Gravis/Myoneural Disorders and Guillain-Barre Syndrome/Inflammatory and Toxic Neuropathy',
    76:	    'Muscular Dystrophy',
    77:	    'HCC 77 Description Varies Between HCC Versions',
    78:	    'HCC 78 Description Varies Between HCC Versions',
    79:	    'HCC 79 Description Varies Between HCC Versions',
    80:	    'HCC 80 Description Varies Between HCC Versions',
    81:	    'Ulcerative Colitis',
    82:	    'Respirator Dependence/Tracheostomy Status',
    83:	    'Respiratory Arrest',
    84:	    'Cardio-Respiratory Failure and Shock',
    85:	    'Congestive Heart Failure',
    86:	    'Acute Myocardial Infarction',
    87:	    'Unstable Angina and Other Acute Ischemic Heart Disease',
    88:	    'Angina Pectoris',
    92:	    'Bone/Joint/Muscle/Severe Soft Tissue Infections/Necrosis',
    93:	    'Rheumatoid Arthritis and Other Specified Inflammatory Rheumatic Disorders',
    94:	    'Systemic Lupus Erythematosus and Other Specified Systemic Connective Tissue Disorders',
    96:	    'Specified Heart Arrhythmias',
    99:	    'Intracranial Hemorrhage',
    100:	'Ischemic or Unspecified Stroke',
    103:	'Hemiplegia/Hemiparesis',
    104:	'Monoplegia, Other Paralytic Syndromes',
    106:	'Atherosclerosis of the Extremities with Ulceration or Gangrene',
    107:	'HCC 107 Description Varies Between HCC Versions',
    108:	'HCC 108 Description Varies Between HCC Versions',
    109:	'Acquired Hemolytic, Aplastic, and Sideroblastic Anemias',
    110:	'Cystic Fibrosis',
    111:	'HCC 111 Description Varies Between HCC Versions',
    112:	'HCC 112 Description Varies Between HCC Versions',
    114:	'HCC 114 Description Varies Between HCC Versions',
    115:	'HCC 115 Description Varies Between HCC Versions',
    122:	'Proliferative Diabetic Retinopathy and Vitreous Hemorrhage',
    124:	'Exudative Macular Degeneration',
    125:	'Dementia, Severe',
    126:	'Dementia, Moderate',
    127:	'Dementia, Mild or Unspecified',
    134:	'Dialysis Status',
    135:	'HCC 135 Description Varies Between HCC Versions',
    136:	'HCC 136 Description Varies Between HCC Versions',
    137:	'HCC 137 Description Varies Between HCC Versions',
    138:	'HCC 138 Description Varies Between HCC Versions',
    139:	'Alcohol Use Disorder, Moderate/Severe, or Alcohol Use with Specified Non-Psychotic Complications',
    151:	'Schizophrenia',
    152:	'Psychosis, Except Schizophrenia',
    153:	'Personality Disorders; Anorexia/Bulimia Nervosa',
    154:	'Bipolar Disorders without Psychosis',
    155:	'Major Depression, Moderate or Severe, without Psychosis',
    157:	'Pressure Ulcer of Skin with Necrosis Through to Muscle, Tendon, or Bone',
    158:	'Pressure Ulcer of Skin with Full Thickness Skin Loss',
    159:	'Pressure Ulcer of Skin with Partial Thickness Skin Loss',
    161:	'Chronic Ulcer of Skin, Except Pressure',
    162:	'Severe Skin Burn or Condition',
    166:	'Severe Head Injury',
    167:	'Major Head Injury',
    169:	'Vertebral Fractures without Spinal Cord Injury',
    170:	'Hip Fracture/Dislocation',
    173:	'Traumatic Amputations and Complications',
    176:	'Complications of Specified Implanted Device or Graft',
    180:	'Quadriplegia',
    181:	'Paraplegia',
    182:	'Spinal Cord Disorders/Injuries',
    186:	'Major Organ Transplant or Replacement Status',
    188:	'Artificial Openings for Feeding or Elimination',
    189:	'Amputation Status, Lower Limb/Amputation Complications',
    190:	'Amyotrophic Lateral Sclerosis and Other Motor Neuron Disease, Spinal Muscular Atrophy',
    191:	'Quadriplegic Cerebral Palsy',
    192:	'Cerebral Palsy, Except Quadriplegic',
    193:	'Chronic Inflammatory Demyelinating Polyneuritis and Multifocal Motor Neuropathy',
    195:	'Myasthenia Gravis with (Acute) Exacerbation',
    196:	'Myasthenia Gravis without (Acute) Exacerbation and Other Myoneural Disorders',
    197:	'Muscular Dystrophy',
    198:	'Multiple Sclerosis',
    199:	'Parkinson and Other Degenerative Disease of Basal Ganglia',
    200:	'Friedreich and Other Hereditary Ataxias; Huntington Disease',
    201:	'Seizure Disorders and Convulsions',
    202:	'Coma, Brain Compression/Anoxic Damage',
    211:	'Respirator Dependence/Tracheostomy Status/Complications',
    212:	'Respiratory Arrest',
    213:	'Cardio-Respiratory Failure and Shock',
    221:	'Heart Transplant Status/Complications',
    222:	'End-Stage Heart Failure',
    223:	'Heart Failure with Heart Assist Device/Artificial Heart',
    224:	'Acute on Chronic Heart Failure',
    225:	'Acute Heart Failure (Excludes Acute on Chronic)',
    226:	'Heart Failure, Except End-Stage and Acute',
    227:	'Cardiomyopathy/Myocarditis',
    228:	'Acute Myocardial Infarction',
    229:	'Unstable Angina and Other Acute Ischemic Heart Disease',
    238:	'Specified Heart Arrhythmias',
    248:	'Intracranial Hemorrhage',
    249:	'Ischemic or Unspecified Stroke',
    253:	'Hemiplegia/Hemiparesis',
    254:	'Monoplegia, Other Paralytic Syndromes',
    263:	'Atherosclerosis of Arteries of the Extremities with Ulceration or Gangrene',
    264:	'Vascular Disease with Complications',
    267:	'Deep Vein Thrombosis and Pulmonary Embolism',
    276:    'Lung Transplant Status/Complications',
    277:	'Cystic Fibrosis',
    278:	'Idiopathic Pulmonary Fibrosis and Lung Involvement in Systemic Sclerosis',
    279:	'Severe Persistent Asthma',
    280:	'Chronic Obstructive Pulmonary Disease, Interstitial Lung Disorders, and Other Chronic Lung Disorders',
    282:	'Aspiration and Specified Bacterial Pneumonias',
    283:	'Empyema, Lung Abscess',
    298:	'Severe Diabetic Eye Disease, Retinal Vein Occlusion, and Vitreous Hemorrhage',
    300:	'Exudative Macular Degeneration',
    326:	'Chronic Kidney Disease, Stage 5',
    327:	'Chronic Kidney Disease, Severe (Stage 4)',
    328:	'Chronic Kidney Disease, Moderate (Stage 3B)',
    329:	'Chronic Kidney Disease, Moderate (Stage 3, Except 3B)',
    379:	'Pressure Ulcer of Skin with Necrosis Through to Muscle, Tendon, or Bone',
    380:	'Chronic Ulcer of Skin, Except Pressure, Through to Bone or Muscle',
    381:	'Pressure Ulcer of Skin with Full Thickness Skin Loss',
    382:	'Pressure Ulcer of Skin with Partial Thickness Skin Loss',
    383:	'Chronic Ulcer of Skin, Except Pressure, Not Specified as Through to Bone or Muscle',
    385:	'Severe Skin Burn',
    387:	'Pemphigus, Pemphigoid, and Other Specified Autoimmune Skin Disorders',
    397:	'Major Head Injury with Loss of Consciousness > 1 Hour',
    398:	'Major Head Injury with Loss of Consciousness < 1 Hour or Unspecified',
    399:	'Major Head Injury without Loss of Consciousness',
    401:	'Vertebral Fractures without Spinal Cord Injury',
    402:	'Hip Fracture/Dislocation',
    405:	'Traumatic Amputations and Complications',
    409:	'Amputation Status, Lower Limb/Amputation Complications',
    454:	'Stem Cell, Including Bone Marrow, Transplant Status/Complications',
    463:	'Artificial Openings for Feeding or Elimination'
}

export default categoryMapping
