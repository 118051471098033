import React, { Component } from "react";
import { connect } from "react-redux";
import { Dropdown, Responsive } from 'semantic-ui-react';
import { API_URL } from "../../constant";
import { api } from "../../action/api";
import { patientRegionFilter } from "./currentUserUtils";

/**
 * Provides a standalone patient region select widget
 *
 * Available region filters are determined by the current user's region filter
 * user property. If the current user has a region filter property, the value
 * is used to populate the select, otherwise, regions are requested from the API
 * with the assumption that the user can see all regions
 *
 * @param onChange Callback function to execute when a region is selected
 *   arguments: (e, {name, value}) where value is the region alias or "" if
 *   the ALL option is selected
 * @param name Name of the patient region select widget in a form context
 * @param value Initial selected patient region alias
 * @param placeholder Placeholder text. Default: Regions
 * @param allOption If true, include an 'ALL' option. Default: true
 */
class PatientRegionSelect extends Component {

  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);

    this.state = {
      regions: [],
      allOption: this.props.allOption === false ? false : true
    };
  }

  componentDidMount() {
    this.fetchRegions();
  }

  fetchRegions() {
    const userRegions = patientRegionFilter(this.props.currentUser);
    if ( userRegions ) {
      // flat list of region aliases, transform to something that looks like
      // a region entity
      this.setState({ regions: userRegions.map((region) => {
        return {
          id: region,
          alias: region,
          name: region.replaceAll('_', ' ')
        };
      })});
    } else {
      const url = API_URL.GET_REGIONS;
      api.get(url, {}).then(({ data }) => {
        this.setState({regions: data});
      });
    }
  }

  onChange = (e, { value }) => {
    if ( this.props.onChange ) {
      const name = this.props.name ? this.props.name : "patientRegion";
      const val = value === 'ALL' ? null : value;
      this.props.onChange(e, {name: name, value: val});
    }
  }

  render() {
    const options = this.state.regions.map(region => {
      return {
        key: region.alias,
        value: region.alias,
        text: region.name
      };
    });
    if ( this.state.allOption === true ) {
      options.unshift({ key: "ALL", text: "All", value: "ALL" });
    }
    const placeholder = this.props.placeholder ? this.props.placeholder : "Regions";
    const value = this.props.value ? this.props.value : undefined;
    return (
      <Responsive
        as={Dropdown}
        selection
        placeholder={placeholder}
        onChange={this.onChange}
        options={options}
        value={value} />
    );
  }
}

export default connect(
  state => ({
    currentUser: state.user
  })
)(PatientRegionSelect);
