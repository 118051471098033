import React, { PropsWithChildren } from 'react'

type Props = {
  isTrue: boolean
}

function If(props: PropsWithChildren<Props>): JSX.Element {
  const { isTrue, children } = props
  return isTrue ? <>{ children }</> : <></>
}

export default If
