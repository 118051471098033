import React, { Dispatch, SetStateAction, useState } from 'react'
import { Dropdown, Icon, Menu, Responsive } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
// Local
import { MESSAGE, PAGES, ROLES, TABLET_WIDTH } from '../../constant'
import { HeaderLogo } from '../common/brandingUtils'
import { hasRole } from '../common/roleUtils'
import { HeaderEncounterSearch } from '../encounter/SearchById'
import CdiCounter from '../cdi/cdiCounter'
import CoderCounter from '../coder/coderCounter'
import './header.css'
import { User } from './types'
import CoderEncounterModal from '../encounter/coder/CoderEncounterModal'
import { logout } from '../../action/user'

type MenuLinks = {
  key: string
  link: string
  icon: string
  description: string
  validRoles: string[]
}

const menuLinks: MenuLinks[] = [
  {
    key: 'home',
    link: PAGES.HOME,
    icon: 'home',
    description: 'Home',
    validRoles: [
      ROLES.PHYSICIAN,
      ROLES.CDI,
      ROLES.CDI_RESTRICTED,
      ROLES.CLINICAL_ADMIN,
      ROLES.CODER,
      ROLES.CDI_QA_MANAGER,
      ROLES.CODER_QA_MANAGER
    ]
  },
  {
    key: 'patient',
    link: PAGES.PATIENT_LIST,
    icon: 'user outline',
    description: 'Patients',
    validRoles: [
      ROLES.PHYSICIAN,
      ROLES.CDI,
      ROLES.CDI_RESTRICTED,
      ROLES.CDI_QA_MANAGER,
      ROLES.CLINICAL_ADMIN
    ]
  },
  {
    key: 'encounter',
    link: PAGES.ENCOUNTER_LIST,
    icon: 'calendar alternate outline',
    description: 'Appointments',
    validRoles: [
      ROLES.CDI,
      ROLES.CDI_RESTRICTED,
      ROLES.CDI_QA_MANAGER,
      ROLES.CLINICAL_ADMIN,
      ROLES.CODER,
      ROLES.CODER_QA_MANAGER
    ]
  },
  {
    key: 'report',
    link: PAGES.REPORT,
    icon: 'list ol',
    description: 'Reports',
    validRoles: [
      ROLES.CDI,
      ROLES.CLINICAL_ADMIN,
      ROLES.CODER,
      ROLES.CDI_QA_MANAGER,
      ROLES.CODER_QA_MANAGER
    ]
  }
]

type ReduxProps = {
  hasFormUpdates?: boolean | undefined
}

type HeaderMenuLinkProps = {
  validRoles: string[]
  currentUser: User
  icon: any
  description: string
  link: string
  active: string
  hasFormUpdates: boolean | undefined
  setActionState: Dispatch<SetStateAction<string | undefined>>
}
export function HeaderMenuLink(props: HeaderMenuLinkProps): JSX.Element {
  const {
    validRoles,
    currentUser,
    icon,
    description,
    link,
    active,
    hasFormUpdates,
    setActionState
  } = props

  if (hasRole(validRoles, currentUser.type as string)) {
    const onMenuItemClick = (event: any) => {
      if (hasFormUpdates) {
        event.stopPropagation()
        event.preventDefault()
        setActionState(link)
      } else {
        setActionState(undefined)
        window.location.href = link
      }
    }
    return (
      <Menu.Item
        onClick={ onMenuItemClick }
        as={ Link }
        to={ link }
        active={ active === link }
      >
        <Responsive
          // eslint-disable-next-line react/jsx-props-no-spreading
          { ...Responsive.onlyMobile }
          as={ Icon }
          name={ icon }
          fitted
        />
        <Responsive as="div" minWidth={ TABLET_WIDTH.minWidth }>
          { description }
        </Responsive>
      </Menu.Item>
    )
  }
  return <></>
}

type HeaderDropDownProps = {
  setActionState: Dispatch<SetStateAction<string | undefined>>
  onAction: (page: string) => void
  hasFormUpdates: boolean | undefined
  dispatch?: any
}

export function HeaderDropDown(props: HeaderDropDownProps): JSX.Element {
  const { setActionState, onAction, hasFormUpdates, dispatch } = props
  const handleClick = (page: string) => {
    if (hasFormUpdates) {
      setActionState(page)
    } else {
      setActionState(undefined)
      onAction(page)
    }
  }

  return (
    <Dropdown item icon="sidebar" pointing="top right">
      <Dropdown.Menu>
        <Dropdown.Item
          name={ PAGES.ACCOUNT }
          onClick={ () => handleClick(PAGES.ACCOUNT) }
        >
          Account
        </Dropdown.Item>
        <Dropdown.Item
          name={ PAGES.VERSION }
          onClick={ () => handleClick(PAGES.VERSION) }
        >
          Version
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item
          name={ PAGES.LOGOUT }
          onClick={ () => {
            logout(MESSAGE.LOGOUT, dispatch)
          } }
        >
          Logout
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  )
}

/**
 * @param active Name of active route (will match active header tab)
 * @param currentUser Current user
 */
type HeaderProps = ReduxProps & {
  currentUser: any
  active: any
  dispatch?: any
}

function isCdiWorker(role: string): boolean {
  return role === ROLES.CDI || role === ROLES.CDI_RESTRICTED
}

function Header(props: HeaderProps): JSX.Element {
  const { currentUser, active, hasFormUpdates, dispatch } = props
  const key = new Date().getTime()
  const [ actionState, setActionState ] = useState<string | undefined>(undefined)
  const goToPage = (page: string) => {
    window.location.href = page
  }
  const handleLogoClick = (event: any) => {
    if (hasFormUpdates) {
      event.stopPropagation()
      event.preventDefault()
      setActionState(PAGES.HOME)
    } else {
      setActionState(undefined)
      window.location.href = PAGES.HOME
    }
  }
  return (
    <div id="curation-ui-header" key={ key }>
      <CoderEncounterModal
        onAction={ goToPage }
        actionState={ actionState }
        setActionState={ setActionState }
      />
      <Menu>
        <Menu.Item
          onClick={ handleLogoClick }
          as={ Link }
          to={ PAGES.HOME }
          id="header-logo"
        >
          <HeaderLogo />
        </Menu.Item>
        { menuLinks.map(hl => (
          <HeaderMenuLink
            key={ hl.key }
            link={ hl.link }
            icon={ hl.icon }
            validRoles={ hl.validRoles }
            currentUser={ currentUser }
            description={ hl.description }
            active={ active }
            hasFormUpdates={ hasFormUpdates }
            setActionState={ setActionState }
          />
        )) }
        <Menu.Menu position="right">
          <HeaderEncounterSearch setActionState={ setActionState } />
          { ( isCdiWorker(currentUser.type) ) && (
            <Menu.Item>
              <CdiCounter />
            </Menu.Item>
          ) }
          { currentUser.type === ROLES.CODER && (
            <Menu.Item>
              <CoderCounter />
            </Menu.Item>
          ) }
          <Menu.Item>
            <Responsive
              minWidth={ TABLET_WIDTH.minWidth }
              className="current-user"
            >
              { ( isCdiWorker(currentUser.type) ) && (
                <span>
                  <span>{ currentUser.username }</span>
                  <span> - </span>
                  <span>CDI</span>
                </span>
              ) }
              { currentUser.type === ROLES.CODER && (
                <span>
                  <span>{ currentUser.username }</span>
                  <span> - </span>
                  <span>Coder</span>
                </span>
              ) }
              { currentUser.type === ROLES.PHYSICIAN && (
                <span>
                  <span>{ currentUser.username }</span>
                  <span> - </span>
                  <span>Physician</span>
                </span>
              ) }
              { currentUser.type === ROLES.CLINICAL_ADMIN && (
                <span>{ currentUser.username }</span>
              ) }
              { currentUser.type === ROLES.ADMIN && (
                <span>
                  <span>{ currentUser.username }</span>
                  <span> - </span>
                  <span>Admin</span>
                </span>
              ) }
            </Responsive>
          </Menu.Item>
          <HeaderDropDown
            onAction={ goToPage }
            setActionState={ setActionState }
            hasFormUpdates={ hasFormUpdates }
            dispatch={ dispatch }
          />
        </Menu.Menu>
      </Menu>
    </div>
  )
}

const mapStateToProps = (state: any): any => ({
  hasFormUpdates: state.coder.hasFormUpdates
})

export default connect(mapStateToProps)(Header)
