/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react'
import { Button, Form, List } from 'semantic-ui-react'
import { format } from 'date-fns'
import { CONDITION, FORMAT, NOTE, ROLES } from '../../../constant'
import {
  EncounterConditionDisplay,
  ObjectValues
} from '../../authenticated/types'
import If from '../../common/if'

/**
 * Provides a component that displays condition notes and provides an editor
 * for adding condition notes.
 *
 * Condition notes can be CDI / Coder comments or Coder queries, identified by
 * the noteReasonCode
 *
 * @param condition Encounter condition
 * @param userRole Role of current user. Typically: CDI or CODER
 * @param notes List of current condition notes
 * @param onSubmit Callback function, called on note add
 * Arguments: condition, noteBody, noteReasonCode
 */
type Props = {
  condition: EncounterConditionDisplay
  onSubmit: (
    condition: EncounterConditionDisplay,
    note: string,
    noteReasonCode: string
  ) => void
  userRole: ObjectValues<typeof ROLES>
}

function ConditionNotes(props: Props) {
  const { condition, onSubmit, userRole } = props

  const [ note, setNote ] = useState<string>('')

  const onSubmitComment = () => {
    onSubmit(condition, note, NOTE.REASON.OPEN)
  }

  const onSubmitQuery = () => {
    onSubmit(condition, note, NOTE.REASON.CODER_QUERY)
  }

  const showCoderActions = () => userRole === ROLES.CODER
  const showCdiActions = () => userRole === ROLES.CDI

  const showOnlyCommentButton = () =>
    condition.addressedDate ||
    condition.conditionStatus === CONDITION.TYPE.ACTIVITY

  const showCommentAndQueryButtons = () => {
    if (
      (condition.conditionStatus === CONDITION.TYPE.KNOWN ||
        condition.conditionStatus === CONDITION.TYPE.POTENTIAL) &&
      condition.addressedDate === null
    ) {
      return true
    }
    return false
  }
  return (
    <div>
      <div className="condition-notes">
        { condition.notes &&
          condition.notes.map(conditionNote => (
            <List.Item key={ conditionNote.id }>
              { conditionNote.noteReasonCode === NOTE.REASON.CODER_QUERY && (
                <span className="bold">Query: </span>
              ) }
              <span className="italic">
                &quot;{ conditionNote.noteBody }&quot;
              </span>
              <span> -</span>
              <span> { conditionNote.createdByUserName }</span>
              <span>
                { ' ' }
                ({ format(conditionNote.creationDate, FORMAT.DATE_TIME) })
              </span>
            </List.Item>
          )) }
      </div>
      <Form.TextArea
        name="note"
        value={ note }
        className="condition-notes-textarea"
        placeholder="Condition Notes: Only viewable by CDI and Coders. Coders please include query here."
        onChange={ (event: any, noteValue: any) => {
          setNote(noteValue.value as string)
        } }
      />
      <If isTrue={ !!note && note.length > 0 }>
        <>
          <If isTrue={ showCoderActions() }>
            <>
              <If isTrue={ showCommentAndQueryButtons() }>
                <div className="text-align-center">
                  <span className="m-1-right">
                    <Button color="blue" type="submit" onClick={ onSubmitQuery }>
                      Add Query
                    </Button>
                  </span>
                  <span className="m-1-left">
                    <Button
                      color="blue"
                      type="submit"
                      onClick={ onSubmitComment }
                    >
                      Add Comment
                    </Button>
                  </span>
                </div>
              </If>
              <If isTrue={ !!showOnlyCommentButton() }>
                <div className="text-align-right">
                  <Button color="blue" type="submit" onClick={ onSubmitComment }>
                    Add Comment
                  </Button>
                </div>
              </If>
            </>
          </If>
          <If isTrue={ showCdiActions() }>
            <div className="text-align-right">
              <Button color="blue" type="submit" onClick={ onSubmitComment }>
                Add Comment
              </Button>
            </div>
          </If>
        </>
      </If>
    </div>
  )
}

export default ConditionNotes
