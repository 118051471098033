/* eslint-disable no-param-reassign */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/sort-comp */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/unbound-method */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Accordion } from 'semantic-ui-react'
import filter from 'lodash/filter'
import { CONDITION, NOTE, ROLES } from '../../../constant'
import {
  condition as conditionUtils,
  conditionGroupTitle,
  filterNlpConditions,
  filterWithStatus,
  groupByAddressed,
  hasConditionsOrActivities,
  orderConditionsByHccConfidenceDescending
} from '../../common/conditionUtils'
import {
  encounterConditionGroups,
  encounterConditionGroupsEnabled,
  hccGroupingEnabled
} from '../../common/currentUserUtils'
import { groupByHccCode } from "../../common/hccUtils";
import * as lockUtils from '../../common/lockUtils'
import ConditionHeader from '../common/conditionHeader'
import ConditionCreate from '../common/conditionCreate'
import CdiConditionEdit from './cdiConditionEdit'
import CdiConditionHccGroup from './cdiConditionHccGroup'
import CdiConditionRead from './cdiConditionRead'
import If from '../../common/if'
import '../detail.css'

/**
 * Provides a component that renders the CDI encounter conditions and condition
 * editors in the Version 2 display format.
 *
 * The CDI version 2 display format is very similar to the Physician UI version
 * display format. Conditions are grouped under HCC code, sorting unaddressed
 * HCCs to the top
 *
 * @param encounterDetail Encounter detail entity
 * @param activeConditionId Active condition ID (default: null). This is the
 *        last condition that the user acted on
 * @param activeHccGroupId Active HCC group ID
 * @param onConditionAction Callback for condition action.
 *        Arguments: action, condition
 * @param onConditionNoteAction Callback for condition note action
 *        Arguments action, condition, content, reason code (default: OPEN)
 * @param onHccGroupAction Callback for HCC group action.
 *        Arguments: action, hcc-group-id
 */
class CdiEncounterConditionsV2 extends Component {

  constructor(props) {
    super(props)
    this.onClickConditionTitle = this.onClickConditionTitle.bind(this)
    this.onSubmitConditionNote = this.onSubmitConditionNote.bind(this)
    this.onCreateCondition = this.onCreateCondition.bind(this)
  }

  onClickConditionTitle(condition) {
    if ( this.isActiveCondition(condition) ) {
      this.props.onConditionAction(CONDITION.ACTION.DEACTIVATE, condition)
    } else {
      this.props.onConditionAction(CONDITION.ACTION.ACTIVATE, condition)
    }
  }

  onSubmitConditionNote(condition, noteContent, noteReason) {
    const { onConditionNoteAction } = this.props
    onConditionNoteAction(
      NOTE.ACTION.CREATE,
      condition,
      noteContent,
      noteReason
    )
  }

  onCreateCondition(condition) {
    const { onConditionAction } = this.props
    onConditionAction(CONDITION.ACTION.CREATE, condition)
  }

  isActiveCondition(condition) {
    return condition && condition.id &&
      condition.id === this.props.activeConditionId
  }

  filterConditions(conditions, conditionStatusCode) {
    const nlpFiltered = filterNlpConditions(this.props.currentUser, conditions);
    const ignoreCodes = [CONDITION.CODE.REMOVED, CONDITION.CODE.DISMISSED];
    return orderConditionsByHccConfidenceDescending(
      filterWithStatus(ROLES.CDI, nlpFiltered,
        conditionStatusCode, ignoreCodes));
  }

  groupConditions(conditions) {
    const currentUser = this.props.currentUser;

    if ( hccGroupingEnabled(currentUser) ) {
      return groupByHccCode(conditions);
    } else {
      return groupByAddressed(conditions);
    }
  }

  sortConditions(conditions) {
    const addressed = filter(conditions, (condition) => {
      return conditionUtils.isConditionAddressed(condition);
    });

    const notAddressed = filter(conditions, (condition) => {
      return !conditionUtils.isConditionAddressed(condition);
    });

    return notAddressed.concat(addressed);
  }

  sortConditionGroups(groups) {
    const withHcc = groups.filter(g => { return g.hccCode !== null });
    const withHccAddressed = withHcc.filter(g => { return g.addressed === true });
    const withHccNotAddressed = withHcc.filter(g => { return g.addressed === false });
    const withoutHcc = groups.filter(g => { return g.hccCode === null; });
    const withoutHccAddressed = withoutHcc.filter(g => { return g.addressed === true });
    const withoutHccNotAddressed = withoutHcc.filter(g => { return g.addressed === false });
    return withHccNotAddressed.concat(withoutHccNotAddressed).concat(withHccAddressed).concat(withoutHccAddressed);
  }

  renderHccGroup(group, index, groupTitle, editMode) {
    return (
      <CdiConditionHccGroup
        key={group.hccCode + "-" + index}
        editMode={editMode}
        hccCode={group.hccCode}
        hccDescription={group.hccDescription}
        activeHccGroupId={this.props.activeHccGroupId}
        conditionGroupTitle={groupTitle}
        conditions={group.conditions}
        onSubmitNote={this.onSubmitConditionNote}
        onConditionAction={this.props.onConditionAction}
        onHccGroupAction={this.props.onHccGroupAction}
      />
    );
  }

  renderUngrouped(group, index, editMode) {
    return group.conditions.map((condition, conditionIndex) => {
      return editMode ? (
        <CdiConditionEdit
          key={ condition.id }
          condition={ condition }
          displayIndex={ conditionIndex }
          isActive={ this.isActiveCondition(condition) }
          onClickTitle={ this.onClickConditionTitle }
          onSubmitNote={ this.onSubmitConditionNote }
          onConditionAction={ this.props.onConditionAction }
          hccChild={false}
        />
      ) : (
        <CdiConditionRead
          key={ condition.id }
          condition={ condition }
          displayIndex={ conditionIndex }
          isActive={ this.isActiveCondition(condition) }
          onClickTitle={ this.onClickConditionTitle }
          onSubmitNote={ this.onSubmitConditionNote }
          hccChild={ false }
        />
      );
    });
  }

  renderConditions(encounterDetail, displayConditions, editMode) {
    const { patient } = encounterDetail.encounter
    const key = encounterDetail.encounter.id;
    return (
      <div id='cdi-encounter-conditions' className='m-1-top' key={key}>
        {
          displayConditions.map((display, displayIndex) => {
            display.hasInsightsToShow = hasConditionsOrActivities(display.conditions, this.props.currentUser.type);
            return (
              <div key={displayIndex} >
                <ConditionHeader title={display.title} displayIndex={displayIndex} />
                { display.hasInsightsToShow &&
                  <Accordion styled fluid>
                  {
                    display.conditions.map((group, groupIndex) => {
                      if ( group.hccCode === null && group.conditions.length > 0 ) {
                        return this.renderUngrouped(group, groupIndex, editMode);
                      } else if ( group.hccCode !== null && group.conditions && group.conditions.length > 0 ) {
                        return this.renderHccGroup(group, groupIndex, display.title, editMode);
                      } else {
                        return null;
                      }
                    })
                  }
                  </Accordion>
                }
                { !display.hasInsightsToShow &&
                  <Accordion styled fluid>
                    <Accordion.Content>
                      <span>{`No ${display.title} to show at this time.`}</span>
                    </Accordion.Content>
                  </Accordion>
                }
              </div>
            )
          })
        }
        <If isTrue={ editMode }>
          <div id='cdi-encounter-condition-create'>
            <ConditionHeader title="Add Condition" />
            <Accordion fluid styled>
              <ConditionCreate
                patient={ patient }
                onSave={ this.onCreateCondition }
              />
            </Accordion>
          </div>
        </If>
      </div>
    );
  }

  render() {
    const { currentUser, encounterDetail } = this.props
    const { lock } = encounterDetail.encounter
    const conditions = encounterDetail.conditions;
    const conditionGroups = encounterConditionGroups(currentUser, this.props.conditionGroups);
    const groupsEnabled = encounterConditionGroupsEnabled(currentUser);
    const editMode = lockUtils.canEdit(lock, currentUser)
    const displayConditions = [];

    conditionGroups.forEach((g, i) => {
      if ( groupsEnabled[g] === true ) {
        displayConditions.push(
          {
            title: conditionGroupTitle(g),
            conditions: this.sortConditionGroups(
              this.groupConditions(
                this.sortConditions(
                  this.filterConditions(conditions, g))))
          }
        );
      }
    });

    displayConditions.push(
      {
        title: 'Removed Conditions',
        conditions: groupByAddressed(
          orderConditionsByHccConfidenceDescending(
            filter(conditions,
              c => c.conditionCode === CONDITION.CODE.REMOVED ||
                c.conditionCode === CONDITION.CODE.DISMISSED)))
      }
    )

    return this.renderConditions(encounterDetail, displayConditions, editMode);
  }
}

export default connect(
  state => ({
    currentUser: state.user
  }), {}
)(CdiEncounterConditionsV2)
