/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable prefer-rest-params */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable no-multi-assign */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unused-state */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { formatDob } from '../common/formatUtils'
import { Container, Icon, Segment } from 'semantic-ui-react'
import { isNil } from 'ramda'
// Local
import { LIST_PAGE_SIZE, LIST_MAX_PAGES, FORMAT, PATIENT } from '../../constant'
import { loadPatients } from '../../action/patient'
import { loadEncounters, updateList } from '../../action/encounter'
import SubHeader from '../common/subHeader'
import Table from '../common/table'
import Filter, { generateQueryFilters } from '../common/filter'
import { patientMrn } from '../common/patientUtils'
import {
  allowPatientTabSchedulingCdi,
  patientOffshoreFilter,
  patientRegionFilter
} from '../common/currentUserUtils'
import { updateUserPatientListFilters } from '../../action/user'
import './index.css'
import {
  cursorClass,
  defaultSort,
  filterMetaData,
  filterMetaDataWithReview,
  INITIAL_PAGES_DISPLAYED
} from './utils'
import PatientScheduleModal from './patientScheduleModal'
import { semiColonsToLineBreaks } from '../v2/utils/utils'
import { isCdiWorkerNow, isCdiQaManagerNow } from '../common/roleUtils'

const getEmptyStringFromNull = value => isNil(value) ? '' : value

/** Map patient data into the proper display format. */
const generateTableData = patients =>
  patients.map(patient => {
    const mrn = patientMrn(patient, patient.tenantId)
    const result = {
      id: patient.id,
      name: `${patient.firstName} ${patient.lastName}`,
      birthDate: isNil(patient.birthDate)
        ? ''
        : formatDob(patient.birthDate, FORMAT.DATE),
      mrn: semiColonsToLineBreaks(mrn),
      email: getEmptyStringFromNull(patient.personalEmail),
      phone: getEmptyStringFromNull(patient.homePhone),
      insurance: getEmptyStringFromNull(patient.insurance),
      prevEncounter: isNil(patient.prevEncounter) ? null : patient.prevEncounter,
      proposedEncounter: isNil(patient.proposedEncounter) ? null : patient.proposedEncounter,
      nextEncounter: isNil(patient.nextEncounter) ? null : patient.nextEncounter,
      meta: {
        className: cursorClass
      }
    }
    return result
  })

const shouldShowScheduleButton = user => 
  allowPatientTabSchedulingCdi(user) && (isCdiWorkerNow(user.type) || isCdiQaManagerNow(user.type))

function computerColumns(user) {
  const baseColumns = [
    { name: 'Name', value: 'name' },
    { name: 'DOB', value: 'birthDate' },
    { name: 'MRN(s)', value: 'mrn' },
    { name: 'Email', value: 'email' },
    { name: 'Phone', value: 'phone' }
  ]

  if (shouldShowScheduleButton(user)) {
    baseColumns.push({ name: 'Upcoming Appointment', value: 'nextEncounter' })
    baseColumns.push({ name: 'Schedule New Appointment', value: 'newAppointment' })
    return baseColumns
  }
  baseColumns.push({ name: 'Appointment', value: 'nextEncounter' })
  return baseColumns
}

/**
 * We need to determine if the filter section should be expanded or collapsed.
 * We want to selectively remove the defaultPatientOffshoreFilter since that is a
 * invisible default. Thus it should not affect the expanded or collapsed state.
 * @param {*} o
 * @returns edited filter object
 */
function filterOutDefault(o) {
  const safeCopy = {...o}
  delete safeCopy.defaultPatientOffshoreFilter
  return safeCopy
}

function hasPatientFilters(filters) {
  if (filters === undefined) {
    return false
  }
  return Object.values(filterOutDefault(filters)).some(x => x !== null && x !== '')
}

/** Display a list of patients */
function PatientList(props) {
  const {
    displayModal,
    user,
    encounterList,
    updateUserPatientListFilters,
    historInReduxy
  } = props
  const hasPatientListFilters = hasPatientFilters(user.patientListFilters)
  // Whether to display the advanced search filters or not
  const [ shouldDisplayFilterState, setShouldshouldDisplayFilterState ] = useState(hasPatientListFilters)
  // Values specified in the advanced search filters
  const [ filtersState, setFiltersState ] = useState(user.patientListFilters)
  // How many pages or results are currently displayed
  const [ pagesDisplayedState, setPagesDisplayedState ] = useState(
    INITIAL_PAGES_DISPLAYED
  )
  // Determine whether to display a specific patients encounters in a modal or not
  const [ shouldDisplayModalState, setShouldDisplayModalState ] =
    useState(displayModal || false)
  // Patients encounters to display in a modal.
  const [ patientSelectedState, setPatientSelectedState ] = useState(undefined)
  // Controls whether we query for onshore/offshore patients
  const patientOffshoreFilterValue = patientOffshoreFilter(user)
  // Region limitations set by the admin
  const patientRegionFilterValue = patientRegionFilter(user)

  /** add the offshore filter if it is defined */
  const addOffshoreFilterAsQuery = o => {
    // patientOffshoreFilterValue is an array
    if (patientOffshoreFilterValue) {
      const original = o.query
      o.query = { ...original, onOffShore: patientOffshoreFilterValue }
    }
    return o
  }

  /** add the offshore filter if it is defined */
  const addOffshoreFilter = o => {
    const safe = { ...o }
    // patientOffshoreFilterValue is an array
    if (patientOffshoreFilterValue) {
      safe.onOffShore = patientOffshoreFilterValue
    }
    return safe
  }

  /** add the default region filter if necessary */
  function addRegionFilterAsQuery(o) {
    if (o.query.hasOwnProperty('patientRegion') || patientRegionFilterValue === null) {
      // if they explicitly chose something, go with that region
      return o
    }
    // patientRegionFilterValue is an array
    const original = o.query
    o.query = { ...original, patientRegion: patientRegionFilterValue }
    return o
  }

  /** add the region filter if it is defined */
  function addRegionFilter(o) {
    if (o.hasOwnProperty('patientRegion') || patientRegionFilterValue === null) {
      // if they explicitly chose a region, we will go with that choice
      return o
    }
    const safe = { ...o }
    // patientRegionFilterValue is an array
    safe.patientRegion = patientRegionFilterValue
    return safe
  }

  function addPatientStatusFilter(o) {
    const safe = { ...o }
    safe.patientStatus = PATIENT.STATUS.ACTIVE
    return safe
  }

  /** Load the default search parameters for patient data. */
  useEffect(() => {
    const loadArgs = {
      query: addPatientStatusFilter(generateQueryFilters(filterMetaData, filterOutDefault(user.patientListFilters))),
      sort: defaultSort,
      userRole: user.type
    }
    loadPatients(addRegionFilterAsQuery(addOffshoreFilterAsQuery(loadArgs)))
  }, [])

  /** Toggle whether to display the advanced search or not */
  function toggleFilter() {
    setShouldshouldDisplayFilterState(!shouldDisplayFilterState)
  }

  function appendFilterMetaData(currentUser) {
    if (allowPatientTabSchedulingCdi(currentUser)) {
      return filterMetaDataWithReview
    }
    return filterMetaData
  }

  /** Track any modifications to filter search values */
  function onChangeFilter(name, value) {
    setFiltersState(Object.assign({}, filtersState, { [name]: value }))
  }

  /** Load additional patients based on existing search criteria. */
  function onMore() {
    const q = generateQueryFilters(filterMetaData,
      addRegionFilter(addOffshoreFilter(addPatientStatusFilter(filtersState))))
    const loadArgs = {
      query: q,
      limit: LIST_PAGE_SIZE,
      offset: LIST_PAGE_SIZE * pagesDisplayedState + 1,
      sort: defaultSort,
      append: true,
      userRole: user.type
    }
    loadPatients(loadArgs)
    setPagesDisplayedState(pagesDisplayedState + 1)
  }

  /** Initial search for patients for a given set of filters. */
  function searchWithFilter(values) {
    const q = addRegionFilter(addOffshoreFilter(addPatientStatusFilter(values)))
    const loadArgs = {
      query: q,
      sort: defaultSort,
      userRole: user.type
    }
    setPagesDisplayedState(INITIAL_PAGES_DISPLAYED)
    loadPatients(loadArgs)
    setShouldDisplayModalState(true)
  }

  /**
    Select a patient.  When selected,  display set of encounters for that patient.
    NOTE we only display the first 200 encounters.  Can be improved.
  */
  function selectRecord(id) {
    setShouldDisplayModalState(true)
    setPatientSelectedState(id)
    updateList([], false)
    loadEncounters({
      userRole: user.userRole,
      query: { patientId: id },
      limit: LIST_PAGE_SIZE * LIST_MAX_PAGES,
      offset: 0,
      sort: 'start asc'
    })
  }

  /** Close patient modal. */
  function closeModal() {
    setShouldDisplayModalState(false)
    setPatientSelectedState(undefined)
  }

  // const { pagesDisplayed, filters, displayModal } = state
  const tableData = generateTableData(props.list)
  const filterButton = (
    <Icon
      name="filter"
      className="pointer"
      size="large"
      onClick={ toggleFilter }
    />
  )
  let displayMaxRecords
  let shouldDisplayMore = (displayMaxRecords = false)
  if (tableData.length > LIST_PAGE_SIZE * pagesDisplayedState) {
    shouldDisplayMore = pagesDisplayedState < LIST_MAX_PAGES
    displayMaxRecords = pagesDisplayedState >= LIST_MAX_PAGES
    tableData.pop()
  }
  const filterMetaDataList = appendFilterMetaData(user)

  return (
    <div className="header-padding">
      <Container>
        <SubHeader left="Patients" right={ filterButton } />
        { shouldDisplayFilterState && (
          <Filter
            initialValues={ filtersState }
            user={ user }
            setInitialValuesState={ setFiltersState }
            updateUserPatientListFilters={ updateUserPatientListFilters }
            onChange={ onChangeFilter }
            submit={ searchWithFilter }
            filters={ filterMetaDataList }
          />
        ) }
        <Segment attached className="no-margin">
          <Table
            computerColumns={ computerColumns(user) }
            mobileColumns={ [
              { value: 'name', width: '11' },
              { value: 'birthDate', width: '5' }
            ] }
            data={ tableData }
            onClick={ selectRecord }
            more={ shouldDisplayMore }
            // max={ displayMaxRecords }
            onMore={ onMore }
          />
        </Segment>
        { displayModal && (
          <PatientScheduleModal
            list={ encounterList }
            historInReduxy={ historInReduxy }
            closeModal={ closeModal }
          />
        ) }
      </Container>
    </div>
  )
}

export default connect(
  state => ({
    user: state.user,
    list: state.patient.list,
    encounterList: state.encounter.list
  }), { updateUserPatientListFilters }
)(PatientList)