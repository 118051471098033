import React, { Component } from 'react'
import { connect } from 'react-redux'
import { startOfDay } from 'date-fns'
// Local
import { getBaseAppUrl } from '../../config'
import { PAGES, ROLES } from '../../constant'
import AdminWelcome from '../admin/adminWelcome'
import CdiWorklist from '../worklist/cdi/cdiWorklist'
import ClinicalAdminWorklist from '../worklist/clinicalAdmin/clinicalAdminWorklist'
import CoderWorklist from '../worklist/coder/coderWorklist'
import PhysicianWorklist from '../worklist/physician/physicianWorklist'
import './index.css'

/**
 * Provides the top-level Home component. Determines which work list to display
 * based on current user role, handles encounter select from the work list
 */
export class Home extends Component {

  onEncounterSelect = (id, event) => {
    const { history } = this.props
    const route = `${PAGES.ENCOUNTER_DETAIL}${id}`
    if (event && (event.metaKey || event.ctrlKey)) {
      window.open(getBaseAppUrl() + route, '_blank').focus()
    } else {
      history.push(route)
    }
  }

  render() {
    const { match, type, userId } = this.props
    const dateParam = match.params.date
    const startDate = dateParam ? startOfDay(dateParam) : startOfDay(new Date())
    const userRole = type

    if (userRole === ROLES.CLINICAL_ADMIN) {
      return (
        <ClinicalAdminWorklist
          onSelect={ this.onEncounterSelect }
          viewDate={ startDate } />
      )
    }

    if (userRole === ROLES.PHYSICIAN) {
      return (
        <PhysicianWorklist
          practitionerId={ userId }
          onSelect={ this.onEncounterSelect }
          viewDate={ startDate } />
      )
    }

    if (ROLES.isCdi(userRole)) {
      return (
        <CdiWorklist
          onSelect={ this.onEncounterSelect }
          startDate={ startDate } 
          userRole={ userRole } />
      )
    }

    if (userRole === ROLES.CODER || userRole === ROLES.CODER_QA_MANAGER) {
      return (
        <CoderWorklist onSelect={ this.onEncounterSelect } userRole={ userRole } />
      )
    }

    if (userRole === ROLES.ADMIN) {
      return (
        <AdminWelcome />
      )
    }

    return null
  }
}

export default connect(
  state => ({
    type: state.user.type,
    userId: state.user.userId,
    username: state.user.username,
    physicianDay: state.encounter.physicianDay
  }), {}
)(Home)

