import React, { Component } from "react";
import { Grid, Popup, Segment, Table } from "semantic-ui-react";
import { format } from "date-fns";
import filter from "lodash/filter";
import orderBy from "lodash/orderBy";
import uniq from "lodash/uniq";
import { API_URL, FORMAT, REVIEW } from "../../constant";
import { api } from "../../action/api";

/**
 * Reports the reviews completed by each reviewer user (CDI and Coder)
 */
class DailyProductivityCount extends Component {

  constructor(props) {
    super(props);
    this.onClickRefresh = this.onClickRefresh.bind(this);
    this.state = {
      reportData: null
    }
  }

  componentDidMount() {
    this.fetchReportData();
  }

  fetchReportData() {
    const url = API_URL.DASHBOARD_REVIEW_DAILY;
    api.get(url).then(({ data }) => {
      this.setState({ reportData: data });
    });
  }

  onClickRefresh() {
    this.fetchReportData();
  }

  renderRefresh() {
    return (
      <Grid columns={1}>
        <Grid.Column textAlign="right">
          <span className="italic">
            <span>{format(new Date(), FORMAT.TIMESTAMP)}</span>
            <span> </span>
            <span className="linkable"
              onClick={this.onClickRefresh}>Refresh</span>
          </span>
        </Grid.Column>
      </Grid>
    );
  }

  renderEmpty() {
    return (
      <div>
        <span className="italic">Sorry, no counts yet today</span>
        <div>{this.renderRefresh()}</div>
      </div>
    );
  }

  distinctUsers(data) {
    return orderBy(uniq(data.map((row) => { return row.reviewerName })));
  }

  preVisitCount(user, data) {
    const withUser = filter(data, (row) => {
      return row.reviewerName === user;
    });
    const withCode = filter(withUser, (row) => {
      return row.reviewCode === REVIEW.CODE.READY_FOR_PHYSICIAN ||
        row.reviewCode === REVIEW.CODE.READY_FOR_CDI_QA;
    });
    return withCode.reduce((a, b) => {
      return a + b.reviewCount;
    }, 0);
  }

  postVisitCount(user, data) {
    const withUser = filter(data, (row) => {
      return row.reviewerName === user;
    });
    const withCode = filter(withUser, (row) => {
      return row.reviewCode === REVIEW.CODE.COMPLETED ||
        row.reviewCode === REVIEW.CODE.READY_FOR_CODER_QA;
    });
    return withCode.reduce((a, b) => {
      return a + b.reviewCount;
    }, 0);
  }

  reviewCodeCount(user, reviewCode, data) {
    const withUser = filter(data, (row) => {
      return row.reviewerName === user;
    });
    const withCode = filter(withUser, (row) => {
      return row.reviewCode === reviewCode;
    });
    return withCode.reduce((a, b) => {
      return a + b.reviewCount;
    }, 0);
  }

  // the report API returns rows grouped by user and review code
  // transform these into pre visit and post visit counts
  renderReport(data) {
    const users = this.distinctUsers(data);
    return (
      <div>
        <Table definition fixed>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell />
              <Table.HeaderCell>Pre-Visit Review</Table.HeaderCell>
              <Table.HeaderCell>Post-Visit Review</Table.HeaderCell>
              <Table.HeaderCell>Total</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {users.map((user) => {
              const cdiQa = this.reviewCodeCount(user, REVIEW.CODE.READY_FOR_CDI_QA, data);
              const readyForPhysician = this.reviewCodeCount(user, REVIEW.CODE.READY_FOR_PHYSICIAN, data);
              const coderQa = this.reviewCodeCount(user, REVIEW.CODE.READY_FOR_CODER_QA, data);
              const completed = this.reviewCodeCount(user, REVIEW.CODE.COMPLETED, data);
              const preCount = cdiQa + readyForPhysician;
              const postCount = coderQa + completed;
              const total = preCount + postCount;
              return (
                <Table.Row key={user}>
                  <Table.Cell>{user}</Table.Cell>
                  <Table.Cell><Popup
                    content={`Ready for CDI QA: ${cdiQa}; Ready for Physician: ${readyForPhysician}`}
                    trigger={<span>{preCount}</span>}  /></Table.Cell>
                  <Table.Cell><Popup
                    content={`Ready for Coder QA: ${coderQa}; Completed: ${completed}`}
                    trigger={<span>{postCount}</span>} /></Table.Cell>
                  <Table.Cell>{total}</Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
        <div>{ this.renderRefresh() }</div>
      </div>
    );
  }

  render() {
    const key = (new Date()).getTime();
    return (
      <Segment key={key}>
        <h2>Daily Productivity</h2>
        <div className="m-1-top">
          How many encounters have CDIs marked Ready for Physician or
          Coders marked Complete today, by user?
        </div>
        <div className="m-1-top">
          {
            this.state.reportData && this.state.reportData.length > 0
              ? this.renderReport(this.state.reportData)
              : this.renderEmpty()
          }
        </div>
      </Segment>
    );
  }
}

export default DailyProductivityCount;
