import { User, PatientListFilter } from '../authenticated/types'
import { patientOffshoreFilter } from './currentUserUtils'

/**
 * Provides user patient list filter management utilities
 *
 * This is based on the same ideas as the appointmentlist filter. This is 
 * only used by the patient list page.
 */

/**
 * @return Patient list filters all initialized to null
 */
export const emptyPatientListFilters = (): PatientListFilter => ({
  mrn: null,
  firstName: null,
  lastName: null,
  birthDate: null,
  homePhone: null,
  email: null,
  defaultPatientOffshoreFilter: null,
  patientEncounterReviewStatus: null
})

/**
 * Initialize empty patient list filters with defaults
 * @return Initialized patient list filters
 */
export function initPatientListFilters(currentUser: User | any) {
  const filters = emptyPatientListFilters()
  filters.defaultPatientOffshoreFilter = patientOffshoreFilter(currentUser as User)
  return filters
}

/**
 * Update worklist filters. Send all filters that are currently enabled.
 * Required default filters are set
 * @param currentFilters Current state of filters
 * @param updateFilters Filter update requests
 * @return Filters with requested updates applied
 */
export function updatePatientListFiltersUtil(
  currentUser: User | any,
  updateFilters: PatientListFilter
): PatientListFilter {
  const filters = initPatientListFilters(currentUser)
  const {
    firstName,
    lastName,
    birthDate,
    homePhone,
    email,
    mrn,
    defaultPatientOffshoreFilter,
    patientEncounterReviewStatus,
    patientRegion
  } = updateFilters
  
  return {
    ...filters,
    firstName,
    lastName,
    birthDate,
    homePhone,
    email,
    mrn,
    defaultPatientOffshoreFilter,
    patientEncounterReviewStatus,
    patientRegion
  }
}