import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Button, Container, Modal } from 'semantic-ui-react'
// Local
import If from '../../common/if'
import Close from '../../icons/close'
import { updateCoderState as updateCoderStateImport } from '../../../action/coder'

type ReduxProps = {
  hasFormUpdates?: boolean | undefined
  updateCoderState?: any
}

type Props = ReduxProps & {
  onAction?: (action: any) => void
  actionState?: string | undefined
  setActionState?: Dispatch<SetStateAction<string | undefined>>
}

function CoderEncounterModal(props: Props): JSX.Element {
  const {
    actionState,
    onAction,
    setActionState,
    hasFormUpdates,
    updateCoderState
  } = props
  const [ isModalShowing, setIsModalShowing ] = useState<boolean>(false)

  useEffect(() => {
    if (actionState) {
      setIsModalShowing(true)
    }
  }, [ actionState ])

  return (
    <If isTrue={ (hasFormUpdates as boolean) && isModalShowing }>
      <Modal
        onClose={ () => {
          setIsModalShowing(false)
          if (setActionState) setActionState(undefined)
        } }
        onOpen={ () => setIsModalShowing(true) }
        open={ isModalShowing }
        size="tiny"
        closeIcon={
          <div className="modal-close">
            <div className="close-icon">
              <Close />
            </div>
          </div>
        }
        closeOnDimmerClick={ false }
      >
        <div className="cdi-flow">
          <h1>WARNING!</h1>
          <p>
            There are unsaved changes to one or more conditions. Continue
            without saving?
          </p>
          <Container>
            <Button
              className="cancel"
              onClick={ () => {
                setIsModalShowing(false)
                if (setActionState) setActionState(undefined)
              } }
            >
              Cancel
            </Button>
            <Button
              color="blue"
              onClick={ () => {
                setIsModalShowing(false)
                if (setActionState) setActionState(undefined)
                updateCoderState(false)
                if (onAction) onAction(actionState)
              } }
            >
              Continue
            </Button>
          </Container>
        </div>
      </Modal>
    </If>
  )
}

const mapStateToProps = (state: any): any => ({
  hasFormUpdates: state.coder.hasFormUpdates
})

export default connect(mapStateToProps, {
  updateCoderState: updateCoderStateImport
})(CoderEncounterModal)
