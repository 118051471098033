import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Container } from 'semantic-ui-react'

import { loadVersion } from '../../action/version'

import './index.css'

export class Version extends Component {

  componentDidMount() {
    this.props.loadVersion()
  }

  render() {
    return (
      <Container>
        <table className="versionTable ui celled table">
          <tbody>
            <tr>
              <td>Name</td>
              <td>{ this.props.name }</td>
            </tr>
            <tr>
              <td>Project Version</td>
              <td>{ this.props.projectVersion }</td>
            </tr>
            <tr>
              <td>Environment Version (use to promote deploys)</td>
              <td>{ this.props.envVersion }</td>
            </tr>
            <tr>
              <td>Build Number</td>
              <td>{ this.props.buildNumber }</td>
            </tr>
            <tr>
              <td>Date</td>
              <td>{ this.props.date }</td>
            </tr>
            <tr>
              <td>Host</td>
              <td>{ this.props.host }</td>
            </tr>
          </tbody>
        </table>
      </Container>
    )
  }
}

export default connect(
  state => ({
    name: state.version.name,
    projectVersion: state.version.projectVersion,
    envVersion: state.version.envVersion,
    buildNumber: state.version.buildNumber,
    date: state.version.date,
    host: state.version.host
  }),
  { loadVersion }
)(Version)
