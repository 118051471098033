import React, { Component } from "react";
import { connect } from "react-redux";
import { Accordion, Grid } from "semantic-ui-react";
import ReactMarkdown from "react-markdown";
import { CONDITION } from "../../../constant";
import { conditionClaimSource, allowConditionDismiss }  from "../../common/conditionUtils";
import ConditionAddressedDate from "../common/conditionAddressedDate";
import ConditionDismissButton from "../common/conditionDismissButton";
import ConditionReasons from "../common/conditionReasons";
import ClinicalAdminConditionHeaderTitle from "./clinicalAdminConditionHeaderTitle";

/**
 * Provides a component that renders a Clinical Admin condition view
 *
 * @param condition Condition entity
 * @param mode View mode. Options: "smart", null. Default: null
 * @param active If true, display condition expanded. Default: false
 * @param hccChild True if condition is a member of an HCC display group
 * @param hccAddressed True if condition is a member of an HCC display group
 *        and the group is marked as addressed
 * @param displayIndex Condition display index in its display group
 * @param onClickTitle Callback for condition accordion title click
 *        Arguments: condition
 * @param onConditionAction Callback for condition action
 *        Arguments action, condition
 */
class ClinicalAdminCondition extends Component {

  constructor(props) {
    super(props);
    this.onClickTitle = this.onClickTitle.bind(this);
    this.onClickDismiss = this.onClickDismiss.bind(this);
    this.state = {
      active: this.props.active === true ? true : false
    };
  }

  onClickTitle() {
    if ( this.props.hccChild === true ) {
      return; // no-op
    } else {
      const activate = CONDITION.ACTION.ACTIVATE;
      const deactivate = CONDITION.ACTION.DEACTIVATE;
      const read = CONDITION.ACTION.READ;
      if ( this.state.active === true ) {
        // deactivate condition
        this.setState({ active: false }, () => {
          this.props.onConditionAction(deactivate, this.props.condition);
        });
      } else {
        // activate condition
        this.setState({ active: true },() => {
          this.props.onConditionAction(activate, this.props.condition);
          this.props.onConditionAction(read, this.props.condition);
        });
      }
    }
  }

  onClickDismiss() {
    const condition = this.props.condition;
    if ( condition.conditionCode === CONDITION.CODE.DISMISSED ) {
      this.props.onConditionAction(CONDITION.ACTION.MARK_OPEN, condition);
    } else {
      this.props.onConditionAction(CONDITION.ACTION.MARK_DISMISSED, condition);
    }
  }

  render() {
    const currentUser = this.props.currentUser;
    const condition = this.props.condition;
    const claimSource = conditionClaimSource(condition);
    const allowDismiss = allowConditionDismiss(condition, currentUser);
    const isActive = this.state.active;
    const clazz = this.props.displayIndex && this.props.displayIndex === 0
      ? "first-accordion" : "";

    return (
      <div className="condition">
        <Accordion.Title
          active={isActive}
          onClick={this.onClickTitle}
          index={condition.id}
          className={clazz}>
          <ClinicalAdminConditionHeaderTitle
            condition={condition}
            mode={this.props.mode}
            hccChild={this.props.hccChild}
            hccAddressed={this.props.hccAddressed}
            active={isActive}
            onConditionAction={this.props.onConditionAction} />
        </Accordion.Title>
        <Accordion.Content active={isActive}>
          <Grid className="internal-condition" columns={1} divided stackable>
            <Grid.Row className="first-row">
              <Grid.Column>
                <div>
                  <ConditionReasons condition={condition} />
                  <ReactMarkdown children={condition.content} linkTarget="_blank" />
                  <ConditionAddressedDate condition={condition} />
                </div>
              </Grid.Column>
            </Grid.Row>
            { allowDismiss &&(
              <Grid.Row>
                <Grid.Column textAlign="center">
                  <ConditionDismissButton condition={condition}
                    onClick={this.onClickDismiss} />
                </Grid.Column>
              </Grid.Row>
            )}
            { claimSource && (
              <Grid.Row>
                <Grid.Column textAlign="center">
                  <span className="footnote">
                    Initial source of condition: {claimSource}
                  </span>
                </Grid.Column>
              </Grid.Row>
            )}
          </Grid>
        </Accordion.Content>
      </div>
    );
  }
}

export default connect(
  state => ({
    currentUser: state.user
  }), {}
)(ClinicalAdminCondition);
