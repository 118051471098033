import { api } from '../../action/api'
import { API_URL, PAGES } from '../../constant'

const resetEncounter = async (encounterId: string) => {

  await api.put(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    API_URL.RESET_ENCOUNTER(encounterId), {}
  )
  window.location.href = `${PAGES.ENCOUNTER_DETAIL}${encounterId}`
}

export default resetEncounter
