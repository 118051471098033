import React, { Component } from 'react'
import { Card, Container, Form, Grid, Icon, Message, Segment } from "semantic-ui-react";
import { API_URL } from "../../constant";
import { api } from "../../action/api";

/**
 * Provides a registration page for new users to set password, and update
 * first name and last name
 * @param user Unregistered user entity
 * @param token Registration token
 * @param onSave Callback function for save.
 *        Arguments: user entity
 */
class UserRegistrationForm extends Component {

  constructor(props) {
    super(props);

    this.onClickRegister = this.onClickRegister.bind(this);

    this.state = {
      user: {
        id: this.props.user.id,
        email: this.props.user.email,
        username: this.props.user.username,
        firstName: this.props.user.firstName,
        lastName: this.props.user.lastName,
        password: "",
        passwordConfirm: ""
      },
      errors: null
    }
  }

  onClickRegister() {
    const user = this.state.user;
    const errors = this.validate(user);
    if ( errors.length === 0 ) {
      const url = API_URL.UNREGISTERED_USER_VALIDATE(this.props.token);
      api.put(url, user).then(({ data }) => {
        if ( data.valid === true ) {
          this.setState({user: user, errors: null}, () => {
            this.props.onSave(user);
          })
        } else {
          this.setState({user: user, errors: data.errors})
        }
      });
    } else {
      this.setState({user: user, errors: errors});
    }
  }

  validate(user) {
    const errors = [];
    if ( !user.firstName || user.firstName.length === 0 ) {
      errors.push(
        'First Name is required.'
      );
    }
    if ( !user.lastName || user.lastName.length === 0 ) {
      errors.push(
        'Last Name is required.'
      );
    }
    if ( !user.password || !user.passwordConfirm ||
      user.password.length < 7 || user.passwordConfirm.length < 7 ||
      user.password !== user.passwordConfirm ) {
      errors.push(
        'Password and Password Confirmation must meet password requirements'
      );
    }
    return errors;
  }

  isValid(user) {
    return this.validate(user).length === 0;
  }

  onChangeValue = (e, { name, value }) => {
    const user = this.state.user;
    user[name] = value;
    this.setState({ user: user });
  };

  render() {
    const user = this.state.user;
    const savable = this.isValid(user);
    const errors = this.state.errors ? this.state.errors : null;
    return (
      <div>
        <Container>
          <Segment>
            <div className="bold">Welcome to the Curation Health Platform!</div>
            <div className="m-1-top">
              Please verify your first and last name and create a password.
            </div>
          </Segment>
          {errors && (
            <Message error header='Registration Error' list={errors} />
          )}
          <Form>
            <Grid>
              <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={16}>
                  <Form.Group widths="equal">
                    <Form.Input
                      required
                      label='First Name'
                      placeholder='First Name'
                      name='firstName'
                      value={user.firstName}
                      onChange={this.onChangeValue}
                    />
                    <Form.Input
                      required
                      label='Last Name'
                      placeholder='Last Name'
                      name='lastName'
                      value={user.lastName}
                      onChange={this.onChangeValue}
                    />
                  </Form.Group>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={16}>
                  <Grid columns={2} divided relaxed="very" stackable>
                    <Grid.Column>
                      <Form.Input required
                        type='password'
                        label='Password'
                        placeholder='Password'
                        name='password'
                        value={user.password}
                        onChange={this.onChangeValue}
                      />
                      <Form.Input required
                        type='password'
                        label='Confirm Password'
                        placeholder='Confirm Password'
                        name='passwordConfirm'
                        value={user.passwordConfirm}
                        onChange={this.onChangeValue}
                      />
                      {savable && (
                        <div className="m-1-top text-align-center">
                          <Form.Button primary
                            onClick={this.onClickRegister}>Register</Form.Button>
                        </div>
                      )}
                      {!savable && (
                        <div className="m-1-top text-align-center">
                          <span onClick={this.onClickRegister}>
                            <Form.Button disabled>Register</Form.Button>
                          </span>
                        </div>
                      )}
                    </Grid.Column>
                    <Grid.Column>
                      <Card>
                        <Card.Content>
                          <Card.Header>
                            <span>
                              <Icon name="check circle outline" /> Password Requirements
                            </span>
                          </Card.Header>
                        </Card.Content>
                        <Card.Content>
                          <ul>
                            <li>Your password must be at least seven characters long</li>
                            <li>It must contain 1 each of the following:
                              <ul>
                                <li>Upper case letter</li>
                                <li>Lower case letter</li>
                                <li>Number</li>
                                <li>Special character
                                  <div>Examples: ! # $ % ^ & *</div>
                                </li>
                              </ul>
                            </li>
                            <li>Passwords must not be based on something that can be easily guessed or obtained using
                            personal information (e.g. names, favorite sports team, etc.)</li>
                            <li>Passwords must not use four or more sequential or repetitive characters</li>
                            <li>Passwords that have been compromised in a data breach must not be used</li>
                          </ul>
                        </Card.Content>
                      </Card>
                    </Grid.Column>
                  </Grid>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={16}></Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        </Container>
      </div>
    );
  }
}

export default UserRegistrationForm;
