import { API_URL, LOCK } from '../../constant'
import { api } from '../../action/api'
import { User, EncounterLock } from '../authenticated/types'

/** *****************************************************************************
 * Provides helpers for encounter locking
 */

/**
 * @return true if lock is active
 */

export function locked(lock: EncounterLock | undefined): boolean {
  return !!lock && lock.statusCode === LOCK.STATUS.ACTIVE
}
/**
 * @return true if current user owns the lock
 */
export function lockOwner(
  lock: EncounterLock | undefined | null,
  currentUser: User
): boolean {
  return !!lock && !!currentUser && lock.createdById === currentUser.userId
}

/**
 * Checks to see if lock is active and the current user owns the lock
 * @return true if current user has edit permission
 */
export function canEdit(
  lock: EncounterLock | undefined | null,
  currentUser: User
): boolean {
  return !!locked(lock) && lockOwner(lock, currentUser)
}

/**
 * Unlock encounter
 * @param encounterId
 * @param onSuccess Function to call after API response. Arguments: API response
 */
export function unlockEncounter(
  encounterId: string,
  onSuccess?: (data: any) => void
) {
  const url = API_URL.UNLOCK_ENCOUNTER(encounterId)
  api.put(url, {}).then(({ data }) => {
    if (onSuccess) {
      onSuccess(data)
    }
  })
}

/**
 * Lock encounter. Attempts to lock the encounter with the current user. The
 * attempt is successful is the lock API returns an active lock that the current
 * user owns. Otherwise, the attempt is considered a failure
 * @param encounterId
 * @param currentUser
 * @param onSuccess Function to call on lock success. Arguments: EncounterLock entity
 * @param onFailure Function to call on lock failure. Arguments: EncounterLock entity
 */
export function lockEncounter(
  encounterId: string,
  currentUser: User,
  onSuccess?: (data: any) => void,
  onFailure?: (data: any) => void
) {
  const url = API_URL.LOCK_ENCOUNTER_WITH_ROLE(currentUser.type, encounterId)
  api.put(url, {}).then(({ data }: { data: EncounterLock }) => {
    if (locked(data) && lockOwner(data, currentUser)) {
      if (onSuccess) onSuccess(data)
    } else if (onFailure) onFailure(data)
  })
}

/**
 * Toggle the specified lock
 *
 * @param lock EncounterLock entity
 * @param encounterId
 * @param currentUser
 * @param onSuccess Function to call on lock success. Arguments: EncounterLock entity
 * @param onFailure Function to call on lock failure. Arguments: EncounterLock entity
 */
export function toggleLock(
  lock: EncounterLock,
  encounterId: string,
  currentUser: User,
  onSuccess: (data: any) => void,
  onFailure: (data: any) => void
) {
  if (locked(lock)) {
    unlockEncounter(encounterId, onSuccess)
  } else {
    lockEncounter(encounterId, currentUser, onSuccess, onFailure)
  }
}
