import { push } from 'react-router-redux'
import * as Sentry from '@sentry/browser'
import { Action, Dispatch } from 'redux'
import { Filters, StoreUser, AutoLoginParams } from '../../types'
import {
  UPDATE_LOGIN,
  CLEAR_ALL_STATE,
  UPDATE_APPOINTMENT_FILTER,
  UPDATE_WORKLIST_FILTER,
  UPDATE_PATIENT_LIST_FILTER
} from '../reducer/user'
import { API_URL, AUTH_STATUS, DISPLAY_MODE, MESSAGE, PAGES } from '../constant'
import {
  emptyWorklistFilters,
  initWorklistFilters,
  updateWorklistFilters
} from '../component/common/worklistFilterUtils'
import {
  emptyAppointmentlistFilters,
  initAppointmentlistFilters,
  updateAppointmentlistFilters
} from '../component/common/appointmentlistFilterUtils'
import {
  allowPatientTabSchedulingCdi
} from '../component/common/currentUserUtils'
import {
  isCdi
} from '../component/common/roleUtils'
import { api } from './api'
import { MfaRequest, PatientListFilter, User, isMfaRequest, isUser } from '../component/authenticated/types'
import {
  emptyPatientListFilters,
  initPatientListFilters,
  updatePatientListFiltersUtil
} from '../component/common/patientListFilterUtils'
import { isNil, isString } from 'lodash'

/**
 * User actions
 */
export function loginSuccess(loginUser: User) {
  Sentry.configureScope(scope => {
    scope.setUser({ id: loginUser.id })
  })
  return {
    type: UPDATE_LOGIN,
    payload: {
      valid: true,
      authType: null,
      qrCodeUuid: null,
      authStatus: AUTH_STATUS.LOGIN_SUCCESS,
      authMessage: null,
      authAttempts: 0,
      tenantId: loginUser.tenantId,
      userId: loginUser.id,
      username: loginUser.username,
      password: null,
      alias: loginUser.alias,
      type: loginUser.roleName,
      roles: loginUser.roles,
      tenantProperties: loginUser.tenantProperties,
      userProperties: loginUser.userProperties,
      worklistFilters: initWorklistFilters(loginUser),
      appointmentlistFilters: initAppointmentlistFilters(loginUser),
      patientListFilters: initPatientListFilters(loginUser)
    }
  }
}

export function loginMfa(email: string, username: string, password: string, authType: string, qrCodeUuid: string) {
  return {
    type: UPDATE_LOGIN,
    payload: {
      valid: true,
      authType: authType,
      qrCodeUuid: qrCodeUuid,
      authStatus: AUTH_STATUS.MFA_REQUIRED,
      authMessage: null,
      authAttempts: 0,
      tenantId: null,
      userId: null,
      email: email,
      username: username,
      password: password,
      alias: null,
      type: null,
      roles: [],
      tenantProperties: [],
      userProperties: [],
      worklistFilters: emptyWorklistFilters(),
      appointmentlistFilters: emptyAppointmentlistFilters(),
      patientListFilters: emptyPatientListFilters()
    }
  }
}

// Based on the HTTP error data, figure out the appropriate error message
function exactLoginError(errorData: string) {
  if (isString(errorData) && errorData.includes('locked')) {
    return MESSAGE.MAX_LOGIN
  }
  return MESSAGE.AUTHENTICATE 
}

export function loginFailure(authAttempts: number, errorData: string) {
  return {
    type: UPDATE_LOGIN,
    payload: {
      valid: false,
      authType: null,
      qrCodeUuid: null,
      authStatus: AUTH_STATUS.LOGIN_FAILURE,
      authMessage: exactLoginError(errorData),
      authAttempts: authAttempts + 1,
      tenantId: null,
      userId: null,
      username: null,
      password: null,
      alias: null,
      type: null,
      roles: [],
      tenantProperties: [],
      userProperties: [],
      worklistFilters: emptyWorklistFilters(),
      appointmentlistFilters: emptyAppointmentlistFilters(),
      patientListFilters: emptyPatientListFilters()
    }
  }
}

export function mfaFailure(email: string, username: string, password: string, authAttempts: number, authType: string, qrCodeUuid: string, errorData: string | null) {
  return {
    type: UPDATE_LOGIN,
    payload: {
      valid: false,
      authType: authType,
      qrCodeUuid: qrCodeUuid,
      authStatus: AUTH_STATUS.MFA_REQUIRED,
      authMessage: (isString(errorData) && errorData.includes('locked')) ? MESSAGE.MAX_LOGIN : MESSAGE.MFA_ERROR,
      authAttempts: authAttempts + 1,
      tenantId: null,
      userId: null,
      email: email,
      username: username,
      password: password,
      alias: null,
      type: null,
      roles: [],
      tenantProperties: [],
      userProperties: [],
      worklistFilters: emptyWorklistFilters(),
      appointmentlistFilters: emptyAppointmentlistFilters(),
      patientListFilters: emptyPatientListFilters()
    }
  }
}

export function setUserRole(storeUser: StoreUser, roleUpdate: string) {
  return {
    type: UPDATE_LOGIN,
    payload: {
      valid: true,
      authStatus: AUTH_STATUS.LOGIN_SUCCESS,
      authMessage: null,
      authAttempts: 0,
      tenantId: storeUser.tenantId,
      userId: storeUser.userId,
      username: storeUser.username,
      password: null,
      alias: storeUser.alias,
      type: roleUpdate,
      roles: storeUser.roles,
      tenantProperties: storeUser.tenantProperties,
      userProperties: storeUser.userProperties,
      worklistFilters: initWorklistFilters(storeUser),
      appointmentlistFilters: initAppointmentlistFilters(storeUser),
      patientListFilters: initPatientListFilters(storeUser)
    }
  }
}

function setUserWorklistFilters(
  storeUser: StoreUser,
  worklistFilters: Filters
) {
  return {
    type: UPDATE_WORKLIST_FILTER,
    payload: {
      worklistFilters: updateWorklistFilters(storeUser, worklistFilters)
    }
  }
}

export function setUserPatientListFilters(
  storeUser: StoreUser,
  patientListFilters: PatientListFilter
) {
  return {
    type: UPDATE_PATIENT_LIST_FILTER,
    payload: {
      patientListFilters: updatePatientListFiltersUtil(storeUser, patientListFilters)
    }
  }
}

function setUserAppointmentlistFilters(user: User, apptFilters: Filters) {
  return {
    type: UPDATE_APPOINTMENT_FILTER,
    payload: {
      appointmentlistFilters: updateAppointmentlistFilters(user, apptFilters)
    }
  }
}

export function switchUserRole(storeUser: StoreUser, roleUpdate: string) {
  return (dispatch: Dispatch<Action>) => {
    dispatch(setUserRole(storeUser, roleUpdate))
    dispatch(push(PAGES.HOME))
  }
}

export function updateUserAppointmentlistFilters(
  user: User,
  appointmentlistFilters: Filters
) {
  return (dispatch: Dispatch<Action>) => {
    dispatch(setUserAppointmentlistFilters(user, appointmentlistFilters))
  }
}

export function updateUserWorklistFilters(
  storeUser: StoreUser,
  worklistFilters: Filters
) {
  return (dispatch: Dispatch<Action>) => {
    dispatch(setUserWorklistFilters(storeUser, worklistFilters))
  }
}

export function updateUserPatientListFilters(
  storeUser: StoreUser,
  filters: PatientListFilter
) {
  return (dispatch: Dispatch<Action>) => {
    dispatch(setUserPatientListFilters(storeUser, filters))
  }
}

export const logout = async (
  message: string,
  dispatch: any,
  callback?: () => void
) => {
  // This calls across multiple reducers
  Sentry.configureScope(scope => {
    scope.setUser({})
  })
  await api.get(API_URL.LOGOUT)
  dispatch({ type: CLEAR_ALL_STATE })
  dispatch({ type: UPDATE_LOGIN, payload: { authMessage: message } })
  if (callback) {
    callback()
  }
}

export const withCurrentUser = (
  currentUser: User,
  encounter: string | undefined,
  dispatch: any
) => {
  dispatch(loginSuccess(currentUser))
  if (encounter) {
    dispatch(push(PAGES.ENCOUNTER_DETAIL + encounter))
  } else {
    if ( allowPatientTabSchedulingCdi(currentUser) && isCdi(currentUser.roles) ) {
      dispatch(push(PAGES.PATIENT_LIST))
    } else {
      dispatch(push(PAGES.HOME))
    }
  }
}

/**
 * Executes login. The login API response may be the current user or a
 * directive to negotiate MFA
 */
export const login = (
  username: string,
  password: string,
  recaptchaToken: string,
  authAttempts: number,
  params: AutoLoginParams,
  dispatch: any
) => {
  api
    .postUnsafe(API_URL.LOGIN, { username, password, recaptchaToken })
    .then(({ data }: { data: User | MfaRequest }) => {
      if ( isMfaRequest(data) ) {
        // MFA required, delegate to MFA negotiation
        const email = data.mfaEmail
        const authType = data.mfaType
        const qrCodeUuid = data.qrCodeUuid
        dispatch(loginMfa(email, username, password, authType, qrCodeUuid))
      } else if ( isUser(data) ) {
        // user successfully authenticated, no MFA required
        dispatch(loginSuccess(<User>data))
        if (params && params.encounter) {
          dispatch(push(`${PAGES.ENCOUNTER_DETAIL}${params.encounter}`))
        } else {
          if ( allowPatientTabSchedulingCdi(data) && isCdi(data.roles) ) {
            dispatch(push(PAGES.PATIENT_LIST))
          } else {
            dispatch(push(PAGES.HOME))
          }
        }
      } else {
        // we do not know what is happening
        dispatch(loginFailure(authAttempts, ''))
      }
    })
    .catch((error) => {
      const {response} = error
      const data = (!isNil(response) && response.hasOwnProperty('data')) ? response.data : ''
      dispatch(loginFailure(authAttempts, data))
    })
}

export const mfaLogin = (
  mfaCode: string,
  email: string,
  username: string,
  password: string,
  rememberMe: string,
  authAttempts: number,
  params: AutoLoginParams,
  dispatch: any
) => {
  api
    .postUnsafe(API_URL.MFA_LOGIN, { username, password, mfaCode, rememberMe })
    .then(({ data }: { data: User }) => {
      dispatch(loginSuccess(data))
      if (params && params.encounter) {
        dispatch(push(`${PAGES.ENCOUNTER_DETAIL}${params.encounter}`))
      } else {
        if ( allowPatientTabSchedulingCdi(data) && isCdi(data.roles) ) {
          dispatch(push(PAGES.PATIENT_LIST))
        } else {
          dispatch(push(PAGES.HOME))
        }
      }
    })
    .catch((error) => {
      const {response} = error
      dispatch(mfaFailure(email, username, password, authAttempts, '', '', response.data))
    })
}

export const mfaTotpLogin = (
  mfaCode: string,
  email: string,
  username: string,
  password: string,
  rememberMe: string,
  authAttempts: number,
  authType: string,
  qrCodeUuid: string,
  params: AutoLoginParams,
  dispatch: any
) => {
  api
    .postUnsafe(API_URL.MFA_TOTP_LOGIN, { username, password, mfaCode, rememberMe })
    .then(({ data }: { data: User }) => {
      dispatch(loginSuccess(data))
      if (params && params.encounter) {
        dispatch(push(`${PAGES.ENCOUNTER_DETAIL}${params.encounter}`))
      } else {
        if ( allowPatientTabSchedulingCdi(data) && isCdi(data.roles) ) {
          dispatch(push(PAGES.PATIENT_LIST))
        } else {
          dispatch(push(PAGES.HOME))
        }
      }
    })
    .catch((error) => {
      const {response} = error
      dispatch(mfaFailure(email, username, password, authAttempts, authType, qrCodeUuid, response.data))
    })
}

export const mfaQrTotpLogin = (
  mfaCode: string,
  email: string,
  username: string,
  password: string,
  rememberMe: string,
  authAttempts: number,
  authType: string,
  qrCodeUuid: string,
  params: AutoLoginParams,
  dispatch: any
) => {
  api
    .postUnsafe(API_URL.MFA_TOTP_QR_LOGIN, { username, password, mfaCode, rememberMe })
    .then(({ data }: { data: User }) => {
      dispatch(loginSuccess(data))
      if (params && params.encounter) {
        dispatch(push(`${PAGES.ENCOUNTER_DETAIL}${params.encounter}`))
      } else {
        if ( allowPatientTabSchedulingCdi(data) && isCdi(data.roles) ) {
          dispatch(push(PAGES.PATIENT_LIST))
        } else {
          dispatch(push(PAGES.HOME))
        }
      }
    })
    .catch((error) => {
      const {response} = error
      dispatch(mfaFailure(email, username, password, authAttempts, authType, qrCodeUuid, response.data))
    })
}

export function autoLogin(params: AutoLoginParams) {
  return (dispatch: Dispatch<Action>) =>
    api.get(API_URL.USERS_CURRENT).then(({ data }) => {
      dispatch(loginSuccess(data as User))
      if (params.encounter) {
        let q = ''
        let mode = DISPLAY_MODE.SMART
        if (params.embedded && params.embedded === 'true') {
          mode = DISPLAY_MODE.EMBEDDED
        }
        if (params.conditions) {
          q = `/conditions/${params.conditions}`
        }
        if (mode === DISPLAY_MODE.EMBEDDED) {
          dispatch(
            push(`${PAGES.EMBEDDED_ENCOUNTER_DETAIL}${params.encounter}${q}`)
          )
        } else {
          dispatch(
            push(`${PAGES.SMART_ENCOUNTER_DETAIL}${params.encounter}${q}`)
          )
        }
      } else if (params.scope && params.scope === 'empty') {
        dispatch(push(PAGES.EMPTY_SCOPE))
      } else {
        dispatch(push(PAGES.HOME))
      }
    })
}

export function initiateResetPassword(callback: (data: unknown) => void) {
  return () =>
    api.get(API_URL.PASSWORD_RESET).then(({ data }) => {
      callback(data)
    })
}
// is this right for callback??

export function doResetPassword(
  passwordChange: string,
  callback: (data: unknown) => void
) {
  return () =>
    api.post(API_URL.PASSWORD_RESET, passwordChange).then(({ data }) => {
      callback(data)
    })
}

export function updateUser(user: User, callback: (data: unknown) => void) {
  const url = API_URL.UPDATE_USER(user.userId)
  return () =>
    api.post(url, user).then(({ data }) => {
      callback(data)
    })
}

export function goHome() {
  return (dispatch: Dispatch<Action>) => {
    dispatch(push(PAGES.HOME))
  }
}
