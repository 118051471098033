import React from 'react'
import { connect } from 'react-redux'
import { Divider } from 'semantic-ui-react'
// Local
import { ENCOUNTER } from '../../../constant'
import * as lockUtils from '../../common/lockUtils'
import EncounterAppointment from '../common/encounterAppointment'
import EncounterCancelButton from '../common/EncounterCancelButton'
import EncounterCoderQa from '../common/encounterCoderQa'
import EncounterPatient from '../common/encounterPatient'
import EncounterPractitioner from '../common/encounterPractitioner'
import EncounterProfile from '../common/encounterProfile'
import EncounterReason from '../common/encounterReason'
import EncounterSource from '../common/encounterSource'
import EncounterReviewer from '../common/encounterReviewer'
import EncounterStatus from '../common/encounterStatus'
import PatientRegion from '../common/patientRegion'
import CoderEncounterTypeEdit from './CoderEncounterTypeEdit'
import CoderEncounterTypeRead from './CoderEncounterTypeRead'
import VbcProgram from '../common/vbcProgram'
import {
  Encounter,
  EncounterDetail,
  ObjectValues,
  User
} from '../../authenticated/types'
import If from '../../common/if'
import { Patient } from '../../patient/patient'

/**
 * Provides a component that displays the left column of the Coder encounter
 * detail
 * @param encounterDetail Encounter detail entity
 * @param onAction Callback for action. Arguments: action
 */
type Props = {
  onAction: (
    action: ObjectValues<typeof ENCOUNTER.ACTION>,
    encounter: Encounter | undefined
  ) => void
  encounterDetail: EncounterDetail
}

type PropsFromRedux = Omit<Props, 'currentUser'> & {
  currentUser: User
}

function CoderEncounterLeft(props: PropsFromRedux): JSX.Element {
  const { onAction, encounterDetail, currentUser } = props

  const { encounter } = encounterDetail
  const { conditions } = encounterDetail
  const isEditMode = lockUtils.canEdit(encounter && encounter.lock, currentUser)
  const isReadMode = !isEditMode

  const onClickCancel = () => {
    onAction(ENCOUNTER.ACTION.STATUS_TOGGLE, encounter)
  }

  return (
    <div>
      <Divider className="green-border" />
      <EncounterCoderQa
        mode="left"
        review={ encounter && encounter.review }
        conditions={ conditions }
      />
      <EncounterProfile mode="left" encounter={ encounter } />
      <EncounterPatient mode="left" encounter={ encounter } />
      <If isTrue={ !!encounter && !!encounter.patient }>
        <PatientRegion
          mode="left"
          patient={ encounter && (encounter.patient as Patient) }
        />
      </If>
      <EncounterSource mode="left" encounter={ encounter } />
      <EncounterReason mode="left" encounter={ encounter } />
      <EncounterAppointment mode="left" encounter={ encounter } />
      <EncounterStatus mode="left" encounter={ encounter } />
      <EncounterPractitioner mode="left" encounter={ encounter } />
      <EncounterReviewer mode="left" review={ encounter && encounter.review } />
      <If isTrue={ !!encounter && !!encounter.patient }>
        <VbcProgram
          mode="left"
          patient={ encounter && (encounter.patient as Patient) }
        />
      </If>
      <If isTrue={ isReadMode && !!encounter }>
        <CoderEncounterTypeRead
          mode="left"
          encounter={ encounter as Encounter }
        />
      </If>
      <If isTrue={ isEditMode && !!encounter }>
        <div>
          <CoderEncounterTypeEdit
            mode="left"
            encounter={ encounter as Encounter }
            onAction={ onAction }
          />
          <EncounterCancelButton
            mode="left"
            encounter={ encounter as Encounter }
            onClick={ onClickCancel }
          />
        </div>
      </If>
    </div>
  )
}

const mapStateToProps = (state: any): any => ({
  currentUser: state.user
})

export default connect(mapStateToProps)(CoderEncounterLeft)
