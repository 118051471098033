import React, { useEffect, useState } from 'react'
import {
  Accordion,
  Button,
  Container,
  Icon,
  Modal,
  Grid
} from 'semantic-ui-react'
import { connect } from 'react-redux'
// Local
import { API_URL, ENCOUNTER, NOTE, PAGES, REVIEW } from '../../../constant'
import { api } from '../../../action/api'
import * as lockUtils from '../../common/lockUtils'
import { spliceCondition } from '../../common/conditionUtils'
import ConditionHeader from '../common/conditionHeader'
import EncounterNotes from '../common/EncounterNotes'
import PreviousEncounters from '../common/previousEncounters'
import CoderEncounterHeader from './CoderEncounterHeader'
import CoderEncounterLeft from './CoderEncounterLeft'
import CoderEncounterConditions from './CoderEncounterConditions'
import CoderEncounterMessage from './CoderEncounterMessage'
import {
  Encounter,
  EncounterConditionDisplay,
  EncounterDetail,
  EncounterNoteRow,
  ObjectValues,
  User
} from '../../authenticated/types'
import If from '../../common/if'

// type Props = {
//   onEncounterNav: (encounterId: string) => void
//   encounterId: string
// }

// type PropsFromRedux = Omit<Props, 'currentUser'> & {
//   currentUser: User
//   hasFormUpdates: boolean
// }

/**
 * Provides the top-level Coder encounter detail component
 *
 * This component is holding state for all of the sub-components in the Coder
 * encounter view. Actions taken on encounter and condition entities should
 * bubble up to this component, which will set the updated encounter detail
 * into state, triggering a render of sub-components
 *
 * @param encounterId Encounter ID
 * @param onEncounterNav Callback for encounter navigation request
 *        Arguments: Encounter ID
 */

function CoderEncounter(props: any): JSX.Element | null {
  const { onEncounterNav, encounterId, currentUser } = props

  const [ encounterIDState, setEncounterIDState ] = useState<string>(
    encounterId as string
  )
  const [ encounterDetail, setEncounterDetail ] =
    useState<EncounterDetail | null>(null)
  const [ isLockFailureModalOpen, setIsLockFailureModalOpen ] =
    useState<boolean>(false)
  const [ activeConditionId, setActiveConditionId ] = useState<string | null>(
    null
  )
  const [ isFormSaved, setIsFormSaved ] = useState<boolean>(false)

  const fetchEncounter = (id: string) => {
    const url = `${API_URL.GET_ENCOUNTER_DETAIL_BY_ID}${id}`
    api.get(url).then(({ data }) => {
      setEncounterIDState(id)
      setEncounterDetail(data as EncounterDetail)
    })
  }

  const toggleEncounterStatus = () => {
    if (encounterDetail && encounterDetail.encounter !== undefined) {
      const status: ObjectValues<typeof ENCOUNTER.STATUS> =
        encounterDetail.encounter.status === ENCOUNTER.STATUS.CANCELLED
          ? ENCOUNTER.STATUS.PLANNED
          : ENCOUNTER.STATUS.CANCELLED
      const url = `${API_URL.UPDATE_ENCOUNTER_STATUS_WITH_ROLE(currentUser.type)}/${encounterDetail.encounter.id}/${status}`
      api.put(url, {}).then(() => {
        setEncounterDetail({
          ...encounterDetail,
          encounter: {
            ...encounterDetail.encounter,
            status
          }
        })
      })
    }
  }

  const toggleEncounterType = (encounter: Encounter) => {
    if (encounterDetail && encounterDetail.encounter) {
      const url = `${API_URL.UPDATE_ENCOUNTER_TYPE}/${encounterDetail.encounter.id}/${encounter.encounterTypeCode}`
      api.put(url, {}).then(() => {
        setEncounterDetail({
          ...encounterDetail,
          encounterTypeCode: encounter.encounterTypeCode
        })
      })
    }
  }

  const onSubmitEncounterNote = async (content: string): Promise<void> => {
    if (
      encounterDetail &&
      encounterDetail.encounter &&
      encounterDetail.encounter.id
    ) {
      const { id } = encounterDetail.encounter
      const url = API_URL.CREATE_ENCOUNTER_NOTE
      const body = {
        encounterId: encounterDetail.encounter.id,
        noteBody: content,
        noteReasonCode: NOTE.REASON.OPEN
      }
      const response = await api.post(url, body)
      const { data } = response as { data: EncounterNoteRow }
      await api.get(API_URL.GET_ENCOUNTER_NOTES_WITH_ROLE(id, currentUser.type))
      const existingNotes = encounterDetail && encounterDetail.notes ? encounterDetail.notes : []
      const newNotes = [ data ] || []
      const revisedNotes = [
        ...newNotes,
        ...existingNotes
      ]
      setEncounterDetail({
        ...encounterDetail,
        notes: revisedNotes
      })
    }
  }

  const onLockFailureModalClose = () => {
    if (
      encounterDetail &&
      encounterDetail.encounter &&
      encounterDetail.encounter.id
    ) {
      setIsLockFailureModalOpen(false)
      setEncounterDetail(null)
      fetchEncounter(encounterDetail.encounter.id)
    }
  }

  useEffect(() => {
    fetchEncounter(encounterIDState)
  }, [])

  const toggleLock = () => {
    if (
      encounterDetail &&
      encounterDetail.encounter &&
      encounterDetail.encounter.lock !== undefined
    ) {
      const onSuccess = () => {
        setEncounterDetail(null)
        fetchEncounter(encounterIDState)
      }
      const onFailure = () => {
        setIsLockFailureModalOpen(true)
      }
      lockUtils.toggleLock(
        encounterDetail.encounter.lock,
        encounterIDState,
        currentUser as User,
        onSuccess,
        onFailure
      )
    }
  }

  const updateReviewQueryStatus = () => {
    if (
      encounterDetail &&
      encounterDetail.encounter &&
      encounterDetail.encounter.review
    ) {
      const queryOutstanding = REVIEW.QUERY_STATUS.REVIEW_QUERY_OUTSTANDING
      const queryReceived = REVIEW.QUERY_STATUS.REVIEW_QUERY_RESPONSE_RECEIVED
      const newStatus =
        encounterDetail.encounter.review.queryStatusCode === queryOutstanding
          ? queryReceived
          : queryOutstanding
      const url = `${API_URL.UPDATE_REVIEW_QUERY_STATUS}/${encounterDetail.encounter.review.id}/${newStatus}`
      api.put(url).then(({ data }) => {
        setEncounterDetail({
          ...encounterDetail,
          encounter: {
            ...encounterDetail.encounter,
            review: data
          }
        })
      })
    }
  }

  const updateReviewCode = (
    newReviewCode: ObjectValues<typeof REVIEW.CODE>
  ) => {
    if (encounterDetail && encounterDetail.encounter) {
      const revisedReview = {
        ...encounterDetail.encounter.review,
        reviewCode: newReviewCode
      }
      api.put(API_URL.UPDATE_REVIEW, revisedReview).then(() => {
        lockUtils.unlockEncounter(encounterIDState, () => {
          window.location.href = PAGES.HOME
        })
      })
    }
  }

  const onLeftAction = (
    action: ObjectValues<typeof ENCOUNTER.ACTION>,
    encounter: Encounter | undefined
  ) => {
    const { STATUS_TOGGLE, TYPE_TOGGLE } = ENCOUNTER.ACTION
    if (action === STATUS_TOGGLE) toggleEncounterStatus()
    if (action === TYPE_TOGGLE && !!encounter) toggleEncounterType(encounter)
  }

  const createConditionNote = (
    condition: EncounterConditionDisplay,
    content: string,
    reason: ObjectValues<typeof NOTE.REASON>
  ) => {
    if (
      encounterDetail &&
      encounterDetail.encounter &&
      encounterDetail.encounter.id
    ) {
      const { id } = encounterDetail.encounter
      const url = API_URL.CREATE_ENCOUNTER_NOTE
      const body = {
        encounterId: encounterIDState,
        encounterConditionId: condition.id,
        noteBody: content,
        noteReasonCode: reason || NOTE.REASON.OPEN
      }
      api.post(url, body).then(() => {
        api
          .get(API_URL.GET_ENCOUNTER_CONDITION_NOTES_WITH_ROLE(id, condition.id, currentUser.type))
          .then(({ data }) => {
            const conditions =
              encounterDetail.conditions &&
              encounterDetail.conditions.map((c: EncounterConditionDisplay) => {
                if (c.id === condition.id) {
                  return {
                    ...condition,
                    notes: data
                  }
                }
                return c
              })
            setActiveConditionId(condition.id)
            setEncounterDetail({
              ...encounterDetail,
              conditions
            })
          })
      })
    }
  }

  const onConditionNoteAction = (
    action: ObjectValues<typeof NOTE.ACTION>,
    condition: EncounterConditionDisplay,
    content: string,
    reason: ObjectValues<typeof NOTE.REASON>
  ) => {
    if (action === NOTE.ACTION.CREATE)
      createConditionNote(condition, content, reason)
  }

  const onEncounterNavAction = (id: string) => {
    onEncounterNav(id)
  }

  const onHeaderAction = (
    action: ObjectValues<typeof ENCOUNTER.ACTION>
  ): void => {
    if (action === ENCOUNTER.ACTION.LOCK_TOGGLE) toggleLock()
    if (action === ENCOUNTER.ACTION.FOLLOW_UP_TOGGLE) updateReviewQueryStatus()
    if (action === ENCOUNTER.ACTION.MARK_CODER_COMPLETE)
      updateReviewCode(REVIEW.CODE.COMPLETED)
    if (action === ENCOUNTER.ACTION.MARK_READY_FOR_CODER_QA)
      updateReviewCode(REVIEW.CODE.READY_FOR_CODER_QA)
    if (action === ENCOUNTER.ACTION.MARK_OPEN)
      updateReviewCode(REVIEW.CODE.READY_FOR_CODER)
  }

  const onConditionAction = (condition: EncounterConditionDisplay): void => {
    setActiveConditionId(condition.id)

    setEncounterDetail({
      ...encounterDetail,
      conditions: spliceCondition(
        condition,
        encounterDetail && encounterDetail.conditions
          ? encounterDetail.conditions
          : []
      )
    })

    setIsFormSaved(true)
  }

  const renderDetail = (detail: EncounterDetail) => {
    console.log('detail', detail)
    const { encounter } = detail
    const { notes } = detail
    const key = new Date().getTime()
    return (
      <div className="header-padding">
        <Container>
          <CoderEncounterHeader
            key={ `header-${key}` }
            encounter={ encounter }
            onAction={ onHeaderAction }
            currentUser={ currentUser }
          />
          <div className="ui attached segment no-margin">
            <div className="ui stackable grid">
              <If isTrue={ encounter !== undefined }>
                <CoderEncounterMessage
                  encounter={ encounter as Encounter }
                  currentUser={ currentUser }
                  mode="grid"
                />
              </If>
              <Grid.Row>
                <Grid.Column width={ 4 }>
                  <CoderEncounterLeft
                    key={ `left-${key}` }
                    encounterDetail={ detail }
                    onAction={ onLeftAction }
                    currentUser={ currentUser }
                  />
                </Grid.Column>
                <Grid.Column width={ 12 }>
                  <ConditionHeader title="Notes" displayIndex={ 0 } />
                  <Accordion fluid styled>
                    <Accordion.Content>
                      <EncounterNotes
                        key={ `encounter-notes-${notes ? notes.length : 0}` }
                        notes={ notes }
                        onSubmit={
                          (content: string) => onSubmitEncounterNote(content)
                        }
                      />
                    </Accordion.Content>
                  </Accordion>
                  <CoderEncounterConditions
                    key={ `encounter-conditions-${key}` }
                    activeConditionId={ activeConditionId || '' }
                    encounterDetail={ detail }
                    onConditionAction={ onConditionAction }
                    onConditionNoteAction={ onConditionNoteAction }
                    currentUser={ currentUser }
                    isFormSaved={ isFormSaved }
                  />
                  <ConditionHeader title="Previous Encounters" />
                  <Accordion fluid styled>
                    <PreviousEncounters
                      key={ encounter && encounter.id }
                      encounterId={ encounter && encounter.id }
                      subjectId={
                        encounter && encounter.patient && encounter.patient.id
                      }
                      onClickGoToPrevious={ onEncounterNavAction }
                    />
                  </Accordion>
                </Grid.Column>
              </Grid.Row>
            </div>
          </div>
        </Container>
        <Modal
          open={ isLockFailureModalOpen }
          onClose={ onLockFailureModalClose }
          basic
          size="small"
        >
          { /* <Header icon="lock" content="Encounter Lock Conflict" /> */ }
          <div className="lock Encounter Lock Conflict" />
          <Modal.Content>
            <h3>
              <div>Sorry, could not lock this encounter for you.</div>
              <div>
                Between opening the encounter and clicking the lock icon,
                another user has acquired a lock on this encounter.
              </div>
            </h3>
          </Modal.Content>
          <Modal.Actions>
            <Button color="green" onClick={ onLockFailureModalClose } inverted>
              <Icon name="checkmark" /> Ok
            </Button>
          </Modal.Actions>
        </Modal>
      </div>
    )
  }

  return encounterDetail ? renderDetail(encounterDetail) : null
}

const mapStateToProps = (state: any): any => ({
  currentUser: state.user
})

export default connect(mapStateToProps)(CoderEncounter)
