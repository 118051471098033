import React, { useEffect, useState } from 'react'
import { Button, Icon, Popup, Search } from 'semantic-ui-react'
import map from 'lodash/map'
// Local
import { API_URL } from '../../../constant'
import { api } from '../../../action/api'
import { searchGenerator } from '../../../action/search'
import { formatIcd10Code, rawIcd10Code } from '../../common/formatUtils'
import useDebounce from '../../../hooks/useDebounce'
import If from '../../common/if'

/**
 * Provides an ICD10 search
 *
 * Notes:
 *
 * This is a surprisingly tricky component.
 *
 * this.state.value is either a formatted ICD10 code or search input
 *
 * We format ICD10 codes for display with a decimal and store them as raw.
 * Example: Formatted: A01.01, Raw: A0101
 *
 * @param name Component name. Default: icd10
 * @param value Initial ICD10 value
 * @param placeholder Place holder override. Default: ICD10
 * @param onSelect Callback function for ICD10 code select
 *        Arguments: Object: { id, description }, matching the storage model,
 *        where id is the raw ICD10 code
 */

type OnSelectParams = {
  title: string
  description: string
}

type OnSelectCallbackParams = {
  id: string
  description: string
}

type Props = {
  name?: string
  value: string
  placeholder?: string
  setConditionDiagnosisCode?: (title: string) => void
  onSelect: (x: any, onSelectParams: OnSelectCallbackParams) => void
  hasSearchDescription?: boolean
  error?: string
}

function Icd10Search(props: Props): JSX.Element {
  const {
    name = 'icd10',
    value: valueProp,
    placeholder = 'ICD10',
    setConditionDiagnosisCode,
    onSelect,
    hasSearchDescription,
    error
  } = props

  const [ valueState, setValueState ] = useState<string>(
    valueProp ? formatIcd10Code(valueProp) : ''
  )
  const [ isValidState, setIsValidState ] = useState<boolean>(true)
  const [ isLoadingState, setIsLoadingState ] = useState<boolean>(false)
  const [ resultsState, setResultsState ] = useState<
    { title: string; description: string }[]
  >([])

  const onResultSelect = (e: any, { result }: { result: any }) => {
    setValueState(result.title as string)
    setIsValidState(true)

    if (setConditionDiagnosisCode) {
      setConditionDiagnosisCode(rawIcd10Code(result.title as string))
    }

    if (!setConditionDiagnosisCode && onSelect) {
      onSelect(null, {
        id: rawIcd10Code(result.title as string),
        description: result.description
      })
    }
  }

  const fetchIcd10Codes = (value: string) => {
    const icd10 = rawIcd10Code(value)
    const query = null
    const limit = 5
    const offset = 0
    const sort = null
    const queryOverride =
      hasSearchDescription === false
        ? `id:${icd10}%`
        : `id:${icd10}%,OR desc:${value}%`
    const params = searchGenerator(query, limit, offset, sort, queryOverride)
    const url = API_URL.ICD10_SEARCH
    setIsLoadingState(true)
    setValueState(value)
    setIsValidState(false)

    api.get(url, { params }).then(({ data }) => {
      const results = map(data, icd10Code => ({
        title: formatIcd10Code(icd10Code.id as string),
        description: icd10Code.description as string
      }))
      setResultsState(results)
      setIsLoadingState(false)
    })
  }

  const onClear = () => {
    setValueState('')
    onSelect(null, { id: '', description: '' })
  }

  const onBlur = () => {
    if (isValidState === false) {
      // handle case where input contains valid, but unselected, icd10 code
      const result: OnSelectParams | undefined = resultsState.find(
        r => rawIcd10Code(r.title) === rawIcd10Code(valueState)
      )
      if (result) {
        onSelect(null, { id: result.title, description: result.description })
      } else {
        setValueState('')
        setIsValidState(true)
      }
    }
  }
  const className = `field icd10 ${error ? 'error' : ''}`

  const debouncedValue = useDebounce<string>(valueState)

  useEffect(() => {
    fetchIcd10Codes(debouncedValue)
  }, [ debouncedValue ])

  return (
    <div className="coder-search-input m-1-top">
      <Search
        className={ className }
        name={ name }
        value={ valueState }
        loading={ isLoadingState }
        placeholder={ placeholder }
        results={ resultsState }
        onResultSelect={ onResultSelect }
        onSearchChange={ (event: any) => {
          setValueState(event.target.value as string)
        } }
        aligned="left"
        icon={ null }
        fluid={ true }
        onBlur={ onBlur }
        clear={ true }
      />
      <If isTrue={ !!valueState }>
        <Popup
          content="Clear ICD10 Value"
          mouseEnterDelay={ 500 }
          trigger={
            <Button icon onClick={ onClear }>
              <Icon name="cancel" />
            </Button>
          }
        />
      </If>
    </div>
  )
}

export default Icd10Search
