import React, { Component } from "react";
import { Grid, Icon, Popup, Segment, Table } from "semantic-ui-react";
import { format } from "date-fns";
import { BASE_URL } from "../../config";
import { API_URL, FORMAT } from "../../constant";
import { api } from "../../action/api";

/**
 * Reports the total number of reviews in each work list
 */
class TotalReviewCount extends Component {

  constructor(props) {
    super(props);
    this.onClickRefresh = this.onClickRefresh.bind(this);
    this.onClickCdiWorklistReportOk = this.onClickCdiWorklistReportOk.bind(this);
    this.onClickCoderWorklistReportOk = this.onClickCoderWorklistReportOk.bind(this);
    this.onClickCdiWorklistReportDownload = this.onClickCdiWorklistReportDownload.bind(this);
    this.onClickCoderWorklistReportDownload = this.onClickCoderWorklistReportDownload.bind(this);
    this.state = {
      cdiWorklistReportLoading: false,
      coderWorklistReportLoading: false,
      reportData: null
    }
  }

  componentDidMount() {
    this.fetchReportData();
  }

  fetchReportData() {
    const url = API_URL.DASHBOARD_REVIEW_COUNTS;
    api.get(url).then(({ data }) => {
      this.setState({ reportData: data });
    });
  }

  onClickRefresh() {
    this.fetchReportData();
  }

  onClickCdiWorklistReportOk() {
    this.setState({ cdiWorklistReportLoading: false });
  }

  onClickCoderWorklistReportOk() {
    this.setState({ coderWorklistReportLoading: false });
  }

  onClickCdiWorklistReportDownload() {
    this.setState({ cdiWorklistReportLoading: true });
  }

  onClickCoderWorklistReportDownload() {
    this.setState({ coderWorklistReportLoading: true });
  }

  renderRefresh() {
    return (
      <Grid columns={1}>
        <Grid.Column textAlign="right">
          <span className="italic">
            <span>{format(new Date(), FORMAT.TIMESTAMP)}</span>
            <span> </span>
            <span className="linkable"
              onClick={this.onClickRefresh}>Refresh</span>
          </span>
        </Grid.Column>
      </Grid>
    );
  }

  renderEmpty() {
    return (
      <div>
        <span className="italic">Sorry, no counts yet today</span>
        <div>{this.renderRefresh()}</div>
      </div>
    );
  }

  renderReport(data) {
    const key = (new Date()).getTime();
    const cdiWorklistReportUrl = BASE_URL + API_URL.REPORT_CDI_CLIENT_VIEW_WORKLIST;
    const cdiWorklistFileName = "cdi-worklist.xlsx";
    const coderWorklistReportUrl = BASE_URL + API_URL.REPORT_CODER_WORKLIST;
    const coderWorklistFileName = "coder-worklist.xlsx";
    return (
      <div key={key}>
        <Table definition fixed>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell />
              <Table.HeaderCell>CDI Worklist</Table.HeaderCell>
              <Table.HeaderCell>CDI Manager QA</Table.HeaderCell>
              <Table.HeaderCell>Ready for Physician</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>Pre-Visit</Table.Cell>
              <Table.Cell>{data.today.reviewCountOpen}</Table.Cell>
              <Table.Cell>{data.today.reviewCountReadyForCdiQa}</Table.Cell>
              <Table.Cell>{data.today.reviewCountReadyForPhysician}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell><span className="bold">End of Prior Day Count</span></Table.Cell>
              <Table.Cell>{data.yesterday.reviewCountOpen}</Table.Cell>
              <Table.Cell></Table.Cell>
              <Table.Cell></Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell><span className="bold">Excel Report</span></Table.Cell>
              <Table.Cell>
                { this.state.cdiWorklistReportLoading === true && (
                  <span>
                    <span>Generating CDI worklist report...</span>
                    <span> </span>
                    <span className="linkable"
                      onClick={this.onClickCdiWorklistReportOk}>Ok</span>
                  </span>
                )}
                { this.state.cdiWorklistReportLoading === false && (
                  <Popup content="Download CDI worklist as an Excel report"
                    mouseEnterDelay={500}
                    trigger={
                      <a href={cdiWorklistReportUrl} download={cdiWorklistFileName}
                        onClick={this.onClickCdiWorklistReportDownload}>
                          <Icon name="file excel outline" />
                          CDI Worklist Report
                        </a>
                    } />
                )}
              </Table.Cell>
              <Table.Cell></Table.Cell>
              <Table.Cell></Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
        <Table definition fixed>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell />
              <Table.HeaderCell>Coder Worklist</Table.HeaderCell>
              <Table.HeaderCell>Coder Manager QA</Table.HeaderCell>
              <Table.HeaderCell>Completed</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell><span className="bold">Post-Visit</span></Table.Cell>
              <Table.Cell>{data.today.reviewCountReadyForCoder}</Table.Cell>
              <Table.Cell>{data.today.reviewCountReadyForCoderQa}</Table.Cell>
              <Table.Cell>{data.today.reviewCountCompleted}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell><span className="bold">End of Prior Day Count</span></Table.Cell>
              <Table.Cell>{data.yesterday.reviewCountReadyForCoder}</Table.Cell>
              <Table.Cell></Table.Cell>
              <Table.Cell></Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell><span className="bold">Excel Report</span></Table.Cell>
              <Table.Cell>
                { this.state.coderWorklistReportLoading === true && (
                  <span>
                    <span>Generating coder worklist report...</span>
                    <span> </span>
                    <span className="linkable"
                      onClick={this.onClickCoderWorklistReportOk}>Ok</span>
                  </span>
                )}
                { this.state.coderWorklistReportLoading === false && (
                  <Popup content="Download Coder worklist as an Excel report"
                    mouseEnterDelay={500}
                    trigger={
                      <a href={coderWorklistReportUrl} download={coderWorklistFileName}
                        onClick={this.onClickCoderWorklistReportDownload}>
                        <Icon name="file excel outline" />
                        Coder Worklist Report
                      </a>
                    } />
                )}
              </Table.Cell>
              <Table.Cell></Table.Cell>
              <Table.Cell></Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
        <div>{this.renderRefresh()}</div>
      </div>
    );
  }

  render() {
    const key = (new Date()).getTime();
    return (
      <Segment key={key}>
        <h2>Worklist</h2>
        <div className="m-1-top">
          How many encounters are in the pre and post visit work lists?
        </div>
        <div className="m-1-top">
          {
            this.state.reportData
              ? this.renderReport(this.state.reportData)
              : this.renderEmpty()
          }
        </div>
      </Segment>
    );
  }
}

export default TotalReviewCount;
