import { Dispatch, SetStateAction } from 'react'
import { AxiosResponse } from 'axios'
import { api } from '../../action/api'
import { API_URL } from '../../constant'
import { EncounterDetail } from '../authenticated/types'

export type CopyEncounterProps = {
  currentEncounterId?: string
  previousEncounterId?: string
  setEncounterDetail: Dispatch<SetStateAction<EncounterDetail>>
  setIsModalShowing: Dispatch<SetStateAction<boolean>>
  setIsACopiedEncounter: Dispatch<SetStateAction<boolean>>
}

const copyEncounter = async (props: CopyEncounterProps): Promise<void> => {
  const {
    currentEncounterId,
    previousEncounterId,
    setEncounterDetail,
    setIsModalShowing,
    setIsACopiedEncounter
  } = props

  await api.put(
    API_URL.COPY_ENCOUNTER(
      currentEncounterId,
      previousEncounterId
    ), {}
  ).then( (response: AxiosResponse<EncounterDetail>) => {
    setEncounterDetail(response.data)
    setIsModalShowing(false)
    setIsACopiedEncounter(true)
  } )
}

export default copyEncounter
