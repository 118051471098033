/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Container, Header, Icon, Image, Menu, Segment, Grid } from 'semantic-ui-react'
import { NotificationContainer, NotificationManager } from 'react-notifications'
import 'react-notifications/lib/notifications.css'

import { doResetPassword } from '../../action/user'
import { PAGES } from '../../constant'
import UserPasswordChange from './userPasswordChange'
import './index.css'

export class Reset extends Component {

  constructor(props) {
    super(props)
    const { match } = this.props
    const {token} = match.params
    this.state = {
      passwordChange: {
        token,
        email: '',
        password: '',
        confirmPassword: ''
      },
      errors: null
    }
  }

  onSavePasswordChange(passwordChange) {
    this.props.doResetPassword(passwordChange, data => {
      passwordChange.password = ''
      passwordChange.confirmPassword = ''
      if ( data.status === 'OK' ) {
        NotificationManager.success('Your password has been reset.')
        this.setState({
          passwordChange,
          errors: null
        })
        // pause to allow the notification to be read
        // and then jump to the login page
        setTimeout(_ => this.props.history.push(PAGES.LOGIN), 2500)
      } else {
        this.setState({
          passwordChange,
          errors: data.errors
        })
      }
    })
  }

  render() {
    const {passwordChange} = this.state
    const errors = this.state.errors ? this.state.errors : null
    const key = new Date()
    return (
      <div>
        <Menu borderless>
          <Menu.Item>
            <Image size="small" src="/asset/image/curation-logo-no-tag.png" />
          </Menu.Item>
        </Menu>
        <div className="header-padding">
          <Container>
            <Header as="h4" attached="top" block className="main-schedule-header">
              <Icon name="lock" /> Reset Password
            </Header>
            <NotificationContainer />
            <Segment attached className="no-margin">
              <Grid stackable>
                <Grid.Row>
                  <Grid.Column mobile={ 16 } tablet={ 16 } computer={ 16 }>
                    <UserPasswordChange
                      key={ key }
                      passwordChange={ passwordChange }
                      errors={ errors }
                      onSave={ this.onSavePasswordChange.bind(this) } />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Segment>
          </Container>
        </div>
      </div>
    )
  }
}

export default withRouter(connect(
  state => ({}),
  { doResetPassword }
)(Reset))
