export const UPDATE_VERSION = "UPDATE_VERSION";

const initState = {
  name: "",
  projectVersion: "",
  envVersion: "",
  buildNumber: "",
  date: "",
  host: "",
};

export default (state = initState, action) => {
  switch (action.type) {
    case UPDATE_VERSION:
      return Object.assign({}, state, {
        name: action.payload.name,
        projectVersion: action.payload.projectVersion,
        envVersion: action.payload.envVersion,
        buildNumber: action.payload.buildNumber,
        date: action.payload.date,
        host: action.payload.host
      });
    default:
      return state;
  }
};
