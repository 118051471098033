/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react'
import { Grid, Table } from 'semantic-ui-react'
// Local
import ComputerTable from './computerTable'
import If from './if'
import MaxTableFooter from './maxTableFooter'
import MoreTableFooter from './moreTableFooter'
import MobileRow from './mobileRow'
import './table.css'

function MobileTable(props) {
  const {
    data,
    more,
    max,
    onClick,
    mobileColumns,
    computerColumns,
    onMore
  } = props

  return (
    <Table
      compact
      color="green"
      selectable
    >
      <Table.Body>
        { data.map(record => (
          <MobileRow
            key={ record.id }
            record={ record }
            onClick={ onClick }
            mobileColumns={ mobileColumns }
          />
        )) }
      </Table.Body>
      <If isTrue={ more }>
        <MoreTableFooter
          columns={ computerColumns.length }
          onMore={ onMore }
        />
      </If>
      <If isTrue={ max !== undefined }>
        <MaxTableFooter
          columns={ computerColumns.length }
        />
      </If>
    </Table>
  )
}

function TableComponent(props) {
  const {
    computerColumns,
    mobileColumns,
    data,
    onClick,
    more,
    max,
    onMore,
    sortOnHeaderClick
  } = props
  return (
    <Grid id="table">
      <Grid.Row
        columns={ 1 }
        only="computer tablet"
        className="left-padding"
      >
        <ComputerTable
          computerColumns={ computerColumns }
          mobileColumns={ mobileColumns }
          data={ data }
          onClick={ onClick }
          more={ more }
          max={ max }
          onMore={ onMore }
          sortOnHeaderClick={ sortOnHeaderClick }
        />
      </Grid.Row>
      <Grid.Row
        columns={ 1 }
        only="mobile"
        className="left-padding"
      >
        <MobileTable
          computerColumns={ computerColumns }
          mobileColumns={ mobileColumns }
          data={ data }
          onClick={ onClick }
          more={ more }
          max={ max }
          onMore={ onMore }
          sortOnHeaderClick={ sortOnHeaderClick }
        />
      </Grid.Row>
    </Grid>
  )
}

export default TableComponent
