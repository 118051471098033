import React, { useEffect, useState } from 'react'
import { flatten, map, pathOr, pipe, reduce } from 'ramda'
// Local
import {
  FinalOutput,
  KnownAndPotential,
  processConditions
} from '../../v2/processConditions'
import { API_URL, ENCOUNTER, PAGES } from '../../../constant'
import { api } from '../../../action/api'
import Accordion from '../../v2/accordion'
import Card from '../../v2/card'
import If from '../../common/if'
import NavHeader from '../common/navHeader'
import EncounterDateWarning from '../common/encounterDateWarning'
import Header from '../../v2/header'
import ClinicalConditions from './clinicalConditions'
import Condition from '../../v2/condition'
import AlertBar from '../../v2/alertBar'
import {
  PatientType,
  EncounterConditionDisplayModified,
  EncounterLink,
  Encounter,
  TagStatus,
  ObjectValues
} from '../../authenticated/types'

type Props = {
  mode: string | null
  encounterId: any
  currentUser: any
  onEncounterNav: (id: string) => void
}

type APIResponse = {
  data: PatientType
}

const idsToTagStatuses = (
  idsToTagStatus: Record<string, TagStatus>,
  conditionOrActivity: EncounterConditionDisplayModified
): Record<string, TagStatus> => {
  if (
    // A condition or activity can be both "Required" and "Not Evaluated"
    conditionOrActivity.isRequired &&
    conditionOrActivity.practitionerResponseCode !== TagStatus.NotEvaluated
  ) {
    return {
      ...idsToTagStatus,
      [conditionOrActivity.id]: TagStatus.NotClicked
    }
  }
  return idsToTagStatus
}

const conditionIdsToTags = pipe<
  KnownAndPotential[][],
  EncounterConditionDisplayModified[][],
  EncounterConditionDisplayModified[],
  Record<string, TagStatus>
>(
  map(x => x.conditions),
  flatten,
  reduce(idsToTagStatuses, {})
)

function PhysicianEncounterTwo(props: Props) {
  const { mode, encounterId, currentUser, onEncounterNav } = props

  const [ conditionGroups, setConditionGroups ] = useState<FinalOutput>(
    {} as FinalOutput
  )

  const [ encounterLinks, setEncounterLinks ] = useState<EncounterLink>({
    prev: null,
    next: null
  })

  const [ encounter, setEncounter ] = useState<Encounter>({} as Encounter)

  const [ tagsClicked, setTagsClicked ] = useState<Record<string, TagStatus>>({})

  const idToTagStatus = ({
    conditions,
    activities
  }: FinalOutput): Record<string, TagStatus> => ({
    ...conditionIdsToTags(conditions),
    ...activities.reduce(idsToTagStatuses, {})
  })

  useEffect(() => {
    async function fetchDetail(): Promise<void> {
      const response = (await api.get(
        `${API_URL.GET_ENCOUNTER_DETAIL_BY_ID}${encounterId}`
      )) as APIResponse
      const processed = processConditions(response.data.conditions, currentUser)
      const idsToTags = idToTagStatus(processed)
      setTagsClicked(idsToTags)
      setConditionGroups(processed)
      // setConditionGroups(processConditions(theData.conditions))
      setEncounter(response.data.encounter)
      setEncounterLinks(response.data.links)
    }
    fetchDetail()
  }, [])

  function onHeaderAction(action: ObjectValues<typeof ENCOUNTER.ACTION>): void {
    const { PREVIOUS_ENCOUNTER, NEXT_ENCOUNTER, WORKLIST } = ENCOUNTER.ACTION
    if (action === PREVIOUS_ENCOUNTER) {
      if (encounterLinks.prev) onEncounterNav(encounterLinks.prev)
    }
    if (action === NEXT_ENCOUNTER) {
      if (encounterLinks.next) onEncounterNav(encounterLinks.next)
    }
    if (action === WORKLIST) {
      window.location.href = PAGES.HOME
    }
  }

  const reviewer =
    encounter && encounter.review && encounter.review.reviewer
      ? encounter.review.reviewer
      : null

  const isNotClicked = (value: string): boolean =>
    tagsClicked[value] === TagStatus.NotClicked
  const itemsNeedingAction = Object.keys(tagsClicked).filter(isNotClicked)

  return (
    <div
      className="external-conditions-ui-wrapper"
      id="external-conditions-ui-wrapper"
    >
      <If isTrue={ !!Object.keys(encounter).length }>
        <NavHeader
          mode={ mode }
          encounter={ encounter }
          links={ encounterLinks }
          onAction={ onHeaderAction }
        />
        <EncounterDateWarning encounter={ encounter } mode={ mode } />

        <Header encounter={ encounter } reviewer={ reviewer } currentUser={ currentUser }/>
      </If>

      { /* Alert Bar */ }
      <If isTrue={ !!itemsNeedingAction.length }>
        <AlertBar items={ itemsNeedingAction } />
      </If>
      <div
        className="curation-ui-accordion-container"
        id="curation-ui-accordion-container"
      >
        <Accordion
          id="clinical-conditions-group"
          title="Clinical Conditions"
          isTopLevel
        >
          <div className="curation-ui-patient-chart" id="clinical-conditions">
            <ClinicalConditions
              conditions={ pathOr([], [ 'conditions' ], conditionGroups) }
              tagsClicked={ tagsClicked }
              setTagsClicked={ setTagsClicked }
            />
          </div>
        </Accordion>

        <Accordion id="activities-group" title="Activities" isTopLevel>
          <div className="curation-ui-patient-chart" id="activities">
            { pathOr([], [ 'activities' ], conditionGroups).length === 0 ? (
              <Card id="no-activities">
                <>No Activities to show at this time.</>
              </Card>
            ) : (
              conditionGroups.activities.map(
                (
                  condition: EncounterConditionDisplayModified,
                  index: number
                ) => (
                  <Card key={ condition.id } id={ `activity-card-${index}` }>
                    <Condition
                      id={ `activity-${index}` }
                      conditions={ [] }
                      condition={ condition }
                      isStandAloneCondition={ true }
                      areAllConditionsInGroupPotential={ false }
                      tagsClicked={ tagsClicked }
                      setTagsClicked={ setTagsClicked }
                    />
                  </Card>
                )
              )
            ) }
          </div>
        </Accordion>
      </div>
    </div>
  )
}

export default PhysicianEncounterTwo
