/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Dispatch, SetStateAction } from 'react'
import { Form } from 'semantic-ui-react'
// Local
import { showNotReportableDiagnosisCodeEdit } from '../../common/conditionUtils'
import { formatIcd10Code } from '../../common/formatUtils'
import Icd10SearchCoder from '../condition/Icd10SearchCoder'
import { EncounterConditionDisplay } from '../../authenticated/types'
import If from '../../common/if'
import { modifyCoderTags } from './utils'

type Props = {
  condition: EncounterConditionDisplay
  setCondition: Dispatch<SetStateAction<EncounterConditionDisplay>>
}

function CoderDiagnosisCodeEditor(props: Props): JSX.Element {
  const { condition, setCondition } = props

  const coderTags = modifyCoderTags(condition)

  const onSelectIcd10 = (x: any, result: any) => {
    setCondition(coderTags({
      conditionDiagnosisCode: result.id
    }))
  }

  const diagnosisCode = condition.coderTags
    ? condition.coderTags.conditionDiagnosisCode
    : null
  const icd10 = diagnosisCode && diagnosisCode.length > 0 ? diagnosisCode : ''

  return (
    <Form.Field className="m-1-top">
      <label className="bold">
        Diagnosis Code
        { diagnosisCode && `: ${formatIcd10Code(diagnosisCode)}` }
      </label>
      <If isTrue={ showNotReportableDiagnosisCodeEdit(condition) }>
        <div className="m-1-top">
          <Icd10SearchCoder value={ icd10 } onSelect={ onSelectIcd10 } />
        </div>
      </If>
    </Form.Field>
  )
}

export default CoderDiagnosisCodeEditor
