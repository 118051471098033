import React, { Component } from "react";
import { Popup, Responsive } from "semantic-ui-react";
import reduce from "lodash/reduce";
import { API_URL, MOBILE_WIDTH, REVIEW, TABLET_WIDTH } from "../../constant";
import { api } from "../../action/api";

/**
 * Provides a component that displays the Coder user review count for the current
 * day. Count includes reviews marked READY_FOR_CODER_QA and COMPLETED
 * Requires no properties.
 */
class CoderCounter extends Component {

  constructor(props) {
    super(props);
    this.state = {
      reviewCount: 0,
      readyForCoderQa: 0,
      completed: 0
    };
  }

  componentDidMount() {
    this.fetchReviewCount();
  }

  reduceReviewCounts(reviewCounts, reviewCode) {
    return reduce(reviewCounts, (result, row) => {
      if ( row.reviewCode === reviewCode ) {
        return result + row.reviewCount
      } else {
        return result
      }
    }, 0);
  }

  fetchReviewCount() {
    const url = API_URL.REVIEW_COUNT;
    api.get(url).then(({ data }) => {
      const coderQaCount = this.reduceReviewCounts(data, REVIEW.CODE.READY_FOR_CODER_QA);
      const completedCount = this.reduceReviewCounts(data, REVIEW.CODE.COMPLETED);
      const total = coderQaCount + completedCount;
      this.setState({
        reviewCount: total,
        readyForCoderQa: coderQaCount,
        completed: completedCount
      });
    })
  }

  counterMessage() {
    const msgs = [];
    if ( this.state.completed > 0 ) {
      msgs.push(`Completed: ${this.state.completed}`);
    }
    if ( this.state.readyForCoderQa > 0 ) {
      msgs.push(`; Ready for Coder QA: ${this.state.readyForCoderQa}`);
    }
    if ( this.state.reviewCount === 0 ) {
      msgs.push("Completed: 0; Ready for Coder QA: 0")
    }
    return msgs.join("; ");
  }

  render() {
    const msg = this.counterMessage();
    return (
      <span>
        <Responsive minWidth={TABLET_WIDTH.minWidth} className="bold">
          <Popup content={msg} mouseEnterDelay={1000}
            trigger={<span>Reviewed: {this.state.reviewCount}</span>} />
        </Responsive>
        <Responsive maxWidth={MOBILE_WIDTH.maxWidth} className="bold">
          {this.state.reviewCount}
        </Responsive>
      </span>
    );
  }
}

export default CoderCounter;
