/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import { withRouter } from 'react-router'
import { PAGES } from './constant'
import { isEmbeddedView } from './component/common/currentUserUtils'
import PrivateRoute from './component/common/privateRoute'
import AutoLogin from './component/login/autoLogin'
import Authenticated from './component/authenticated'
import EmptyScope from './component/fallback/emptyScope'
import Encounter from './component/encounter/encounter'
import Feedback from './component/feedback'
import Login from './component/login'
import Register from './component/register'
import Reset from './component/reset'
import './css/main-stylus.css'
import './app.css'

/**
 * Provides the top level app container and routing for the app
 *
 * This top-level routing is handling 2 scenarios: The user is not logged in;
 * or the user is logged in via an external source and will pass through the
 * auto-login routing as part of a launch or embedded integration.
 *
 * Once the user is signed in the request passes through a second phase of
 * routing in the Authenticated component: authenticated/index.js
 *
 * The PrivateRoute components allow the app to render without visual headers.
 * Again, this is used in launch and embedded integrations
 *
 * See also: login/autoLogin.js and action/user.js: autoLogin(params)
 */
export class App extends Component {
  render() {
    return (
      <div className="App">
        <Switch>
          <Route exact path={ PAGES.LOGIN } component={ Login } />
          <Route exact path={ PAGES.AUTO_LOGIN } component={ AutoLogin } />
          <Route exact path={ PAGES.PASSWORD_RESET } component={ Reset } />
          <Route exact path={ PAGES.REGISTER } component={ Register } />
          <PrivateRoute
            path={ `${PAGES.SMART_ENCOUNTER_DETAIL  }:id/conditions/:conditions` }
            component={ Encounter }
            authenticated={ this.props.isAuthenticated }
          />
          <PrivateRoute
            path={ `${PAGES.SMART_ENCOUNTER_DETAIL  }:id` }
            component={ Encounter }
            authenticated={ this.props.isAuthenticated }
          />
          <PrivateRoute
            path={ `${PAGES.EMBEDDED_ENCOUNTER_DETAIL  }:id/conditions/:conditions` }
            component={ Encounter }
            authenticated={ this.props.isAuthenticated }
          />
          <PrivateRoute
            path={ `${PAGES.EMBEDDED_ENCOUNTER_DETAIL  }:id` }
            component={ Encounter }
            authenticated={ this.props.isAuthenticated }
          />
          <PrivateRoute
            path={ `${PAGES.EMPTY_SCOPE }` }
            component={ EmptyScope }
            authenticated={ this.props.isAuthenticated }
          />
          <PrivateRoute
            path="/auth"
            component={ Authenticated }
            authenticated={ this.props.isAuthenticated } />
          <Route component={ Login } />
        </Switch>
        {
          !isEmbeddedView(this.props.location.pathname) &&
          <Feedback />
        }
      </div>
    )
  }
}

export default withRouter(
  connect(state => ({ isAuthenticated: state.user.isAuthenticated }), {})(App)
)