import { encounterPractitionerTagsEnabled, encounterPractitionerTagOptions } from './currentUserUtils'
import {
  condition as conditionUtils,
  conditions as conditionsUtils,
  hasExternal,
  hasKnown,
  hasPotential,
  withAddressedConditions
} from './conditionUtils'

/** *****************************************************************************
 * Provides utilities for managing practitioner tags
 */

/**
 * @param tagOptions Practitioner tag options
 * @return True if tag options include options for external conditions
 */
export function hasExternalTagOptions(tagOptions) {
  return tagOptions && tagOptions.EXTERNAL && tagOptions.EXTERNAL.length > 0
}

/**
 * @param tagOptions Practitioner tag options
 * @return True if tag options include options for activity conditions
 */
export function hasActivityTagOptions(tagOptions) {
  return tagOptions && tagOptions.ACTIVITY && tagOptions.ACTIVITY.length > 0
}

/**
 * @param tagOptions Practitioner tag options
 * @return True if tag options include options for known conditions
 */
export function hasKnownTagOptions(tagOptions) {
  return tagOptions && tagOptions.KNOWN && tagOptions.KNOWN.length > 0
}

/**
 * @param tagOptions Practitioner tag options
 * @return True if tag options include options for potential conditions
 */
export function hasPotentialTagOptions(tagOptions) {
  return tagOptions && tagOptions.POTENTIAL && tagOptions.POTENTIAL.length > 0
}

/**
 * Test a group of conditions to determine if there are applicable practitioner
 * tag options for the conditions
 * @param tagOptions Practitioner tag options
 * @param conditions Group of conditions
 * @return True if conditions have tag options; false if not
 */
export function hasPractitionerTagOptions(tagOptions, conditions) {
  if (conditionsUtils.hasActivity(conditions) && hasActivityTagOptions(tagOptions)) {
    return true
  }
  if (hasKnown(conditions) && hasKnownTagOptions(tagOptions)) {
    return true
  }
  if (hasPotential(conditions) && hasPotentialTagOptions(tagOptions)) {
    return true
  }
  if (hasExternal(conditions) && hasExternalTagOptions(tagOptions)) {
    return true
  }
  return false
}

/**
 * Determines the correct practitioner options based on the condition type.
 *
 * If the condition is external this overrides the activity, known, potential
 * condition types
 *
 * It is a good practice to first verify that tag options are available for the
 * condition type with hasPractitionerTagOptions, before calling this function
 *
 * @param condition Condition entity
 * @param tagOptions Practitioner tag options
 * @return Practitioner tag options that correspond the the condition type or
 *         null if no matching options
 */
export function conditionTagOptions(condition, tagOptions) {
  if (conditionUtils.isExternal(condition)) {
    return hasExternalTagOptions(tagOptions) ? tagOptions.EXTERNAL : null
  }
  if (conditionUtils.isActivity(condition)) {
    return hasActivityTagOptions(tagOptions) ? tagOptions.ACTIVITY : null
  }
  if (conditionUtils.isKnown(condition)) {
    return hasKnownTagOptions(tagOptions) ? tagOptions.KNOWN : null
  }
  if (conditionUtils.isPotential(condition)) {
    return hasPotentialTagOptions(tagOptions) ? tagOptions.POTENTIAL : null
  }
  return null
}

/**
 * This is a kitchen sink function to determine if practitioner tags are
 * available for display. Checks:
 * - Practitioner tags enabled for tenant
 * - Practitioner tag options defined for tenant
 * - Tag options defined for the specified group of conditions
 * - If the group of conditions is an HCC display group, the group does not
 *   contain any addressed conditions. An HCC group is considered addressed
 *   if any condition in the group is addressed
 * @param currentUser Current user entity
 * @param conditions Group of conditions
 * @param isHccGroup True if the client intends to display conditions as HCC group
 * @return True if it looks like practitioner tags are available for display
 *         false if not
 */
export function practitionerTagDisplayCheck(currentUser, conditions, isHccGroup) {
  const tagsEnabled = encounterPractitionerTagsEnabled(currentUser)
  const tagOptions = encounterPractitionerTagOptions(currentUser)
  const hasTagOptions = hasPractitionerTagOptions(tagOptions, conditions)
  const hccAddressed = withAddressedConditions(conditions).length > 0
  if (isHccGroup === true) {
    return tagsEnabled && hasTagOptions && !hccAddressed
  }
  return tagsEnabled && hasTagOptions

}
