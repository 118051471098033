import React, { useState } from 'react'
import { Button, Icon, Popup } from 'semantic-ui-react'
// Local
import { ENCOUNTER } from '../../../constant'
import { Encounter, ObjectValues } from '../../authenticated/types'

/**
 * Provides a component that renders an encounter cancel button. The component
 * includes support for both canceling and undoing the cancel
 * @param encounter Encounter entity
 * @param onClick Callback function for button click.
 *        Arguments: the updated status of the encounter
 * @param mode Render mode. Default: left
 */

type Props = {
  encounter: Encounter
  onClick: (encounterStatus: ObjectValues<typeof ENCOUNTER.STATUS>) => void
  mode?: string
}

function EncounterCancelButton(props: Props): JSX.Element | null {
  const { encounter, onClick, mode = 'left' } = props
  const [ encounterStatus, setEncounterStatus ] = useState(encounter.status)

  const handleOnClick = () => {
    const newStatus =
      encounterStatus === ENCOUNTER.STATUS.CANCELLED
        ? ENCOUNTER.STATUS.PLANNED
        : ENCOUNTER.STATUS.CANCELLED
    setEncounterStatus(newStatus)
    onClick(newStatus)
  }

  if (mode !== 'left') return null

  if (encounterStatus === ENCOUNTER.STATUS.CANCELLED) {
    return (
      <div className="ui segment attached compact left-segment">
        <div className="m-1-top m-1-bottom left-title-center">
          <Popup
            content="Mark Patient Encounter as Planned"
            mouseEnterDelay={ 1000 }
            trigger={
              <Button type="submit" onClick={ handleOnClick }>
                <Icon name="undo" />
                Undo Encounter Cancel
              </Button>
            }
          />
        </div>
      </div>
    )
  }

  return (
    <div className="ui segment attached compact left-segment">
      <div className="m-1-top m-1-bottom left-title-center">
        <Popup
          content="Mark Patient Encounter Cancelled"
          mouseEnterDelay={ 1000 }
          trigger={
            <Button type="submit" onClick={ handleOnClick }>
              <Icon name="trash" />
              Cancel Encounter
            </Button>
          }
        />
      </div>
    </div>
  )
}

export default EncounterCancelButton
