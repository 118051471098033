import React, { Component } from "react";
import { Responsive, Segment } from "semantic-ui-react";
import { TABLET_WIDTH } from "../../../constant";
import PatientProfile from "./patientProfile";

/**
 * Provides a component that renders the encounter patient profile image
 * @param encounter Encounter entity
 * @param mode Render mode. Default: left
 */
class EncounterProfile extends Component {

  renderLeft(patient) {
    return (
      <Responsive as={Segment} attached className="left-segment-image"
        minWidth={TABLET_WIDTH.minWidth}>
        <PatientProfile key={ `patient-profile-${patient.id}` }
          patientId={patient.id} clazz="left-image" />
      </Responsive>
    );
  }

  render() {
    const mode = this.props.mode ? this.props.mode : "left";
    if ( mode === "left" ) {
      return this.renderLeft(this.props.encounter.patient);
    } else {
      return null;
    }
  }
}

export default EncounterProfile;
