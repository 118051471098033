import React, { Dispatch, SetStateAction, useState } from 'react'
import { includes, map, pathOr, pipe, propEq } from 'ramda'
// Local
import Title from '../encounter/physician/title'
import { condition } from '../common/conditionUtils'
import ActionButtons from './actionButtons'
import Description from '../encounter/physician/description'
import If from '../common/if'
import {
  ButtonOptions,
  EncounterConditionDisplayModified,
  TagStatus
} from '../authenticated/types'

type Props = {
  id: string
  conditions: EncounterConditionDisplayModified[]
  condition: EncounterConditionDisplayModified
  tagsClicked: Record<string, TagStatus>
  setTagsClicked: Dispatch<SetStateAction<Record<string, TagStatus>>>
  isStandAloneCondition: boolean
  areAllConditionsInGroupPotential: boolean
  required?: number
  setRequired?: Dispatch<SetStateAction<number>>
}

const responseCode = (x: EncounterConditionDisplayModified): TagStatus =>
  x.practitionerResponseCode || TagStatus.NotClicked

const areAnyNotEvaluated = pipe<
  EncounterConditionDisplayModified[][],
  string[],
  boolean
>(map(responseCode), includes(TagStatus.NotEvaluated))

function Condition(props: Props): JSX.Element {
  // const { condition, tagOptions, currentUser } = props
  const {
    id,
    conditions,
    condition: conditionProp,
    tagsClicked,
    setTagsClicked,
    isStandAloneCondition,
    areAllConditionsInGroupPotential,
    required = 0,
    setRequired
  } = props

  const [ wasActionClicked, setWasActionClicked ] = useState<boolean>(false)

  const defaultSelectedButton = (): string => {
    const selectedOption: TagStatus | null =
      conditionProp.practitionerResponseCode
    const selectedButton: ButtonOptions | undefined =
      conditionProp.tagOptions.find(propEq('value', selectedOption))
    return pathOr('', [ 'text' ], selectedButton)
  }

  const clickedTagValue: string = tagsClicked
    ? tagsClicked[conditionProp.id]
    : ''
  const isNotEvaluated = clickedTagValue === TagStatus.NotEvaluated

  const isRequired =
    (!wasActionClicked && conditionProp.isRequired) || isNotEvaluated

  function isAddressed(): boolean {
    if (conditionProp.isKnownAddressed) return true
    if (isNotEvaluated) return false
    if (conditionProp.isAddressed && wasActionClicked) return true
    return !!conditionProp.practitionerResponseCode && conditionProp.isAddressed
  }

  const isActivity = condition.isActivity(conditionProp)

  function shouldShowDescription() {
    if (isRequired || isActivity) return true
    if (isStandAloneCondition && conditionProp.isPotential) return true
    if (!isStandAloneCondition && conditionProp.isPotential) {
      if (areAnyNotEvaluated(conditions)) return false
      if (areAllConditionsInGroupPotential) return true
      if (!areAllConditionsInGroupPotential) return false
    }
    return false
  }

  const [ isDescriptionShowing, setIsDescriptionShowing ] = useState<boolean>(
    shouldShowDescription()
  )

  function handleRequired(value: string): void {
    if (setRequired) {
      // Not Evaluated Clicked - Increment Required
      if (
        conditionProp.practitionerResponseCode === null &&
        value === TagStatus.NotEvaluated
      ) {
        setRequired(required + 1)
      }
      // Something Else Clicked - Decrement Required
      if (value !== TagStatus.NotEvaluated)
        setRequired(required > 0 ? required - 1 : 0)
    }
  }

  function onClick(value: string): void {
    setWasActionClicked(true)
    setTagsClicked({
      ...tagsClicked,
      [conditionProp.id]: value as TagStatus
    })
    handleRequired(value)
  }

  function shouldShowPractitionerTags(): boolean {
    if (conditionProp.isKnownAddressed) return false
    return !!conditionProp.tagOptions.length
  }

  return (
    <div
      className={ `curation-ui-condition ${
        isDescriptionShowing ? 'showing' : 'hidden'
      }` }
      id={ id }
    >
      <Title
        description={ conditionProp.title }
        isAddressed={ isAddressed() }
        isRequired={ isRequired }
        isDescriptionShowing={ isDescriptionShowing }
        setIsDescriptionShowing={ setIsDescriptionShowing }
      />
      <Description
        condition={ conditionProp }
        sources={ conditionProp.description }
      />

      <If isTrue={ shouldShowPractitionerTags() }>
        <ActionButtons
          id={ id }
          condition={ conditionProp }
          buttons={ conditionProp.tagOptions }
          onClick={ onClick }
          defaultSelectedButton={ defaultSelectedButton() }
        />
      </If>
    </div>
  )
}

export default Condition
