import { API_URL } from '../constant'
import { UPDATE_VERSION } from '../reducer/version'
import { api } from './api'

export function loadVersion() {
  return (dispatch: (arg0: { type: string; payload: any }) => void) => api.get(API_URL.VERSION).then(({ data }) => {
    dispatch({ type: UPDATE_VERSION, payload: data })
  })
}

export default loadVersion
