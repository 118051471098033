import React, { Component } from "react";
import { connect } from "react-redux";
import { Dropdown, Radio, Responsive } from 'semantic-ui-react';
import { CONDITION } from "../../../constant";
import { isSmartDisplay } from "../../common/displayUtils";
import { encounterPractitionerTagsEnabled, encounterPractitionerTagOptions } from "../../common/currentUserUtils"
import { conditionTagOptions, hasPractitionerTagOptions } from "../../common/practitionerTagUtils";

/**
 * Physician condition header toolbar. Provides editors for encounter condition
 * practitioner tags intended for display in the encounter condition header
 *
 * @param condition Condition entity
 * @param onConditionAction Callback for condition update.
 *        Arguments: action, Array of conditions (in this case an array with a
 *        single condition)
 * @param mode View mode: smart or undefined. Default: undefined
 */
class PhysicianConditionHeaderToolbar extends Component {

  constructor(props) {
    super(props);
    this.onChangePractitionerTag = this.onChangePractitionerTag.bind(this);
    this.state = {
      condition: this.props.condition
    };
  }

  onChangePractitionerTag = (e, { value }) => {
    e.stopPropagation();
    const action = CONDITION.ACTION.UPDATE_PRACTITIONER_TAGS;
    const condition = this.state.condition;
    if ( condition.practitionerTags ) {
      condition.practitionerTags.practitionerResponseCode = value;
    } else {
      condition.practitionerTags = {
        encounterConditionId: condition.id,
        practitionerResponseCode: value
      }
    }
    this.setState({ condition: condition }, () => {
      this.props.onConditionAction(action, [condition]);
    })
  }

  renderRadio(condition, tagOptions) {
    const currentTags = condition.practitionerTags;
    const value = currentTags && currentTags.practitionerResponseCode
      ? currentTags.practitionerResponseCode : "";
    const name = `${condition.id}-practitionerResponseCode`;

    return (
      <span className="physician-condition-toolbar-header">
        {tagOptions.map((option, i) => {
          return (
            <span key={`${condition.id}-${option.key}`}>
              <Responsive as={Radio}
                className={i > 0 ? "m-12px-left" : ""}
                label={option.text}
                name={name}
                value={option.value}
                checked={option.value === value}
                onChange={this.onChangePractitionerTag} />
            </span>
          );
        })}
      </span>
    );
  }

  renderDropdown(condition, tagOptions) {
    const currentTags = condition.practitionerTags;
    const value = currentTags && currentTags.practitionerResponseCode
      ? currentTags.practitionerResponseCode : "";

    return (
      <span className="physician-condition-toolbar-header">
        <Responsive as={Dropdown} selection
          placeholder="Mark As"
          onChange={this.onChangePractitionerTag}
          options={tagOptions}
          value={value} />
      </span>
    );
  }

  renderPractitionerTags(condition) {
    const currentUser = this.props.currentUser;
    const tagsEnabled = encounterPractitionerTagsEnabled(currentUser);
    const tagOptions = encounterPractitionerTagOptions(currentUser);
    if ( tagsEnabled === true && hasPractitionerTagOptions(tagOptions, [condition]) ) {
      return isSmartDisplay(this.props)
        ? this.renderDropdown(condition, conditionTagOptions(condition, tagOptions))
        : this.renderRadio(condition, conditionTagOptions(condition, tagOptions));
    } else {
      return null;
    }
  }

  render() {
    if ( this.state.condition ) {
      return this.renderPractitionerTags(this.state.condition);
    } else {
      return null;
    }
  }
}

export default connect(
  state => ({
    currentUser: state.user
  }), {}
)(PhysicianConditionHeaderToolbar);

