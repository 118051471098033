/* eslint-disable react/destructuring-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable class-methods-use-this */
import React, { Component } from 'react'
import { Icon, Message } from 'semantic-ui-react'
import { format } from 'date-fns'

/**
 * Provides a component that displays a warning message if the encounter is
 * past or future
 * @param encounter Encounter entity
 */
class EncounterDateWarning extends Component {

  previousEncounter(startDt) {
    const today = format(new Date(), 'YYYYMMDD')
    const start = format(startDt, 'YYYYMMDD')

    return start < today
  }

  futureEncounter(startDt) {
    const today = format(new Date(), 'YYYYMMDD')
    const start = format(startDt, 'YYYYMMDD')

    return start > today
  }

  renderPrevious() {
    return (
      <Message warning>
        <Icon name="warning circle" />
        Please note: you are viewing a past encounter for this patient
      </Message>
    )
  }

  renderFuture() {
    return (
      <Message warning>
        <Icon name="warning circle" />
        Please note: you are viewing a future encounter for this patient
      </Message>
    )
  }

  renderMessage(startDt) {
    if ( this.previousEncounter(startDt) ) {
      return this.renderPrevious()
    } if ( this.futureEncounter(startDt) ) {
      return this.renderFuture()
    }
    return null

  }

  render() {
    if ( this.props.encounter && this.props.encounter.start ) {
      return this.renderMessage(this.props.encounter.start)
    }
    return null

  }
}

export default EncounterDateWarning
