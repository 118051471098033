import React, { Component } from "react";
import { Segment } from "semantic-ui-react";

/**
 * Provides a component that renders the encounter practitioner
 * @param encounter Encounter entity
 * @param mode Render mode. Default: left
 */
class EncounterPractitioner extends Component {

  renderLeft(practitioner) {
    const name = practitioner.firstName + " " + practitioner.lastName;
    return (
      <Segment attached compact className="left-segment">
        <div className="left-title">Practitioner:</div>
        <div className="left-content">
          <span>
            <span>{name}</span>
          </span>
        </div>
      </Segment>
    );
  }

  render() {
    const mode = this.props.mode ? this.props.mode : "left";
    if ( mode === "left" ) {
      return this.renderLeft(this.props.encounter.practitioner);
    } else {
      return null;
    }
  }
}

export default EncounterPractitioner;
