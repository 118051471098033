import React from 'react'
// Local
import { IconProps } from './iconProps'

type LockProps = IconProps & {
  onClick?: () => void
  className?: string
}

const baseClassName = 'lock-icon'

function Lock(props: LockProps): JSX.Element {
  const { size = 24, color = '#000000', onClick, className } = props
  return (
    <svg
      width={ size }
      height={ size }
      viewBox="0 0 24 24"
      fill="none"
      stroke={ color }
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={ className ? `${baseClassName} ${className}` : baseClassName }
      onClick={ () => {
        if (onClick) onClick()
      } }
    >
      <rect x="3" y="11" width="18" height="11" rx="2" ry="2" />
      <path d="M7 11V7a5 5 0 0 1 10 0v4" />
    </svg>
  )
}

export default Lock
