import React, { Component } from "react";
import { Container, Grid } from "semantic-ui-react";
import filter from "lodash/filter";
import { API_URL, USER_PROPERTY } from "../../constant";
import { api } from "../../action/api";
import { propertyValue } from "../common/userPropertyUtils";

/**
 * Provides a component for displaying a user's user properties
 * @param currentUser Current user entity
 */
class UserPropertyDetails extends Component {

  constructor(props) {
    super(props);

    this.state = {
      organization: null
    };
  }

  componentDidMount() {
    const currentUser = this.props.currentUser;
    const userProperties = currentUser.userProperties;
    const orgId = propertyValue(userProperties, USER_PROPERTY.USER_PATIENT_ORG_FILTER);
    if ( orgId ) {
      this.fetchOrganization(orgId);
    }
  }

  fetchOrganization(orgId) {
    const url = API_URL.GET_ORGANIZATION(orgId);
    api.get(url, { }).then(({ data }) => {
      this.setState({ organization: data });
    });
  }

  withInterestingProperties(userProperties) {
    if ( userProperties && userProperties.length > 0 ) {
      return filter(userProperties, function(property) {
        return property.propertyKey === USER_PROPERTY.USER_PATIENT_REGION_FILTER ||
          property.propertyKey === USER_PROPERTY.USER_PATIENT_ORG_FILTER;
      });
    } else {
      return [];
    }
  }

  regionFilterDetail(property) {
    return (
      <Grid.Row key={property.propertyKey}>
        <Grid.Column mobile={16} tablet={16} computer={16}>
          <h4>Worklist Region Filtering</h4>
          <div>The following regions are applied to encounter worklists:</div>
          <ul>
            {property.propertyValue.split(",").map(region => {
              return (<li key={region}>{region.replaceAll("_", " ")}</li>)
            })}
          </ul>
        </Grid.Column>
      </Grid.Row>
    );
  }

  organizationFilterDetail(property) {
    return (
      <Grid.Row key={property.propertyKey}>
        <Grid.Column mobile={16} tablet={16} computer={16}>
          <h4>Worklist Organization Filtering</h4>
          <div>The following organization is applied to encounter worklists:</div>
          <ul>
            { this.state.organization ?
              <li key={property.propertyValue}>{this.state.organization.name}</li>
              :
              <li key={property.propertyValue}>{property.propertyValue}</li>
            }
          </ul>
        </Grid.Column>
      </Grid.Row>
    );
  }

  renderUserPropertyDetail(property) {
    if ( property.propertyKey === USER_PROPERTY.USER_PATIENT_REGION_FILTER ) {
      return this.regionFilterDetail(property);
    }
    if ( property.propertyKey === USER_PROPERTY.USER_PATIENT_ORG_FILTER ) {
      return this.organizationFilterDetail(property);
    }
    return null;
  }

  renderUserProperties(userProperties) {
    return (
      <Container>
        <Grid>
          {userProperties.map(property => this.renderUserPropertyDetail(property))}
        </Grid>
      </Container>
    );
  }

  render() {
    const currentUser = this.props.currentUser;
    const userProperties = currentUser.userProperties;
    const interestingProperties = this.withInterestingProperties(userProperties);
    if ( interestingProperties.length > 0 ) {
      return this.renderUserProperties(interestingProperties);
    } else {
      return null;
    }
  }
}

export default UserPropertyDetails;
