import React, { Component } from 'react'
import { Icon, Message } from 'semantic-ui-react'
import { format } from 'date-fns'
import { ENCOUNTER, FORMAT } from '../../../constant'

/**
 * Provides a component that displays a warning message when an encounter is
 * displayed as a patient snapshot
 * @param encounterDetail Encounter detail entity
 */
class PatientSnapshotWarning extends Component {

  previousEncounter(startDt) {
    const today = parseInt(format(new Date(), 'YYYYMMDD'), 10);
    const start = parseInt(format(startDt, 'YYYYMMDD'), 10);

    return start < today
  }

  futureEncounter(startDt) {
    const today = parseInt(format(new Date(), 'YYYYMMDD'), 10);
    const start = parseInt(format(startDt, 'YYYYMMDD'), 10);

    return start > today
  }

  proposedEncounter(encounter) {
    return encounter.status === ENCOUNTER.STATUS.PROPOSED;
  }

  proposedText(encounter, patient) {
    return "The content of this snapshot is based on the most current" +
      " clinical and claims data available to the Curation platform.";
  }

  previousText(encounter) {
    const startDt = encounter.start;
    return "The content of this snapshot is based on the last seen" +
      ` encounter for this patient, date: ${format(startDt, FORMAT.DATE_TIME)}.`;
  }

  futureText(encounter) {
    const startDt = encounter.start;
    return "The content of this snapshot is based on the next known" +
      ` encounter for this patient, date: ${format(startDt, FORMAT.DATE_TIME)}.`;
  }

  messageContent(encounter, patient) {
    const firstName = patient.firstName.toUpperCase();
    const lastName = patient.lastName.toUpperCase();
    const pre = "You are viewing a patient snapshot for patient: " +
       firstName + " " + lastName + ".";
    if ( this.proposedEncounter(encounter) ) {
      return pre + " " + this.proposedText(encounter);
    } else if ( this.previousEncounter(encounter.start) ) {
      return pre + " " + this.previousText(encounter);
    } else if ( this.futureEncounter(encounter) ) {
      return pre + " " + this.futureText(encounter);
    } else {
      return pre;
    }
  }

  renderMessage(encounter, patient) {
    const txt = this.messageContent(encounter, patient);
    return (
      <Message warning>
        <Icon name="warning circle" />
        <span>{txt}</span>
      </Message>
    );
  }

  render() {
    const detail = this.props.encounterDetail;
    if ( detail && detail.encounter && detail.encounter.patient ) {
      return this.renderMessage(detail.encounter, detail.encounter.patient);
    } else {
      return null;
    }
  }
}

export default PatientSnapshotWarning;
