import { Action, Dispatch } from 'redux'
// Local
import { HAS_FORM_UPDATES } from '../reducer/coder'

const update = (hasFormChanges: boolean) => ({
  type: HAS_FORM_UPDATES,
  payload: hasFormChanges
})

export const updateCoderState = (hasFormChanges: boolean) => (dispatch: Dispatch<Action>) => {
  dispatch(update(hasFormChanges))
}

export default updateCoderState
