import React, { useState } from 'react'
import { connect } from 'react-redux'
// Local
import * as formatUtils from '../../common/formatUtils'
import CoderEncounterHeaderButtons from './CoderEncounterHeaderButtons'
import { Encounter, ObjectValues, User } from '../../authenticated/types'
import { ENCOUNTER } from '../../../constant'
import CoderEncounterModal from './CoderEncounterModal'
import { updateCoderState as updateCoderStateImport } from '../../../action/coder'

/**
 * Provides an encounter detail header for Coder users
 *
 * @param encounter Encounter entity. Required
 * @param onAction Callback for header button action. Arguments: action
 */

type ReduxProps = {
  hasFormUpdates?: boolean | undefined
  updateCoderState?: any
}

type Props = ReduxProps & {
  onAction?: (action: any) => void
  encounter: Encounter | undefined
  currentUser: User
}

function CoderEncounterHeader(props: Props): JSX.Element | null {
  const {
    onAction,
    encounter,
    currentUser,
    hasFormUpdates,
    updateCoderState
  } = props

  const [ actionState, setActionState ] = useState<
    ObjectValues<typeof ENCOUNTER.ACTION> | undefined
  >(undefined)

  const onClickHeaderButton = (
    action: ObjectValues<typeof ENCOUNTER.ACTION>
  ) => {
    if (hasFormUpdates) {
      setActionState(action)
      return
    }
    if (onAction) {
      onAction(action)
      updateCoderState(false)
    }
  }

  if (!encounter) return null
  const date = formatUtils.formatEncounterHeaderDate(encounter.start)
  const patientName = formatUtils.formatPatientNameDob(
    encounter && encounter.patient
  )
  return (
    <>
      <CoderEncounterModal
        onAction={ onAction }
        actionState={ actionState }
        setActionState={ setActionState }
      />
      <h4 className="ui block top attached header main-schedule-header">
        <div className="time">{ date }</div>
        <div className="name">{ patientName }</div>
        <div className="header-arrows">
          <CoderEncounterHeaderButtons
            encounter={ encounter }
            onClick={ onClickHeaderButton }
            currentUser={ currentUser }
          />
        </div>
      </h4>
    </>
  )
}

const mapStateToProps = (state: any): any => ({
  hasFormUpdates: state.coder.hasFormUpdates
})

export default connect(mapStateToProps, {
  updateCoderState: updateCoderStateImport
})(CoderEncounterHeader)
