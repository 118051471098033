import {
  compose,
  filter,
  is,
  isEmpty,
  map,
  not,
  pipe,
  split,
  trim
} from 'ramda'
import React, { Fragment } from 'react'
import Highlighter from 'react-highlight-words'
import { Popup } from 'semantic-ui-react'
import { NLPPayload } from '../../json/nlpPayload'
// Local

type NLPFormatted = {
  pageText: string[]
  predictedText: string[]
  tooltip: JSX.Element | string
}

type Props = {
  payload: NLPPayload[]
}

const notIsEmpty = compose(not, isEmpty)

const doubleNewLine = /\n\n/

/**
 * Formats the page text into an array of strings to be rendered
 * as paragraphs in the UI
 */
const processPageText = pipe<string[], string[], string[], string[]>(
  split(doubleNewLine),
  map(trim),
  filter(notIsEmpty)
)

const reformattedNLPPayload = (payload: NLPPayload[]) =>
  payload.reduce((accumulator, currentValue) => {
    const pageText = processPageText(currentValue.pageText)
    const predictedText = is(String, currentValue.predictedText)
      ? [ currentValue.predictedText ]
      : currentValue.predictedText
    return [
      ...accumulator,
      {
        pageText,
        predictedText,
        tooltip: (
          <div>
            <div>{ `HCC${currentValue.hccCode}: ${currentValue.hccDescription}` }</div>
            <div>The highlighted language frequently coincides with one of the following conditions and may indicate a new diagnosis. If appropriate, please document in your visit note: </div>
              <ul>
                <li>{ `${currentValue.icd10Code}: ${currentValue.icd10Description}` }</li>
                <li>Other</li>
                <li>Unknown / unable to determine</li>
              </ul>
          </div>
        )
      }
    ]
  }, [] as NLPFormatted[])

function Highlight(props: { highlightedItem: string; tooltip: JSX.Element | string }) {
  const { highlightedItem, tooltip } = props
  // return <strong className="highlighted-text">{ children }</strong>
  return (
    <Popup
      content={ tooltip }
      mouseEnterDelay={ 1000 }
      trigger={ <span className="highlight">{ highlightedItem }</span> }
    />
  )
}

function Nlp(props: Props): JSX.Element {
  const { payload } = props
  return (
    <div className="nlp-document">
      { reformattedNLPPayload(payload).map(reformattedPayload =>
        reformattedPayload.pageText.map(textBlock => (
          <Fragment key={ textBlock }>
            <Highlighter
              highlightClassName="YourHighlightClass"
              searchWords={ reformattedPayload.predictedText }
              highlightTag={ highlightedItem =>
                Highlight({
                  highlightedItem: highlightedItem.children,
                  tooltip: reformattedPayload.tooltip
                })
              }
              autoEscape={ true }
              textToHighlight={ textBlock }
            />
          </Fragment>
        ))) }
    </div>
  )
}

export default Nlp
