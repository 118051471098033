import React from "react";
import { Container, Divider } from "semantic-ui-react";
import { format } from "date-fns";
import "./footer.css";

const FooterComponent = props => {
  return (
    <Container id="footer">
      <Divider className="footer-divider" />
      <div className="right-text">Curation Health Copyright &#169; {format(new Date(), "YYYY")}</div>
    </Container>
  );
};

export default FooterComponent;
