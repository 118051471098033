import React from 'react'

type Props = {
  items: string[]
}

function AlertBar(props: Props): JSX.Element {
  const { items } = props
  return (
    <div className="curation-ui-alert-bar" id="curation-ui-alert-bar">
      <div className="title">
        <div className="alert-badge">{ items.length }</div>
        <span>Action Recommended</span>
      </div>
    </div>
  )
}

export default AlertBar
