/* eslint-disable @typescript-eslint/naming-convention */
import { hasRole } from "./component/common/roleUtils"

export const BRANDING_IDENTITY = 'CURATION'
export const DISPLAY_MODE = { EMBEDDED: 'EMBEDDED', SMART: 'SMART' }
export const MOBILE_WIDTH = { minWidth: 320, maxWidth: 767 }
export const TABLET_WIDTH = { minWidth: 768, maxWidth: 991 }
export const COMPUTER_WIDTH = { minWidth: 992 }
export const LARGE_SCREEN_WIDTH = { minWidth: 1200, maxWidth: 1919 }
export const WIDESCREEN_WIDTH = { minWidth: 1920 }

export const LIST_PAGE_SIZE = 40
export const LIST_MAX_PAGES = 5

export const ONE_HOUR_MS = 3600000
export const TWENTY_FOUR_HOURS_MS = 86400000

export const ATHENA_CHROME_PLUGIN_URL =
  'https://chrome.google.com/webstore/detail/trust-healthcare-athena-i/dmaplghjlnoeeijjbnicelkialmkckko'

export const ATHENA_EDGE_PLUGIN_URL =
  'https://microsoftedge.microsoft.com/addons/detail/obeakimbaifmcoabfelgjcjiadpndake'

export const AUTH_STATUS = {
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILURE: 'LOGIN_FAILURE',
  MFA_REQUIRED:  'MFA_REQUIRED'
}

export const AUTH_TYPE = {
    EMAIL: 'EMAIL',
    TOTP: 'TOTP',
    QR_TOTP: 'QR-TOTP'
}

export const API_URL = {
  AUDIT: (action: string, entityType: string, entityId: string): string =>
    `/api/audit/${action}/${entityType}/${entityId}`,
  DASHBOARD_REVIEW_COUNTS: '/api/dashboard/review/count',
  DASHBOARD_REVIEW_DAILY: '/api/dashboard/review/daily',
  GET_APPOINTMENT_TYPES: '/api/appointment/types',
  CRON_JOB_EXECUTE: (
    jobId: string, tenantId: string
  ): string => `/api/admin/cron/job/${jobId}/tenant/${tenantId}/execute`,
  SCHEDULE_ENCOUNTER: (
    encounterId: string,
    practitionerId: string,
    start: number
  ) =>
    `/api/appointment/schedule/${encounterId}?practitionerId=${practitionerId}&start=${start}`,
  GET_ENCOUNTER_CONDITION_NOTES_WITH_ROLE: (encounterId: string, conditionId: string, role: string) =>
    '/api'.concat(role === ROLES.CDI_RESTRICTED ? '/restricted' : '', `/encounter/note/${encounterId}/condition/${conditionId}`),
  GET_ENCOUNTER_DETAIL_BY_ID: '/api/encounter/detail/',
  GET_ENCOUNTER_DETAIL_BY_ID_WITH_ROLE: (role: string): string =>
    '/api'.concat(role === ROLES.CDI_RESTRICTED ? '/restricted' : '', '/encounter/detail/'),
  GET_ENCOUNTER_LIST: '/api/encounter/',
  GET_ENCOUNTER_NOTES_WITH_ROLE: (id: string, role: string) =>
    '/api'.concat(role === ROLES.CDI_RESTRICTED ? '/restricted' : '', `/encounter/note/${id}`),
  GET_ORGANIZATION: (orgId: string) => `/api/organization/${orgId}`,
  GET_ORGANIZATIONS: '/api/organization',
  GET_PATIENT_WITH_ROLE: (role: string) =>
    '/api'.concat(role === ROLES.CDI_RESTRICTED ? '/restricted' : '', '/patient/enhanced_search'),
  GET_PREVIOUS_ENCOUNTERS_BY_SUBJECT_ID: (
    subjectId: string,
    encounterId?: string
  ) => `/api/encounter/previous/${subjectId}/${encounterId}`,
  GET_PREVIOUS_ENCOUNTERS_BY_SUBJECT_ID_WITH_ROLE: (
    role: string,
    subjectId: string,
    encounterId?: string
  ) => '/api'.concat(role === ROLES.CDI_RESTRICTED ? '/restricted' : '', `/encounter/previous/${subjectId}/${encounterId}`),
  GET_REGIONS: '/api/region',
  ENCOUNTER_SEARCH_WITH_ROLE: (role: string): string =>
      '/api'.concat(role === ROLES.CDI_RESTRICTED ? '/restricted' : '', '/encounter/search'),
  PATIENT_PROFILE_WITH_ROLE: (role: string): string =>
     '/api'.concat(role === ROLES.CDI_RESTRICTED ? '/restricted' : '', '/patient/profile/'),
  LOCK_ENCOUNTER_WITH_ROLE: (role: string, id: string) =>
    '/api'.concat(role === ROLES.CDI_RESTRICTED ? '/restricted' : '', `/encounter/lock/${id}`),
  UNLOCK_ENCOUNTER: (id: string) => `/api/encounter/unlock/${id}`,
  LOGIN: '/api/sso/login',
  LOGOUT: '/api/sso/logout',
  MFA_LOGIN: '/api/sso/login/mfa',
  MFA_TOTP_LOGIN: '/api/sso/login/mfa/totp',
  MFA_TOTP_QR_LOGIN: '/api/sso/login/mfa/totp/qr',
  MFA_QR_CODE: (qrCodeUuid: string) => `/api/sso/qr/${qrCodeUuid}`,
  MFA_CODE_RESEND: '/api/sso/login/mfa/resend',
  VERSION: '/api/version',
  USERS_CURRENT: '/api/user/current',
  COPY_ENCOUNTER: (
    currentEncounterId?: string,
    previousEncounterId?: string
  ) => `/api/encounter/${currentEncounterId}/copy/${previousEncounterId}`,
  GET_ENCOUNTER_COPIES: (encounterId: string | undefined) => `/api/encounter/${encounterId}/copies`,
  CREATE_CONDITION: '/api/encounter/condition',
  UPDATE_CONDITION: '/api/encounter/condition',
  CREATE_CONDITION_NOTE: '/api/encounter/note',
  CREATE_ENCOUNTER_NOTE: '/api/encounter/note',
  RESET_ENCOUNTER: ( encounterId: string ) => `/api/encounter/reset/${encounterId}`,
  UPDATE_CONDITION_RULE: '/api/encounter/condition/rule',
  UPDATE_CONDITION_QUERY_STATUS: (id: string) =>
    `/api/encounter/condition/${id}/query`,
  UPDATE_CONDITION_CDI_TAGS: (id: string) =>
    `/api/encounter/condition/${id}/tags/cdi`,
  UPDATE_CONDITION_CODER_TAGS: (id: string) =>
    `/api/encounter/condition/${id}/tags/coder`,
  UPDATE_CONDITION_PRACTITIONER_TAGS:
    '/api/encounter/condition/tags/practitioner',
  UPDATE_ENCOUNTER: '/api/encounter',
  UNLOCK_REVIEW: '/api/review/unlock',
  UPDATE_REVIEW: '/api/review',
  UPDATE_REVIEW_CODE: '/api/review/code',
  UPDATE_REVIEW_QUERY_STATUS: '/api/review/query',
  UPDATE_ENCOUNTER_STATUS_WITH_ROLE: (role: string) => 
    '/api'.concat(role === ROLES.CDI_RESTRICTED ? '/restricted' : '', '/encounter/status'),
  UPDATE_ENCOUNTER_TYPE: '/api/encounter/type',
  ICD10_SEARCH: '/api/icd10/search',
  CDI_QUERY_SEARCH: '/api/cdi/query/search',
  FEEDBACK: '/api/feedback',
  PATIENT_PROPERTY_VALUES: (key: string) =>
    `/api/patient/property/${key}/values`,
  PDF_ENCOUNTER_REPORT: (id: string) => `/api/pdf/encounter/${id}`,
  PDF_ENCOUNTER_FOR_CDI_REPORT: (id: string) => `/api/pdf/cdi/encounter/${id}`,
  PDF_HUDDLE_REPORT: (start: string) => `/api/pdf/huddle?startDt=${start}`,
  PDF_ORG_HUDDLE_REPORT: (orgId: string, start: Date) =>
    `/api/pdf/huddle/org/${orgId}?startDt=${start}`,
  PDF_PRACTITIONER_HUDDLE_REPORT: (practitionerId: string, start: string) =>
    `/api/pdf/huddle/practitioner/${practitionerId}?startDt=${start}`,
  PDF_REGION_HUDDLE_REPORT: (region: string, start: Date) =>
    `/api/pdf/huddle/region/${region}?startDt=${start}`,
  PRACTITIONER_SEARCH: (withOrg: string | boolean, query?: string) =>
    `/api/practitioner/search?withOrg=${withOrg}${
      query ? `&q=lastName:${query}%25` : ''
    }`,
  RAF_UPDATE: '/api/patient/raf',
  // REPORT_CDI_WORKLIST: '/api/report/worklist/cdi',
  REPORT_CDI_CLIENT_VIEW_WORKLIST: '/api/report/worklist/client/cdi',
  REPORT_CODER_WORKLIST: '/api/report/worklist/coder',
  REVIEW_COUNT: '/api/review/counts',
  PASSWORD_RESET: '/api/sso/reset',
  UNREGISTERED_USER: (token: string) => `/api/user/registration/${token}`,
  UNREGISTERED_USER_VALIDATE: (token: string) =>
    `/api/user/registration/${token}/validate`,
  UPDATE_USER: (userId: string) => `/api/user/${userId}/update`,
  NLP_TEXT: (documentId: string) => `/api/nlp/prediction/${documentId}`
}

export const CONDITION = {
  ACTION: {
    ACTIVATE: 'ACTIVATE',
    ADD_RULE: 'ADD_RULE',
    COLLAPSE_ALL: 'COLLAPSE_ALL',
    DEACTIVATE: 'DEACTIVATE',
    EXPAND_ALL: 'EXPAND_ALL',
    MARK_ACTIVE: 'MARK_ACTIVE',
    MARK_ACTIVITY: 'MARK_ACTIVITY',
    MARK_DISMISSED: 'MARK_DISMISSED',
    MARK_KNOWN: 'MARK_KNOWN',
    MARK_OPEN: 'MARK_OPEN',
    MARK_POTENTIAL: 'MARK_POTENTIAL',
    MARK_REMOVED: 'MARK_REMOVED',
    READ: 'READ',
    SET_QUERY_STATUS: 'SET_QUERY_STATUS',
    UPDATE: 'UPDATE',
    UPDATE_CDI_TAGS: 'UPDATE_CDI_TAGS',
    UPDATE_CODER_TAGS: 'UPDATE_CODER_TAGS',
    UPDATE_PRACTITIONER_TAGS: 'UPDATE_PRACTITIONER_TAGS',
    UPDATE_WITH_RULES: 'UPDATE_WITH_RULES'
  },
  ACTIVITY_MET_STATUS: {
    ACTIVITY_MET: 'ACTIVITY_MET',
    ACTIVITY_NOT_MET: 'ACTIVITY_NOT_MET',
    NOT_APPLICABLE: 'NOT_APPLICABLE'
  },
  CODE: {
    ADDRESSED: 'ADDRESSED',
    DISMISSED: 'DISMISSED',
    OPEN: 'OPEN',
    SEEN: 'SEEN',
    REMOVED: 'REMOVED',
    CLOSED: 'CLOSED'
  },
  KNOWN_POTENTIAL: 'KNOWN_POTENTIAL',
  NET_NEW: 'NET_NEW',
  ON_CLAIM_STATUS: {
    ON_CLAIM: 'ON_CLAIM',
    NOT_ON_CLAIM: 'NOT_ON_CLAIM'
  },
  PRACTITIONER_RESPONSE: {
    NONE: 'NONE'
  },
  PRIORITY: 'PRIORITY',
  QUERY_STATUS: {
    QUERY_OUTSTANDING: 'QUERY_OUTSTANDING',
    QUERY_RESPONSE_RECEIVED: 'QUERY_RESPONSE_RECEIVED',
    QUERY_RESPONSE_NOT_RECEIVED: 'QUERY_RESPONSE_NOT_RECEIVED'
  },
  REPORTABLE_STATUS: {
    REPORTABLE: 'REPORTABLE',
    NOT_REPORTABLE: 'NOT_REPORTABLE'
  },
  REPORTABLE_HEADER: {
    NOT_DOCUMENTED: 'NOT_DOCUMENTED',
    CODING_DETAILS: 'CODING_DETAILS',
    OTHER: 'OTHER'
  },
  REPORTABLE_REASON: {
    ACUTE_VISIT: 'ACUTE_VISIT',
    CODE_NOT_IN_MAX_ALLOWABLE_BILLED: 'CODE_NOT_IN_MAX_ALLOWABLE_BILLED',
    CODER_RECOMMEND_ADD_DX: 'CODER_RECOMMEND_ADD_DX',
    CODER_RECOMMEND_DELETE_DX: 'CODER_RECOMMEND_DELETE_DX',
    CODER_RECOMMEND_MODIFY_DX: 'CODER_RECOMMEND_MODIFY_DX',
    CODING_CONFLICT: 'CODING_CONFLICT',
    CONDITION_NOT_DOCUMENTED_OR_ADDRESSED:
      'CONDITION_NOT_DOCUMENTED_OR_ADDRESSED',
    CONDITION_REPORTED_RESOLVED: 'CONDITION_REPORTED_RESOLVED',
    CONFLICTING_DOCUMENTATION: 'CONFLICTING_DOCUMENTATION',
    DOES_NOT_SATISFY_GUIDELINES: 'DOES_NOT_SATISFY_GUIDELINES',
    DOES_NOT_MEET_GUIDELINES: 'DOES_NOT_MEET_GUIDELINES',
    EXCLUDES_NOTES: 'EXCLUDES_NOTES',
    LACK_OF_HISTORICAL_DATA: 'LACK_OF_HISTORICAL_DATA',
    LESS_SPECIFIC_DX_DOCUMENTED: 'LESS_SPECIFIC_DX_DOCUMENTED',
    NOT_DOCUMENTED_DURING_ENCOUNTER: 'NOT_DOCUMENTED_DURING_ENCOUNTER',
    MORE_OR_LESS_SPECIFIC_CODE_DOCUMENTED:
      'MORE_OR_LESS_SPECIFIC_CODE_DOCUMENTED',
    MORE_SPECIFIC_CONDITION_DOCUMENTED: 'MORE_SPECIFIC_CONDITION_DOCUMENTED',
    OTHER: 'OTHER',
    PATIENT_REFUTES_DIAGNOSIS_NO_RECORD_TO_VALIDATE:
      'PATIENT_REFUTES_DIAGNOSIS_NO_RECORD_TO_VALIDATE',
    PROVIDER_DOCUMENTATION_SUPPORTS_CODE:
      'PROVIDER_DOCUMENTATION_SUPPORTS_CODE',
    SIGNS_SYMPTOMS_AS_PDX: 'SIGNS_SYMPTOMS_AS_PDX',
    SUSPECTED_VS_CONFIRMED: 'SUSPECTED_VS_CONFIRMED'
  },
  RULE: {
    ACTION: {
      CREATE: 'CREATE',
      UPDATE: 'UPDATE'
    }
  },
  STATUS: {
    ACTIVE: 'ACTIVE',
    HIDDEN: 'HIDDEN',
    REMOVED: 'REMOVED'
  },
  TARGET_SOURCE_CODE: {
    CURATION: 'CURATION',
    EXTERNAL: 'EXTERNAL'
  },
  TYPE: {
    KNOWN: 'KNOWN',
    POTENTIAL: 'POTENTIAL',
    ACTIVITY: 'ACTIVITY'
  },
  CHECKMARK: {
    GREEN: 'GREEN',
    NONE: 'NONE'
  }
} as const

export const CLAIM = {
  SOURCE_DISPLAY_NAMES: {
    BILLED_CPTS: 'Billed CPTs',
    ADJUDICATED_DIAGNOSES: 'Adjudicated Claims',
    BILLED_DIAGNOSES: 'Billed Diagnoses',
    COVID: 'COVID Diagnosis',
    EXTERNAL: 'External',
    OBSERVATIONS_LABS: 'Observations/Labs',
    QUALITY: 'Quality Measures',
    RX: 'Rx Medications',
    NLP_DOCUMENT: 'NLP Document'
  }
}

export const CODER = {
  QUERY_EXPIRE_HOURS_DEFAULT: 24,
  WORKLIST_QUERY_START_DAYS: 90,
  WORKLIST_QUERY_END_HOURS: 3
}

export const DEFAULT_PRACTITIONER = {
    OPTUM: 'cc8cdc07-cef1-46de-aac4-3c52bc21f755'
}

export const ENCOUNTER = {
  ACTION: {
    LOCK_TOGGLE: 'LOCK_TOGGLE',
    FOLLOW_UP_TOGGLE: 'FOLLOW_UP_TOGGLE',
    MARK_CODER_COMPLETE: 'MARK_CODER_COMPLETE',
    MARK_OPEN: 'MARK_OPEN',
    MARK_READY_FOR_CDI_QA: 'MARK_READY_FOR_CDI_QA',
    MARK_READY_FOR_CODER: 'MARK_READY_FOR_CODER',
    MARK_READY_FOR_CODER_QA: 'MARK_READY_FOR_CODER_QA',
    MARK_READY_FOR_PHYSICIAN: 'MARK_READY_FOR_PHYSICIAN',
    NEXT_ENCOUNTER: 'NEXT_ENCOUNTER',
    PREVIOUS_ENCOUNTER: 'PREVIOUS_ENCOUNTER',
    REVIEW_QUERY_OUTSTANDING: 'REVIEW_QUERY_OUTSTANDING',
    REVIEW_QUERY_RESOLVED: 'REVIEW_QUERY_RESOLVED',
    STATUS_TOGGLE: 'STATUS_TOGGLE',
    STATUS_TOGGLE_CANCELLED: 'STATUS_TOGGLE_CANCELLED',
    STATUS_TOGGLE_IGNORED: 'STATUS_TOGGLE_IGNORED',
    STATUS_TOGGLE_PLANNED: 'STATUS_TOGGLE_PLANNED',
    TYPE_TOGGLE: 'TYPE_TOGGLE',
    WORKLIST: 'WORKLIST'
  },
  STATUS: {
    ARRIVED: 'ARRIVED',
    CANCELLED: 'CANCELLED',
    FINISHED: 'FINISHED',
    IGNORED: 'IGNORED',
    IN_PROGRESS: 'IN_PROGRESS',
    PLANNED: 'PLANNED',
    PROPOSED: 'PROPOSED'
  },
  TYPE: {
    TELEHEALTH: 'TELEHEALTH',
    TELEPHONIC: 'TELEPHONIC',
    UNKNOWN: 'UNKNOWN'
  }
}

export const ERROR = {
  TYPE: {
    EXPIRED_TOKEN: 'EXPIRED_TOKEN'
  }
}

export const FORMAT = {
  API_DATE: 'YYYY-MM-DDTHH:mm:ss.SSSZ',
  DATE: 'M/D/YYYY',
  DATE_TIME: 'M/D/YYYY h:mm A',
  DATE_TIME_NO_YEAR: 'M/D h:mm A',
  DAY_DATE: 'ddd M/D/YYYY',
  TIME: 'h:mm A',
  TIME_DATE: ' h:mm A, M/D/YYYY',
  TIME_DATE_NO_YEAR: 'h:mm A, M/D',
  TIMESTAMP: 'M/D/YYYY, HH:mm:ss',
  YMD: 'YYYY-MM-DD'
}

export const HCC_GROUP = {
  ACTION: {
    ACTIVATE: 'ACTIVATE',
    DEACTIVATE: 'DEACTIVATE'
  }
}

export const LOCK = {
  STATUS: {
    ACTIVE: 'ACTIVE',
    DISABLED: 'DISABLED'
  }
}

export const MESSAGE = {
  AUTHENTICATE: 'Invalid username/password.  Please try again.',
  MAX_LOGIN: 'Your account has reached the maximum number of failed login attempts. ' +
    'Please contact Client Success to reset your account.',
  ERROR:
    'An error has occurred and you have been logged out. ' +
    ' If you continue to receive this error, please notify Curation Health' +
    ' at it@curationhealth.co',
  LOGOUT: 'You have been logged out',
  MFA_ERROR: 'We do not recognize this code or your code has expired'
}

export const NOTE = {
  ACTION: {
    CREATE: 'CREATE'
  },
  REASON: {
    CODER_QUERY: 'CODER_QUERY',
    OPEN: 'OPEN',
    PHYSICIAN_ADDRESSED: 'PHYSICIAN_ADDRESSED'
  },
  STATUS: {
    READ: 'READ',
    UNREAD: 'UNREAD'
  }
}

export const PAGES = {
  // public
  AUTO_LOGIN: '/auto-login',
  LOGIN: '/login',
  PASSWORD_RESET: '/reset/:token',
  REGISTER: '/register/:token',
  // protected
  ACCOUNT: '/auth/account',
  EMPTY_SCOPE: '/auth/smart/empty',
  ENCOUNTER_DETAIL: '/auth/encounter/detail/',
  ENCOUNTER_LIST: '/auth/encounter',
  HOME: '/auth/home/',
  LOGOUT: '/auth/logout',
  PATIENT_SNAPSHOT: '/auth/patient/snapshot/',
  PATIENT_LIST: '/auth/patient',
  REPORT: '/auth/report',
  SMART_ENCOUNTER_DETAIL: '/auth/smart/encounter/detail/',
  EMBEDDED_ENCOUNTER_DETAIL: '/auth/embedded/encounter/detail/',
  VERSION: '/auth/version'
}

export const PATIENT = {
  STATUS: {
    ACTIVE: 'ACTIVE'
  }
}

export const PATIENT_PROPERTY = {
  QUALITY_PROGRAM: 'quality_program.name'
}

export const PATIENT_RULES = {
  PATIENT_RULES_FILTERS: 'STANDARD,CLAIM',
  PATIENT_RULE_RISK_CATEGORY_VERSION: 'CMS_V24'
}

// Note: these are just the prefixes of the respective code. E.g. CARD01, NEPH01, ONC01. If we ever have CARD08 filter 
export const PRACTITIONER_SPECIALTIES = {
  BEH: 'Behavioral Health',
  CARD: 'Cardiology',
  END: 'Endocrinology',
  NEPH: 'Nephrology',
  NEU: 'Neurology',
  ONC: 'Oncology',
  OPTHA: 'Ophthalmology',
  ORTH: 'Orthopedics',
  PULM: 'Pulmonology'
}

export const RAF = {
  CODE: {
    CURRENT: 'CURRENT',
    POTENTIAL: 'POTENTIAL'
  },
  STATUS: {
    ACTIVE: 'ACTIVE',
    HISTORY: 'HISTORY'
  }
}

export const REVIEW = {
  CODE: {
    OPEN: 'OPEN',
    READY_FOR_CDI_QA: 'READY_FOR_CDI_QA',
    READY_FOR_PHYSICIAN: 'READY_FOR_PHYSICIAN',
    CODER_BYPASSED: 'CODER_BYPASSED',
    READY_FOR_CODER: 'READY_FOR_CODER',
    READY_FOR_CODER_QA: 'READY_FOR_CODER_QA',
    COMPLETED: 'COMPLETED',
    REMOVED: 'REMOVED',
    LOCKED: 'LOCKED'
  },
  QUERY_STATUS: {
    REVIEW_QUERY_OUTSTANDING: 'REVIEW_QUERY_OUTSTANDING',
    REVIEW_QUERY_RESPONSE_RECEIVED: 'REVIEW_QUERY_RESPONSE_RECEIVED'
  }
}

export const ROLES = {
  ADMIN: 'ADMIN',
  CDI: 'CDI',
  CDI_QA_MANAGER: 'CDI_QA_MANAGER',
  CDI_RESTRICTED: 'CDI_RESTRICTED',
  CLINICAL_ADMIN: 'CLINICAL_ADMIN',
  CODER: 'CODER',
  CODER_QA_MANAGER: 'CODER_QA_MANAGER',
  PHYSICIAN: 'PHYSICIAN',
  PATIENT_SNAPSHOT: 'PATIENT_SNAPSHOT',

  isCdi: (role: any) =>
     role === ROLES.CDI || role === ROLES.CDI_QA_MANAGER || role === ROLES.CDI_RESTRICTED
}

export const RULE_TYPE_CODES = {
  AMPUTATION: {
    key: 'AMPUTATION',
    displayName: CLAIM.SOURCE_DISPLAY_NAMES.BILLED_CPTS
  },
  CLAIM: {
    key: 'CLAIM',
    displayName: CLAIM.SOURCE_DISPLAY_NAMES.ADJUDICATED_DIAGNOSES
  },
  CLAIM_BILLED: {
    key: 'CLAIM_BILLED',
    displayName: CLAIM.SOURCE_DISPLAY_NAMES.BILLED_DIAGNOSES
  },
  COMBO: {
    key: 'COMBO',
    displayName: CLAIM.SOURCE_DISPLAY_NAMES.BILLED_DIAGNOSES
  },
  CONDITION: {
    key: 'CONDITION',
    displayName: CLAIM.SOURCE_DISPLAY_NAMES.OBSERVATIONS_LABS
  },
  COVID: {
    key: 'COVID',
    displayName: CLAIM.SOURCE_DISPLAY_NAMES.COVID
  },
  DEVICE: {
    key: 'DEVICE',
    displayName: CLAIM.SOURCE_DISPLAY_NAMES.BILLED_CPTS
  },
  EXTERNAL: {
    key: 'EXTERNAL',
    displayName: CLAIM.SOURCE_DISPLAY_NAMES.EXTERNAL
  },
  MEDICATION: {
    key: 'MEDICATION',
    displayName: CLAIM.SOURCE_DISPLAY_NAMES.RX
  },
  OBSERVATION: {
    key: 'OBSERVATION',
    displayName: CLAIM.SOURCE_DISPLAY_NAMES.OBSERVATIONS_LABS
  },
  QUALITY: {
    key: 'QUALITY',
    displayName: CLAIM.SOURCE_DISPLAY_NAMES.QUALITY
  },
  NLP_DOCUMENT: {
    key: 'NLP_DOCUMENT',
    displayName: CLAIM.SOURCE_DISPLAY_NAMES.NLP_DOCUMENT
  }
}

export const RULE_FILTER_TYPE_CODES = {
  STANDARD: 'STANDARD',
  CLAIM: 'CLAIM',
  TECH_ONLY: 'TECH_ONLY',
  SPECIALTY: 'SPECIALTY'
}

/**
SELECT '  ''' || id || ''':' || CASE WHEN filter_internal_id LIKE 'DYN%' THEN 'RULE_FILTER_TYPE_CODES.TECH_ONLY,' ELSE 'RULE_FILTER_TYPE_CODES.SPECIALTY,' END || ' // ' || filter_internal_id
  FROM t_rule_filter
 WHERE filter_type_code = 'DYNAMIC'
   AND status_code = 'ACTIVE'
 ORDER BY filter_order, filter_internal_id
*/
export const RULE_FILTER_DYNAMIC_TYPES_BY_ID = {
  'c7221ed3-30e5-48f7-ba91-a826c11e0bdf': RULE_FILTER_TYPE_CODES.TECH_ONLY, // DYN002B
  'f27960b7-798c-47f8-b626-4404218ef081': RULE_FILTER_TYPE_CODES.SPECIALTY, // BEH01
  '346c7469-7145-4039-9ebb-c308461ffc88': RULE_FILTER_TYPE_CODES.SPECIALTY, // CARD01
  '16cc351b-8be5-401b-9770-0a34f95bbd7a': RULE_FILTER_TYPE_CODES.SPECIALTY, // END01
  '77b03389-8efe-41f7-86f2-bebc3a89848b': RULE_FILTER_TYPE_CODES.SPECIALTY, // NEPH01
  'fce8f039-00a9-4f6e-b48d-c3f719fc3da4': RULE_FILTER_TYPE_CODES.SPECIALTY, // NEU01
  'a3b7ebf1-5496-4b2f-b221-a33f7ee013cf': RULE_FILTER_TYPE_CODES.SPECIALTY, // ONC01
  '73a85492-2fcc-4df2-b704-aec05af254a9': RULE_FILTER_TYPE_CODES.SPECIALTY, // OPTHA01
  '4c2d0585-1177-4130-81fe-c1ea0589974c': RULE_FILTER_TYPE_CODES.SPECIALTY, // ORTH01
  '5a33044d-5305-4400-baa2-2ee782a89e76': RULE_FILTER_TYPE_CODES.SPECIALTY  // PULM01
}

export const TENANT = {
  CONCERTOCARE: '265da02b-60d5-4509-bda7-469826280fa1',
  DEVOTED: 'ec5c2203-dabc-4853-abfa-edcaf30af2fe',
  OPTUM: '0eef8043-1139-4986-9023-9e73ee80c9f0'
}

export const TENANT_PROPERTY = {
  ENCOUNTER_CDI_COPY_PASTE_ENABLED: 'encounter.cdi.copy.paste.enabled',
  ENCOUNTER_CDI_RULE_FILTER_AND_RULE_RISK_CATEGORY_VERSION_ENABLED:
    'encounter.cdi.rule.filter.and.rule.risk.category.version.enabled',
  ENCOUNTER_CONDITION_DISPLAY_FEATURE_VERSION:
    'encounter.condition.display.feature.version',
  ENCOUNTER_CONDITION_CDI_DISPLAY_FEATURE_VERSION:
    'encounter.condition.cdi.display.feature.version',
  ENCOUNTER_CONDITION_CLINICAL_ADMIN_DISPLAY_FEATURE_VERSION:
    'encounter.condition.clinical.admin.display.feature.version',
  ENCOUNTER_CONDITION_DISPLAY_NLP_INSIGHTS:
    'encounter.condition.display.nlp.insights',
  ENCOUNTER_CONDITION_DISPLAY_GROUPS: 'encounter.condition.display.groups',
  ENCOUNTER_CONDITION_DISPLAY_GROUPING: 'encounter.condition.display.grouping',
  ENCOUNTER_CONDITION_CLINICAL_ADMIN_DISMISS_ENABLED:
    'encounter.condition.clinical.admin.dismiss.enabled',
  ENCOUNTER_CONDITION_EXPECTED_DIAGNOSIS_ENABLED:
    'encounter.condition.expected.diagnosis.enabled',
  ENCOUNTER_PRACTITIONER_TAGS_ENABLED: 'encounter.practitioner.tags.enabled',
  ENCOUNTER_PRACTITIONER_TAGS_VALUE: 'encounter.practitioner.tags.value',
  PATIENT_TAB_PROVIDER_ENABLED: 'patient.tab.provider.enabled',
  PATIENT_TAB_PROVIDER_SCHEDULE_ENABLED:
    'patient.tab.provider.schedule.enabled',
  PATIENT_TAB_CDI_SCHEDULE_ENABLED: 'patient.tab.cdi.schedule.enabled',
  REVIEW_CODER_QUERY_WINDOW_HOURS: 'review.coder.query_window_hours',
  VBC_DISPLAY_ENABLED: 'vbc.display.enabled',
  WORKLIST_CODER_QUERY_END_HOURS: 'worklist.coder.query.end.hours',
  WORKLIST_CODER_QUERY_START_DAYS: 'worklist.coder.query.start.days',
  TIMEZONE: 'timezone',
  ENCOUNTER_CONDITION_KNOWN_DISMISS_ENABLED:
    'encounter.condition.known.dismiss.enabled',
  OFFSHORE_FILTER_ENABLED: 'user.patient.offshore.filter.enabled'  
}

export const USER_PROPERTY = {
  ENCOUNTER_CONDITION_DISPLAY_FEATURE_VERSION:
    'encounter.condition.display.feature.version',
  ENCOUNTER_CONDITION_CLINICAL_ADMIN_DISPLAY_FEATURE_VERSION:
    'encounter.condition.clinical.admin.display.feature.version',
  ENCOUNTER_CONDITION_DISPLAY_NLP_INSIGHTS:
    'encounter.condition.display.nlp.insights',
  USER_PATIENT_ORG_FILTER: 'user.patient.organization.filter',
  USER_PATIENT_REGION_FILTER: 'user.patient.region.filter',
  USER_PATIENT_OFFSHORE_FILTER: 'user.patient.offshore.filter'
}

export const CRON_JOB = {
  DEMO_DATA_JOB_ID: '2e62438c-08da-45e8-88e7-cd0a4621c7e6'
}

export const WORKLIST_VIEW = {
  CODER: {
    WORKLIST: 'WORKLIST',
    REVIEW_REQUEST: 'REVIEW_REQUEST',
    READY_FOR_CODER_QA: 'READY_FOR_CODER_QA',
    READY_FOR_PHYSICIAN: 'READY_FOR_PHYSICIAN',
    COMPLETED: 'COMPLETED',
    LOCKED: 'LOCKED'
  },
  CDI: {
    WORKLIST: 'WORKLIST',
    CANCELLED: 'CANCELLED',
    LOCKED: 'LOCKED',
    OPEN: 'OPEN',
    READY_FOR_CDI_QA: 'READY_FOR_CDI_QA',
    READY_FOR_PHYSICIAN: 'READY_FOR_PHYSICIAN',
    REVIEW_REQUEST: 'REVIEW_REQUEST'
  }
}

export const CODER_ON_CLAIM_STATUSES = [
  {
    key: CONDITION.ON_CLAIM_STATUS.ON_CLAIM,
    text: 'On claim'
  },
  {
    key: CONDITION.ON_CLAIM_STATUS.NOT_ON_CLAIM,
    text: 'Not on claim'
  }
]

export const CODER_REPORTABLE_STATUSES = [
  {
    key: CONDITION.REPORTABLE_STATUS.REPORTABLE,
    text: 'Reportable'
  },
  {
    key: CONDITION.REPORTABLE_STATUS.NOT_REPORTABLE,
    text: 'Not reportable'
  }
]