import React, { Component } from "react";
import { Grid } from "semantic-ui-react";
import ReactMarkdown from "react-markdown";
import ConditionAddressedDate from "../common/conditionAddressedDate";
import ConditionReasons from "../common/conditionReasons";

/**
 * @param condition Condition entity
 */
class CdiConditionDetail extends Component {

  render() {
    const condition = this.props.condition;

    return (
      <Grid.Row className="first-row">
        <Grid.Column>
          <div>
            <ConditionReasons condition={condition} />
            <ReactMarkdown children={condition.content} linkTarget="_blank" />
            <ConditionAddressedDate condition={condition} />
          </div>
        </Grid.Column>
      </Grid.Row>
    );
  }
}

export default CdiConditionDetail;
