import React, { Component } from "react";
import { Dropdown, Responsive } from 'semantic-ui-react';
import { API_URL } from "../../constant";
import { api } from "../../action/api";

/**
 * Provides a stand-alone appointment type select widget
 * @param onChange Callback function on appointment type select
 *   Arguments: (e, {name, value}) where value is the appointment type name or
 *   "" if the ALL option is selected
 * @param name Name of the select widget in a form context. Default:
 *   appointmentTypeSelect
 * @param value Initial selected appointment type
 * @param placeholder Placeholder text. Default: Appointment Types
 * @param multiple Allow multiple selections. Default: true
 * @param allOption If true, include an 'ALL' option. Default: true
 */
class AppointmentTypeSelect extends Component {

  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);

    this.state = {
      appointmentTypes: [],
      multiple: this.props.multiple === false ? false : true,
      allOption: this.props.allOption === false ? false : true
    }
  }

  componentDidMount() {
    this.fetchAppointmentTypes();
  }

  fetchAppointmentTypes() {
    const url = API_URL.GET_APPOINTMENT_TYPES;
    api.get(url).then(({ data }) => {
      this.setState({appointmentTypes: data});
    });
  }

  onChange = (e, { value }) => {
    if ( this.props.onChange ) {
      const name = this.props.name ? this.props.name : "appointmentTypeSelect";
      const val = value === 'ALL' ? null : value;
      this.props.onChange(e, {name: name, value: val});
    }
  }

  render() {
    const options = this.state.appointmentTypes.map(aptType => {
      return {
        key: aptType,
        value: aptType,
        text: aptType
      };
    });
    if ( this.state.allOption === true ) {
      options.unshift({ key: "ALL", text: "All", value: "ALL" });
    }
    const placeholder = this.props.placeholder ? this.props.placeholder : "Appointment Types";
    if ( this.state.multiple === true ) {
      const value = this.props.value ? this.props.value : [];
      return (
        <Responsive
          as={Dropdown}
          fluid multiple selection
          placeholder={placeholder}
          onChange={this.onChange}
          options={options}
          value={value} />
      );
    } else {
      const value = this.props.value ? this.props.value : undefined;
      return (
        <Responsive
          as={Dropdown}
          fluid selection
          placeholder={placeholder}
          onChange={this.onChange}
          options={options}
          value={value} />
      );
    }
  }
}

export default AppointmentTypeSelect;
