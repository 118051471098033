/* eslint-disable max-len */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Button, Form, Message, Radio } from 'semantic-ui-react'
import { format } from 'date-fns'
import { AxiosResponse } from 'axios'
import { equals } from 'ramda'
// Local
import {
  coderReportableHeaders,
  coderReportableReasonsByHeader,
  coderTagsDropdownValidation,
  condition as conditionUtils,
  showCoderReportableDiagnosisCodeEdit
} from '../../common/conditionUtils'
import {
  EncounterConditionCoderTagsRow,
  EncounterConditionDisplay,
  ObjectValues
} from '../../authenticated/types'
import If from '../../common/if'
import utils, { modifyCoderTags } from './utils'
import { API_URL, CONDITION, FORMAT } from '../../../constant'
import { api } from '../../../action/api'
import {
  formatIcd10Code,
  formatIcd10Json,
  rawIcd10Code
} from '../../common/formatUtils'
// Components
import CoderDiagnosisCodeEditor from './CoderDiagnosisCodeEditor'
import { updateCoderState as updateCoderStateImport } from '../../../action/coder'

type ConditionClaimStatus = ObjectValues<typeof CONDITION.ON_CLAIM_STATUS>

type Props = {
  condition: EncounterConditionDisplay
  onConditionAction: (condition: EncounterConditionDisplay) => void
  isFormSaved: boolean
  activeConditionID: string
  updateCoderState?: any
}

function CoderConditionEditor(props: Props): JSX.Element {
  const {
    condition,
    onConditionAction,
    isFormSaved,
    activeConditionID,
    updateCoderState
  } = props

  const [ conditionState, setConditionState ] =
    useState<EncounterConditionDisplay>(condition)
  const [ isSavingForm, setIsSavingForm ] = useState<boolean>(false)

  const coderTags = modifyCoderTags(conditionState)

  const coderUtils = utils(conditionState)

  const areCoderTagsUpdated = !equals(
    condition.coderTags,
    conditionState.coderTags
  )

  useEffect(() => {
    updateCoderState(areCoderTagsUpdated)
  }, [ areCoderTagsUpdated ])

  const hasMoreOrLessSpecificCodeDocumentedWithoutICD10 =
    !!conditionState.coderTags &&
    conditionState.coderTags.reportableReason ===
      CONDITION.REPORTABLE_REASON.MORE_OR_LESS_SPECIFIC_CODE_DOCUMENTED &&
    !!conditionState.coderTags &&
    !conditionState.coderTags.conditionDiagnosisCode

  const isDisabled =
    !areCoderTagsUpdated ||
    (!coderUtils.hasReportableStatusCode &&
      !coderUtils.hasReportableReason &&
      !coderUtils.hasReportableReasontext) ||
    hasMoreOrLessSpecificCodeDocumentedWithoutICD10

  const [ shouldShowReportableErrorMsg, setShouldShowReportableErrorMsg ] =
    useState(
      conditionUtils.isNotReportable(conditionState) &&
        !!conditionState.coderTags &&
        coderTagsDropdownValidation(conditionState.coderTags)
    )

  const onChangeQueryStatus = (queryStatusCode: string) => {
    setConditionState(
      coderTags({
        queryStatusCode
      })
    )
  }

  const formattedICD10JSON = formatIcd10Json(conditionState.icd10CodeJson)[0]

  const formattedICD10CodeOrNull =
    conditionState.icd10CodeJson && formattedICD10JSON
      ? formattedICD10JSON
      : null

  const isNotOnClaimButIsReportablePayload = {
    reportableStatusCode: CONDITION.REPORTABLE_STATUS.REPORTABLE,
    reportableHeaderCode: null,
    reportableReason:
      CONDITION.REPORTABLE_REASON.PROVIDER_DOCUMENTATION_SUPPORTS_CODE,
    reportableText: null,
    conditionDiagnosisCode: rawIcd10Code(formattedICD10CodeOrNull)
  }

  const onChangeOnClaim = (onClaimStatusCode: ConditionClaimStatus) => {
    const isNotOnClaimButIsReportable =
      !!conditionState.coderTags &&
      conditionState.coderTags.reportableHeaderCode ===
        CONDITION.REPORTABLE_STATUS.REPORTABLE &&
      onClaimStatusCode === CONDITION.ON_CLAIM_STATUS.NOT_ON_CLAIM
    if (isNotOnClaimButIsReportable) {
      setConditionState(coderTags(isNotOnClaimButIsReportablePayload))
    } else {
      setConditionState(coderTags({ onClaimStatusCode }))
    }
  }

  const handleReportableReasonClick = (
    reportableHeaderCode: ObjectValues<typeof CONDITION.REPORTABLE_HEADER>
  ) => {
    setConditionState(
      coderTags({
        reportableHeaderCode,
        reportableReason: null,
        reportableText: null,
        conditionDiagnosisCode: null
      })
    )
  }

  const handleCodingOptionClick = (reportableReason: string) => {
    setConditionState(
      coderTags({
        reportableReason,
        reportableText: null,
        conditionDiagnosisCode: null
      })
    )
    setShouldShowReportableErrorMsg(false)
  }

  const onChangeConditionActivity = (activityMetStatusCode: string) => {
    setConditionState(
      coderTags({
        activityMetStatusCode
      })
    )
  }

  const isCheckedConditionActivity = (
    activityMetStatus: ObjectValues<typeof CONDITION.ACTIVITY_MET_STATUS>
  ): boolean =>
    !!conditionState.coderTags &&
    conditionState.coderTags.activityMetStatusCode === activityMetStatus

  const setQueryStatus =
    async (): Promise<EncounterConditionDisplay | null> => {
      const queryStatusCode = conditionState.coderTags
        ? conditionState.coderTags.queryStatusCode
        : null
      if (queryStatusCode) {
        const url = `${API_URL.UPDATE_CONDITION_QUERY_STATUS(
          conditionState.id
        )}/${queryStatusCode}`
        const { data }: AxiosResponse<EncounterConditionDisplay> =
          await api.put(url, conditionState)
        return data
      }
      return null
    }

  const updateCoderTags = async (): Promise<EncounterConditionCoderTagsRow> => {
    const url = API_URL.UPDATE_CONDITION_CODER_TAGS(conditionState.id)
    const { data }: AxiosResponse<EncounterConditionCoderTagsRow> =
      await api.put(url, conditionState.coderTags)
    return data
  }

  const onSubmitForm = async (): Promise<void> => {
    setIsSavingForm(true)
    const coderTagsUpdated = await updateCoderTags()
    const conditionUpdated = await setQueryStatus()
    setIsSavingForm(false)
    if (conditionUpdated) {
      onConditionAction(conditionUpdated)
    } else {
      onConditionAction({
        ...conditionState,
        coderTags: coderTagsUpdated
      })
    }
  }

  const onChangeReportableStatusCode = (
    reportableStatusCode: ObjectValues<typeof CONDITION.REPORTABLE_STATUS>
  ) => {
    if (reportableStatusCode === CONDITION.REPORTABLE_STATUS.NOT_REPORTABLE) {
      setShouldShowReportableErrorMsg(true)
    }
    const isNotOnClaimButIsReportable =
      !!conditionState.coderTags &&
      conditionState.coderTags.onClaimStatusCode ===
        CONDITION.ON_CLAIM_STATUS.NOT_ON_CLAIM &&
      reportableStatusCode === CONDITION.REPORTABLE_STATUS.REPORTABLE
    if (isNotOnClaimButIsReportable) {
      setConditionState(coderTags(isNotOnClaimButIsReportablePayload))
    } else {
      setConditionState(
        coderTags({
          reportableStatusCode,
          reportableHeaderCode: null,
          reportableReason: null,
          reportableText: null,
          conditionDiagnosisCode: null
        })
      )
    }
  }

  return (
    <div>
      <If isTrue={ coderUtils.shouldShowActivityEditor }>
        <form>
          <Form.Group inline>
            <label className="bold">Activity Met</label>
            <Form.Field
              control={ Radio }
              label="Yes"
              name="activityMetGroup"
              value={ CONDITION.ACTIVITY_MET_STATUS.ACTIVITY_MET }
              checked={ isCheckedConditionActivity(
                CONDITION.ACTIVITY_MET_STATUS.ACTIVITY_MET
              ) }
              onChange={ (e: any, radioValue: any) =>
                onChangeConditionActivity(radioValue.value as string)
              }
              className="m-1-top"
            />
            <Form.Field
              control={ Radio }
              label="No"
              name="activityMetGroup"
              value={ CONDITION.ACTIVITY_MET_STATUS.ACTIVITY_NOT_MET }
              checked={ isCheckedConditionActivity(
                CONDITION.ACTIVITY_MET_STATUS.ACTIVITY_NOT_MET
              ) }
              onChange={ (e: any, radioValue: any) =>
                onChangeConditionActivity(radioValue.value as string)
              }
            />
            <Form.Field
              control={ Radio }
              label="Not Applicable"
              name="activityMetGroup"
              value={ CONDITION.ACTIVITY_MET_STATUS.NOT_APPLICABLE }
              checked={ isCheckedConditionActivity(
                CONDITION.ACTIVITY_MET_STATUS.NOT_APPLICABLE
              ) }
              onChange={ (e: any, radioValue: any) =>
                onChangeConditionActivity(radioValue.value as string)
              }
            />
          </Form.Group>
        </form>
      </If>
      <If isTrue={ coderUtils.shouldShowQueryStatusEditor }>
        <form>
          <Form.Group>
            <label className="bold">Query Status</label>
            <Form.Field
              id={ `${conditionState.id}-${CONDITION.QUERY_STATUS.QUERY_OUTSTANDING}` }
              control={ Radio }
              label="Query Outstanding"
              name="queryRadioGroup"
              value={ CONDITION.QUERY_STATUS.QUERY_OUTSTANDING }
              checked={
                conditionState.coderTags &&
                conditionState.coderTags.queryStatusCode ===
                  CONDITION.QUERY_STATUS.QUERY_OUTSTANDING
              }
              onChange={ (e: any, radioValue: any) =>
                onChangeQueryStatus(radioValue.value as string)
              }
              className="m-1-top"
            />
            <Form.Field
              id={ `${conditionState.id}-${CONDITION.QUERY_STATUS.QUERY_RESPONSE_RECEIVED}` }
              control={ Radio }
              label="Query Response Received"
              name="queryRadioGroup"
              value={ CONDITION.QUERY_STATUS.QUERY_RESPONSE_RECEIVED }
              checked={
                conditionState.coderTags &&
                conditionState.coderTags.queryStatusCode ===
                  CONDITION.QUERY_STATUS.QUERY_RESPONSE_RECEIVED
              }
              onChange={ (e: any, radioValue: any) =>
                onChangeQueryStatus(radioValue.value as string)
              }
            />
            <Form.Field
              id={ `${conditionState.id}-${CONDITION.QUERY_STATUS.QUERY_RESPONSE_NOT_RECEIVED}` }
              control={ Radio }
              label="Query Response Not Received"
              name="queryRadioGroup"
              value={ CONDITION.QUERY_STATUS.QUERY_RESPONSE_NOT_RECEIVED }
              checked={
                conditionState.coderTags &&
                conditionState.coderTags.queryStatusCode ===
                  CONDITION.QUERY_STATUS.QUERY_RESPONSE_NOT_RECEIVED
              }
              onChange={ (e: any, radioValue: any) =>
                onChangeQueryStatus(radioValue.value as string)
              }
            />
          </Form.Group>
          <If
            isTrue={
              !!conditionState.coderTags &&
              (!!conditionState.coderTags.queryCreationDate ||
                !!conditionState.coderTags.queryResponseDate)
            }
          >
            <Message>
              <If
                isTrue={
                  !!conditionState.coderTags &&
                  !!conditionState.coderTags.queryCreationDate
                }
              >
                <div>
                  <span>Query sent: </span>
                  <span>
                    { format(
                      conditionState.coderTags &&
                        conditionState.coderTags.queryCreationDate
                        ? conditionState.coderTags.queryCreationDate
                        : '',
                      FORMAT.DATE_TIME
                    ) }
                  </span>
                  <If
                    isTrue={
                      !!conditionState.coderTags &&
                      !!conditionState.coderTags.queryCreationUsername
                    }
                  >
                    <div className="p-1-left">
                      { ` - ${
                        conditionState.coderTags
                          ? conditionState.coderTags.queryCreationUsername
                          : ''
                      }` }
                    </div>
                  </If>
                </div>
              </If>
              <If
                isTrue={
                  !!conditionState.coderTags &&
                  !!conditionState.coderTags.queryResponseDate
                }
              >
                <div className="m-1-top">
                  <span>Query response: </span>
                  <span>
                    { format(
                      conditionState.coderTags &&
                        conditionState.coderTags.queryResponseDate
                        ? conditionState.coderTags.queryResponseDate
                        : '',
                      FORMAT.DATE_TIME
                    ) }
                  </span>
                  <If
                    isTrue={
                      !!conditionState.coderTags &&
                      !!conditionState.coderTags.queryResponseUsername
                    }
                  >
                    <div className="p-1-left">
                      { ` - ${
                        conditionState.coderTags
                          ? conditionState.coderTags.queryResponseUsername
                          : ''
                      }` }
                    </div>
                  </If>
                </div>
              </If>
            </Message>
          </If>
        </form>
      </If>
      <If isTrue={ coderUtils.shouldShowOnClaimEditor }>
        <form className="m-1-top">
          <Form.Group inline>
            <label className="bold">On Claim</label>
            <Form.Field
              control={ Radio }
              label="Yes"
              name="onClaimGroup"
              value={ CONDITION.ON_CLAIM_STATUS.ON_CLAIM }
              checked={
                conditionState.coderTags &&
                conditionState.coderTags.onClaimStatusCode ===
                  CONDITION.ON_CLAIM_STATUS.ON_CLAIM
              }
              onChange={ (e: any, radioValue: any) =>
                onChangeOnClaim(radioValue.value as ConditionClaimStatus)
              }
              className="m-1-top"
            />
            <Form.Field
              control={ Radio }
              label="No"
              name="onClaimGroup"
              value={ CONDITION.ON_CLAIM_STATUS.NOT_ON_CLAIM }
              checked={
                conditionState.coderTags &&
                conditionState.coderTags.onClaimStatusCode ===
                  CONDITION.ON_CLAIM_STATUS.NOT_ON_CLAIM
              }
              onChange={ (e: any, radioValue: any) =>
                onChangeOnClaim(radioValue.value as ConditionClaimStatus)
              }
            />
          </Form.Group>
        </form>
      </If>
      <If isTrue={ coderUtils.shouldShowReportableEdit }>
        <form>
          <Form.Group inline className="m-1-top">
            <label className="bold">Reportable</label>
            <Form.Field
              control={ Radio }
              label="Yes"
              name="reportableGroup"
              value={ CONDITION.REPORTABLE_STATUS.REPORTABLE }
              checked={
                conditionState.coderTags &&
                conditionState.coderTags.reportableStatusCode ===
                  CONDITION.REPORTABLE_STATUS.REPORTABLE
              }
              onChange={ (e: any, radioValue: any) =>
                onChangeReportableStatusCode(
                  radioValue.value as typeof CONDITION.REPORTABLE_STATUS.REPORTABLE
                )
              }
              className="m-1-top"
            />
            <Form.Field
              control={ Radio }
              label="No"
              name="reportableGroup"
              value={ CONDITION.REPORTABLE_STATUS.NOT_REPORTABLE }
              checked={
                conditionState.coderTags &&
                conditionState.coderTags.reportableStatusCode ===
                  CONDITION.REPORTABLE_STATUS.NOT_REPORTABLE
              }
              onChange={ (e: any, radioValue: any) =>
                onChangeReportableStatusCode(
                  radioValue.value as typeof CONDITION.REPORTABLE_STATUS.NOT_REPORTABLE
                )
              }
            />
          </Form.Group>
        </form>
        <If
          isTrue={
            coderUtils.isNotOnClaim &&
            coderUtils.isReportable &&
            coderUtils.doesNotHaveConditionDiagnosisCode
          }
        >
          <Form.Field>
            <Message positive className="coder-reportable">
              { `This selection indicates that: "Compliant Provider Documentation Supports Coder Addition of Diagnosis Code: "${formattedICD10CodeOrNull}".` }
            </Message>
          </Form.Field>
        </If>
        <If
          isTrue={
            !!conditionState.coderTags &&
            !!conditionState.coderTags.conditionDiagnosisCode &&
            conditionState.coderTags.reportableReason !==
              CONDITION.REPORTABLE_REASON
                .MORE_OR_LESS_SPECIFIC_CODE_DOCUMENTED &&
            formatIcd10Code(conditionState.coderTags.conditionDiagnosisCode) ===
              formatIcd10Json(conditionState.icd10CodeJson)[0]
          }
        >
          <Message positive>
            { `Successfully marked this condition as on claim and reportable with Compliant Provider Documentation Supporting Coder Addition of Diagnosis Code: "${formattedICD10CodeOrNull}".` }
          </Message>
        </If>
      </If>
      <If
        isTrue={
          coderUtils.shouldShowReportableDiagnosisCodeEdit &&
          !conditionUtils.isPotential(conditionState)
        }
      >
        <div>
          <CoderDiagnosisCodeEditor
            condition={ conditionState }
            setCondition={ setConditionState }
          />
          <div className="m-1-top">
            <span className="italic">
              If diagnosis is marked as Clinical Indicator with no ICD10, please
              indicate specific ICD code documented by provider
            </span>
          </div>
        </div>
      </If>
      <If isTrue={ coderUtils.shouldShowNotReportableReason }>
        <Form.Group>
          <Form.Field>
            <Form.Group inline className="m-1-top">
              <label className="bold">Reportable Reason</label>
              { coderReportableHeaders.map(
                ({ key, text, value }, index: number) => (
                  <Form.Field
                    control={ Radio }
                    label={ text }
                    key={ key }
                    name="reportableHeaderGroupCodes"
                    value={ value }
                    checked={ coderUtils.activeReportableHeaderCode === key }
                    onChange={ (event: any, radioValue: any) =>
                      handleReportableReasonClick(
                        radioValue.value as ObjectValues<
                          typeof CONDITION.REPORTABLE_HEADER
                        >
                      )
                    }
                    className={ index === 0 ? 'm-1-top' : '' }
                  />
                )
              ) }
            </Form.Group>
          </Form.Field>
          <Form.Field>
            <If
              isTrue={
                !!coderUtils.activeReportableHeaderCode &&
                coderUtils.activeReportableHeaderCode ===
                  CONDITION.REPORTABLE_HEADER.OTHER
              }
            >
              <Form.TextArea
                placeholder="Please provide a coding reason..."
                value={
                  conditionState.coderTags &&
                  conditionState.coderTags.reportableText
                }
                onInput={ (e: any) => {
                  setConditionState(
                    coderTags({
                      reportableText: e.target.value as string
                    })
                  )
                } }
                style={ { minHeight: 100 } }
                className="m-1-top coder-text-area"
              />
            </If>
            <If
              isTrue={
                !!coderUtils.activeReportableHeaderCode &&
                coderUtils.activeReportableHeaderCode !==
                  CONDITION.REPORTABLE_HEADER.OTHER
              }
            >
              <div className="m-1-top">
                <Form.Dropdown
                  placeholder="Select coding feedback"
                  fluid
                  selection
                  options={ coderReportableReasonsByHeader(
                    coderUtils.activeReportableHeaderCode || ''
                  ) }
                  value={ coderUtils.activeReportableReason }
                  onChange={ (e: any, dropDown: any) =>
                    handleCodingOptionClick(dropDown.value as string)
                  }
                />
              </div>
            </If>
          </Form.Field>
          <If
            isTrue={ showCoderReportableDiagnosisCodeEdit(
              coderUtils.activeReportableReason as keyof typeof CONDITION.REPORTABLE_REASON
            ) }
          >
            <CoderDiagnosisCodeEditor
              condition={ conditionState }
              setCondition={ setConditionState }
            />
          </If>
          <If isTrue={ shouldShowReportableErrorMsg }>
            <Message positive>
              If Not Reportable, A Reason Must be Provided
            </Message>
          </If>
        </Form.Group>
      </If>
      <Button
        disabled={ isSavingForm || isDisabled }
        onClick={ () => {
          onSubmitForm()
        } }
        className="coder-submit-button"
        content={ isSavingForm ? 'Submitting' : 'Submit' }
        color="blue"
      />
      <If isTrue={ isFormSaved && conditionState.id === activeConditionID }>
        <Message positive>Successfully submitted coder details.</Message>
      </If>
    </div>
  )
}

const mapStateToProps = (): any => ({})

export default connect(mapStateToProps, {
  updateCoderState: updateCoderStateImport
})(CoderConditionEditor)
