import React, { Component } from "react";
import { Segment } from "semantic-ui-react";

/**
 * Provides a component that renders the encounter reviewer
 * @param review Encounter review entity
 * @param mode Render mode. Default: left
 */
class EncounterReviewer extends Component {

  renderLeft(review) {
    const name = review && review.reviewer && review.reviewer.userId
      ? `${review.reviewer.firstName} ${review.reviewer.lastName}`
      : <span className="italic">CDI Review Bypassed</span>;

    return (
      <Segment attached compact className="left-segment">
        <div className="left-title">Reviewed By:</div>
        <div className="left-content">{name}</div>
      </Segment>
    );
  }

  render() {
    const mode = this.props.mode ? this.props.mode : "left";
    if ( mode === "left" ) {
      return this.renderLeft(this.props.review);
    } else {
      return null;
    }
  }
}

export default EncounterReviewer;
