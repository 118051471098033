import React, { Dispatch, SetStateAction, useState } from 'react'
// Local
import Card from './card'
import { conditions as conditionsUtils } from '../common/conditionUtils'
import If from '../common/if'
import Accordion from './accordion'
import Condition from './condition'
import { KnownAndPotential } from './processConditions'
import { EncounterConditionDisplayModified, TagStatus } from '../authenticated/types'
import { isNil } from 'ramda'

type Props = {
  conditions: KnownAndPotential[]
  tagsClicked: Record<string, TagStatus>
  setTagsClicked: Dispatch<SetStateAction<Record<string, TagStatus>>>
}

function ClinicalConditions(props: Props): JSX.Element {
  const { conditions, tagsClicked, setTagsClicked } = props

  function getTitle(c: KnownAndPotential): string {
    const hccDescription = c.conditions[0].hccDescription
    const hccCode = c.conditions[0].hccCode

    return ((!isNil(hccDescription) && hccDescription.length > 0) &&
      (!isNil(hccCode) && hccCode.length > 0)) ? hccDescription.concat(` (HCC ${hccCode})`) : ''
  }

  const renderedConditions = conditions.map(
    (c: KnownAndPotential, index: number) => {
      const areAllConditionsPotential = conditionsUtils.areAllPotential(
        c.conditions
      )

      const [ required, setRequired ] = useState<number>(c.required)

      const otherConditionHccs = [null, "", "0"]

      return (
        <>
          <If
            isTrue={ !otherConditionHccs.includes(c.conditions[0].hccCode) }
            key={ c.title }
          >
            <Accordion
              id={ `clinical-condition-${index + 1}` }
              title={ getTitle(c) }
              isRequired={ required > 0 }
              isAddressed={ required === 0 && !!c.knownAddressed }
            >
              { c.conditions.map(
                (
                  subCondition: EncounterConditionDisplayModified,
                  nestedIndex: number
                ): JSX.Element => (
                  <Condition
                    id={ `condition-${index + 1}-nested-${nestedIndex + 1}` }
                    key={ subCondition.id }
                    conditions={ c.conditions }
                    condition={ subCondition }
                    isStandAloneCondition={ false }
                    areAllConditionsInGroupPotential={ areAllConditionsPotential }
                    required={ required }
                    setRequired={ setRequired }
                    tagsClicked={ tagsClicked }
                    setTagsClicked={ setTagsClicked }
                  />
                )
              ) }
            </Accordion>
          </If>

          <If
            isTrue={ otherConditionHccs.includes(c.conditions[0].hccCode) }
          >
            { c.conditions.map(
              (
                subCondition: EncounterConditionDisplayModified,
                nestedIndex: number
              ) => (
                <Card
                  key={ subCondition.id }
                  id={ `clinical-condition-${index + 1}` }
                >
                  <Condition
                    id={ `condition-${index + 1}-nested-${nestedIndex + 1}` }
                    conditions={ c.conditions }
                    condition={ subCondition }
                    isStandAloneCondition={ true }
                    areAllConditionsInGroupPotential={ areAllConditionsPotential }
                    required={ required }
                    setRequired={ setRequired }
                    tagsClicked={ tagsClicked }
                    setTagsClicked={ setTagsClicked }
                  />
                </Card>
              )
            ) }
          </If>
        </>
      )
    }
  )

  return <>{ renderedConditions }</>
}

export default ClinicalConditions
