import React, { Component } from "react";
import { Dropdown, Responsive } from 'semantic-ui-react';
import { API_URL } from "../../constant";
import { api } from "../../action/api";
import { searchGenerator } from "../../action/search";

/**
 * Provides a stand-alone practitioner select widget
 * @param onChange Callback function to execute when a practitioner is selected
 *   arguments: (e, {name, value}) where value is the practitioner ID or "" if
 *   the ALL option is selected
 * @param name Name of the practitioner select widget in a form context
 * @param value Initial selected practitioner ID
 * @param orgId Optional org ID to constrain practitioners
 * @param placeholder Placeholder text. Default: Practitioners
 * @param allOption If true, include an 'ALL' option. Default: true
 */
class PractitionerSelect extends Component {

  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);

    this.state = {
      practitioners: [],
      allOption: this.props.allOption === false ? false : true
    };
  }

  componentDidMount() {
    this.fetchPractitioners();
  }

  fetchPractitioners() {
    const orgId = this.props.orgId ? this.props.orgId : null;
    const withOrg = "true";
    const url = API_URL.PRACTITIONER_SEARCH(withOrg);
    const query = {statusCode: "ACTIVE"};
    if ( orgId ) {
      query['orgId'] = orgId;
    }
    const limit = 5000;
    const offset = 0;
    const params = searchGenerator(query, limit, offset)
    api.get(url, {params: params}).then(({ data }) => {
      this.setState({practitioners: data});
    });
  }

  onChange = (e, { value }) => {
    if ( this.props.onChange ) {
      const name = this.props.name ? this.props.name : "practitionerSelect";
      const val = value === 'ALL' ? null : value;
      this.props.onChange(e, {name: name, value: val});
    }
  }

  render() {
    const options = this.state.practitioners.map(practitioner => {
      return {
        key: practitioner.id,
        value: practitioner.id,
        text: practitioner.lastName + ", " + practitioner.firstName
      };
    });
    if ( this.state.allOption === true ) {
      options.unshift({ key: "ALL", text: "All", value: "ALL" });
    }
    const placeholder = this.props.placeholder ? this.props.placeholder : "Practitioners";
    const value = this.props.value ? this.props.value : undefined;
    return (
      <Responsive
        as={Dropdown}
        selection
        placeholder={placeholder}
        onChange={this.onChange}
        options={options}
        value={value} />
    );
  }
}

export default PractitionerSelect;
