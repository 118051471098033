import React from "react";
import { format, isSameDay } from "date-fns";
import { Icon, Popup } from "semantic-ui-react";
import { ENCOUNTER, FORMAT, LOCK, REVIEW } from "../../../constant";
import { expandSpecialtyFilter } from "../../common/ruleFilterUtils";

/**
 * Provides an icon indicating the encounter status, relevant to CDI users
 * @param encounter Encounter record
 * @param currentUser Current user
 */
const CdiEncounterStatusIcon = (props) => {

  const lockMessage = (lock) => {
    const lockOwner = lock.createdByUsername;
    const date = format(lock.creationDate, FORMAT.DATE_TIME);
    return `Locked for ${lockOwner}, ${date}`;
  };

  const lockColor = (lock, currentUser) => {
    return ( lock.createdById === currentUser.userId ) ? "green" : "black";
  }

  const sameDayEncounter = (ingestDate, startDate) => {
    return ingestDate && startDate && isSameDay(ingestDate, startDate);
  }

  const currentUser = props.currentUser;
  const encounter = props.encounter;
  const review = encounter.review;
  const lock = encounter.lock;
  const lastFilter = encounter.patient.rulesFilterSpecialtyPrefix ? encounter.patient.rulesFilterSpecialtyPrefix : ''
  const fullSpecialtyName = lastFilter === '' ? '' : expandSpecialtyFilter(lastFilter);

  return (
    <span>
      {lock && lock.statusCode === LOCK.STATUS.ACTIVE && (
        <Popup mouseEnterDelay={500} content={lockMessage(lock)}
          trigger={
            <Icon name="lock" color={lockColor(lock, currentUser)} />
          } />
      )}
      {sameDayEncounter(encounter.ingestDate, encounter.start) && (
        <Popup mouseEnterDelay={500} content="Same Day Encounter"
          trigger={
            <Icon name="rocket" color="red" />
          } />
      )}
      {encounter.status === ENCOUNTER.STATUS.CANCELLED && (
        <Popup mouseEnterDelay={1000} content="Cancelled"
          trigger={<Icon name="times circle" color="red" />} />
      )}
      {review && review.reviewCode === REVIEW.CODE.READY_FOR_CDI_QA && (
        <Popup mouseEnterDelay={1000} content="Ready for CDI QA"
          trigger={<Icon name="question circle" color="yellow" />} />
      )}
      {review && review.reviewCode === REVIEW.CODE.READY_FOR_PHYSICIAN && (
        <Popup mouseEnterDelay={1000} content="Ready for Physician"
          trigger={<Icon name='checkmark' color="green" />} />
      )}
      {review && review.queryStatusCode === REVIEW.QUERY_STATUS.REVIEW_QUERY_OUTSTANDING && (
        <Popup mouseEnterDelay={1000} content="CDI Review Requested"
          trigger={<Icon name="hourglass half" color="red" />} />
      )}
      {fullSpecialtyName && (
        <Popup mouseEnterDelay={1000} content={ fullSpecialtyName }
          trigger={<Icon name="doctor" color="violet" />} />
      )}
    </span>
  );
}

export default CdiEncounterStatusIcon;
