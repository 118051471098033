import React, { Component } from "react";
import { Grid, Icon, Message } from "semantic-ui-react";
import { isReadyForCdiQa } from "../../common/reviewUtils";
import { isCdiQaManager } from "../../common/roleUtils";

/**
 * Provides a component that displays encounter level messages. If there are
 * no messages to display, the return is null
 * @param encounter Encounter entity
 * @param currentUser Current user entity
 * @param mode Render mode. Either undefined, or "grid"
 */
class CdiEncounterMessage extends Component {

  reviewerName(reviewer) {
    if ( reviewer ) {
      return `${reviewer.firstName} ${reviewer.lastName}`;
    } else {
      return null;
    }
  }

  renderMessages(messages) {
    if ( messages.length > 1 ) {
      return (
      <Message warning><Icon name="info circle" />
        <ul>
          {
            messages.map((message, i) => {
              return (<li key={i}>{message}</li>);
            })
          }
        </ul>
      </Message>
      )
    } else if ( messages.length === 1 ) {
      return (
        <Message warning><Icon name="info circle" />{messages[0]}</Message>
      )
    } else {
      return null;
    }
  }

  render() {
    const encounter = this.props.encounter;
    const review = encounter.review;
    const currentUser = this.props.currentUser;
    const messages = [];

    if ( isReadyForCdiQa(review) && !isCdiQaManager(currentUser.roles) ) {
      const name = this.reviewerName(review.reviewer);
      if ( name ) {
        messages.push(`This encounter review is flagged for CDI QA review from ${name}`);
      } else {
        messages.push("This encounter review is flagged for CDI QA review");
      }
    }

    if ( messages.length === 0 ) {
      return null;
    } else {
      const messageUi = this.renderMessages(messages);
      if ( this.props.mode === "grid" ) {
        return (
          <Grid.Row>
            <Grid.Column width={16}>{messageUi}</Grid.Column>
          </Grid.Row>
        );
      } else {
        return messageUi;
      }
    }
  }
}

export default CdiEncounterMessage;
