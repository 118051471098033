import React, { PropsWithChildren } from 'react'

type Props = {
  children: JSX.Element | JSX.Element[]
  id: string
}

function Card(props: PropsWithChildren<Props>): JSX.Element {
  const { children, id } = props
  return (
    <div id={ id } className="curation-ui-card">
      { children }
    </div>
  )
}

export default Card
