import React, { Component } from "react";
import { Dropdown, Responsive } from 'semantic-ui-react';
import orderBy from "lodash/orderBy";
import filter from "lodash/filter";
import { API_URL } from "../../constant";
import { api } from "../../action/api";
import { searchGenerator } from "../../action/search";

class OrganizationSelect extends Component {

  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);

    this.state = {
      organizations: [],
      multiple: this.props.multiple === false ? false : true,
      allOption: this.props.allOption === false ? false : true
    }
  }

  componentDidMount() {
    this.fetchOrganizations();
  }

  fetchOrganizations() {
    const url = API_URL.GET_ORGANIZATIONS;
    const query = null;
    const limit = 999;
    const offset = 0;
    const sort = "name asc";
    const params = searchGenerator(query, limit, offset, sort);
    api.get(url, {params: params}).then(({ data }) => {
      const orgs = orderBy(filter(data, (e) => {
        return e.active === true;
      }), 'name', ['ASC']);
      this.setState({organizations: orgs});
    });
  }

  onChange = (e, { value }) => {
    if ( this.props.onChange ) {
      const name = this.props.name ? this.props.name : "organizationSelect";
      const val = value === 'ALL' ? null : value;
      this.props.onChange(e, {name: name, value: val});
    }
  }

  render() {
    const options = this.state.organizations.map(org => {
      return {
        key: org.id,
        value: org.id,
        text: org.name
      };
    });
    if ( this.state.allOption === true ) {
      options.unshift({ key: "ALL", text: "All", value: "ALL" });
    }
    const placeholder = this.props.placeholder ? this.props.placeholder : "Organizations";
    if ( this.state.multiple === true ) {
      const value = this.props.value ? this.props.value : [];
      return (
        <Responsive
          as={Dropdown}
          fluid multiple selection
          placeholder={placeholder}
          onChange={this.onChange}
          options={options}
          value={value} />
      );
    } else {
      const value = this.props.value ? this.props.value : undefined;
      return (
        <Responsive
          as={Dropdown}
          fluid selection
          placeholder={placeholder}
          onChange={this.onChange}
          options={options}
          value={value} />
      );
    }
  }
}

export default OrganizationSelect;
