import React, { Component } from "react";
import { connect } from 'react-redux'
import { Accordion } from "semantic-ui-react";
import { CONDITION, HCC_GROUP } from "../../../constant";
import CdiConditionEdit from './cdiConditionEdit'
import CdiConditionRead from './cdiConditionRead'
import CdiConditionHccHeaderTitle from "./cdiConditionHccHeaderTitle";

/**
 * Provides a CDI user role component that displays conditions grouped
 * under an HCC
 *
 * @param editMode True if encounter is locked and editing is enabled
 * @param hccCode HCC code of the display group
 * @param hccDescription HCC description corresponding to HCC code
 * @param activeHccGroupId Identifier of currently active HCC group
 * @param conditionGroupTitle The conditionStatus group
 *        Values: KNOWN, POTENTIAL, ACTIVITY
 * @param conditions List of conditions to display in the group
 * @param onSubmitNote Callback for condition note save
 *        Arguments: condition, noteContent, noteReason
 * @param onConditionAction Callback for condition action.
 *        Arguments: action, condition
 * @param onHccGroupAction Callback for HCC group action
 *        Arguments: action, hcc-group-id
 */
class CdiConditionHccGroup extends Component {

  hccGroupId() {
    const title = this.props.conditionGroupTitle
    const prefix = title.replaceAll(' ' , '-').toLowerCase()
    return `${prefix}-${this.props.hccCode}`
  }

  isActiveHccGroup() {
    return this.hccGroupId() === this.props.activeHccGroupId
  }

  onClickHccGroupTitle = () => {
    const ACTIVATE = HCC_GROUP.ACTION.ACTIVATE;
    const DEACTIVATE = HCC_GROUP.ACTION.DEACTIVATE;
    const READ = CONDITION.ACTION.READ;
    if ( this.isActiveHccGroup() ) {
      this.props.onHccGroupAction(DEACTIVATE, this.hccGroupId())
    } else {
      this.props.onHccGroupAction(ACTIVATE, this.hccGroupId());
      this.props.conditions.forEach((c) => {
        this.props.onConditionAction(READ, c);
      })
    }
  }

  onClickConditionTitle = () => {
    // in the hcc group display context, ignore the condition title click
    return;
  }

  hccGroupTitle(conditions) {
    const hccCode = this.props.hccCode
    const hccDescription = this.props.hccDescription
    const isActive = this.isActiveHccGroup()

    return (
      <CdiConditionHccHeaderTitle
        conditions={conditions}
        active={isActive}
        mode={this.props.mode}
        hccCode={hccCode}
        hccDescription={hccDescription}
        onConditionAction={this.props.onConditionAction} />
    );
  }

  renderConditions(conditions) {
    const { editMode } = this.props
    const isActive = this.isActiveHccGroup()
    return (
      <div id={`cdi-encounter-condition-hcc-group-${this.hccGroupId()}`}>
        <Accordion.Title
          key={this.hccGroupId()}
          active={isActive}
          onClick={this.onClickHccGroupTitle}>
          <div>{this.hccGroupTitle(conditions)}</div>
        </Accordion.Title>
        <Accordion.Content active={isActive}>
          <Accordion fluid>
            {
              this.props.conditions.map((condition, displayIndex) => {
                return editMode ? (
                  <CdiConditionEdit
                    key={condition.id}
                    condition={condition}
                    displayIndex={displayIndex}
                    isActive={isActive}
                    onClickTitle={ this.onClickConditionTitle }
                    onSubmitNote={ this.props.onSubmitNote }
                    onConditionAction={ this.props.onConditionAction }
                    hccChild={ true } />
                ) : (
                  <CdiConditionRead
                    key={condition.id}
                    condition={condition}
                    displayIndex={displayIndex}
                    isActive={isActive}
                    onClickTitle={ this.onClickConditionTitle }
                    onSubmitNote={ this.props.onSubmitNote }
                    hccChild={ true } />
                );
              })
            }
          </Accordion>
        </Accordion.Content>
      </div>
    );
  }

  render() {
    return this.props.conditions && this.props.conditions.length > 0
      ? this.renderConditions(this.props.conditions)
      : null;
  }
}

export default connect(
  state => ({
    currentUser: state.user
  }), {}
)(CdiConditionHccGroup)
