import React from "react";
import { Button, Form } from "semantic-ui-react";
import * as c from "../../../constant";
import forEach from "lodash/forEach";
import filter from "lodash/filter";

export const NEW_RULE = "NEW_RULE";

// TODO - sboles - Refactor! This needs to be a component
//      - This needs to be entirely refactored. Make it a component that has
//        state. The current implementation causes the parent component to
//        mutate state during its render when this thing renders with an initial
//        empty rule row
export const ReasonHandler = props => {

  const onChangeRule = (props, ruleToUpdate, e, data) => {
    forEach(props.rules, rule => {
      if (rule.id === ruleToUpdate.id) {
        rule.reason = data.value;
      }
    });
    props.onChangeValue(null, { name: "rules", value: props.rules });
  };

  const addRow = (props, e, data) => {
    props.rules.push({ type: NEW_RULE, reason: "", id: Math.random() });
    props.onChangeValue(null, { name: "rules", value: props.rules });
  };

  const createRow = (rule, lastRow) => {
    return (
      <div key={rule.id}>
        <Form.Group widths="equal">
          <Form.Input
            name="reason"
            placeholder="Reason"
            value={rule.reason}
            onChange={onChangeRule.bind(this, props, rule)}
          />
        </Form.Group>
        {lastRow && (
          <div className="add-button">
            <Button color="blue" type="button" onClick={addRow.bind(this, props)}>
              Add More
            </Button>
          </div>
        )}
      </div>
    );
  };

  const activeRules = filter(
    props.rules,
    rule => rule.statusCode === c.CONDITION.STATUS.ACTIVE || rule.type === NEW_RULE
  );

  if (activeRules.length === 0) {
    addRow(props);
  }

  const rowLen = activeRules.length;

  return (
    <div className="reason">
      {
        activeRules.map((rule, count) => createRow(rule, rowLen === count + 1))
      }
    </div>
  );
};
