import { Action,Dispatch } from 'redux'
import forEach from 'lodash/forEach'
import { api } from './api'
import { API_URL } from '../constant'
import {
  SET_SELECTED_CONDITION,
  SET_SELECTED_PREVIOUS_CONDITION,
  UPDATE_ENCOUNTER_CONDITIONS,
  UPDATE_PREVIOUS_CONDITIONS,
  UPDATE_EDIT_CONDITION,
  RESET_EDIT_CONDITION,
  ADDRESSED,
  NOT_ADDRESSED
} from '../reducer/condition'
// eslint-disable-next-line import/no-cycle
import { loadSelected, updateRaf } from './encounter'
import { auditConditionRead } from './audit'
// import { Condition } from '../component/v2/data/data-two'
import { EncounterConditionDisplay, EncounterConditionRuleRow } from '../component/authenticated/types'

export function selectCondition(conditionId: string, activeConditionId: string) {
  // if you are selecting the one already selected, remove it.
  if (conditionId === activeConditionId) {
    return {
      type: SET_SELECTED_CONDITION,
      payload: -1
    }
  }
  auditConditionRead(conditionId)
  return {
    type: SET_SELECTED_CONDITION,
    payload: conditionId
  }
}

export function selectPreviousCondition(conditionId: string, activeConditionId: string) {
  // if you are selecting the one already selected, remove it.
  if (conditionId === activeConditionId) {
    return {
      type: SET_SELECTED_PREVIOUS_CONDITION,
      payload: -1
    }
  }
  return {
    type: SET_SELECTED_PREVIOUS_CONDITION,
    payload: conditionId
  }
}

export function changeCondition(condition: EncounterConditionDisplay , encounterId: string) {
  // eslint-disable-next-line max-len
  return (dispatch: Dispatch<any>) => api.put(`${API_URL.UPDATE_CONDITION  }/${  condition.id}`, condition).then(() => {
    dispatch(loadSelected(encounterId))
  })
}

export function setConditionQueryStatus(condition: EncounterConditionDisplay, encounterId: string, status: string) {
  return async (dispatch: Dispatch<any>) => {
    const url = `${API_URL.UPDATE_CONDITION_QUERY_STATUS(condition.id)  }/${  status}`
    await api.put(url, {})
    dispatch(loadSelected(encounterId))
  }
}

export function updateConditionAddressed(conditionId: string, isAddressed: boolean) {
  return isAddressed ? { type: ADDRESSED, payload: conditionId } : { type: NOT_ADDRESSED, payload: conditionId }
}

export function clearEditCondition() {
  return {
    type: RESET_EDIT_CONDITION
  }
}

export function updateEditCondition(id: string) {
  return {
    type: UPDATE_EDIT_CONDITION,
    payload: id
  }
}
export function editCondition(condition: EncounterConditionDisplay) {
  return (dispatch: Dispatch<Action>) => {
    dispatch(clearEditCondition())
    dispatch(updateEditCondition(condition.id))
  }
}

export function addRule(rule: EncounterConditionRuleRow, encounterId: string) {
  return (dispatch: Dispatch<any>) => api.post(API_URL.UPDATE_CONDITION_RULE, rule).then(() => {
    dispatch(loadSelected(encounterId))
  })
}

export function addCondition(condition: EncounterConditionDisplay, encounterId: string, rules: EncounterConditionRuleRow[]) {
  return (dispatch: Dispatch<any>) => api.post(API_URL.CREATE_CONDITION, condition).then(({ data }) => {
    forEach(rules, rule => {
      // eslint-disable-next-line no-param-reassign
      rule.encounterConditionId = data.id
      dispatch(addRule(rule, encounterId))
    })
    dispatch(loadSelected(encounterId))
  })
}

export function addConditionNote(conditionNote: EncounterConditionDisplay) {
  return (dispatch: any) => api.post(API_URL.CREATE_CONDITION_NOTE, conditionNote).then(({ data }) => {
    dispatch(loadSelected(data.encounterId))
  })
}

export function updateEncounterConditions(conditions: EncounterConditionDisplay[]) {
  return {
    type: UPDATE_ENCOUNTER_CONDITIONS,
    payload: conditions
  }
}
// changed to string based upon CONDITION_TYPE object in component/encounter/detail.js
export function updatePreviousConditions(previous: EncounterConditionDisplay[]) {
  return {
    type: UPDATE_PREVIOUS_CONDITIONS,
    payload: previous
  }
}

export function updateConditions(conditionList: EncounterConditionDisplay[], patientId: string, encounterId: string) {
  return async (dispatch: any) => {
    dispatch(updateEncounterConditions(conditionList))
    await api.put(API_URL.UPDATE_CONDITION, conditionList)
    // I can't use the result of the update because it doesn't have the rules on it.
    // I then have to redispatch for a load. This is inefficient as it requires an extra API call.
    dispatch(loadSelected(encounterId)).then(() => {
      if (patientId && encounterId) {
        dispatch(updateRaf(patientId, encounterId))
      }
    })
  }
}

export function changeRule(rule: EncounterConditionRuleRow, encounterId: string) {
  return (dispatch: Dispatch<any>) => api.put(`${API_URL.UPDATE_CONDITION_RULE  }/${  rule.id}`, rule).then(() => {
    dispatch(loadSelected(encounterId))
  })
}

