import React from 'react'
import { Grid, Icon, Message } from 'semantic-ui-react'
// Local
import { isReadyForCoderQa } from '../../common/reviewUtils'
import { isCoderQaManager } from '../../common/roleUtils'
import { Encounter, EncounterReviewer, User } from '../../authenticated/types'

/**
 * Provides a component that displays encounter level messages. If there are
 * no messages to display, the return is null
 * @param encounter Encounter entity
 * @param currentUser Current user entity
 * @param mode Render mode. Either undefined, or "grid"
 */
type Props = {
  encounter: Encounter
  currentUser: User
  mode: 'grid' | string
}

function CoderEncounterMessage(props: Props): JSX.Element | null {
  const { encounter, currentUser, mode } = props
  const { review } = encounter

  const messages = []

  const reviewerName = (reviewer: EncounterReviewer | null): string | null => {
    if (reviewer) return `${reviewer.firstName} ${reviewer.lastName}`
    return null
  }

  const renderMessages = (renderedMessages: string[]) => {
    if (renderedMessages.length > 1) {
      return (
        <Message warning>
          <Icon name="info circle" />
          <ul>
            { renderedMessages.map((message: string) => (
              <li key={ message }>{ message }</li>
            )) }
          </ul>
        </Message>
      )
    }
    if (renderedMessages.length === 1) {
      return (
        <Message warning>
          <Icon name="info circle" />
          { renderedMessages[0] }
        </Message>
      )
    }
    return null
  }

  if (review) {
    if (isReadyForCoderQa(review) && !isCoderQaManager(currentUser.roles)) {
      const name = reviewerName(review.reviewer)
      if (name) {
        messages.push(
          `This encounter review is flagged for Coder QA review from ${name}`
        )
      } else {
        messages.push('This encounter review is flagged for Coder QA review')
      }
    }
  }

  if (messages.length === 0) return null
  const messageUi = renderMessages(messages)
  if (mode === 'grid') {
    return (
      <Grid.Row>
        <Grid.Column width={ 16 }>{ messageUi }</Grid.Column>
      </Grid.Row>
    )
  }
  return messageUi
}

export default CoderEncounterMessage
