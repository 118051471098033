import React from 'react'
// Local
import { Encounter } from '../../authenticated/types'

/**
 * Provides a component that renders the encounter type code
 * @param encounter Encounter entity
 * @param mode Render mode. Default: left
 */
type Props = {
  mode?: string
  encounter: Encounter
}

function CoderEncounterTypeRead(props: Props): JSX.Element | null {
  const { encounter, mode = 'left' } = props

  const EncounterType = encounter.encounterTypeCode ? (
    <span>encounter.encounterTypeCode</span>
  ) : (
    <span className="italic">Unavailable</span>
  )

  if (mode !== 'left') return null

  return (
    <div className="ui attached segment left-segment compact">
      <div className="left-title">Encounter Type Confirmed by Coder:</div>
      <div className="left-content">{ EncounterType }</div>
    </div>
  )
}

export default CoderEncounterTypeRead
