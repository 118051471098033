/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unstable-nested-components */
import React, { useState } from 'react'
import { connect } from 'react-redux'
// import { hasPath, pathOr, prop } from 'ramda'
import { hasPath, prop } from 'ramda'
import {  Table, Popup } from 'semantic-ui-react'
import { format } from 'date-fns'
// Local
import If from './if'
// import { ReviewCode } from '../patient/Patient'
import { allowPatientTabSchedulingProvider, allowPatientTabSchedulingCdi } from './currentUserUtils'
import { FORMAT, PAGES } from '../../constant'
import ScheduleButton from './scheduleAppointment/scheduleButton'
import ScheduleModal from './scheduleAppointment/scheduleModal'
import MoreTableFooter from './moreTableFooter'
import MaxTableFooter from './maxTableFooter'
import { isCdi } from './roleUtils'
import { isOptum } from './tenantUtils'

function ComputerTable(
  props
  // props: TableProps & { reduxState: any }
) {
  const {
    computerColumns,
    data,
    onClick,
    more,
    max,
    onMore,
    sortOnHeaderClick,
    reduxState
  } = props

  const [ isScheduleCallBeingMade, setIsScheduleCallBeingMade ] = useState(false)
  const [ isModalShowing, setIsModalShowing ] = useState(false)
  const [ encounterIdState, setEncounterIdState ] = useState('')

  const { user } = reduxState
  const scheduleButtonLabel = allowPatientTabSchedulingProvider(user) ? 'Start Encounter' :
   (allowPatientTabSchedulingCdi(user) && isOptum(user.tenantId) ? 'Start' : 'Schedule')
  const practitionerId = user.userId

  function ComputerCell(
    column,
    record
    // record: PatientRecordForDisplay
  ) {
    const { value } = column
    const { meta } = record
    const className = hasPath([ 'className' ], meta) ? meta.className : ''
    return (
      <Table.Cell key={ value }>
        { record[`${value}_icon`] }
        <span className={ `${value} ${className}` }>{ record[value] }</span>
      </Table.Cell>
    )
  }

  // function ComputerRow(record: PatientRecordForDisplay) {
  function ComputerRow(record) {
    const {
      id,
      meta,
      name,
      nextEncounter,
      proposedEncounter
    } = record

    const shouldShowScheduleButton = (
      allowPatientTabSchedulingProvider(user) ||
      (allowPatientTabSchedulingCdi(user) && isCdi(user.roles))
    )

    function startDateForNextEncounter() {
      if (nextEncounter && nextEncounter.encounterStart) {
        return (
          <a href={ `${PAGES.ENCOUNTER_DETAIL}${nextEncounter.encounterId}` }>
            { format(nextEncounter.encounterStart, FORMAT.DATE) }
          </a>
        )
      }

      return <>No upcoming appointment scheduled.</>
    }

    function scheduleNewEncounterButton() {

      if (proposedEncounter && proposedEncounter.encounterStart && proposedEncounter.encounterId) {
        return (
          <>
            <ScheduleButton
              label={ scheduleButtonLabel }
              setIsModalShowing={ setIsModalShowing }
              setEncounterIdState={ setEncounterIdState }
              isScheduleCallBeingMade={ isScheduleCallBeingMade }
              encounterId={ proposedEncounter.encounterId }
              setIsScheduleCallBeingMade={ setIsScheduleCallBeingMade }
              isOptum={ isOptum }
            />
            <a
              className="m-12px-left"
              href={ `${PAGES.PATIENT_SNAPSHOT}${proposedEncounter.encounterId}` }
              target="_blank"
              rel="noopener noreferrer"
            >
              View Patient
            </a>
          </>
        )
      }

      if ( isOptum(user.tenantId) ) {
        return <></>
      } else {
        return <>Please contact your Curation Health client success manager.</>
      }
    }

    const revisedRecord = {
      ...record,
      nextEncounter: shouldShowScheduleButton ? (
        startDateForNextEncounter()
      ) : (
        <></>
      ),
      newAppointment: shouldShowScheduleButton ? (
        scheduleNewEncounterButton()
      ) : (
        <></>
      ),
      name
    }

    const className = hasPath([ 'className' ], meta) ? meta.className : ''
    return (
      <Table.Row
        key={ id }
        onClick={ event => onClick(id, event) }
        className={ className }
      >
        { computerColumns.map(
          computerColumn =>
            ComputerCell(computerColumn, revisedRecord)
        ) }
      </Table.Row>
    )
  }

  function ComputerHeader(column) {
    const { name, value } = column
    return (
      <Table.HeaderCell
        onClick={ !sortOnHeaderClick ? null : sortOnHeaderClick(value) }
        key={ value }
      >
        <Popup content={ column.tooltip ? column.tooltip : name }
          mouseEnterDelay={ column.tooltipDelay ? column.tooltipDelay : 500 }
          trigger={ <span>{ name }</span> } />
      </Table.HeaderCell>
    )
  }

  return (
    <>
      <ScheduleModal
        encounterIdState={ encounterIdState }
        setEncounterIdState={ setEncounterIdState }
        isModalShowing={ isModalShowing }
        setIsModalShowing={ setIsModalShowing }
        practitionerId={ practitionerId }
        setIsScheduleCallBeingMade={ setIsScheduleCallBeingMade }
        user={ user }
      />
      <Table compact color="green" selectable>
        <Table.Header>
          <Table.Row>{ computerColumns.map(ComputerHeader) }</Table.Row>
        </Table.Header>
        <Table.Body>{ data.map(ComputerRow) }</Table.Body>
        <If isTrue={ more }>
          <MoreTableFooter columns={ computerColumns.length } onMore={ onMore } />
        </If>
        <If isTrue={ max !== undefined }>
          <MaxTableFooter columns={ computerColumns.length } />
        </If>
      </Table>
    </>
  )
}

const mapStateToProps = state => ({
  reduxState: state
})

export default connect(mapStateToProps)(ComputerTable)
