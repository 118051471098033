import React from 'react'
// Local
import Checkmark from './checkmark'
import If from '../common/if'

type Props = {
  isAddressed: boolean
  isRequired: boolean
}

function Icon(props: Props): JSX.Element {
  const { isRequired } = props

  function getClassNames() {
    const isRequiredClass = isRequired ? 'required' : ''
    return `condition-status ${isRequiredClass}`
  }

  return (
    <div className={ getClassNames() }/>
  )
}

export default Icon
