import { CONDITION } from '../../../constant'
import {
  EncounterConditionCoderTagsRow,
  EncounterConditionDisplay
} from '../../authenticated/types'
import { condition as conditionUtils } from '../../common/conditionUtils'
import { formatIcd10Code, formatIcd10Json } from '../../common/formatUtils'
import { ConditionType } from '../../patient/patient'

const utils = (condition: EncounterConditionDisplay) => ({
  shouldShowActivityEditor:
    // TODO: This needs to be using a util ~Tal
    condition.conditionStatus === ConditionType.Activity &&
    conditionUtils.isOpenOrAddressed(condition),
  shouldShowQueryStatusEditor:
    conditionUtils.isKnownOrPotential(condition) &&
    conditionUtils.isOpenOrAddressed(condition) &&
    !conditionUtils.isConditionAddressed(condition),
  shouldShowOnClaimEditor:
    conditionUtils.isKnownOrPotential(condition) &&
    conditionUtils.isOpen(condition) &&
    !conditionUtils.isConditionAddressed(condition),
  shouldShowReportableEdit:
    conditionUtils.isKnownOrPotential(condition) &&
    conditionUtils.isOpen(condition) &&
    !conditionUtils.isConditionAddressed(condition),
  shouldShowNotReportableReason: conditionUtils.isNotReportable(condition),
  shouldShowReportableDiagnosisCodeEdit:
    conditionUtils.isPotential(condition) &&
    conditionUtils.isOnClaim(condition) &&
    conditionUtils.isReportable(condition),
  hasReportableStatusCode:
    !!condition.coderTags &&
    condition.coderTags.reportableStatusCode !==
      CONDITION.REPORTABLE_STATUS.NOT_REPORTABLE,
  hasReportableReason:
    !!condition.coderTags && condition.coderTags.reportableReason !== null,
  hasReportableReasontext:
    !!condition.coderTags && !!condition.coderTags.reportableText,
  diagnosisCode:
    !!condition.coderTags && condition.coderTags.conditionDiagnosisCode
      ? condition.coderTags.conditionDiagnosisCode
      : null,
  activeReportableHeaderCode:
    condition.coderTags && condition.coderTags.reportableHeaderCode
      ? condition.coderTags.reportableHeaderCode
      : null,
  activeReportableReason:
    condition.coderTags && condition.coderTags.reportableReason
      ? condition.coderTags.reportableReason
      : null,
  conditionDiagnosisCode:
    condition.coderTags && condition.coderTags.conditionDiagnosisCode
      ? condition.coderTags.conditionDiagnosisCode
      : null,
  isNotOnClaim:
    !!condition.coderTags &&
    !!condition.coderTags.onClaimStatusCode &&
    condition.coderTags.onClaimStatusCode ===
      CONDITION.ON_CLAIM_STATUS.NOT_ON_CLAIM,
  isReportable:
    !!condition.coderTags &&
    !!condition.coderTags.reportableStatusCode &&
    condition.coderTags.reportableStatusCode ===
      CONDITION.REPORTABLE_STATUS.REPORTABLE,
  doesNotHaveConditionDiagnosisCode:
    !!condition.coderTags &&
    !condition.coderTags.conditionDiagnosisCode &&
    formatIcd10Code(condition.coderTags.conditionDiagnosisCode) !==
      formatIcd10Json(condition.icd10CodeJson)[0]
})

export const modifyCoderTags =
  (condition: EncounterConditionDisplay) =>
    (
      coderTagProperties: Partial<EncounterConditionCoderTagsRow>
    ): EncounterConditionDisplay => ({
      ...condition,
      coderTags: {
        ...condition.coderTags,
        ...coderTagProperties
      }
    })

export default utils
