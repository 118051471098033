import React, { Component } from "react";
import { Dropdown, Responsive } from 'semantic-ui-react';
import { expandSpecialtyFilter, specialtyFilterKeys } from "./ruleFilterUtils";

/**
 * Provides a stand-alone select widget that allows you to select the specialty
 * Thus the CDI role can focus on Cardiology encounters, Nephrology encounters, etc.
 *
 * @param onChange Callback function on potential count select
 *   Arguments: (e, {name, value}) where value is the specialty
 *    or "" if the ALL option is selected
 * @param name Name of the select widget in a form context. Default:
 *   specialty
 * @param value Initial selected specialties
 * @param placeholder Placeholder text. Default: Specialty
 * @param multiple Allow multiple selections. Default: true
 */
class SpecialtySelect extends Component {

  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);

    this.state = {
      specialty: specialtyFilterKeys(),
      multiple: this.props.multiple === false ? false : true,
    }
  }

  onChange = (e, { value }) => {
    if ( this.props.onChange ) {
      const name = this.props.name ? this.props.name : 'specialty';
      this.props.onChange(e, {name: name, value: value});
    }
  }

  render() {
    const options = this.state.specialty.map(element => {
      return {
        key: element,
        value: element,
        text: expandSpecialtyFilter(element),
        id: 'specialty-' + element.toLowerCase()
      };
    });

    const placeholder = this.props.placeholder ? this.props.placeholder : 'Specialty';
    const id = 'specialty-select';
    if ( this.state.multiple === true ) {
      const value = this.props.value ? this.props.value : [];
      return (
        <Responsive
          as={Dropdown}
          id={id}
          fluid multiple selection
          placeholder={placeholder}
          onChange={this.onChange}
          options={options}
          value={value} />
      );
    } else {
      const value = this.props.value ? this.props.value : undefined;
      return (
        <Responsive
          as={Dropdown}
          id={id}
          fluid selection
          placeholder={placeholder}
          onChange={this.onChange}
          options={options}
          value={value} />
      );
    }
  }
}

export default SpecialtySelect;