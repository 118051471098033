import React, { Component } from "react";
import { CONDITION } from "../../../constant";

/**
 * Provides a component that renders a message if the condition is marked as
 * Dismissed
 * @param condition Condition entity
 */
class ConditionDismissed extends Component {

  render() {
    if ( this.props.condition.conditionCode === CONDITION.CODE.DISMISSED ) {
      return (
        <span className="italic">Dismissed by Provider</span>
      );
    } else {
      return null;
    }
  }
}

export default ConditionDismissed;
