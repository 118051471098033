/* eslint-disable react/sort-comp */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Accordion, Button, Container, Grid, Header, Icon, Modal, Segment } from 'semantic-ui-react'
import { API_URL, CONDITION, ENCOUNTER, HCC_GROUP, LOCK, NOTE, PAGES, REVIEW } from '../../../constant'
import { api } from '../../../action/api'
import * as lockUtils from '../../common/lockUtils'
import { spliceCondition, withEncounter } from '../../common/conditionUtils'
import ConditionHeader from '../common/conditionHeader'
import EncounterNotes from '../common/EncounterNotes'
import PreviousEncounters from '../common/previousEncounters'
import PreviousEncountersSticky from '../common/previousEncountersSticky'
import CdiEncounterHeader from './cdiEncounterHeader'
import CdiEncounterLeft from './cdiEncounterLeft'
import CdiEncounterConditionsV1 from './cdiEncounterConditionsV1'
import CdiEncounterConditionsV2 from './cdiEncounterConditionsV2'
import CdiEncounterMessage from './cdiEncounterMessage'
import { allowEncounterCDICopyPaste, allowPatientTabSchedulingCdi, encounterConditionCdiDisplayVersion } from '../../common/currentUserUtils'
import If from '../../common/if'

/**
 * Provides the top-level CDI encounter detail component
 *
 * @param encounterId Encounter ID
 * @param onEncounterNav Callback for encounter navigation request
 *        Arguments: Encounter ID
 */
class CdiEncounter extends Component {

  constructor(props) {
    super(props)
    const { encounterId } = this.props
    this.onHeaderAction = this.onHeaderAction.bind(this)
    this.onLeftAction = this.onLeftAction.bind(this)
    this.onLockFailureModalClose = this.onLockFailureModalClose.bind(this)
    this.onEncounterNavAction = this.onEncounterNavAction.bind(this)
    this.onConditionAction = this.onConditionAction.bind(this)
    this.onConditionNoteAction = this.onConditionNoteAction.bind(this)
    this.onHccGroupAction = this.onHccGroupAction.bind(this)
    this.onSubmitEncounterNote = this.onSubmitEncounterNote.bind(this)
    this.setEncounterDetail = this.setEncounterDetail.bind(this)
    this.setIsACopiedEncounter = this.setIsACopiedEncounter.bind(this)
    this.state = {
      encounterId,
      encounterDetail: null,
      activeConditionId: null,
      activeHccGroupId: null,
      lockFailureModalOpen: false,
      isACopiedEncounter: false
    }
  }

  componentDidMount() {
    this.fetchEncounter(this.state.encounterId)
  }

  updateConditionWithRules(condition) {
    const rules = condition.rules ? condition.rules : []
    this.createOrUpdateConditionRules(condition, rules, rulesResult => {
      const url = `${API_URL.UPDATE_CONDITION}/${condition.id}`
      condition.content = condition.content.trim().replace(/ {2,}/, ' ')
      api.put(url, condition).then(({ data }) => {
        const detail = this.state.encounterDetail
        data.rules = rulesResult
        detail.conditions = withEncounter(
          spliceCondition(data, detail.conditions), detail.encounter
        )
        this.setState({
          activeConditionId: condition.id,
          encounterDetail: detail
        })
      })
    })
  }

  onEncounterNavAction(encounterId) {
    this.props.onEncounterNav(encounterId)
  }

  updateCdiTags(condition) {
    const { cdiTags } = condition
    const url = API_URL.UPDATE_CONDITION_CDI_TAGS(condition.id)
    api.put(url, cdiTags).then(({ data }) => {
      const detail = this.state.encounterDetail
      detail.conditions = withEncounter(
        spliceCondition(data, detail.conditions), detail.encounter
      )
      this.setState({
        activeConditionId: condition.id,
        encounterDetail: detail
      })
    })
  }

  createCondition(condition) {
    const rules = condition.rules ? condition.rules : []
    const encounterId = this.state.encounterDetail.encounter.id
    condition.encounter = { id: encounterId }
    const url = API_URL.CREATE_CONDITION
    condition.content = condition.content.trim().replace(/ {2,}/, ' ')
    api.post(url, condition).then(({ data }) => {
      this.createOrUpdateConditionRules(data, rules, rulesResult => {
        const detail = this.state.encounterDetail
        data.rules = rulesResult
        detail.conditions.push(data)
        detail.conditions = withEncounter(detail.conditions, detail.encounter)
        this.setState({
          activeConditionId: data.id,
          encounterDetail: detail
        })
      })
    })
  }

  onHeaderAction(action) {
    if (action === ENCOUNTER.ACTION.LOCK_TOGGLE) {
      this.toggleLock()
    }
    if (action === ENCOUNTER.ACTION.REVIEW_QUERY_OUTSTANDING) {
      this.updateReviewQueryStatus(REVIEW.QUERY_STATUS.REVIEW_QUERY_OUTSTANDING)
    }
    if (action === ENCOUNTER.ACTION.REVIEW_QUERY_RESOLVED) {
      this.updateReviewQueryStatus(REVIEW.QUERY_STATUS.REVIEW_QUERY_RESOLVED)
    }
    if (action === ENCOUNTER.ACTION.MARK_OPEN) {
      this.updateReviewCode(REVIEW.CODE.OPEN)
    }
    if (action === ENCOUNTER.ACTION.MARK_READY_FOR_CDI_QA) {
      this.updateReviewCode(REVIEW.CODE.READY_FOR_CDI_QA)
    }
    if (action === ENCOUNTER.ACTION.MARK_READY_FOR_PHYSICIAN) {
      this.updateReviewCode(REVIEW.CODE.READY_FOR_PHYSICIAN)
    }
  }

  onLeftAction(action) {
    if (action === ENCOUNTER.ACTION.STATUS_TOGGLE_PLANNED) {
      this.updateEncounterStatus(ENCOUNTER.STATUS.PLANNED)
    }
    if (action === ENCOUNTER.ACTION.STATUS_TOGGLE_CANCELLED) {
      this.updateEncounterStatus(ENCOUNTER.STATUS.CANCELLED)
    }
    if (action === ENCOUNTER.ACTION.STATUS_TOGGLE_IGNORED) {
      this.updateEncounterStatus(ENCOUNTER.STATUS.IGNORED)
    }
  }

  /**
   * Currently CDI condition groups are modal: either 1 condition (or condition
   * group) is active or no conditions are active
   */
  onHccGroupDeactivate() {
    this.setState({
      activeHccGroupId: null
    });
  }

  /**
   * @param hccGroupId Null or HCC group identifier
   */
  onHccGroupActivate(hccGroupId) {
    this.setState({
      activeConditionId: null,
      activeHccGroupId: hccGroupId
    });
  }

  /**
   * @param condition Can be: null, condition or ID. An ID can be a condition or
   * a group identifier
   */
  onConditionActivate(condition) {
    if ( condition === null ) {
      this.setState({
        activeConditionId: null
      });
    } else {
      const id = typeof(condition) === "string" ? condition : condition.id;
      this.setState({
        activeConditionId: id,
        activeHccGroupId: null
      });
    }
  }

  onConditionDeactivate() {
    this.setState({
      activeConditionId: null
    });
  }

  onHccGroupAction(action, hccGroupId) {
    if ( action === HCC_GROUP.ACTION.ACTIVATE ) {
      this.onHccGroupActivate(hccGroupId);
    }
    if ( action === HCC_GROUP.ACTION.DEACTIVATE ) {
      this.onHccGroupDeactivate(hccGroupId);
    }
  }

  onConditionAction(action, condition) {
    if ( action === CONDITION.ACTION.ACTIVATE ) {
      this.onConditionActivate(condition);
    }
    if ( action === CONDITION.ACTION.DEACTIVATE ) {
      this.onConditionDeactivate(condition);
    }
    if (action === CONDITION.ACTION.MARK_ACTIVITY) {
      condition.conditionStatus = CONDITION.TYPE.ACTIVITY
      this.updateCondition(condition)
    }
    if (action === CONDITION.ACTION.MARK_KNOWN) {
      condition.conditionStatus = CONDITION.TYPE.KNOWN
      this.updateCondition(condition)
    }
    if (action === CONDITION.ACTION.MARK_OPEN) {
      condition.conditionCode = CONDITION.CODE.OPEN
      this.updateCondition(condition)
    }
    if (action === CONDITION.ACTION.MARK_POTENTIAL) {
      condition.conditionStatus = CONDITION.TYPE.POTENTIAL
      this.updateCondition(condition)
    }
    if (action === CONDITION.ACTION.MARK_REMOVED) {
      condition.conditionCode = CONDITION.CODE.REMOVED
      this.updateCondition(condition)
    }
    if (action === CONDITION.ACTION.CREATE) {
      this.createCondition(condition)
    }
    if (action === CONDITION.ACTION.UPDATE) {
      this.updateCondition(condition)
    }
    if (action === CONDITION.ACTION.UPDATE_WITH_RULES) {
      this.updateConditionWithRules(condition)
    }
    if (action === CONDITION.ACTION.UPDATE_CDI_TAGS) {
      this.updateCdiTags(condition)
    }
  }

  onLockFailureModalClose() {
    const encounterId = this.state.encounterDetail.encounter.id
    this.setState({ encounterDetail: null, lockFailureModalOpen: false },
      () => { this.fetchEncounter(encounterId) })
  }

  onConditionNoteAction(action, condition, content, reason) {
    if (action === NOTE.ACTION.CREATE) {
      this.createConditionNote(condition, content, reason)
    }
  }

  onSubmitEncounterNote(noteContent) {
    this.createEncounterNote(noteContent, NOTE.REASON.OPEN)
  }

  createEncounterNote(content, reason) {
    const encounterId = this.state.encounterDetail.encounter.id
    const activeConditionId = this.state.activeConditionId;
    const url = API_URL.CREATE_ENCOUNTER_NOTE
    const body = {
      encounterId,
      noteBody: content,
      noteReasonCode: reason || NOTE.REASON.OPEN
    }
    const currentRole = this.props.currentUser.type
    api.post(url, body).then(({ data }) => {
      api.get(API_URL.GET_ENCOUNTER_NOTES_WITH_ROLE(encounterId, currentRole)).then(({ data }) => {
        const detail = this.state.encounterDetail
        detail.notes = data
        this.setState({
          encounterDetail: detail,
          activeConditionId: activeConditionId
        })
      })
    })
  }

  createConditionNote(condition, content, reason) {
    const encounterId = this.state.encounterDetail.encounter.id
    const url = API_URL.CREATE_ENCOUNTER_NOTE
    const body = {
      encounterId,
      encounterConditionId: condition.id,
      noteBody: content,
      noteReasonCode: reason || NOTE.REASON.OPEN
    }
    const currentRole = this.props.currentUser.type
    api.post(url, body).then(({ data }) => {
      api.get(API_URL.GET_ENCOUNTER_CONDITION_NOTES_WITH_ROLE(encounterId, condition.id, currentRole)).then(({ data }) => {
        const detail = this.state.encounterDetail
        const conditions = detail.conditions.map(c => {
          if (c.id === condition.id) {
            condition.notes = data
            return condition
          }
          return c

        })
        detail.conditions = conditions
        this.setState({
          activeConditionId: condition.id,
          encounterDetail: detail
        })
      })
    })
  }

  createOrUpdateConditionRules(condition, rules, callback) {
    const url = API_URL.UPDATE_CONDITION_RULE
    const conditionRules = rules.map(rule => {
      rule.encounterConditionId = condition.id
      return rule
    })
    if (rules.length > 0) {
      api.put(url, conditionRules).then(({ data }) => {
        if (callback) callback(data)
      })
    } else if (callback) callback([])
  }

  updateCondition(condition) {
    const url = `${API_URL.UPDATE_CONDITION}/${condition.id}`
    if ( condition.content ) {
      condition.content = condition.content.trim().replace(/ {2,}/, ' ') 
    }
    api.put(url, condition).then(({ data }) => {
      const detail = this.state.encounterDetail
      detail.conditions = withEncounter(
        spliceCondition(data, detail.conditions), detail.encounter
      )
      this.setState({
        activeConditionId: condition.id,
        encounterDetail: detail
      })
    })
  }

  toggleLock() {
    const { lock } = this.state.encounterDetail.encounter
    const { encounterId } = this.state
    const { currentUser } = this.props
    const encounterStatus = this.state.encounterDetail.encounter.status
    const isIgnored = encounterStatus === ENCOUNTER.STATUS.IGNORED
    const onSuccess = response => {
      if (isIgnored) {
        this.props.history.push(PAGES.HOME)
      } else {
        this.setState({ encounterDetail: null },
          () => { this.fetchEncounter(encounterId) })
      }
    }
    const onFailure = response => {
      this.setState({ lockFailureModalOpen: true })
    }
    lockUtils.toggleLock(lock, encounterId, currentUser, onSuccess, onFailure)
  }

  setEncounterDetail(encounterDetail) {
    this.setState({ encounterDetail })
  }

  setIsACopiedEncounter(bool) {
    this.setState({ isACopiedEncounter: bool })
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }

  updateReviewQueryStatus(queryStatus) {
    const { encounter } = this.state.encounterDetail
    const encounterId = encounter.id
    const { review } = encounter
    const url = `${API_URL.UPDATE_REVIEW_QUERY_STATUS}/${review.id}/${queryStatus}`
    api.put(url).then(({ data }) => {
      if (data.queryStatusCode === REVIEW.QUERY_STATUS.REVIEW_QUERY_OUTSTANDING) {
        lockUtils.unlockEncounter(encounterId, data => {
          this.props.history.push(PAGES.HOME)
        })
      } else {
        const detail = this.state.encounterDetail
        detail.encounter.review = data
        this.setState({ encounterDetail: detail })
      }
    })
  }

  updateEncounterStatus(encounterStatus) {
    const { encounter } = this.state.encounterDetail
    const { currentUser } = this.props
    const encounterId = encounter.id
    const baseUrl = API_URL.UPDATE_ENCOUNTER_STATUS_WITH_ROLE(currentUser.type)
    const url = `${baseUrl}/${encounterId}/${encounterStatus}`
    api.put(url, {}).then(({ data }) => {
      const detail = this.state.encounterDetail
      detail.encounter.status = encounterStatus
      this.setState({
        encounterDetail: detail
      })
    })
  }

  updateReviewCode(reviewCode) {
    const { encounterId } = this.state
    const { review } = this.state.encounterDetail.encounter
    const url = `${API_URL.UPDATE_REVIEW_CODE}/${review.id}/${reviewCode}`
    const { currentUser } = this.props
    api.put(url, {}).then(({ data }) => {
      const destination = allowPatientTabSchedulingCdi(currentUser) ? PAGES.PATIENT_LIST : PAGES.HOME
      lockUtils.unlockEncounter(encounterId, data => {
        this.props.history.push(destination)
      })
    })
  }

  fetchEncounter(encounterId) {
    const userRole = this.props.currentUser.type
    const url = `${API_URL.GET_ENCOUNTER_DETAIL_BY_ID_WITH_ROLE(userRole)}${encounterId}`
    api.get(url).then(({ data }) => {
      this.setState({
        encounterId,
        encounterDetail: data
      })
    })
  }

  renderDetail(detail) {
    const { encounter } = detail
    const { notes } = detail
    const key = (new Date()).getTime()
    const encounterLeftKey = `cdi-encounter-left-${encounter.id}-${encounter.status}`
    const shouldAllowCDICopyPaste = allowEncounterCDICopyPaste(this.props.currentUser)
    const displayVersionValue = encounterConditionCdiDisplayVersion(this.props.currentUser)
    const displayVersion = displayVersionValue === '2' ? '2' : '1';
    const containerId = `cdi-encounter-${encounter.id}`
    return (
      <div id={containerId} className="header-padding">
        <Container>
          <CdiEncounterHeader
            key={ `header-${key}` }
            encounter={ encounter }
            isACopiedEncounter={ this.state.isACopiedEncounter }
            onAction={ this.onHeaderAction } />
          <Segment attached className="no-margin">
            <Grid stackable>
              <CdiEncounterMessage encounter={ encounter }
                currentUser={ this.props.currentUser } mode="grid" />
              <Grid.Row>
                <Grid.Column width={ 4 }>
                  <CdiEncounterLeft
                    key={ encounterLeftKey }
                    encounterDetail={ detail }
                    onAction={ this.onLeftAction } />
                </Grid.Column>
                <Grid.Column width={ 12 }>
                  <ConditionHeader title="Notes" displayIndex={ 0 } />
                  <Accordion fluid styled>
                    <Accordion.Content>
                      <EncounterNotes
                        key={ `encounter-notes-${notes ? notes.length : 0}` }
                        encounterId={ encounter.id }
                        notes={ notes }
                        onSubmit={ this.onSubmitEncounterNote } />
                    </Accordion.Content>
                  </Accordion>
                  {
                    displayVersion === '2' ? (
                      <CdiEncounterConditionsV2
                        key={ `encounter-conditions-${key}` }
                        activeConditionId={ this.state.activeConditionId }
                        activeHccGroupId={ this.state.activeHccGroupId }
                        encounterDetail={ detail }
                        onConditionAction={ this.onConditionAction }
                        onConditionNoteAction={ this.onConditionNoteAction }
                        onHccGroupAction={ this.onHccGroupAction } />
                    ) : (
                      <CdiEncounterConditionsV1
                        key={ `encounter-conditions-${key}` }
                        activeConditionId={ this.state.activeConditionId }
                        encounterDetail={ detail }
                        onConditionAction={ this.onConditionAction }
                        onConditionNoteAction={ this.onConditionNoteAction } />
                      )
                  }
                  <div>
                    <ConditionHeader
                      title="Previous Encounters"
                      shouldShowIcon
                      message="some message"
                    />
                  </div>
                  <Accordion fluid styled>
                    <If isTrue={ shouldAllowCDICopyPaste }>
                      <PreviousEncountersSticky
                        key={ encounter.id }
                        encounter={ encounter }
                        isEncounterLocked={ encounter && encounter.lock
                          && encounter.lock.statusCode && encounter.lock.statusCode === LOCK.STATUS.ACTIVE }
                        subjectId={ encounter.patient.id }
                        onClickGoToPrevious={ this.onEncounterNavAction }
                        currentUser={ this.props.currentUser }
                        setEncounterDetail={ this.setEncounterDetail }
                        setIsACopiedEncounter={ this.setIsACopiedEncounter }
                      />
                    </If>
                    <If isTrue={ !shouldAllowCDICopyPaste }>
                      <PreviousEncounters
                        key={ encounter.id }
                        encounterId={ encounter.id }
                        subjectId={ encounter.patient.id }
                        onClickGoToPrevious={ this.onEncounterNavAction }
                        user={ this.props.currentUser }
                      />
                    </If>
                  </Accordion>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        </Container>
        <Modal
          open={ this.state.lockFailureModalOpen }
          onClose={ this.onLockFailureModalClose }
          basic size="small">
          <Header icon="lock" content="Encounter Lock Conflict" />
          <Modal.Content>
            <h3>
              <div>
                Sorry, could not lock this encounter for you.
              </div>
              <div>
                Between opening the encounter and clicking the lock icon,
                another user has acquired a lock on this encounter.
              </div>
            </h3>
          </Modal.Content>
          <Modal.Actions>
            <Button color="green" onClick={ this.onLockFailureModalClose } inverted>
              <Icon name="checkmark" /> Ok
            </Button>
          </Modal.Actions>
        </Modal>
      </div>
    )
  }

  render() {
    const detail = this.state.encounterDetail
    return detail ? this.renderDetail(detail) : null
  }
}

export default withRouter(connect(
  state => ({
    currentUser: state.user
  }), {}
)(CdiEncounter))