import React from 'react'
// Local
import { IconProps } from './iconProps'

type CalendarProps = IconProps & {
  onClick: () => void
}

function Calendar(props: CalendarProps): JSX.Element {
  const { size = 24, color = '#000000', onClick } = props
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      onClick={onClick}
      className="calendar"
    >
      <rect x="3" y="4" width="18" height="18" rx="2" ry="2" />
      <line x1="16" y1="2" x2="16" y2="6" />
      <line x1="8" y1="2" x2="8" y2="6" />
      <line x1="3" y1="10" x2="21" y2="10" />
    </svg>
  )
}

export default Calendar
