import React, { Component } from "react";
import { Segment } from "semantic-ui-react";

/**
 * Provides a component that renders the encounter appointment type
 * @param encounter Encounter entity
 * @param mode Render mode. Default: left
 */
class EncounterAppointment extends Component {

  renderLeft(encounter) {
    const aptType = encounter.appointmentType
      ? encounter.appointmentType
      : <span className="italic">Unavailable</span>;

    return (
      <Segment attached compact className="left-segment">
        <div className="left-title">Appointment Type:</div>
        <div className="left-content">{aptType}</div>
      </Segment>
    );
  }

  render() {
    const mode = this.props.mode ? this.props.mode : "left";
    if ( mode === "left" ) {
      return this.renderLeft(this.props.encounter);
    } else {
      return null;
    }
  }
}

export default EncounterAppointment;
