/* eslint-disable default-param-last */
import { User } from '../component/authenticated/types'
import { emptyWorklistFilters } from '../component/common/worklistFilterUtils'
import { emptyAppointmentlistFilters } from '../component/common/appointmentlistFilterUtils'
import { ROLES } from '../constant'
import { emptyPatientListFilters } from '../component/common/patientListFilterUtils'

/**
 * User reducer
 */

export const CLEAR_ALL_STATE = 'CLEAR_ALL_STATE'
export const CLEAR_STATE = 'USER_CLEAR_STATE'
export const UPDATE_LOGIN = 'USER_UPDATE_LOGIN'
export const UPDATE_WORKLIST_FILTER = 'USER_UPDATE_WORKLIST_FILTER'
export const UPDATE_PATIENT_LIST_FILTER = 'UPDATE_PATIENT_LIST_FILTER'
export const UPDATE_APPOINTMENT_FILTER = 'USER_UPDATE_APPT_FILTER'

// TODO - sboles - fix vague naming
// - rename type to roleName
// - really, it would be nice to store the entire user object returned from API
const initialState: User = {
  tenantId: '',
  userId: '',
  username: '',
  password: '',
  alias: '',
  type: '' as keyof typeof ROLES,
  roles: [],
  tenantProperties: [],
  userProperties: [],
  worklistFilters: [ emptyWorklistFilters() ],
  appointmentlistFilters: [ emptyAppointmentlistFilters() ],
  patientListFilters: [ emptyPatientListFilters() ],
  authMessage: null,
  id: '',
  isAuthenticated: false,
  roleName: '',
  token: '',
  valid: false,
  authStatus: '',
  authAttempts: 0,
  authType: '',
  qrCodeUuid: ''
}

type Action = {
  type: typeof CLEAR_ALL_STATE | typeof CLEAR_STATE | typeof UPDATE_LOGIN
        | typeof UPDATE_APPOINTMENT_FILTER | typeof UPDATE_PATIENT_LIST_FILTER
        | typeof UPDATE_WORKLIST_FILTER,
  payload: any
}

export default (state = initialState, action: Action) => {
  switch (action.type) {
    case CLEAR_STATE:
    case CLEAR_ALL_STATE:
      return initialState
    case UPDATE_LOGIN: {
      const {
        valid,
        authType,
        qrCodeUuid,
        authStatus,
        authMessage,
        authAttempts,
        tenantId,
        userId,
        email,
        username,
        password,
        alias,
        type,
        roles,
        tenantProperties,
        userProperties,
        worklistFilters,
        appointmentlistFilters,
        patientListFilters
      } = action.payload
      return {
        ...state,
        isAuthenticated: valid,
        authType,
        qrCodeUuid,
        authStatus,
        authMessage,
        authAttempts,
        tenantId,
        userId,
        email,
        username,
        password,
        alias,
        type,
        roles,
        tenantProperties,
        userProperties,
        worklistFilters,
        appointmentlistFilters,
        patientListFilters
      }
    }
    case UPDATE_APPOINTMENT_FILTER: {
      const {
        appointmentlistFilters
      } = action.payload
      return {
        ...state,
        appointmentlistFilters
      }
    }
    case UPDATE_PATIENT_LIST_FILTER: {
      const {
        patientListFilters
      } = action.payload
      return {
        ...state,
        patientListFilters
      }
    }
    case UPDATE_WORKLIST_FILTER: {
      const {
        worklistFilters
      } = action.payload
      return {
        ...state,
        worklistFilters
      }
    }
    default:
      return state
  }
}
