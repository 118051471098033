/* eslint-disable @typescript-eslint/naming-convention */
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Form, Input, Menu, Responsive } from 'semantic-ui-react'
// Local
import { clearAndLoadSelected } from '../../action/encounter'
import * as c from '../../constant'

const EncounterSearch = (WrappedComponent: any) => {
  function EncounterHOC({
    type,
    hasFormUpdates,
    setActionState,
    clearAndLoadSelected: clearAndLoadSelectedTwo
  }: any) {
    const [ encounterId, setEncounterId ] = useState('')

    const handleChange = (e: any, input: any) => {
      setEncounterId(input.value as string)
    }

    const onClick = () => {
      if (hasFormUpdates) {
        setActionState(c.PAGES.ENCOUNTER_DETAIL + encounterId)
      } else {
        setEncounterId('')
        clearAndLoadSelectedTwo(encounterId)
        setActionState(undefined)
        window.location.href = c.PAGES.ENCOUNTER_DETAIL + encounterId
      }
    }

    // Only show the encounter field for Coders.
    if (type !== c.ROLES.CODER) return null

    return (
      <WrappedComponent
        handleChange={ handleChange }
        hasFormUpdates={ hasFormUpdates }
        onClick={ onClick }
        setActionState={ setActionState }
        encounterId={ encounterId }
      />
    )
  }

  return EncounterHOC
}

type HeaderProps = {
  onClick: () => void
  handleChange: () => void
  encounterId: string
}

function HeaderEncounterSearchImpl(props: HeaderProps) {
  const { onClick, handleChange, encounterId } =
    props
  return (
    <Responsive as={ Menu.Item } minWidth={ c.TABLET_WIDTH.minWidth }>
      <Input
        action={ {
          type: 'submit',
          content: 'Go',
          onClick
        } }
        onChange={ handleChange }
        placeholder="Search By Encounter:"
        value={ encounterId }
      />
    </Responsive>
  )
}

function MobileEncounterSearchImpl(props: HeaderProps) {
  const { onClick, handleChange, encounterId } =
    props
  return (
    <Responsive
      as={ Form }
      maxWidth={ c.MOBILE_WIDTH.maxWidth }
      className="no-margin"
    >
      <Form.Group>
        <Form.Field width={ 16 }>
          <Input
            placeholder="Encounter Search:"
            action={ {
              type: 'submit',
              content: 'Go',
              onClick,
              style: { width: '55px' }
            } }
            onChange={ handleChange }
            value={ encounterId }
          />
        </Form.Field>
      </Form.Group>
    </Responsive>
  )
}

const mapStateToProps = (state: any, ownProps: any) => ({
  type: state.user.type,
  setActionState: ownProps.setActionState,
  hasFormUpdates: state.coder.hasFormUpdates
})

const mapDispatchToProps = {
  clearAndLoadSelected
}

const HeaderEncounterSearch = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(EncounterSearch(HeaderEncounterSearchImpl))
)

const MobileEncounterSearch = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(EncounterSearch(MobileEncounterSearchImpl))
)

export { HeaderEncounterSearch, MobileEncounterSearch }
