import React, { Component } from "react";
import { Icon, Popup } from "semantic-ui-react";
import { startOfDay } from "date-fns";
import DatePicker from "react-datepicker";
import "./datePickerIcon.css";

/**
 * Provides a date picker widget that displays with a custom input that is an
 * Icon inside of a Popup. Ideal for use in toolbars.
 *
 * @param onSelect Callback function triggered on date select.
 *        Argument: date with time set at start of day
 * @param startDate Default: Today. Override start date with instance of Date
 * @param iconName Default: calendar. Override the icon
 * @param iconColor Default: null. Override icon color
 * @param popperClassName Default: null. Adds style overrides to the popper.
 *        Useful for adjusting placement. See datePickerIcon.scss
 * @param popupContent Default: 'Select a Date'. Override popup content
 * @param mouseDelay Default: 1000. Override MS to delay popup
 */
export class DatePickerIcon extends Component {

  constructor(props) {
    super(props);

    this.state = {
      startDate: props.startDate ? props.startDate : new Date()
    };
  }

  handleChange(value) {
    const date = startOfDay(value);
    this.setState({startDate: date});
    this.props.onSelect(date);
  }

  render() {
    const props = this.props;
    const iconName = props.iconName ? props.iconName : "calendar";
    const iconColor = props.iconColor ? props.iconColor : "grey";
    const popupContent = props.popupContent ? props.popupContent : "Select a Date";
    const mouseDelay = props.mouseDelay ? props.mouseDelay : 1000;
    const popperClassName = props.popperClassName ? props.popperClassName : null;

    return (
      <DatePicker
        popperClassName={popperClassName}
        customInput={<DatePickerCustomInput
          iconName={iconName} iconColor={iconColor}
          popupContent={popupContent} mouseDelay={mouseDelay} />}
        selected={this.state.startDate}
        onChange={this.handleChange.bind(this)} />
    );
  }
}

/**
 * Not intended for use outside of DatePickerWidget
 *
 * Provides the date picker custom input. The onClick callback property is
 * provided by the DatePicker widget
 */
class DatePickerCustomInput extends Component {

  render() {
    const iconName = this.props.iconName;
    const iconColor = this.props.iconColor;
    const popupContent = this.props.popupContent;
    const mouseDelay = this.props.mouseDelay;

    return (
      <Popup content={popupContent} mouseEnterDelay={mouseDelay}
        trigger={<Icon size="large" className="pointer"
          name={iconName} color={iconColor}
          onClick={this.props.onClick} />} />
    );
  }
}
