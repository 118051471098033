import { User, AppointmentlistFilter } from '../authenticated/types'
import { patientOffshoreFilter } from './currentUserUtils'

/**
 * Provides user appointmentlist filter management utilities
 *
 * This is based on the same ideas as the worklist filter. This is 
 * only used by the CDI appointments page.
 */

/**
 * @return Appointmentlist filters all initialized to null
 */
export const emptyAppointmentlistFilters = (): AppointmentlistFilter => ({
  mrn: null,
  start: null,
  firstName: null,
  lastName: null,
  birthDate: null,
  practitionerId: null,
  defaultPatientOffshoreFilter: null
})

/**
 * Initialize empty appointmentlist filters with defaults
 * @return Initialized appointmentlist filters
 */
export function initAppointmentlistFilters(currentUser: User | any) {
  const filters = emptyAppointmentlistFilters()
  filters.defaultPatientOffshoreFilter = patientOffshoreFilter(currentUser as User)
  return filters
}

/**
 * Update appointmentlist filter. Send all filters that are currently enabled.
 * Required default filters are set
 * @param updateFilters Filter update requests
 * @return Filters with requested updates applied
 */
export function updateAppointmentlistFilters(
  user: User,
  updateFilters: AppointmentlistFilter
): AppointmentlistFilter {
  const filters = initAppointmentlistFilters(user)
  const {
    mrn,
    start,
    firstName,
    lastName,
    birthDate,
    practitionerId
  } = updateFilters
  // defaultPatientOffshoreFilter value passes through
  return {
    ...filters,
    mrn,
    start,
    firstName,
    lastName,
    birthDate,
    practitionerId
  }
}