import React from 'react'
import { Header } from 'semantic-ui-react'
import './subHeader.css'

const SubHeaderComponent = props => {
  const { left, center, right } = props
  return (
    <Header id="sub-header" attached="top" block className="main-sub-header">
      <div className="sub-left">{ left }</div>
      <div className="sub-center">{ center }</div>
      <div className="sub-right">{ right }</div>
    </Header>
  )
}

export default SubHeaderComponent
