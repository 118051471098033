import React, { Component } from "react";
import { Icon, Message, Popup } from "semantic-ui-react";
import { hasReviewQueryOutstanding, isReadyForCdiQa, reviewerName } from "../../common/reviewUtils";

/**
 * Provides a component that renders the encounter CDI QA messages
 * @param review Encounter review entity
 * @param mode Render mode. Default: left
 */
class EncounterCdiQa extends Component {

  renderLeft(review) {
    const readyForQa = isReadyForCdiQa(review)
    return (
      <Message warning className="cdi">
        <div className="bold">CDI Patient Review</div>
        {readyForQa && (
          <div>
            <span>Ready for CDI QA</span>
            {reviewerName(review) && (
              <span>: {reviewerName(review)}</span>
            )}
          </div>
        )}
        {hasReviewQueryOutstanding(review) && (
          <div className="m-1-top">
            <Popup content="CDI Follow-Up Requested"
              trigger={<Icon name="hourglass half" color="red" />} />
            <span> </span>
            <span>CDI follow-up requested</span>
          </div>
        )}
      </Message>
    );
  }

  render() {
    const review = this.props.review ? this.props.review : null;
    if ( review ) {
      const mode = this.props.mode ? this.props.mode : "left";
      if ( mode === "left" ) {
        return this.renderLeft(this.props.review);
      } else {
        return null;
      }
    } else {
      return null;
    }
  }
}

export default EncounterCdiQa;
