import React, { Component } from "react";
import { Icon, Popup } from "semantic-ui-react";
import { format } from "date-fns";
import { FORMAT } from "../../../constant";
import * as lockUtils from "../../common/lockUtils";

/**
 * Provides a component that renders a lock icon that reflects the state of the
 * encounter lock and current user permissions
 * @param lock Lock entity
 * @param currentUser Current User
 * @param onClick Callback function on lock icon click. Arguments: None
 */
class LockIcon extends Component {

  render() {
    const lock = this.props.lock;
    const currentUser = this.props.currentUser;
    const onClick = this.props.onClick;

    if ( lockUtils.locked(lock) && lockUtils.lockOwner(lock, currentUser) ) {
      // locked; current user owns lock
      const lockDate = format(lock.creationDate, FORMAT.DATE_TIME)
      const msg = `Locked by you at ${lockDate}. Click to unlock`;
      return (
        <Popup content={msg} mouseEnterDelay={500} trigger={
          <Icon name="lock"
            size="large"
            color="grey"
            onClick={onClick}
            className="pointer" />
        } />
      );
    } else if ( lockUtils.locked(lock) ) {
      // locked; another user owns lock
      const lockDate = format(lock.creationDate, FORMAT.DATE_TIME)
      const lockUser = lock.createdByUsername;
      const msg = `Locked for user ${lockUser} at ${lockDate}`;
      return (
        <Popup content={msg} mouseEnterDelay={500} trigger={
          <Icon name="lock"
            size="large"
            color="red" />
        } />
      );
    } else {
      // unlocked
      const msg = "Click to lock this encounter and begin review";
      return (
        <Popup content={msg} mouseEnterDelay={500} trigger={
          <Icon name="unlock"
            size="large"
            color="grey"
            onClick={onClick}
            className="pointer" />
        } />
      );
    }
  }
}

export default LockIcon;
