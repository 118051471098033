/* eslint-disable react/no-children-prop */
import React from 'react'
import { Accordion, Grid } from 'semantic-ui-react'
import ReactMarkdown from 'react-markdown'
import { CONDITION, ROLES } from '../../../constant'
import {
  conditionClaimSource,
  condition as conditionUtils
} from '../../common/conditionUtils'
import { formatConditionTitle, displayRiskCode } from '../../common/formatUtils'
import AddressedIcon from '../common/addressedIcon'
import ConditionQueryStatusIcon from '../common/conditionQueryStatusIcon'
import ConditionAddressedDate from '../common/conditionAddressedDate'
import ConditionDismissed from '../common/conditionDismissed'
import ConditionNotes from '../common/ConditionNotes'
import ConditionReasons from '../common/conditionReasons'
import CoderTagsRead from './CoderTagsRead'
import { EncounterConditionDisplay } from '../../authenticated/types'

/**
 * Provides a component that renders a Coder condition read-only view
 * @param condition Condition entity
 * @param displayIndex Condition display index relative to its display group
 * @param active If true, display accordion open. Default: false
 * @param onClickTitle Callback for condition accordion title click
 * Arguments: condition
 * @param onSubmitNote Callback for condition note submit
 * Arguments: condition, note body, note reason
 */
type Props = {
  condition: EncounterConditionDisplay
  isActive: boolean
  displayIndex: number
  onClickTitle: (condition: EncounterConditionDisplay) => void
  onSubmitNote: (
    condition: EncounterConditionDisplay,
    noteBody: string,
    noteReason: string
  ) => void
}

function CoderConditionRead(props: Props): JSX.Element {
  const {
    condition,
    isActive,
    displayIndex,
    onClickTitle,
    onSubmitNote: onSubmitNoteProp
  } = props

  const onSubmitNote = (
    c: EncounterConditionDisplay,
    noteBody: string,
    noteReason: string
  ) => {
    onSubmitNoteProp(c, noteBody, noteReason)
  }
  const shouldShowDismissStatus =
    condition.conditionCode === CONDITION.CODE.DISMISSED
  const shouldShowQueryStatus = !shouldShowDismissStatus
  const claimSource = conditionClaimSource(condition)
  const hccCode = condition.hccCode
    ? `(${displayRiskCode(condition.hccCode)})`
    : ''
  const titleText = formatConditionTitle(condition)
  const columns = conditionUtils.hasCoderTags(condition) ? 2 : 1
  const clazz =
    displayIndex && displayIndex === 0
      ? 'flex-title first-accordion'
      : 'flex-title'

  return (
    <div className="condition">
      <Accordion.Title
        active={ isActive }
        index={ condition.id }
        onClick={ () => {
          onClickTitle(condition)
        } }
        className={ clazz }
      >
        <AddressedIcon condition={ condition } />
        <div className="condition-title">
          { isActive ? (
            <strong>{ `${hccCode} ${titleText}` }</strong>
          ) : (
            <span>
              <strong>{ hccCode }</strong> { titleText }
            </span>
          ) }
        </div>
        <div className="condition-toolbar">
          { shouldShowDismissStatus && (
            <ConditionDismissed condition={ condition } />
          ) }
          { shouldShowQueryStatus && (
            <ConditionQueryStatusIcon condition={ condition } />
          ) }
        </div>
      </Accordion.Title>
      <Accordion.Content active={ isActive }>
        <div className="ui stackable grid internal-condition">
          <Grid.Row className="first-row" columns={ columns } divided>
            <Grid.Column>
              <ConditionReasons condition={ condition } />
              <ReactMarkdown
                children={ condition.content || '' }
                linkTarget="_blank"
              />
              <ConditionAddressedDate condition={ condition } />
            </Grid.Column>
            { conditionUtils.hasCoderTags(condition) && (
              <Grid.Column>
                <CoderTagsRead condition={ condition } />
              </Grid.Column>
            ) }
          </Grid.Row>
          { claimSource && (
            <Grid.Row>
              <Grid.Column textAlign="center">
                <span className="footnote">
                  Initial source of condition: { claimSource }
                </span>
              </Grid.Column>
            </Grid.Row>
          ) }
          { conditionUtils.hasPractitionerTags(condition) && (
            <Grid.Row>
              <Grid.Column textAlign="center">
                <span>Provider Response:</span>
                <span> </span>
                <span className="bold">
                  { condition.practitionerTags &&
                    condition.practitionerTags.practitionerResponseCode }
                </span>
              </Grid.Column>
            </Grid.Row>
          ) }
          <Grid.Row className="divider">
            <Grid.Column>
              <ConditionNotes
                condition={ condition }
                userRole={ ROLES.CODER }
                onSubmit={ onSubmitNote }
              />
            </Grid.Column>
          </Grid.Row>
        </div>
      </Accordion.Content>
    </div>
  )
}

export default CoderConditionRead
