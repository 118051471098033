/* eslint-disable no-param-reassign */
/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Accordion } from 'semantic-ui-react'
import filter from 'lodash/filter'
import { CONDITION, ROLES } from '../../../constant'
import {
  condition as conditionUtils,
  conditionGroupTitle,
  filterWithStatus,
  groupByAddressed,
  hasConditionsOrActivities,
  orderConditionsByHccConfidenceDescending
} from '../../common/conditionUtils'
import { groupByHccCode } from '../../common/hccUtils'
import {
  encounterConditionGroups,
  encounterConditionGroupsEnabled,
  hccGroupingEnabled
} from '../../common/currentUserUtils'
import ConditionHeader from '../common/conditionHeader'
import PatientSnapshotCondition from './patientSnapshotCondition'
import PatientSnapshotConditionHccGroup from './patientSnapshotConditionHccGroup'
import '../detail.css'

/**
 * Provides a component that renders the Patient Snapshot encounter conditions.
 *
 * The Patient Snapshot view of conditions is very close to the Clinical Admin view
 * of encounter conditions with the exception that the conditions are 100% read
 * only
 *
 * @param mode View mode. Options: "smart", null. Default: null
 * @param encounterDetail Encounter detail entity
 * @param conditionGroups Optional condition groups to display; overrides tenant property
 * @param activeConditionId Active condition ID (default: null). This is the
 *        last condition or group that the user expanded
 * @param onConditionAction Callback for condition action.
 *        Arguments: action, condition
 */
class PatientSnapshotEncounterConditions extends Component {

  constructor(props) {
    super(props)
    const { activeConditionId } = this.props
    this.state = {
      activeConditionId
    }
  }

  // onClickTitle = condition => {
  //   if (condition.id === this.state.activeConditionId) {
  //     this.setState({ activeConditionId: null }, () => {
  //       this.props.onConditionAction(CONDITION.ACTION.READ, null)
  //     })
  //   } else {
  //     this.setState({ activeConditionId: condition.id }, () => {
  //       this.props.onConditionAction(CONDITION.ACTION.READ, condition)
  //     })
  //   }
  // }

  groupConditions(conditions) {
    const { currentUser } = this.props
    if (hccGroupingEnabled(currentUser)) {
      return groupByHccCode(conditions)
    }
    return groupByAddressed(conditions)

  }

  filterConditions(conditions, conditionStatusCode) {
    const ignoreCodes = [ CONDITION.CODE.REMOVED ]
    return orderConditionsByHccConfidenceDescending(
      filterWithStatus(ROLES.PATIENT_SNAPSHOT, conditions, conditionStatusCode, ignoreCodes)
    )
  }

  sortConditionGroups(groups) {
    const withHcc = groups.filter(g => g.hccCode !== null)
    const withHccAddressed = withHcc.filter(g => g.addressed === true)
    const withHccNotAddressed = withHcc.filter(g => g.addressed === false)
    const withoutHcc = groups.filter(g => g.hccCode === null)
    const withoutHccAddressed = withoutHcc.filter(g => g.addressed === true)
    const withoutHccNotAddressed = withoutHcc.filter(g => g.addressed === false)
    return withHccNotAddressed.concat(withoutHccNotAddressed).concat(withHccAddressed).concat(withoutHccAddressed)
  }

  sortConditions(conditions) {
    const addressed = filter(conditions, condition => conditionUtils.isConditionAddressed(condition))

    const notAddressed = filter(conditions, condition => !conditionUtils.isConditionAddressed(condition))

    return notAddressed.concat(addressed)
  }

  renderHccGroup(group, index, groupTitle) {
    const { mode, onConditionAction } = this.props
    const { activeConditionId } = this.state
    return (
      <PatientSnapshotConditionHccGroup
        key={ `${group.hccCode}-${index}` }
        mode={ mode }
        hccCode={ group.hccCode }
        hccDescription={ group.hccDescription }
        activeConditionId={ activeConditionId }
        conditionGroupTitle={ groupTitle }
        conditions={ group.conditions }
        onConditionAction={ onConditionAction }
      />
    )
  }

  renderUngrouped(group) {
    const { mode, onConditionAction } = this.props
    const { activeConditionId } = this.state
    return group.conditions.map((condition, conditionIndex) => (
      <PatientSnapshotCondition
        key={ condition.id }
        mode={ mode }
        active={ activeConditionId === condition.id }
        condition={ condition }
        displayIndex={ conditionIndex }
        onConditionAction={ onConditionAction }
      />
    ))
  }

  renderConditions(displayConditions) {
    const { currentUser, encounterDetail } = this.props
    const key = encounterDetail.encounter.id
    return (
      <div key={ key }>
        {
          displayConditions.map((display, displayIndex) => {
            display.hasInsightsToShow = hasConditionsOrActivities(display.conditions, currentUser.type)
            return (
              <div key={ display.title } >
                <ConditionHeader title={ display.title } displayIndex={ displayIndex } />
                { display.hasInsightsToShow &&
                  <Accordion styled fluid>
                    {
                      display.conditions.map((group, groupIndex) => {
                        if (group.hccCode === null && group.conditions.length > 0) {
                          return this.renderUngrouped(group, groupIndex)
                        } if (group.hccCode !== null && group.conditions.length > 0) {
                          return this.renderHccGroup(group, groupIndex, display.title)
                        }
                        return null

                      })
                    }
                  </Accordion>
                }
                { !display.hasInsightsToShow &&
                  <Accordion styled fluid>
                    <Accordion.Content>
                      <span>{ `No ${display.title} to show at this time.` }</span>
                    </Accordion.Content>
                  </Accordion>
                }
              </div>
            )
          })
        }
      </div>
    )
  }

  render() {
    const { encounterDetail } = this.props
    const detail = encounterDetail
    const { conditions } = detail
    const { currentUser, conditionGroups: conditionGroupsProp } = this.props
    const conditionGroups = encounterConditionGroups(currentUser, conditionGroupsProp)
    const groupsEnabled = encounterConditionGroupsEnabled(currentUser)
    const displayConditions = []

    conditionGroups.forEach(g => {
      if (groupsEnabled[g] === true) {
        displayConditions.push(
          {
            title: conditionGroupTitle(g),
            conditions: this.sortConditionGroups(
              this.groupConditions(
                this.sortConditions(
                  this.filterConditions(conditions, g)
                )
              )
            )
          }
        )
      }
    })

    return this.renderConditions(displayConditions)
  }
}

export default connect(
  state => ({
    currentUser: state.user
  }), {}
)(PatientSnapshotEncounterConditions)
