import React, { Component } from "react";
import { connect } from "react-redux";
import { Accordion } from "semantic-ui-react";
import filter from "lodash/filter";
import { CONDITION, ROLES } from "../../../constant";
import { condition as conditionUtils, conditionGroupTitle, filterNlpConditions, filterWithStatus, groupByAddressed, hasConditionsOrActivities, orderConditionsByHccConfidenceDescending } from "../../common/conditionUtils";
import { groupByHccCode } from "../../common/hccUtils";
import { encounterConditionGroups, encounterConditionGroupsEnabled, hccGroupingEnabled } from "../../common/currentUserUtils";
import ConditionHeader from "../common/conditionHeader";
import PhysicianCondition from "./physicianCondition";
import PhysicianConditionHccGroup from "./physicianConditionHccGroup";
import "../detail.css";

/**
 * Provides a component that renders the Physician encounter conditions
 *
 * @param mode View mode. Options: "smart", null. Default: null
 * @param encounterDetail Encounter detail entity
 * @param conditionGroups Optional condition groups to display; overrides tenant property
 * @param activeConditionId Active condition ID (default: null). This is the
 *        last condition or group that the user expanded
 * @param onConditionAction Callback for condition action.
 *        Arguments: action, condition
 */
class PhysicianEncounterConditions extends Component {

  constructor(props) {
    super(props);
    this.state = {
      activeConditionId: this.props.activeConditionId
    };
  }

  onClickTitle = (condition) => {
    if ( condition.id === this.state.activeConditionId ) {
      this.setState({ activeConditionId: null }, () => {
        this.props.onConditionAction(CONDITION.ACTION.READ, null);
      });
    } else {
      this.setState({ activeConditionId: condition.id }, () => {
        this.props.onConditionAction(CONDITION.ACTION.READ, condition);
      });
    }
  }

  groupConditions(conditions) {
    const currentUser = this.props.currentUser;

    if ( hccGroupingEnabled(currentUser) ) {
      return groupByHccCode(conditions);
    } else {
      return groupByAddressed(conditions);
    }
  }

  filterConditions(conditions, conditionStatusCode) {
    const filteredConditions = filterNlpConditions(this.props.currentUser, conditions);
    const ignoreCodes = [CONDITION.CODE.REMOVED];
    return orderConditionsByHccConfidenceDescending(
      filterWithStatus(ROLES.PRACTITIONER, filteredConditions, conditionStatusCode, ignoreCodes));
  }

  sortConditionGroups(groups) {
    const withHcc = groups.filter(g => { return g.hccCode !== null });
    const withHccAddressed = withHcc.filter(g => { return g.addressed === true });
    const withHccNotAddressed = withHcc.filter(g => { return g.addressed === false });
    const withoutHcc = groups.filter(g => { return g.hccCode === null; });
    const withoutHccAddressed = withoutHcc.filter(g => { return g.addressed === true });
    const withoutHccNotAddressed = withoutHcc.filter(g => { return g.addressed === false });
    return withHccNotAddressed.concat(withoutHccNotAddressed).concat(withHccAddressed).concat(withoutHccAddressed);
  }

  sortConditions(conditions) {
    const addressed = filter(conditions, (condition) => {
      return conditionUtils.isConditionAddressed(condition);
    });

    const notAddressed = filter(conditions, (condition) => {
      return !conditionUtils.isConditionAddressed(condition);
    });

    return notAddressed.concat(addressed);
  }

  renderHccGroup(group, index, groupTitle) {
    return (
      <PhysicianConditionHccGroup
        key={group.hccCode + "-" + index}
        mode={this.props.mode}
        hccCode={group.hccCode}
        hccDescription={group.hccDescription}
        activeConditionId={this.state.activeConditionId}
        conditionGroupTitle={groupTitle}
        conditions={group.conditions}
        onConditionAction={this.props.onConditionAction}
      />
    );
  }

  renderUngrouped(group, index) {
    return group.conditions.map((condition, conditionIndex) => {
      return (
        <PhysicianCondition
          key={condition.id}
          mode={this.props.mode}
          active={this.state.activeConditionId === condition.id}
          condition={condition}
          displayIndex={conditionIndex}
          onConditionAction={this.props.onConditionAction}
        />
      );
    });
  }

  renderConditions(displayConditions) {
    const detail = this.props.encounterDetail;
    const key = detail.encounter.id;
    return (
      <div key={key}>
        {
          displayConditions.map((display, displayIndex) => {
            display.hasInsightsToShow = hasConditionsOrActivities(display.conditions, this.props.currentUser.type);
            return (
              <div key={displayIndex} >
                <ConditionHeader title={display.title} displayIndex={displayIndex} />
                { display.hasInsightsToShow &&
                  <Accordion styled fluid>
                  {
                    display.conditions.map((group, groupIndex) => {
                      if ( group.hccCode === null && group.conditions.length > 0 ) {
                        return this.renderUngrouped(group, groupIndex);
                      } else if ( group.hccCode !== null && group.conditions.length > 0 ) {
                        return this.renderHccGroup(group, groupIndex, display.title);
                      } else {
                        return null;
                      }
                    })
                  }
                  </Accordion>
                }
                { !display.hasInsightsToShow &&
                  <Accordion styled fluid>
                    <Accordion.Content>
                      <span>{`No ${display.title} to show at this time.`}</span>
                    </Accordion.Content>
                  </Accordion>
                }
              </div>
            )
          })
        }
      </div>
    );
  }

  render() {
    const detail = this.props.encounterDetail;
    const conditions = detail.conditions;
    const currentUser = this.props.currentUser;
    const conditionGroups = encounterConditionGroups(currentUser, this.props.conditionGroups);
    const groupsEnabled = encounterConditionGroupsEnabled(currentUser);
    const displayConditions = [];

    conditionGroups.forEach((g, i) => {
      if ( groupsEnabled[g] === true ) {
        displayConditions.push(
          {
            title: conditionGroupTitle(g),
            conditions: this.sortConditionGroups(
              this.groupConditions(
                this.sortConditions(
                  this.filterConditions(conditions, g))))
          }
        );
      }
    });

    return this.renderConditions(displayConditions);
  }
}

export default connect(
  state => ({
    currentUser: state.user
  }), {}
)(PhysicianEncounterConditions);
