import React, { Component } from "react";
import { Accordion } from "semantic-ui-react";
import { CONDITION } from "../../../constant";
import { withAddressedConditions } from "../../common/conditionUtils";
import PatientSnapshotCondition from "./patientSnapshotCondition";
import ClinicalAdminConditionHccHeaderTitle from "../clinicalAdmin/clinicalAdminConditionHccHeaderTitle";

/**
 * Provides a component that displays conditions grouped under an HCC for the
 * Patient Snapshot view of conditions. The Patient Snapshot view of conditions
 * s very similar to the Clinical Admin view of conditions and may use some of
 * those components
 *
 * @param mode View mode. Options: "smart", null. Default: null
 * @param hccCode HCC code
 * @param hccDescription HCC description
 * @param activeConditionId currently set active conditionId from the last call of "this.props.onConditionAction(ACTIVATE || DEACTIVATE, this.domKey)"
 *                          this value is set by: `${this.props.conditionGroupTitle}-${this.props.hccCode}`
 * @param conditionGroupTitle the conditionStatus group, values: KNOWN, POTENTIAL, or ACTIVITY
 * @param conditions List of conditions to display in the group
 * @param onConditionAction Callback for condition action.
 *        Arguments: action, condition
 */
class PatientSnapshotConditionHccGroup extends Component {

  constructor(props) {
    super(props);
    this.domKey = `${this.props.conditionGroupTitle}-${this.props.hccCode}`;
    this.state = {
      active: this.props.activeConditionId === this.domKey ? true : false
    }
  }

  onClickTitle = () => {
    const ACTIVATE = CONDITION.ACTION.ACTIVATE;
    const DEACTIVATE = CONDITION.ACTION.DEACTIVATE;
    const READ = CONDITION.ACTION.READ;
    if ( this.state.active === true ) {
      // deactivate group
      this.setState({ active: false }, () => {
        this.props.onConditionAction(DEACTIVATE, this.domKey);
      });
    } else {
      // activate group
      this.setState({ active: true },() => {
        this.props.onConditionAction(ACTIVATE, this.domKey);
        this.props.conditions.forEach((c) => {
          this.props.onConditionAction(READ, c);
        })
      });
    }
  }

  hccGroupTitle(conditions) {
    const hccCode = this.props.hccCode;
    const hccDescription = this.props.hccDescription;

    return (
      <ClinicalAdminConditionHccHeaderTitle
        conditions={conditions}
        active={this.state.active}
        mode={this.props.mode}
        hccCode={hccCode}
        hccDescription={hccDescription}
        onConditionAction={this.props.onConditionAction} />
    );
  }

  renderConditions(conditions) {
    const isActive = this.state.active;
    const hccAddressed = withAddressedConditions(conditions).length > 0;
    return (
      <div>
        <Accordion.Title
          key={this.domKey}
          active={isActive}
          onClick={this.onClickTitle}>
          <div>{this.hccGroupTitle(conditions)}</div>
        </Accordion.Title>
        <Accordion.Content active={isActive}>
          <Accordion fluid>
            {
              this.props.conditions.map((condition, displayIndex) => {
                return (
                  <PatientSnapshotCondition
                    key={condition.id}
                    mode={this.props.mode}
                    active={isActive}
                    hccChild={true}
                    hccAddressed={hccAddressed}
                    condition={condition}
                    displayIndex={displayIndex}
                    onConditionAction={this.props.onConditionAction} />
                );
              })
            }
          </Accordion>
        </Accordion.Content>
      </div>
    );
  }

  render() {
    return this.props.conditions && this.props.conditions.length > 0
      ? this.renderConditions(this.props.conditions)
      : null;
  }
}

export default PatientSnapshotConditionHccGroup;
