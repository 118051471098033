import React, { useState } from 'react'
import { Form, Radio } from 'semantic-ui-react'
// Local
import { ENCOUNTER } from '../../../constant'
import { Encounter, ObjectValues } from '../../authenticated/types'

/**
 * Provides a component that renders an encounter type editor.
 * @param encounter Encounter entity
 * @param onAction Callback function for encounter action
 *  Arguments: action, encounter
 * @param mode Render mode. Default: left
 */

type Props = {
  encounter: Encounter
  onAction: (
    action: ObjectValues<typeof ENCOUNTER.ACTION>,
    encounter: Encounter
  ) => void
  mode?: string
}

function CoderEncounterTypeEdit(props: Props): JSX.Element | null {
  const { encounter, onAction, mode = 'left' } = props

  const [ encounterType, setEncounterType ] = useState<string | null | undefined>(
    encounter.encounterTypeCode
  )

  const onChange = (e: any, { value }: { value: string }) => {
    encounter.encounterTypeCode = value
    const revisedEncounter = {
      ...encounter,
      encounterTypeCode: value
    }
    onAction(ENCOUNTER.ACTION.TYPE_TOGGLE, revisedEncounter)
    setEncounterType(value)
  }

  const onClickReset = () => {
    const value = ENCOUNTER.TYPE.UNKNOWN
    encounter.encounterTypeCode = value
    setEncounterType(value)
    onAction(ENCOUNTER.ACTION.TYPE_TOGGLE, encounter)
  }

  if (mode !== 'left') return null
  return (
    <div className="ui attached segment left-margin compact">
      <div className="left-title">Confirm Encounter Type</div>
      <div className="left-content">
        <form className="m-1-top">
          <Form.Field
            control={ Radio }
            label="Telehealth"
            name="encounterTypeGroup"
            value={ ENCOUNTER.TYPE.TELEHEALTH }
            checked={ encounterType === ENCOUNTER.TYPE.TELEHEALTH }
            onChange={ onChange }
          />
          <Form.Field
            control={ Radio }
            label="Telephonic"
            name="encounterTypeGroup"
            value={ ENCOUNTER.TYPE.TELEPHONIC }
            checked={ encounterType === ENCOUNTER.TYPE.TELEPHONIC }
            onChange={ onChange }
          />
        </form>
        <div className="m-1-top m-1-bottom text-align-center">
          <span className="linkable" onClick={ onClickReset }>
            Reset
          </span>
        </div>
      </div>
    </div>
  )
}

export default CoderEncounterTypeEdit
