import { isNil } from 'ramda'
// Local
import { REVIEW } from '../../constant'
import { EncounterReviewer, EncounterReview } from '../authenticated/types'

/** *****************************************************************************
 * Provides utilities for managing reviews
 */

type Review = {
  reviewer: EncounterReviewer
}

export const isOpen = (review: EncounterReview | null | undefined): boolean =>
  isNil(review) ? false : review.reviewCode === REVIEW.CODE.OPEN

export const isReadyForCdiQa = (
  review: EncounterReview | null | undefined
): boolean =>
  isNil(review) ? false : review.reviewCode === REVIEW.CODE.READY_FOR_CDI_QA

export const isReadyForCoderQa = (
  review: EncounterReview | null | undefined
): boolean =>
  isNil(review) ? false : review.reviewCode === REVIEW.CODE.READY_FOR_CODER_QA

export const isReadyForPhysician = (
  review: EncounterReview | null | undefined
): boolean =>
  isNil(review) ? false : review.reviewCode === REVIEW.CODE.READY_FOR_PHYSICIAN

export const hasReviewQueryOutstanding = (
  review: EncounterReview | null | undefined
): boolean =>
  isNil(review)
    ? false
    : review.queryStatusCode === REVIEW.QUERY_STATUS.REVIEW_QUERY_OUTSTANDING

export const hasReviewQueryResponse = (
  review: EncounterReview | null | undefined
): boolean =>
  isNil(review)
    ? false
    : review.queryStatusCode ===
      REVIEW.QUERY_STATUS.REVIEW_QUERY_RESPONSE_RECEIVED

export const reviewerName = (
  review: Review | null | undefined
): string | null =>
  !!review && !!review.reviewer
    ? `${review.reviewer.firstName} ${review.reviewer.lastName}`
    : null
