import React from 'react'
import { Button, Popup, Responsive } from 'semantic-ui-react'
// Local
import { REVIEW } from '../../../constant'

/**
 * Provides an icon indicating review status. The icon should be generic per
 * user-role - it is just explaining the current status of the review
 * @param review Review entity with reviewCode property
 */

type Props = {
  reviewCode: keyof typeof REVIEW.CODE | null
}
const { CODE } = REVIEW

const lookup = {
  [CODE.OPEN]: {
    code: 'O',
    tooltip: 'This review is OPEN for CDI'
  },
  [CODE.READY_FOR_CDI_QA]: {
    code: 'CDQ',
    tooltip: 'This review has been reviewed by CDI and is ready for QA'
  },
  [CODE.READY_FOR_PHYSICIAN]: {
    code: 'P',
    tooltip: 'This review has been reviewed by CDI and QA\'d and is ready for PHYSICIAN'
  },
  [CODE.READY_FOR_CODER]: {
    code: 'CO',
    tooltip: 'This review has been seen by the PHYSICIAN and is ready for CODER'
  },
  [CODE.READY_FOR_CODER_QA]: {
    code: 'COQ',
    tooltip: 'This review has been reviewed by CODER and is ready for QA'
  },
  [CODE.COMPLETED]: {
    code: 'C',
    tooltip: 'This review has been COMPLETED'
  },
  [CODE.REMOVED]: {
    code: 'R',
    tooltip: 'This review has been REMOVED'
  },
  [CODE.LOCKED]: {
    code: 'L',
    tooltip: 'This review is LOCKED'
  }
}

function ReviewStatusIcon(props: Props): JSX.Element | null {
  const { reviewCode } = props
  if (!reviewCode) return null
  const { code, tooltip } = lookup[reviewCode]
  return (
    <Popup
      content={ tooltip }
      trigger={
        <Responsive as={ Button } icon basic size="mini">
          <strong>{ code }</strong>
        </Responsive>
      }
    />
  )
}

export default ReviewStatusIcon
