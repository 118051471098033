import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Form, Grid, Label, Message, Segment } from "semantic-ui-react";
import { DateInput } from "semantic-ui-calendar-react";
import RichTextEditor from "react-rte";
import filter from "lodash/filter";
import { parse, format } from "date-fns";
import { CONDITION, FORMAT } from "../../../constant";
import { cdiExpectedDiagnosisEnabled } from "../../common/currentUserUtils";
import { formatIcd10Code } from "../../common/formatUtils";
import { condition as conditionUtils } from "../../common/conditionUtils";
import { TITLE_ERROR, toolbarConfig } from "../condition/common";
import { NEW_RULE, ReasonHandler } from "../condition/reason";
import Icd10Search from "../condition/Icd10Search";

/**
 * Provides a condition editor for users with CDI role
 *
 * Note that saving the condition and the condition rules are two different
 * API requests. The save callback will need to handle this
 *
 * @param condition Condition entity
 * @param onCancel Callback for cancel
 *        Arguments: None
 * @param onSave Callback for condition save button click
 *        Arguments: Condition, Rules
 */
class CdiConditionEditor extends Component {

  constructor(props) {
    super(props);

    this.onClickSave = this.onClickSave.bind(this);

    const condition = this.props.condition;
    const cdiTags = condition.cdiTags;

    const editorContent = RichTextEditor.createValueFromString(
      condition.content ? condition.content : "", "markdown");
    const icd10Code = condition.icd10CodeJson
      ? condition.icd10CodeJson[0] : "";
    const rules = condition.rules
      ? [...JSON.parse(JSON.stringify(condition.rules))] : [];
    const addressedDate = condition.addressedDate
      ? format(this.props.condition.addressedDate, FORMAT.DATE) : "";
    const expectedDiagnosisCode = cdiTags && cdiTags.expectedDiagnosis
      ? cdiTags.expectedDiagnosis : "";
    const error = !condition.title || condition.title === "" ? TITLE_ERROR : null;

    this.state = {
      content: editorContent,
      icd10Code: icd10Code,
      title: condition.title,
      reason: condition.reason,
      rules: rules,
      addressedDate: addressedDate,
      expectedDiagnosisCode: expectedDiagnosisCode,
      error: error
    };
  }

  onChangeValue = (e, { name, value }) => {
    this.setState({ [name]: value });
  };

  onChangeContent = (content) => {
    this.setState({ content });
  };

  onSelectIcd10 = (result) => {
    this.setState({
      icd10Code: result.id,
      title: result.description
    });
  };

  onSelectExpectedDiagnosis = (result) => {
    this.setState({ expectedDiagnosisCode: result.id });
  };

  onClickSave() {
    let { content, icd10Code, title, rules, addressedDate, expectedDiagnosisCode } = this.state;

    if ( !title || title === "" ) {
      this.setState({ error: { description: TITLE_ERROR, field: "title" } });
      return;
    }

    const condition = this.props.condition;
    const cdiTags = condition.cdiTags ? condition.cdiTags : {};

    condition.title = title;
    condition.content = content.toString("markdown");
    condition.addressedDate = addressedDate ? parse(addressedDate) : null;

    const icd10Json = icd10Code && icd10Code.length > 0 ? [ icd10Code ] : [];
    condition.icd10CodeJson = icd10Json;

    if ( cdiTags.expectedDiagnosis ) {
      // update
      cdiTags.expectedDiagnosis = expectedDiagnosisCode;
      condition.cdiTags = cdiTags;
    } else {
      // create
      if ( expectedDiagnosisCode && expectedDiagnosisCode.length > 0 ) {
        cdiTags.expectedDiagnosis = expectedDiagnosisCode;
        condition.cdiTags = cdiTags;
      }
    }

    const conditionRules = filter(rules, (rule) => {
      // filter out new rules with empty reasons
      if ( rule.type === NEW_RULE ) {
        return !(rule.reason.length === 0 || !rule.reason.trim());
      } else {
        return true;
      }
    }).map((rule) => {
      rule.encounterConditionId = condition.id;
      if ( rule.type === NEW_RULE ) {
        rule.id = undefined;
        rule.type = undefined;
        rule.title = rule.reason;
        rule.statusCode = CONDITION.STATUS.ACTIVE;
      } else if ( rule.reason.length === 0 || !rule.reason.trim() ) {
        rule.statusCode = CONDITION.STATUS.REMOVED;
      }
      return rule;
    });

    condition.rules = conditionRules;

    this.props.onSave(condition);
  };

  render() {
    const currentUser = this.props.currentUser;
    const condition = this.props.condition;
    const showExpectedDiagnosis = conditionUtils.isPotential(condition) &&
      cdiExpectedDiagnosisEnabled(currentUser);

    let { icd10Code, title, content, rules, addressedDate,
      expectedDiagnosisCode, error } = this.state;
    return (
      <Grid.Row className="first-row" key={condition.id}>
        <Grid.Column>
          <Form error={error !== null}>
            {error !== null && <Message error>{error.description}</Message>}
            <Form.Group widths="equal">
              <Form.Input
                error={error !== null && error.field === "title"}
                name="title"
                label="Condition Title"
                placeholder="Title"
                value={title}
                onChange={this.onChangeValue} />
              <span className="field">
                <label>ICD10 Code</label>
                <Icd10Search
                  value={icd10Code}
                  onSelect={this.onSelectIcd10} />
              </span>
            </Form.Group>
            <span className="field">
              <label>Reasons</label>
              <ReasonHandler rules={rules || []} onChangeValue={this.onChangeValue} />
            </span>
            <span className="field">
              <label>Content</label>
              <RichTextEditor
                value={content}
                onChange={this.onChangeContent.bind(this)}
                toolbarConfig={toolbarConfig}
                />
            </span>
            <span className="field">
              <DateInput
                name="addressedDate"
                label="Addressed Date"
                placeholder="Addressed Date"
                value={addressedDate}
                onChange={this.onChangeValue.bind(this)}
                dateFormat={FORMAT.DATE} />
            </span>
            {showExpectedDiagnosis && (
              <Segment>
                <span className="field">
                  <label>Expected Diagnosis</label>
                  <Icd10Search
                    value={expectedDiagnosisCode}
                    onSelect={this.onSelectExpectedDiagnosis}
                    placeholder="Expected Diagnosis" />
                </span>
                <div className="m-1-top">
                  {expectedDiagnosisCode && (
                    <div>
                      <Label pointing="right">Expected Diagnosis Code</Label>
                      <span> {formatIcd10Code(expectedDiagnosisCode)}</span>
                    </div>
                  )}
                  <div className="m-1-top italic">
                    An expected diagnosis code associated with this condition
                    for the purpose of reporting. This diagnosis code is not
                    displayed to the practitioner or coder
                  </div>
                </div>
              </Segment>
            )}
            <div className="m-1-top text-align-right">
              <Button color="blue" type="button" onClick={this.onClickSave}>
                Update
              </Button>
              <Button type="button" onClick={this.props.onCancel}>
                Cancel
              </Button>
            </div>
          </Form>
        </Grid.Column>
      </Grid.Row>
    );
  }
}

export default connect(
  state => ({
    currentUser: state.user
  }), {}
)(CdiConditionEditor);
