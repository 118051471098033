export const UPDATE_LIST = "ICD10_UPDATE_LIST";

export const CLEAR_STATE = "ICD10_CLEAR_STATE";
export const CLEAR_ALL_STATE = "CLEAR_ALL_STATE";

const initState = {
  list: []
};

export default (state = initState, action) => {
  switch (action.type) {
    case CLEAR_STATE:
    case CLEAR_ALL_STATE:
      return initState;
    case UPDATE_LIST:
      return Object.assign({}, state, {
        list: action.payload
      });
    default:
      return state;
  }
};
