import React, { Dispatch, SetStateAction } from 'react'
// Local
import Icon from '../../v2/icon'
import ChevronDown from '../../icons/chevronDown'

type Props = {
  description: string
  isAddressed: boolean
  isRequired: boolean
  isDescriptionShowing: boolean
  setIsDescriptionShowing?: Dispatch<SetStateAction<boolean>>
}

function Title(props: Props): JSX.Element {
  const {
    description,
    isAddressed,
    isRequired,
    isDescriptionShowing,
    setIsDescriptionShowing
  } = props

  function onClick(): void {
    if (setIsDescriptionShowing) setIsDescriptionShowing(!isDescriptionShowing)
  }

  return (
    <div className="title-container" onClick={onClick}>
      <div className="title">
        <Icon isAddressed={isAddressed} isRequired={isRequired} />
        <div className="title-text">{description}</div>
      </div>
      <div
        className={`chevron-container is-${
          isDescriptionShowing ? 'down' : 'up'
        }`}
      >
        <ChevronDown />
      </div>
    </div>
  )
}

export default Title
