import React, { Component } from "react";
import { connect } from "react-redux";
import { Dropdown, Radio, Responsive } from 'semantic-ui-react';
import { CONDITION } from "../../../constant";
import { isSmartDisplay } from "../../common/displayUtils";
import { encounterPractitionerTagsEnabled, encounterPractitionerTagOptions } from "../../common/currentUserUtils"
import { conditionTagOptions, hasPractitionerTagOptions } from "../../common/practitionerTagUtils";

/**
 * Physician condition HCC header toolbar. Provides editors for encounter
 * condition practitioner tags intended for display in the an HCC condition
 * group header.
 *
 * The practitioner tag will apply to all of the conditions in the HCC group
 * and it is assumed that all of the conditions in the group have the same
 * practitioner tag
 *
 * @param mode View mode. Options: null or "smart". Default: null
 * @param conditions Array of conditions in the HCC group
 * @param onConditionAction Callback for update.
 *        Arguments: action, Array of conditions
 * @param mode View mode: smart or undefined. Default: undefined
 */
class PhysicianConditionHccHeaderToolbar extends Component {

  constructor(props) {
    super(props);
    this.onChangePractitionerTag = this.onChangePractitionerTag.bind(this);
    this.state = {
      conditions: this.props.conditions
    };
  }

  onChangePractitionerTag = (e, { value }) => {
    e.stopPropagation();
    const action = CONDITION.ACTION.UPDATE_PRACTITIONER_TAGS;
    const conditions = this.state.conditions.map((c) => {
      if ( c.practitionerTags ) {
        c.practitionerTags.practitionerResponseCode = value;
      } else {
        c.practitionerTags = {
          encounterConditionId: c.id,
          practitionerResponseCode: value
        }
      }
      return c;
    });
    this.setState({ conditions: conditions }, () => {
      this.props.onConditionAction(action, conditions);
    })
  }

  renderRadio(conditions, tagOptions) {
    const head = conditions[0];
    const currentTags = head.practitionerTags;
    const value = currentTags && currentTags.practitionerResponseCode
      ? currentTags.practitionerResponseCode : "";
    const name = `${head.id}-practitionerResponseCode`;

    return (
      <span className="physician-condition-toolbar-header">
        {tagOptions && tagOptions.map((option, i) => {
          return (
            <span key={`${head.id}-${option.key}`}>
              <Responsive as={Radio}
                className={i > 0 ? "m-12px-left" : ""}
                label={option.text}
                name={name}
                value={option.value}
                checked={option.value === value}
                onChange={this.onChangePractitionerTag} />
            </span>
          );
        })}
      </span>
    );
  }

  renderDropdown(conditions, tagOptions) {
    const head = conditions[0];
    const currentTags = head.practitionerTags;
    const value = currentTags && currentTags.practitionerResponseCode
      ? currentTags.practitionerResponseCode : "";

    return (
      <span className="physician-condition-toolbar-header">
        <Responsive as={Dropdown} selection
          placeholder="Mark As"
          onChange={this.onChangePractitionerTag}
          options={tagOptions}
          value={value} />
      </span>
    );
  }

  renderPractitionerTags(conditions) {
    const currentUser = this.props.currentUser;
    const tagsEnabled = encounterPractitionerTagsEnabled(currentUser);
    const tagOptions = encounterPractitionerTagOptions(currentUser);
    if ( tagsEnabled === true && hasPractitionerTagOptions(tagOptions, conditions) ) {
      return isSmartDisplay(this.props)
        ? this.renderDropdown(conditions, conditionTagOptions(conditions[0], tagOptions))
        : this.renderRadio(conditions, conditionTagOptions(conditions[0], tagOptions));
    } else {
      return null;
    }
  }

  render() {
    const conditions = this.state.conditions;
    if ( conditions && conditions.length > 0 ) {
      return this.renderPractitionerTags(conditions);
    } else {
      return null;
    }
  }
}

export default connect(
  state => ({
    currentUser: state.user
  }), {}
)(PhysicianConditionHccHeaderToolbar);


