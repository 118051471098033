import React, { Component } from "react";
import { Icon, Message, Popup } from "semantic-ui-react";
import { REVIEW } from "../../../constant";
import { withOutstandingQuery } from "../../common/conditionUtils";
import ConditionQueryStatusIcon from "./conditionQueryStatusIcon";

/**
 * Provides a component that renders the encounter Coder QA message
 * @param review Encounter review entity
 * @param conditions Encounter conditions
 * @param mode Render mode. Default: left
 */
class EncounterCoderQa extends Component {

  renderLeft(review, conditions) {
    if ( review ) {
      const queryOutstanding = withOutstandingQuery(conditions);
      const reviewQueryOutstanding = review.queryStatusCode === REVIEW.QUERY_STATUS.REVIEW_QUERY_OUTSTANDING;
      return (
        <div>
          <Message warning className="cdi">
            <div className="bold">Coder Patient Review</div>
            {review.reviewCode === REVIEW.CODE.READY_FOR_CODER_QA && (
              <div>Ready for Coder QA</div>
            )}
            {review.reviewCode === REVIEW.CODE.COMPLETED && (
              <div>Completed</div>
            )}
            {queryOutstanding && queryOutstanding.length > 0 && (
              <div>
                <ConditionQueryStatusIcon
                condition={queryOutstanding[0]}
                popup="One or More Conditions with Outstanding Queries" />
                <span> </span>
                <span>Outstanding Query</span>
              </div>
            )}
            {reviewQueryOutstanding && (
              <div>
                <Popup content="Coder Review Requested"
                  trigger={<Icon name="hourglass half" color="black" />} />
                <span> </span>
                <span>Coder Review Requested</span>
              </div>
            )}
          </Message>
        </div>
      );
    } else {
      return null;
    }
  }

  render() {
    const mode = this.props.mode ? this.props.mode : "left";
    if ( mode === "left" ) {
      return this.renderLeft(this.props.review, this.props.conditions);
    } else {
      return null;
    }
  }
}

export default EncounterCoderQa;
