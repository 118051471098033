import React, { Dispatch, SetStateAction } from 'react'
// Local
import Icon from './icon'
import ChevronDown from '../icons/chevronDown'
import { EncounterConditionDisplayModified } from '../authenticated/types'
import { isNil, pathOr } from 'ramda'
import {
  capitalizeFirstLetter,
  formatIcd10Code,
  removeEmptyValues
} from './utils/utils'

type Props = {
  description: string
  isAddressed: boolean
  isRequired: boolean
  isDescriptionShowing: boolean
  setIsDescriptionShowing?: Dispatch<SetStateAction<boolean>>
  condition: EncounterConditionDisplayModified
}

function Title(props: Props): JSX.Element {
  const {
    description,
    isAddressed,
    isRequired,
    isDescriptionShowing,
    setIsDescriptionShowing,
    condition
  } = props

  function onClick(): void {
    if (setIsDescriptionShowing) setIsDescriptionShowing(!isDescriptionShowing)
  }

  const icd10CodeJsonToString: string = isNil(condition.icd10CodeJson)
      ? ''
      : condition.icd10CodeJson.map(formatIcd10Code).join(', ')

  return (
    <div className="title-container" onClick={onClick}>
      <div className="title">
        <Icon isAddressed={isAddressed} isRequired={isRequired} />
        <div className="title-text">{description.replace('Suspect: ', '')}</div>
      </div>
      <div
        className={`chevron-container is-${
          isDescriptionShowing ? 'down' : 'up'
        }`}
      >
        <ChevronDown />
      </div>
    </div>
  )
}

export default Title
