import React, { Component } from "react";
import {format} from "date-fns";
import { CONDITION, FORMAT } from "../../../constant";

/**
 * Provides a component that renders the condition addressed date, if it is
 * defined.
 * @param condition Condition entity
 */
class ConditionAddressedDate extends Component {

  render() {
    const conditionCode = this.props.condition.conditionCode;
    const addressedDate = this.props.condition.addressedDate;
    if ( conditionCode === CONDITION.CODE.ADDRESSED && addressedDate ) {
      return (
        <div className="m-1-top">
          <span className="italic">
            <span>Condition last addressed on</span>
            <span> </span>
            <span>{format(addressedDate, FORMAT.DATE)}</span>
          </span>
        </div>
      );
    } else {
      return null;
    }
  }
}

export default ConditionAddressedDate;
